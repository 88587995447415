import { createError } from 'nuxt/app';
import { defineStore } from 'pinia';
import { COOKIE_KEYS } from 'seed-core';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import { fetchMenuAuthApi } from '@/apis/member.api';
import { fetchSimpleMyInformationApi } from '@/apis/my-info.api';
import { useCookieWithDomain } from '@/composables/useCookieWithDomain';
import { useSeedCore } from '@/composables/useSeedCore';
import { PAGE_NOT_FOUND_404 } from '@/constants/error.const';
import { Confirmation, MenuAuthorityIds } from '@/enums/common.enum';
import type { MemberResponseModel } from '@/types/member/member-model.type';
import type { SimpleGroupResponse } from '@/types/my-info/my-info-response.type';
import { snakeToCamel } from '@/utils/api.util';
import { parseJwt } from '@/utils/jwt.util';

export const useUserStore = defineStore('userStore', () => {
  const accessToken = useCookieWithDomain(COOKIE_KEYS.ACCESS_TOKEN || '');
  const memberNo = parseJwt(accessToken.value || '')?.member_no;

  const route = useRoute();
  const isMember = ref<boolean>(true);
  const userInfo = ref<MemberResponseModel>();
  const joinedGroups = ref<SimpleGroupResponse[]>();
  const selectedGroupId = ref<string>('');
  const selectedGroupName = ref<string>('');
  const selectedGroupInfo = ref<SimpleGroupResponse>();
  const fetchMemberInfo = async () => {
    const { authService: { getMemberInfo } } = useSeedCore();
    const queryProperties =
        '["jid", "country_cd","person_verify_yn","parent_verify_yn","user_id","email","nickname","member_no","email_verify_fn","reg_dt","profile_img","sex","birth_dt"]';
    const memberInfo = await getMemberInfo(queryProperties);
    if (!memberInfo) {
      throw createError({
        statusCode: PAGE_NOT_FOUND_404,
        message: 'Page not found'
      });
    }

    // use after login by STOVE
    userInfo.value = snakeToCamel(memberInfo);
  };
  const setMemberOrNot = (isMemberOrNot: boolean) => {
    isMember.value = isMemberOrNot;
  };
  const setUserNickname = (nickname: string) => {
    userInfo.value = {
      ...userInfo.value,
      nickname,
      memberNo
    };
  };

  const findReadableGroupHome = async () => {
    const groups = joinedGroups.value ?? [];
    let result = '';
    for (const group of groups) {
      const auth = await fetchMenuAuthApi({
        groupId: group.groupId,
        menuAuthorityId: MenuAuthorityIds.GROUP_HOME,
        isOwner: group.ownerYn === Confirmation.YES
      });
      if (auth.isReadable) {
        result = group.groupId;
        break;
      }
    }

    return result;
  };

  const setGroupData = (groupId: string) => {
    if (groupId) {
      const group = joinedGroups.value?.find((g: SimpleGroupResponse) => g.groupId === groupId);
      if (group) {
        selectedGroupName.value = group.groupName;
        selectedGroupInfo.value = { ...group };
        selectedGroupId.value = groupId;
      }
    }
  };

  const fetchSimpleMyInfos = async (newGroupId?: string) => {
    let groupId: string;
    const data = await fetchSimpleMyInformationApi();
    if (data) {
      joinedGroups.value = data.groups ?? [];
      setUserNickname(data.nickname);
      if (newGroupId) {
        groupId = newGroupId;
      } else {
        groupId = route.params.groupId?.toString() ?? (await findReadableGroupHome());
      }
      setGroupData(groupId);
    }
  };

  const isOwner = computed(() => selectedGroupInfo.value?.ownerYn === Confirmation.YES);

  return {
    accessToken,
    userInfo,
    joinedGroups,
    selectedGroupId,
    selectedGroupName,
    selectedGroupInfo,
    fetchMemberInfo,
    setGroupData,
    fetchSimpleMyInfos,
    findReadableGroupHome,
    setUserNickname,
    setMemberOrNot,
    isMember,
    isOwner
  };
});

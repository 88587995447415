// https://wiki.smilegate.net/pages/viewpage.action?pageId=427845764#WDB03.0124059902.%EC%83%81%ED%83%9C%EA%B0%92-%EB%A9%94%EB%89%B4%EB%AA%85-%EA%B7%B8%EB%A3%B9%EB%8B%A8%EC%9C%84

export enum NotificationId {
  CONTRACTION = 'CONTRACTION',
  VERIFICATION = 'VERIFICATION',
  BILL_SETTLEMENT = 'BILL_SETTLEMENT'
}

export enum HistoryType {
  GROUP_MEMBER_INVITE = 'GROUP_MEMBER_INVITE',
  GROUP_MEMBER_JOIN = 'GROUP_MEMBER_JOIN',
  GROUP_MEMBER_REJECT = 'GROUP_MEMBER_REJECT',
  GROUP_MEMBER_MODIFY = 'GROUP_MEMBER_MODIFY',
  GROUP_MEMBER_KICK = 'GROUP_MEMBER_KICK', // Kick
  GROUP_MEMBER_LEAVE = 'GROUP_MEMBER_LEAVE', // Self-exit
  GROUP_MEMBER_ROLE_MODIFY = 'GROUP_MEMBER_ROLE_MODIFY',
  GROUP_MEMBER_MENU_AUTH_MODIFY = 'GROUP_MEMBER_MENU_AUTH_MODIFY',
  GROUP_MEMBER_NOTIFICATION_MODIFY = 'GROUP_MEMBER_NOTIFICATION_MODIFY'
}

export enum HistoryModifyType {
  MENU_AUTH_ADD = 'MENU_AUTH_ADD',
  MENU_AUTH_REMOVE = 'MENU_AUTH_REMOVE',
  ROLE_ADD = 'ROLE_ADD',
  ROLE_REMOVE = 'ROLE_REMOVE'
}

export enum MemberInviteStatus {
  APPLY = 'APPLY'
}

import { useSeedCore } from '@/composables/useSeedCore';
import { getConfigs, host, redirectTo } from '@/utils/common.util';

export const isLogin = () => {
  const {
    authService: { isAuthentication }
  } = useSeedCore();
  return isAuthentication();
};

export const logout = () => {
  const {
    authService: { logout: scLogout }
  } = useSeedCore();
  scLogout('');
};

export const goToLoginPage = async (redirectUrl: string) => {
  const { AUTH_URL } = getConfigs().SEED_CORE;
  await redirectTo(
    `${AUTH_URL}/login?redirect_url=${host()}${encodeURIComponent(redirectUrl)}&style_type=studio`,
    { external: true, withLocale: false }
  );
};

export const goToPolicyStudioBirthPage = async (redirectUrl: string) => {
  const { POLICY_STUDIO_BIRTH_URL } = getConfigs();
  return await redirectTo(`${POLICY_STUDIO_BIRTH_URL}?redirect_url=${host()}${redirectUrl}`, {
    external: true,
    withLocale: false
  });
};
export const goToStudioOnStoveAccountVerificationPage = async (redirectUrl: string) => {
  const { SEED_CORE } = getConfigs();
  return await redirectTo(
    `${SEED_CORE.AUTH_URL}/studio/verification?style_type=studio&redirect_url=${host()}${redirectUrl}`,
    { external: true, withLocale: false }
  );
};

import { CurrencyCode } from '@/enums/currency-code.enum';

export const currencyCodes: CurrencyCode[] = Object.entries(CurrencyCode).map(
  ([, value]: [string, CurrencyCode]) => value
);

export const USD_DIGITS_NUM = 2;
export const KOREAN_DIGITS_NUM = -2;

export const BASE_EXCHANGE_RATE = 1;
export const DEFAULT_CURRENCY_CODE: CurrencyCode = CurrencyCode.Krw;

export const DEFAULT_CURRENCY_LANG_CODE: string = 'ko-KR';

export const COUNTRY_BY_CURRENCY = {
  USD: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_usd_msg',
  KRW: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_krw_msg',
  CNY: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_cny_msg',
  EUR: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_eur_msg',
  GBP: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_gbp_msg',
  HKD: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_hkd_msg',
  IDR: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_idr_msg',
  JPY: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_jpy_msg',
  MYR: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_myr_msg',
  PHP: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_php_msg',
  SGD: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_sgd_msg',
  THB: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_thb_msg',
  VND: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_vnd_msg'
} as const;

export const NON_DIGIT_CURRENCY_CODES = [
  CurrencyCode.Krw,
  CurrencyCode.Idr,
  CurrencyCode.Jpy,
  CurrencyCode.Cny,
  CurrencyCode.Vnd
];

export enum Confirmation {
  YES = 'Y',
  NO = 'N'
}

export enum Sex {
  MALE = 'M',
  FEMALE = 'F'
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum NationsAddress {
  KOREA = 'KR',
  JAPAN = 'JP',
  TAIWAN = 'TW',
  VIETNAM = 'VN',
  USA = 'US',
  CANADA = 'CA',
  OTHER = 'OTHER'
}

export enum Position {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum MenuAuthorityIds {
  GROUP_HOME = 'GROUP_HOME',
  PROJECT_PRODUCT = 'PROJECT_PRODUCT',
  COLLECTION = 'COLLECTION',
  MEMBER_MANAGEMENT = 'MEMBER_MANAGEMENT',
  BIMS_BANK_SETTING = 'BIMS_BANK_SETTING',
  STORE_COMMUNITY = 'STORE_COMMUNITY',
  BILL_SETTLEMENT = 'BILL_SETTLEMENT',
  STATISTICS = 'STATISTICS',
  GAME_MAINTENANCE = 'GAME_MAINTENANCE'
}

export enum Permission {
  WRITE = 'READ_WRITE',
  READ = 'READ',
  NONE = 'NONE',
  READ_AND_WRITE = 'READ_AND_READ_WRITE' // For permission check in member invite history page
}

export enum ApprovalStatus {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT'
}

export enum CommunityType {
  INDIE = 'indie',
  POSTSCRIPT = 'postscript'
}

export enum LocationCode {
  CURRENT = 'CURRENT',
  ALL = 'ALL',
  MAIN = 'MAIN'
}

export enum ProductVerifyStatus {
  NONE = 'NONE',
  REQUEST = 'REQUEST',
  START = 'START',
  PASS = 'PASS',
  REJECT = 'REJECT'
}

export const STOVE_EMAIL = {
  STORE_SUPPORT: 'store.support@smilegate.com'
};

export const STOVE_LINK = {
  DISCORD_INVITE: 'https://discord.com/invite/jdRFRQJrHa'
};

export enum CheckboxStateEnum {
  SELECT_ALL = 'SELECT_ALL',
  INDETERMINATE = 'INDETERMINATE'
}

export enum TranslationFormatType {
  HTML = 'html',
  TEXT = 'text'
}

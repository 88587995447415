import { createError, defineNuxtRouteMiddleware } from 'nuxt/app';
import { storeToRefs } from 'pinia';

import { PAGE_NOT_FOUND_404 } from '@/constants/error.const';
import { useAppStore } from '@/stores/app.store';

export default defineNuxtRouteMiddleware(async () => {
  const appStore = await useAppStore();
  const { hasApiError } = storeToRefs(appStore);
  if (hasApiError.value) {
    throw createError({
      statusCode: PAGE_NOT_FOUND_404,
      message: 'Page not found'
    });
  }
});

<template>
  <div>
    <component
      :is="dialog?.component"
      v-for="dialog in appDialogs"
      v-bind="dialog?.props || {}"
      :key="dialog.id"
      :visible="true"
      :draggable="false"
      :modal="dialog.id === activeDialog?.id"
      :class="{
        'opacity-0': dialog.id !== activeDialog?.id && dialog.id !== activeNotAlertDialog?.id
      }"
      @update:visible="handleUpdateVisible(dialog, $event)"
      @close="handleClose(dialog, $event)"
    />
  </div>
</template>

<script setup lang="ts">
import {
  activeDialog,
  activeNotAlertDialog,
  appDialogs,
  closeDialog
} from '@/composables/useDialog';
import type { AppDialog } from '@/types/app.type';

const handleClose = (dialog: AppDialog, result: any) => {
  closeDialog(dialog, result);
};

const handleUpdateVisible = (dialog: AppDialog, isVisible: boolean) => {
  if (isVisible) {
    return;
  }

  handleClose(dialog, null);
};
</script>

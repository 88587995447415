import validate from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45handle_45error_45middleware_45global from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/01.handle-error.middleware.global.ts";
import _02_45handle_45locale_45middleware_45global from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/02.handle-locale.middleware.global.ts";
import manifest_45route_45rule from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45handle_45error_45middleware_45global,
  _02_45handle_45locale_45middleware_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "build-detail-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/build-detail.middleware.ts"),
  "build-live-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/build-live.middleware.ts"),
  "check-agree-term-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-agree-term.middleware.ts"),
  "check-bank-register-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-bank-register.middleware.ts"),
  "check-business-infos-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-business-infos.middleware.ts"),
  "check-business-register-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-business-register.middleware.ts"),
  "check-invalid-group-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-invalid-group.middleware.ts"),
  "check-invalid-product-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-invalid-product.middleware.ts"),
  "check-login-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-login.middleware.ts"),
  "check-member-register-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-member-register.middleware.ts"),
  "check-menu-readable-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-menu-readable.middleware.ts"),
  "check-menu-writable-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-menu-writable.middleware.ts"),
  "check-product-platform-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-product-platform.middleware.ts"),
  "check-product-readable-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-product-readable.middleware.ts"),
  "check-product-type-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-product-type.middleware.ts"),
  "check-product-writable-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-product-writable.middleware.ts"),
  "check-user-age-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/check-user-age.middleware.ts"),
  "discount-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/discount.middleware.ts"),
  "go-to-brand-page-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/go-to-brand-page.middleware.ts"),
  "handle-product-data-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/handle-product-data.middleware.ts"),
  "member-wait-list-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/member-wait-list.middleware.ts"),
  "my-group-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/my-group.middleware.ts"),
  "not-found-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/not-found.middleware.ts"),
  "redirect-middleware": () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/middleware/redirect.middleware.ts")
}
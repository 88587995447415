import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { type RouteMeta, useRoute } from 'vue-router';

import { fetchMenuAuthApi, getMemberProductRoleApi } from '@/apis/member.api';
import { showAlert } from '@/composables/useDialog';
import { DEFAULT_COVERAGES } from '@/constants/locale.const';
import type { ROLE_ID } from '@/constants/permission.const';
import { DEVELOPERS_STORE_INTRO_PATH } from '@/constants/url.const';
import { Permission } from '@/enums/common.enum';
import { LanguageCode } from '@/enums/language-code.enum';
import { useUserStore } from '@/stores/user.store';
import type { RoleAuthority } from '@/types/member/member-response.type';
import { getConfigs, redirectTo } from '@/utils/common.util';
import { isLogin } from '@/utils/user.utils';

export function useApp() {
  const userStore = useUserStore();
  const route = useRoute();

  const { t, locale } = useI18n();

  const routeMeta = ref<RouteMeta>({});
  const routeParams = ref<any>({});
  const { selectedGroupId, isOwner } = storeToRefs(userStore);

  const showTutorialGuide = ref(true);

  const getMenuPermission = async (menuAuthId: string) => {
    const auth = await fetchMenuAuthApi({
      groupId: selectedGroupId.value,
      menuAuthorityId: menuAuthId,
      isOwner: isOwner.value
    });
    return auth;
  };

  const checkMenuWritable = async (params?: { menuAuthId?: string; redirectUrl?: string }) => {
    const menuAuthId = params?.menuAuthId ?? routeMeta.value.menuAuthId;

    const auth = await getMenuPermission(menuAuthId as string);

    if (!auth.isWritable) {
      await showAlert({ content: t('studio.common.popup_case_h_not_edit') });
      if (params?.redirectUrl) {
        await redirectTo(params.redirectUrl);
      }
      return false;
    }
    return true;
  };

  const checkProductPermission = async (params?: {
    checkType?: 'read' | 'write';
    roleId?: string;
    productId?: string;
  }) => {
    const roleAuthorityId = (params?.roleId ?? routeMeta.value.roleAuthorityId) as typeof ROLE_ID;

    const { checkType = 'write', roleId = roleAuthorityId } = params || {};
    if (checkType === 'read') {
      const menuAuthId = routeMeta.value.menuAuthId as string;
      const menuAuth = await getMenuPermission(menuAuthId);
      if (!menuAuth.isReadable) {
        await showAlert({ content: t('studio.common.popup_case_g_not_view') });
        return false;
      }
    }

    const userStore = useUserStore();
    const { isOwner } = storeToRefs(userStore);
    if (isOwner.value) {
      return true;
    }

    const groupId = routeParams.value.groupId.toString();
    const productId = params?.productId ?? routeParams.value.productId;

    let authorities: RoleAuthority[] = [];
    try {
      const role = await getMemberProductRoleApi({ groupId, productNo: Number(productId) });
      authorities = role?.memberRole?.authorities ?? [];
    } catch (error) {
      // console.log('error :>> ', error);
    }

    if (authorities.length > 0 && !roleId) {
      // Product home
      return true;
    }
    const auth = authorities.find((auth: RoleAuthority) => auth.roleAuthorityId === roleId);
    if (checkType === 'read' && !auth?.permission.includes(Permission.READ)) {
      await showAlert({ content: t('studio.common.popup_case_g_not_view') });
      return false;
    }
    if (checkType === 'write' && auth?.permission !== Permission.WRITE) {
      await showAlert({ content: t('studio.common.popup_case_h_not_edit') });
      return false;
    }
    return true;
  };

  watch(
    route,
    () => {
      routeMeta.value = route.meta;
      routeParams.value = route.params;
      showTutorialGuide.value =
        route.meta.showTutorial === undefined || route.meta.showTutorial === true;
    },
    { immediate: true }
  );

  const changeLocale = async (locale: string, external: boolean = false): Promise<void> => {
    let url = location.pathname;
    for (const langCode of DEFAULT_COVERAGES) {
      if (location.pathname.startsWith(`/${langCode}`)) {
        url = url.replace(`/${langCode}`, `/${locale}`);
        break;
      }
    }
    redirectTo(url, { withLocale: false, external, query: route.query });
  };

  const getDocsUrl = () => {
    const configs = getConfigs();

    const { ADMIN_DEVELOPER_API_URL, DEVELOPERS_DOMAIN } = configs as any;

    const currentLocale = locale.value.toLowerCase();
    let localePath = '';

    // Only ko, en are supported. If not ko, default to en path (but ja has its own path)
    if (currentLocale !== LanguageCode.Ko) {
      // All other languages (except ko) default to English
      localePath = `/${LanguageCode.En}`;
    }

    const baseUrl = `${DEVELOPERS_DOMAIN}${localePath}${DEVELOPERS_STORE_INTRO_PATH}`;

    if (isLogin() && baseUrl && ADMIN_DEVELOPER_API_URL) {
      return `${ADMIN_DEVELOPER_API_URL}/login/onstove/callback?originalRedirect=${baseUrl}`;
    }

    return baseUrl;
  };

  return {
    checkMenuWritable,
    showTutorialGuide,
    getMenuPermission,
    checkProductPermission,
    changeLocale,
    getDocsUrl
  };
}

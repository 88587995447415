import type { PlanningCalendarEvent } from '@/types/calendar.type';

// TODO: check in RELEASE_STATUS if we have real BE api data
export const RELEASE_PLAN_INSPECTION = {
  REQUEST: 'Request',
  REQUESTING: 'Requesting',
  PASSED: 'Passed',
  REJECTED: 'Rejected'
};

export const RELEASE_PLAN_STATUS = {
  COMING_SOON: 1,
  PRE_SALE: 2
};

export const RELEASE_METHODS = {
  OFFICIAL_RELEASE: 1,
  ONLY_PRODUCT_PAGE: 2
};

export const RELEASE_PLAN_PRODUCT_STATUS = {
  BEFORE_REQUIRED_ITEM_ENTERED: 1,
  BEFORE_REQUESTING_REVIEW: 2,
  REQUESTED_REVIEW: 3,
  UNDER_REVIEW: 4,
  OPERATOR_REJECTED_SANCTIONED: 5,
  REVIEW_COMPLETED: 6,
  RELEASE_LIVE: 7,
  RELEASE_STOPPED: 8,
  CUSTOM_ERROR: 9,
  RELEASE_RESTRICT: 10
};

export const RELEASE_PLAN_PRODUCT_SETTINGS_STATUS = {
  IN_COMPLETED: 1,
  COMPLETED: 2
};

export const RELEASE_PLAN_PREPARING_PRODUCT_STATUS = {
  NEED_TO_WRITE: 1,
  COMPLETED: 2,
  ISSUE_REJECT: 3
};

export const RELEASE_PLAN_LIMITED_SALES_STATUS = {
  NONE: 'NONE',
  COUNT: 'COUNT',
  DATE: 'DATE'
};

export const PRODUCT_LAUNCH_TYPE = {
  PAGE: 'PAGE',
  PAGE_BUILD: 'PAGE_BUILD'
} as const;

export const PLAY_OPTION = {
  ALL: 'ALL',
  STOVE_MEMBER: 'MEMBER_NO',
  IP: 'IP',
  NONE: 'NONE'
} as const;

export const RELEASE_PLAN_FILTER_CALENDAR = {
  ALL: 'ALL',
  PRODUCT: 'PRODUCT',
  BUILD: 'BUILD',
  PRICE: 'PRICE'
};

export const RELEASE_DISCOUNT_DAYS_DEFAULT = 1;
export const RELEASE_DISCOUNT_DAYS_MAX = 99;

export const RELEASE_DISCOUNT_RATE_DEFAULT = 1;
export const RELEASE_DISCOUNT_RATE_MAX = 100;

export const RELEASE_TIME_FORMAT = {
  COMING_SOON: 'COMING_SOON',
  YEAR_MONTH_DAY: 'YEAR_MONTH_DAY',
  YEAR_MONTH: 'YEAR_MONTH',
  YEAR_QUARTER: 'YEAR_QUARTER',
  YEAR: 'YEAR'
};

export const EXTRA_FEATURES = {
  IG_IN_GAME_STORE: 'IG',
  TR_TERM_AND_CONDITIONS: 'TR',
  AC_CHALLENGES: 'AC',
  LB_RANKING: 'LB',
  CS_CLOUD_SAVING: 'CS',
  MM_MATCHMAKING: 'MM',
  PC_PURCHASE_CONDITION: 'PC'
};

export const EXTRA_FEATURES_KEY: Record<string, string> = {
  [EXTRA_FEATURES.IG_IN_GAME_STORE]:
    'studio.home.release_timeline.prod_pg_item.community_ingame_store',
  [EXTRA_FEATURES.TR_TERM_AND_CONDITIONS]:
    'studio.home.release_timeline.prod_pg_item.community_term',
  [EXTRA_FEATURES.AC_CHALLENGES]:
    'studio.home.release_timeline.prod_pg_item.community_achievements',
  [EXTRA_FEATURES.LB_RANKING]: 'studio.home.release_timeline.prod_pg_item.community_ranking',
  [EXTRA_FEATURES.CS_CLOUD_SAVING]:
    'studio.home.release_timeline.prod_pg_item.community_cloud_saving',
  [EXTRA_FEATURES.MM_MATCHMAKING]:
    'studio.home.release_timeline.prod_pg_item.community_match_making',
  [EXTRA_FEATURES.PC_PURCHASE_CONDITION]: 'studio.prod_home.pur_cond.pur_cond'
};

export const RELEASE_PLAN_CHECK_LIST_STATUS = {
  NOT_WRITTEN: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  REJECT: 3,
  HIDDEN: 4
} as const;

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458924186
export const RELEASE_PLAN_REVIEW_REJECTION_INFORMATION_VERIFY_TYPE = {
  PAGE_RELEASE: 'PAGE_RELEASE',
  PAGE_BUILD_RELEASE: 'PAGE_BUILD_RELEASE',
  RATING_BUILD: 'RATING_BUILD',
  PRICE: 'PRICE',
  DELETE: 'DELETE'
} as const;

export const RELEASE_PLAN_VERIFY_STATUS_TARGET_TYPE = {
  PRODUCT_SETTING: 'PRODUCT_SETTING',
  PRODUCT_PAGE: 'PRODUCT_PAGE',
  PRICE: 'PRICE',
  BUILD_RATING: 'BUILD_RATING',
  ETC: 'ETC'
} as const;

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458924181
export const RELEASE_PLAN_REQUEST_VERIFY_STATUS = {
  PAGE_RELEASE: 'PAGE_RELEASE',
  PAGE_BUILD_RELEASE: 'PAGE_BUILD_RELEASE',
  RATING_BUILD: 'RATING_BUILD',
  PRICE: 'PRICE'
} as const;

export const CLOUD_SAVING_SAVE_TARGET_FILE_TYPE = {
  ALL: 'ALL',
  DESIGNATION: 'DESIGNATION',
  EXTENSION: 'EXTENSION'
} as const;

export const RELEASE_PLAN_BUILD_OPTION_WEB_MOBILE_DEFAULT_VALUE = 'NONE';

export const CALENDAR_RELEASE_PLAN_STATUS = {
  REQUEST: 'REQUEST',
  VERIFY: 'VERIFY',
  COMPLETE: 'COMPLETE',
  REJECT: 'REJECT',
  LIVE: 'LIVE',
  STOP: 'STOP'
};

export const listStatus = [
  {
    value: CALENDAR_RELEASE_PLAN_STATUS.REQUEST,
    label: '검수요청',
    color: '#7C9CE1',
    bg: '#B8CEFF'
  },
  { value: CALENDAR_RELEASE_PLAN_STATUS.VERIFY, label: '검수중', color: '#D79A00', bg: '#FFC226' },
  {
    value: CALENDAR_RELEASE_PLAN_STATUS.COMPLETE,
    label: '검수완료',
    color: '#228F43',
    bg: '#37A458'
  },
  {
    value: CALENDAR_RELEASE_PLAN_STATUS.REJECT,
    label: '반려/제재',
    color: '#EC3B47',
    bg: '#EC3B47'
  },
  { value: CALENDAR_RELEASE_PLAN_STATUS.LIVE, label: 'LIVE 적용', color: '#1355FF', bg: '#1355FF' },
  { value: CALENDAR_RELEASE_PLAN_STATUS.STOP, label: '중단/삭제', color: '#999C9F', bg: '#BBBFC3' }
] as PlanningCalendarEvent[];

export const RELEASE_CHECKLIST_ID_NAVIGATOR = {
  PREPARE_PRODUCT_PAGE: 'NAVIGATOR_PREPARE_PRODUCT_PAGE',
  PRODUCT_SETTING: 'NAVIGATOR_PRODUCT_SETTING',
  PRODUCT_PAGE: 'NAVIGATOR_PRODUCT_PAGE',
  //
  PRODUCT_PAGE_OPTIONS: 'NAVIGATOR_PRODUCT_PAGE_OPTIONS',
  STORE_COMMUNITY: 'NAVIGATOR_STORE_COMMUNITY',
  EARLY_ACCESS: 'NAVIGATOR_EARLY_ACCESS',
  //
  BUILD_PREPARATION: 'NAVIGATOR_BUILD_PREPARATION',
  EXECUTION_SETTINGS: 'NAVIGATOR_EXECUTION_SETTINGS',
  UPLOAD_BUILD_FILE: 'NAVIGATOR_UPLOAD_BUILD_FILE',
  AGE_RATING_AND_BUILD: 'NAVIGATOR_AGE_RATING_AND_BUILD',
  //
  GAME_SUPPORT_SERVICE_SETTINGS: 'NAVIGATOR_GAME_SUPPORT_SERVICE_SETTINGS',
  IN_GAME_STORE: 'NAVIGATOR_IN_GAME_STORE',
  PRODUCT_TERMS: 'NAVIGATOR_PRODUCT_TERMS',
  ACHIEVEMENTS: 'NAVIGATOR_ACHIEVEMENTS',
  RANKINGS: 'NAVIGATOR_RANKINGS',
  CLOUD_SAVING: 'NAVIGATOR_CLOUD_SAVING',
  MATCH_MAKING: 'NAVIGATOR_MATCH_MAKING',
  //
  PRICE_SETTINGS: 'NAVIGATOR_PRICE_SETTINGS',
  //
  PURCHASE_CONDITIONS: 'NAVIGATOR_PURCHASE_CONDITIONS',
  // RELEASE_PLAN
  RELEASE_PLAN: 'NAVIGATOR_RELEASE_PLAN',
  STORE_PAGE_OPEN: 'NAVIGATOR_STORE_PAGE_OPEN',
  PRE_PURCHASE: 'NAVIGATOR_PRE_PURCHASE',
  OFFICIAL_RELEASE: 'NAVIGATOR_OFFICIAL_RELEASE',
  // BUSINESS INFORMATION
  BUSINESS_INFORMATION: 'NAVIGATOR_BUSINESS_INFORMATION',
  BANK_INFORMATION: 'NAVIGATOR_BANK_INFORMATION'
};

export const RELEASE_CHECKLIST_ID = {
  PREPARE_PRODUCT_PAGE: 'PREPARE_PRODUCT_PAGE',
  PRODUCT_SETTING: 'PRODUCT_SETTING',
  PRODUCT_PAGE: 'PRODUCT_PAGE',
  //
  PRODUCT_PAGE_OPTIONS: 'PRODUCT_PAGE_OPTIONS',
  STORE_COMMUNITY: 'STORE_COMMUNITY',
  EARLY_ACCESS: 'EARLY_ACCESS',
  //
  BUILD_PREPARATION: 'BUILD_PREPARATION',
  EXECUTION_SETTINGS: 'EXECUTION_SETTINGS',
  UPLOAD_BUILD_FILE: 'UPLOAD_BUILD_FILE',
  AGE_RATING_AND_BUILD: 'AGE_RATING_AND_BUILD',
  //
  GAME_SUPPORT_SERVICE_SETTINGS: 'GAME_SUPPORT_SERVICE_SETTINGS',
  IN_GAME_STORE: 'IN_GAME_STORE',
  PRODUCT_TERMS: 'PRODUCT_TERMS',
  ACHIEVEMENTS: 'ACHIEVEMENTS',
  RANKINGS: 'RANKINGS',
  CLOUD_SAVING: 'CLOUD_SAVING',
  MATCH_MAKING: 'MATCH_MAKING',
  //
  PRICE_SETTINGS: 'PRICE_SETTINGS',
  //
  PURCHASE_CONDITIONS: 'PURCHASE_CONDITIONS',
  // RELEASE_PLAN
  RELEASE_PLAN: 'RELEASE_PLAN',
  STORE_PAGE_OPEN: 'STORE_PAGE_OPEN',
  PRE_PURCHASE: 'PRE_PURCHASE',
  OFFICIAL_RELEASE: 'OFFICIAL_RELEASE',
  // BUSINESS INFORMATION
  BUSINESS_INFORMATION: 'BUSINESS_INFORMATION',
  BANK_INFORMATION: 'BANK_INFORMATION'
} as const;

<template>
  <div class="stds-dialog-header">
    <s-text role="title7" as="h1">
      <!-- @slot DialogHeader 헤드라인 -->
      <slot></slot>
    </s-text>
    <button v-if="isCloseButtonShown" class="stds-dialog-header-close-btn" @click="onClick">
      <s-icon icon="ic-v2-control-close-line" size="4xl" srOnlyText="창닫기" />
    </button>
  </div>
</template>
<script setup lang="ts">
withDefaults(
  defineProps<{
    isCloseButtonShown?: boolean;
  }>(),
  {
    isCloseButtonShown: true
  }
);

const emit = defineEmits<{
  clickClose: [v: void];
}>();

const onClick = () => {
  emit('clickClose');
};
</script>

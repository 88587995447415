// eslint-disable-next-line import/no-named-as-default
import Big from 'big.js';
import { type CurrencyInputOptions } from 'vue-currency-input';

import { DEFAULT_CURRENCY_LANG_CODE } from '@/constants/currency-codes.const';
import { CurrencyCode, CurrencyCodeFormat } from '@/enums/currency-code.enum';

export const getVndDigitsPrice = (value: number): number => {
  return (
    Math.trunc(
      Big(value ?? 0)
        .div(500)
        .toNumber()
    ) * 500
  );
};

export const getUpVndDigitsPrice = (value: number): number => {
  return (
    Math.ceil(
      Big(value ?? 0)
        .div(500)
        .toNumber()
    ) * 500
  );
};

export const getDigitsPrice = (value: number, digits: number = 0): number => {
  if (digits > 0) {
    const powerOf10 = 10 ** digits;
    return (
      Math.floor(
        Number(
          Big(value ?? 0)
            .times(powerOf10)
            .toPrecision(15)
        )
      ) / powerOf10
    );
  }

  const powerOf10 = 10 ** (Math.abs(digits) - 1);
  return (
    Math.trunc(
      Big(value ?? 0)
        .div(powerOf10)
        .toNumber()
    ) * powerOf10
  );
};

export const getDigitsFormatNumber = (
  price: number,
  currencyCode: CurrencyCode,
  digits: number
): number => {
  return currencyCode === CurrencyCode.Vnd
    ? getVndDigitsPrice(price)
    : getDigitsPrice(price, digits);
};

export const getExchangeLocalPrice = (price: number, exchangeRate: number): number => {
  return Big(price ?? 0)
    .times(exchangeRate ?? 1)
    .toNumber();
};

export const getLocalPrice = ({
  standardPrice,
  currencyCode,
  exchangeRate,
  displayDigitsNum
}: {
  standardPrice: number;
  currencyCode?: CurrencyCode;
  exchangeRate?: number;
  displayDigitsNum?: number;
}): number => {
  if (!standardPrice || !currencyCode || !exchangeRate || !displayDigitsNum) {
    return standardPrice;
  }

  if (typeof standardPrice !== 'number') {
    return 0;
  }

  return getDigitsFormatNumber(
    getExchangeLocalPrice(standardPrice, exchangeRate),
    currencyCode,
    displayDigitsNum
  );
};

export const getKrwDigitsPrice = (
  standardPrice: number,
  currencyCode: CurrencyCode,
  exchangeRate: number
): number => {
  if (currencyCode === CurrencyCode.Krw) {
    return standardPrice;
  }

  // Convert KRW to target currency
  return Big(standardPrice ?? 0)
    .div(exchangeRate ?? 1)
    .round(getMinimumFractionDigits(currencyCode), 0) // Round to the minimum fraction digits of the target currency
    .toNumber();
};

export const getDiscountPrice = (price: number, rate: number): number => {
  return Big(rate ?? 0)
    .div(100)
    .times(price ?? 0)
    .toNumber();
};

export const getDiscountedPrice = (price: number, rate: number): number => {
  return Big(price ?? 0)
    .minus(getDiscountPrice(price, rate))
    .toNumber();
};

export const isKRW = (currencyCode: CurrencyCode): boolean => {
  return currencyCode === CurrencyCode.Krw;
};

export const getMinimumFractionDigits = (currencyCode: CurrencyCode): number =>
  [
    CurrencyCode.Krw,
    CurrencyCode.Idr,
    CurrencyCode.Jpy,
    CurrencyCode.Cny,
    CurrencyCode.Vnd
  ].includes(currencyCode)
    ? 0
    : 2;

export const currencyAmount = (
  amount: number,
  langCd: string = DEFAULT_CURRENCY_LANG_CODE,
  option: Intl.NumberFormatOptions = {},
  currencyCode: string
) => {
  if (!amount) {
    return '-';
  }

  return new Intl.NumberFormat(langCd, { style: 'currency', currency: currencyCode, ...option })
    .format(amount)
    .replace(/[^0-9,.]/g, '');
};

export const getCurrencyInputOptions = (options: CurrencyInputOptions): CurrencyInputOptions => {
  return {
    hideCurrencySymbolOnFocus: true,
    hideGroupingSeparatorOnFocus: true,
    precision: isKRW(options.currency as CurrencyCode) ? 0 : 2,
    autoDecimalDigits: false,
    valueRange: { min: 0, max: 999999999999999 },
    ...options
  };
};

export const getNumberFormatOptions = (currencyCode: string): Intl.NumberFormatOptions => {
  return {
    minimumFractionDigits: getMinimumFractionDigits(currencyCode as CurrencyCode)
  };
};

export const formatPrice = (price: number, currencyCode?: string): string | number => {
  if (!currencyCode) {
    return 0;
  }

  const langCd = CurrencyCodeFormat[currencyCode as keyof typeof CurrencyCodeFormat];
  return price
    ? currencyAmount(price, langCd, getNumberFormatOptions(currencyCode), currencyCode)
    : 0;
};

export const formatNumberMultipleWithCommas = (number: number, separator: string = ',') => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const parseFormattedNumber = (formattedNumber: string, separator: string = ','): number => {
  const regex = new RegExp(`\\${separator}`, 'g');
  const numberString = formattedNumber.replace(regex, '');
  return parseFloat(numberString);
};

export const getDisplayPrice = (
  standardPrice: number,
  exchangeRate?: number,
  displayDigitsNum?: number,
  currencyCode?: CurrencyCode
) => {
  if (!exchangeRate || !displayDigitsNum || !currencyCode) {
    return 0;
  }

  return getLocalPrice({ standardPrice, currencyCode, exchangeRate, displayDigitsNum });
};

export const convertCurrencyToSymbol = (currencyCode: CurrencyCode): string => {
  switch (currencyCode) {
    case CurrencyCode.Krw:
      return '₩';
    case CurrencyCode.Usd:
      return '$';
    case CurrencyCode.Jpy:
    case CurrencyCode.Cny:
      return '¥';
    case CurrencyCode.Vnd:
      return '₫';
    case CurrencyCode.Idr:
      return 'Rp';
    case CurrencyCode.Eur:
      return '€';
    case CurrencyCode.Gbp:
      return '£';
    case CurrencyCode.Hkd:
      return 'HK$';
    case CurrencyCode.Myr:
      return 'RM';
    case CurrencyCode.Php:
      return '₱';
    case CurrencyCode.Sgd:
      return 'S$';
    case CurrencyCode.Thb:
      return '฿';
    default:
      return '';
  }
};

export const getRoundedDownMoney = (value: number, hundreds?: boolean) => {
  if (hundreds) {
    return Math.floor(value / 100) * 100;
  }
  return Math.floor(value / 10) * 10;
};

export const getRoundedUp = (value: number, digits: number): number => {
  const powerOf10 = digits === 0 ? 1 : 10 ** digits;
  return Math.ceil(value / powerOf10) * powerOf10;
};

export const truncateToDecimals = (value: number, digits: number): number => {
  const powerOf10 = digits === 0 ? 1 : 10 ** digits;
  return (
    Math.floor(
      Number(
        Big(value ?? 0)
          .times(powerOf10)
          .toPrecision(15)
      )
    ) / powerOf10
  );
};

import { checkProperty } from '@/utils/object.util';

const timerCodes: any = {};

export const clearTime = (key: string = 'default'): void => {
  if (checkProperty(timerCodes, key) && timerCodes[key] > -1) {
    window.clearTimeout(timerCodes[key]);
    delete timerCodes[key];
  }
};

/**
 * delayTime 메서드
 *
 * @param milliseconds
 * @param key          여러개의 setTimeout 생성 시 이벤트를 추적해서 제거할 수 있는 키값( 스코프 기능 )
 */
export const delayTime = (milliseconds: number, key: string = 'default'): Promise<boolean> => {
  clearTime(key);

  return new Promise((resolve: any) => {
    timerCodes[key] = window.setTimeout(() => {
      resolve(true);

      clearTime(key);
    }, milliseconds);
  });
};

export const diffHours = (date: number): number => {
  const today = new Date();
  const compareDate = new Date(date);
  const diff = today.getTime() - compareDate.getTime();
  const diffHours = diff / (1000 * 3600);
  return diffHours;
};

export const diffDays = (date: number): number => {
  const today = new Date();
  const compareDate = new Date(date);
  const diff = today.getTime() - compareDate.getTime();
  const diffDays = diff / (1000 * 3600 * 24);
  return diffDays;
};

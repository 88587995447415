import { useRequest } from '@/composables/useRequest';
import { API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod } from '@/enums/api.enum';
import { Confirmation } from '@/enums/common.enum';
import { fetchProductsRelationMapper } from '@/mappers/products.mapper';
import type { SuccessResponse } from '@/types/common/common.type';
import type { Pagination } from '@/types/common/pagination.type';
import type {
  ProductDemoSelectRequest,
  ProductMetaRequest,
  ProductSearchRequest,
  ProductSettingRequest
} from '@/types/product/product-request.type';
import type {
  CheckProductSettingReleaseResponse,
  CountingProductResponse,
  ProductMetaResponse,
  ProductRelationResponse,
  ProductResponse,
  ProductSanctionInquiryResponse,
  ProductSearchItem,
  ProductStatusResponse,
  ProjectProductRelation
} from '@/types/product/product-response.type';
import type { ProductListByProjectResponse } from '@/types/project-product/project-product-response.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

// https://wiki.smilegate.net/pages/viewpage.action?pageId=450851961
export const fetchProductsInfoApi = async (
  productNo: string,
  showLoading: boolean = true
): Promise<ProductResponse | undefined> => {
  const { data } = await useRequest<ProductResponse>(`${apiUrl}/products/${productNo}`, {
    headers: {
      ...generateHeader(
        'X-Nation',
        'X-Timezone',
        'X-Utc-Offset',
        'X-Lang',
        'X-Client-Lang',
        'X-UUID',
        'caller-id'
      )
    },
    showCommonError: false,
    showLoading
  });

  return data;
};

export const fetchProductsRelationApi = async (
  groupId: string
): Promise<ProjectProductRelation[]> => {
  const { data } = await useRequest<
    ProjectProductRelation[],
    { products: ProductRelationResponse[] }
  >(
    `${apiUrl}/products/relation`,
    {
      headers: { ...generateHeader('X-Nation', 'X-Lang') },
      params: camelToSnake({ groupId })
    },
    fetchProductsRelationMapper
  );

  return data || [];
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=450852099
export const updateProductApi = async (
  productNo: number,
  productSetting: ProductSettingRequest
): Promise<SuccessResponse | undefined> => {
  const { data } = await useRequest<SuccessResponse>(`${apiUrl}/products/${productNo}`, {
    method: ApiMethod.Put,
    data: camelToSnake(productSetting),
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435955181
export const fetchProductListByProjectApi = async (
  params: {
    projectId: string;
    q?: string;
    sort?: string;
    direction?: string;
    page?: number;
    size?: number;
  },
  showLoading: boolean = true
): Promise<ProductListByProjectResponse | undefined> => {
  const { projectId, q, sort, direction, page, size } = params;
  const { data } = await useRequest<ProductListByProjectResponse>(
    `${apiUrl}/projects/${projectId}/products`,
    {
      headers: {
        ...generateHeader('X-Nation', 'X-Lang')
      },
      params: {
        q,
        sort: sort || 'CREATED_AT',
        direction: direction || 'ASC',
        page: page || 1,
        size: size || 10
      },
      showLoading
    }
  );

  const res: ProductListByProjectResponse = {
    projectId,
    size: data?.size || 10,
    page: data?.page || 1,
    last: data?.last || false,
    first: data?.first || false,
    totalElements: data?.totalElements || 0,
    totalPages: data?.totalPages || 0,
    number: data?.number || 0,
    contents: data?.contents || []
  };

  return res;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=462590230
export const searchProductsPrecessionApi = async (
  productNo: number,
  params: ProductSearchRequest,
  showLoading: boolean = true
): Promise<Pagination<ProductSearchItem[]> | undefined> => {
  const res = await useRequest<Pagination<ProductSearchItem[]>>(
    `${apiUrl}/products/${productNo}/precession/search`,
    {
      showLoading,
      headers: { ...generateHeader('X-Lang', 'X-Nation') },
      method: ApiMethod.Get,
      params: camelToSnake({
        ...params,
        planStatus: params.planStatus ? params.planStatus.join(',') : undefined
      })
    }
  );

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=447757438
export const searchProductsApi = async (
  params: ProductSearchRequest,
  showLoading: boolean = true
): Promise<Pagination<ProductSearchItem[]> | undefined> => {
  const res = await useRequest<Pagination<ProductSearchItem[]>>(`${apiUrl}/products/search`, {
    showLoading,
    headers: { ...generateHeader('X-Lang', 'X-Nation') },
    method: ApiMethod.Get,
    params: params.planStatus
      ? camelToSnake({ ...params, planStatus: params.planStatus.join(',') })
      : camelToSnake(params)
  });

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=462591449
export const searchProductsDemoSelectApi = async (
  params: ProductDemoSelectRequest,
  showLoading: boolean = true
): Promise<Pagination<ProductSearchItem[]> | undefined> => {
  const { data } = await useRequest<Pagination<ProductSearchItem[]>>(
    `${apiUrl}/products/demo-select`,
    {
      showLoading,
      headers: { ...generateHeader('X-Lang', 'X-Nation') },
      method: ApiMethod.Get,
      params: camelToSnake(params)
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=461501765
export const checkProductSettingReleaseApi = async (productNo: string): Promise<boolean> => {
  const { data } = await useRequest<CheckProductSettingReleaseResponse>(
    `${apiUrl}/products/check/release`,
    {
      method: ApiMethod.Get,
      params: camelToSnake({ productNo })
    }
  );

  return data?.releaseAvailableYn === Confirmation.YES;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+game+services+%7C+counting+by+product+no
export const fetchProductCountingApi = async (
  productNo: string
): Promise<CountingProductResponse | undefined> => {
  const { data } = await useRequest<CountingProductResponse>(
    `${apiUrl}/products/${productNo}/game-service/count`,
    {
      method: ApiMethod.Get
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+release+%7C+import+whitelist
export const importWhitelistApi = async (
  productNo: string,
  type: string,
  file: File
): Promise<SuccessResponse | undefined> => {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await useRequest<SuccessResponse>(`${apiUrl}/products/${productNo}/whitelist`, {
    method: ApiMethod.Post,
    params: camelToSnake({ type }),
    data: formData,
    showCommonError: true
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=447762949
export const fetchProductsMetaApi = async (
  params: ProductMetaRequest
): Promise<ProductMetaResponse | undefined> => {
  const { data } = await useRequest<ProductMetaResponse>(`${apiUrl}/products/meta`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    params: camelToSnake(params),
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=474622610
export const fetchProductSanctionInquiryApi = async (
  productNo: string | number
): Promise<ProductSanctionInquiryResponse | undefined> => {
  const { data } = await useRequest<ProductSanctionInquiryResponse>(
    `${apiUrl}/products/${productNo}/restrict`,
    {
      method: ApiMethod.Get,
      showCommonError: false
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=507578599
export const fetchProductStatusApi = async (
  productNo: number
): Promise<ProductStatusResponse | undefined> => {
  const url = `${apiUrl}/products/${productNo}/status`;
  const { data } = await useRequest<ProductStatusResponse>(url, { showCommonError: false });

  return data;
};

export enum RuleNames {
  URL = 'url',
  REQUIRED = 'required',
  EMAIL = 'email',
  NO_SP_CHARACTER = 'no_sp_character',
  NUMBER = 'number',
  NO_SPACE = 'no_space',
  BETWEEN = 'between',
  MIN_LENGTH = 'min_length',
  MAX_LENGTH = 'max_length',
  MAX_CHAR_COUNT = 'max_char_count',
  LIKE = 'like',
  LTE = 'lte',
  LT = 'lt',
  GTE = 'gte',
  GT = 'gt',
  EQUAL = 'equal',
  NOT_EQUAL = 'not_equal',
  MIN_DATE = 'min_date',
  MAX_DATE = 'max_date',
  REGEX = 'regex',
  MAX_SIZE = 'max_size',
  EXTENSION = 'extension',
  FILE_COUNT = 'file_count',
  DIMENSION = 'dimension',
  ASYNC_RULE = 'async_rule',
  CHECK_DUPLICATE = 'check_duplicate',
  MULTIPLY_BY_TEN = 'multiply_by_ten', // use for generateErrorMessages custom
  LESS_THAN_CURRENT_TIME = 'less_than_current_time' // use for generateErrorMessages custom
}

export enum ErrorViewTypes {
  POPUP = 'popup',
  MESSAGE = 'message'
}

export enum InputTextTypes {
  Text = 'text',
  Number = 'number'
}

import revive_payload_client_4sVQNw7RlN from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import _01_error_C3ZZmsECDo from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/plugins/01.error.ts";
import _02_seed_core_plugin_2ohDlzPzq8 from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/plugins/02.seed-core.plugin.ts";
import click_outside_plugin_nA7Ltgy0ge from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/plugins/click-outside.plugin.ts";
import end_scroll_plugin_viGmwvXMpJ from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/plugins/end-scroll.plugin.ts";
import handle_api_plugin_Y8xH15rT2w from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/plugins/handle-api.plugin.ts";
import sentry_client_plugin_bDoMRpNTRe from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/plugins/sentry-client.plugin.ts";
import uploadable_plugin_LLBPL6kgoY from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/plugins/uploadable.plugin.ts";
import vee_validate_plugin_K20oxML7FZ from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/plugins/vee-validate.plugin.ts";
import vue_froala_plugin_0twh9tzE7X from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/plugins/vue-froala.plugin.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  _01_error_C3ZZmsECDo,
  _02_seed_core_plugin_2ohDlzPzq8,
  click_outside_plugin_nA7Ltgy0ge,
  end_scroll_plugin_viGmwvXMpJ,
  handle_api_plugin_Y8xH15rT2w,
  sentry_client_plugin_bDoMRpNTRe,
  uploadable_plugin_LLBPL6kgoY,
  vee_validate_plugin_K20oxML7FZ,
  vue_froala_plugin_0twh9tzE7X
]
export enum LanguageCode {
  En = 'en',
  Ko = 'ko',
  ZhTw = 'zh-tw',
  ZhCn = 'zh-cn',
  ZhHk = 'zh-hk',
  Ja = 'ja',
  De = 'de',
  Fr = 'fr',
  Es = 'es',
  Pt = 'pt',
  Vi = 'vi',
  Id = 'id',
  Th = 'th',
  It = 'it',
  Ru = 'ru',
  Ms = 'ms',
  Ar = 'ar',
  Nl = 'nl',
  Pl = 'pl',
  Cs = 'cs',
  Tr = 'tr',
  Etc = 'etc'
}

export const JPG_MIME_TYPE = 'image/jpg';
export const JPEG_MIME_TYPE = 'image/jpeg';
export const PNG_MIME_TYPE = 'image/png';
export const GIF_MIME_TYPE = 'image/gif';
export const WEBP_MIME_TYPE = 'image/webp';
export const ICO_MIME_TYPE = 'image/x-icon';
export const ICO_MS_MIME_TYPE = 'image/vnd.microsoft.icon';
export const MP4_MIME_TYPE = 'video/mp4';
export const WEBM_MIME_TYPE = 'video/webm';
export const OLD_EXCEL_TYPE = '.xls';
export const NEW_EXCEL_TYPE = '.xlsx';

export const IMAGE_TYPE = {
  JPG: 'jpg',
  JPEG: 'jpeg',
  PNG: 'png',
  GIF: 'gif',
  WEBP: 'webp',
  ICO: 'ico'
};

export const VIDEO_TYPE = {
  MP4: 'mp4',
  WEBM: 'webm'
};

export const MAP_FILE_TYPE_TO_MINE_TYPE: Record<string, string> = {
  jpg: JPG_MIME_TYPE,
  jpeg: JPEG_MIME_TYPE,
  png: PNG_MIME_TYPE,
  gif: GIF_MIME_TYPE,
  webp: WEBP_MIME_TYPE,
  ico: ICO_MIME_TYPE,
  mp4: MP4_MIME_TYPE,
  webm: WEBM_MIME_TYPE
} as const;

export const ARRAY_FILE_TYPE: Record<string, string> = {
  [JPG_MIME_TYPE]: 'jpg',
  [JPEG_MIME_TYPE]: 'jpeg',
  [PNG_MIME_TYPE]: 'png',
  [GIF_MIME_TYPE]: 'gif',
  [WEBP_MIME_TYPE]: 'webp',
  [ICO_MIME_TYPE]: 'ico',
  [ICO_MS_MIME_TYPE]: 'ico',
  [MP4_MIME_TYPE]: 'mp4',
  [WEBM_MIME_TYPE]: 'webm'
};

export const IMAGE_FILE_EXTENSION = {
  PNG: '.png',
  JPG: '.jpg',
  JPEG: '.jpeg',
  GIF: '.gif',
  WEBP: '.webp',
  ICO: '.ico'
};

export const ARRAY_IMAGE_FILE_EXTENSION_WITHOUT_ICO = [
  JPG_MIME_TYPE,
  JPEG_MIME_TYPE,
  PNG_MIME_TYPE,
  GIF_MIME_TYPE,
  WEBP_MIME_TYPE
];

export const ARRAY_IMAGE_FILE_EXTENSION = [
  ...ARRAY_IMAGE_FILE_EXTENSION_WITHOUT_ICO,
  ICO_MIME_TYPE,
  ICO_MS_MIME_TYPE
];

export const VIDEO_FILE_EXTENSION = {
  MP4: '.mp4',
  WEBM: '.webm'
};

export const ARRAY_VIDEO_FILE_EXTENSION = [MP4_MIME_TYPE];

export const ARRAY_VIDEO_FILE_EXTENSION_WITH_WEBM = [...ARRAY_VIDEO_FILE_EXTENSION, WEBM_MIME_TYPE];

export const IMAGE_MIMES_TYPE = {
  PNG: PNG_MIME_TYPE,
  JPG: JPG_MIME_TYPE,
  JPEG: JPEG_MIME_TYPE,
  GIF: GIF_MIME_TYPE,
  WEBP: WEBP_MIME_TYPE
} as const;

export const IMAGE_MIMES_TYPE_WITHOUT_GIF = {
  PNG: PNG_MIME_TYPE,
  JPG: JPG_MIME_TYPE,
  JPEG: JPEG_MIME_TYPE,
  WEBP: WEBP_MIME_TYPE
} as const;

export const FILE_EXTENSION = {
  ...IMAGE_FILE_EXTENSION,
  ...VIDEO_FILE_EXTENSION
};

export const EXCEL_FILE_EXTENSION = [OLD_EXCEL_TYPE, NEW_EXCEL_TYPE];

export const IMAGE_EXTENSION: string[] = Object.values(IMAGE_MIMES_TYPE);
export const IMAGE_EXTENSION_WITHOUT_GIF: string[] = Object.values(IMAGE_MIMES_TYPE_WITHOUT_GIF);

export const FILE_STORAGE_MINES_TYPE = [
  ...ARRAY_IMAGE_FILE_EXTENSION,
  ...ARRAY_VIDEO_FILE_EXTENSION
];

export const IMAGE_SIZE = [
  {
    name: 'studio.prod_pg.img_edit_popup.img_type1_prod_pg_bg_img',
    width: 1920,
    height: 520
  },
  {
    name: 'studio.prod_pg.img_edit_popup.img_type2_display_vid_thumbnail',
    width: 860,
    height: 483
  },
  {
    name: 'studio.prod_pg.img_edit_popup.img_type3_display_img',
    width: 860,
    height: 483
  },
  {
    name: 'studio.prod_pg.img_edit_popup.img_type4',
    width: 500,
    height: 500
  },
  {
    name: 'studio.prod_pg.img_edit_popup.img_type5',
    width: 756,
    height: 426
  },
  {
    name: 'studio.prod_pg.img_edit_popup.img_type6_title_bg_img',
    width: 1290,
    height: 180
  },
  {
    name: 'studio.prod_pg.img_edit_popup.img_type7_one_line_intro_img',
    width: 558,
    height: 132
  },
  {
    name: 'studio.prod_pg.img_edit_popup.img_type8_award_img',
    width: 860,
    height: 483
  },
  {
    name: 'Image after achieving the challenge/ Image before achieving the challenge',
    width: 512,
    height: 512
  },
  {
    name: 'studio.prod_pg.img_edit_popup.img_type9',
    width: 960,
    height: 882
  },
  {
    name: 'studio.prod_pg.img_edit_popup.img_type10',
    width: 366,
    height: 771
  },
  {
    name: 'studio.prod_pg.img_edit_popup.img_type11',
    width: 960,
    height: 426
  },
  {
    name: 'studio.prod_pg.img_edit_popup.img_type12_pc_logo_img',
    width: 40,
    height: 40
  },
  {
    name: 'studio.prod_pg.img_edit_popup.img_type13_pc_recommended_img',
    width: 1950,
    height: 630
  }
];

export const IMAGE_SIZE_TYPE = {
  PRODUCT_PAGE_BACKGROUND: '1920x520',
  EXHIBITION_VIDEO_THUMBNAIL: '860x483',
  EXHIBITION_VIDEO_IMAGE: '860x483',
  ONE_ONE_IMAGE: '500x500',
  SIXTEEN_NINE_IMAGE: '757x426',
  TITLE_BACKGROUND: '1290x180',
  ONE_LINE_INTRODUCTION: '558x132',
  AWARD_IMAGE: '860x483',
  CHALLENGE_IMAGE: '512x512',
  SQUARE_IMAGE: '960x881',
  VERTICAL_IMAGE: '366x771',
  HORIZONTAL_IMAGE: '960x426',
  PC_CLIENT_GAME_LOGO: '40x40',
  PC_CLIENT_RECOMMENDED: '1950x630'
} as const;

export const SIZE_UNIT = 1024;
export const ONE_GIGABYTES = SIZE_UNIT * SIZE_UNIT * SIZE_UNIT;
export const TEN_GIGABYTES = 10 * ONE_GIGABYTES;
export const EIGHTEEN_GIGABYTES = 18 * ONE_GIGABYTES;
export const ONE_MEGABYTES = SIZE_UNIT * SIZE_UNIT;
export const TEN_MEGABYTES = 10 * ONE_MEGABYTES;
export const ONE_HUNDRED_MEGABYTES = 100 * ONE_MEGABYTES;
export const BYTES_TEXT = 'Bytes';
export const GB_TEXT = 'GB';
export const MB_TEXT = 'MB';
export const KB_TEXT = 'KB';

export const MB = 1024 * 1024;
export const GB = MB * 1024;

export const MAX_LENGTH_FILE_NAME = 50;

export const BUSINESS_IMAGE_EXTENSION = ['png', 'jpg', 'jpeg', 'pdf'];

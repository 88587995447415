export enum ApiMethod {
  Post = 'POST',
  Get = 'GET',
  Put = 'PUT',
  Delete = 'DELETE',
  Patch = 'PATCH'
}
export enum HeaderTypes {
  XNation = 'X-Nation',
  XTimezone = 'X-Timezone',
  XUtcOffset = 'X-Utc-Offset',
  XLang = 'X-Lang',
  XClientLang = 'X-Client-Lang',
  XUuId = 'X-UUID',
  CallerId = 'caller-id',
  CallerDetail = 'caller-detail'
}

import { CALENDAR_RELEASE_PLAN_STATUS } from '@/constants/release-plan.const';
import type { FormOptionGroup } from '@/types/common/form.type';
import type { ProductReleasePlanType } from '@/types/release-plan/release-plan.type';

export const PLAN_PRODUCTS_STATUS = {
  LIVE: 'live',
  NEXT: 'next'
} as const;

export const PRODUCT_CONDITION_TYPE = {
  AND: 'AND',
  OR: 'OR'
} as const;

export const PRODUCT_TYPE_DETAIL = {
  GAME_BASIC: 'game-basic',
  GAME_DEMO: 'game-demo',
  GAME_DLC: 'game-dlc',
  UTILITY_BASIC: 'utility-basic',
  UTILITY_DEMO: 'utility-demo',
  UTILITY_DLC: 'utility-dlc',
  CONTENT_BASIC: 'content-basic',
  CONTENT_DEMO: 'content-demo',
  CONTENT_DLC: 'content-dlc'
};

export const LINKAGE_PRODUCT_TYPE = {
  GAME: 'GAME',
  DEMO: 'DEMO',
  DLC: 'DLC',
  CONTENTS: 'CONTENTS',
  UTILITY: 'UTILITY'
};

export type LinkageProductType = (typeof LINKAGE_PRODUCT_TYPE)[keyof typeof LINKAGE_PRODUCT_TYPE];

export const PRODUCT_TYPE = {
  GAME: 'Game',
  DEMO: 'Demo',
  DLC: 'DLC',
  CONTENT: 'Content',
  UTILITY: 'Utility'
} as const;

export type ProductType = (typeof PRODUCT_TYPE)[keyof typeof PRODUCT_TYPE];
export const PLAN_STATUS = {
  NONE: 'NONE',
  READY: 'READY',
  PAGE_OPEN: 'PAGE_OPEN',
  PRE_PURCHASE: 'PRE_PURCHASE',
  RELEASE: 'RELEASE'
} as const;

export const SALE_START_TYPE = {
  PRE_PURCHASE: 'PRE_PURCHASE',
  RELEASE: 'RELEASE'
} as const;

export const SALE_STATUS = {
  NONE: 'NONE',
  READY: 'READY',
  START: 'START',
  STOP: 'STOP',
  END: 'END'
} as const;

export const VERIFY_STATUS = {
  NONE: 'NONE',
  REQUEST: 'REQUEST',
  START: 'START',
  PASS: 'PASS',
  REJECT: 'REJECT'
} as const;

export const RESTRICT_STATUS = {
  NONE: 'NONE',
  RUN: 'RUN',
  DISPLAY: 'DISPLAY',
  REPORT: 'REPORT',
  PURCHASE: 'PURCHASE',
  COLLECTION_PURCHASE: 'COLLECTION_PURCHASE',
  RATING_DISPLAY: 'RATING_DISPLAY',
  RATING_RUN: 'RATING_RUN'
} as const;

export const DELETE_REQUEST_STATUS = {
  NONE: 'NONE',
  REQUEST: 'REQUEST',
  START: 'START',
  PASS: 'PASS',
  REJECT: 'REJECT'
} as const;

export const RELEASE_STATUS = {
  NONE: '-',
  UNDER_REVIEW: 'UnderReview',
  WAITING_FOR_RELEASE: 'WaitingForRelease',
  ON_SALE: 'OnSale',
  DISCONTINUED: 'Discontinued'
} as const;

export const AFTER_RELEASE_PLAN = {
  PAGE: 'page',
  BUILD: 'build',
  SALE: 'sale'
} as const;

export const SERVER_PRODUCT_TYPE = {
  GAME: 'GAME',
  CONTENTS: 'CONTENTS',
  UTILITY: 'UTILITY'
} as const;

export const COLLECTION_PRODUCT_TYPE = {
  ...SERVER_PRODUCT_TYPE,
  COLLECTION: 'COLLECTION'
};

export const PRODUCT_DETAIL_TYPE = {
  BASIC: 'BASIC',
  DLC: 'DLC',
  SOUND: 'SOUND',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO'
} as const;

export const PRODUCT_CONTENT_DETAIL_TYPE = {
  SOUND: 'SOUND',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO'
} as const;

export const DETAIL_TYPE_OPTIONS: FormOptionGroup[] = [
  {
    label: 'Sound',
    value: PRODUCT_CONTENT_DETAIL_TYPE.SOUND,
    tooltip: 'studio.prj_prod.this_prod.home_product_setting_content_sound_hover_msg'
  },
  {
    label: 'Image',
    value: PRODUCT_CONTENT_DETAIL_TYPE.IMAGE,
    tooltip: 'studio.prj_prod.this_prod.home_product_setting_content_image_hover_msg'
  },
  {
    label: 'Video',
    value: PRODUCT_CONTENT_DETAIL_TYPE.VIDEO,
    tooltip: 'studio.prj_prod.this_prod.home_product_setting_content_video_hover_msg'
  }
];

export const PRODUCT_RELEASE_PLAN_TYPE = {
  PRODUCT_PAGE_VERIFY_REQUEST: 'PRODUCT_PAGE_VERIFY_REQUEST', // Request for product page verification
  PRODUCT_PAGE_VERIFY: 'PRODUCT_PAGE_VERIFY', // Product page verification in progress
  PRODUCT_PAGE_VERIFY_PASS: 'PRODUCT_PAGE_VERIFY_PASS', // Product page verification complete
  PRODUCT_PAGE_VERIFY_REJECT: 'PRODUCT_PAGE_VERIFY_REJECT', // Product page verification rejected
  PRODUCT_PAGE_LIVE: 'PRODUCT_PAGE_LIVE', // Product page is about to open
  PRODUCT_PAGE_OPEN: 'PRODUCT_PAGE_OPEN', // Product page open
  PRODUCT_DISPLAY_OFF: 'PRODUCT_DISPLAY_OFF', // Stop opening product pages
  RELEASE_VERIFY_REQUEST: 'RELEASE_VERIFY_REQUEST', // Request for official release review
  RELEASE_VERIFY: 'RELEASE_VERIFY', // Under review for official release
  RELEASE_VERIFY_PASS: 'RELEASE_VERIFY_PASS', // Official release review completed
  RELEASE_VERIFY_REJECT: 'RELEASE_VERIFY_REJECT', // Official release review rejected
  RELEASE_LIVE: 'RELEASE_LIVE', // Awaiting release
  RELEASE: 'RELEASE', // Official release
  PRODUCT_SALE_OFF: 'PRODUCT_SALE_OFF', // Official release sales stopped
  RESTRICT_RATING: 'RESTRICT_RATING', // Review sanctions
  RESTRICT_RUN: 'RESTRICT_RUN', // Run sanction
  RESTRICT_PURCHASE: 'RESTRICT_PURCHASE', // Purchase sanctions
  RESTRICT_DISPLAY: 'RESTRICT_DISPLAY', // Display sanctions
  RESTRICT_REPORT: 'RESTRICT_REPORT', // Report sanctions
  PRICE_VERIFY_REQUEST: 'PRICE_VERIFY_REQUEST', // Price Verification Request
  PRICE_VERIFY: 'PRICE_VERIFY', // Price verification in progress
  PRICE_VERIFY_PASS: 'PRICE_VERIFY_PASS', // Price verification completed
  PRICE_VERIFY_REJECT: 'PRICE_VERIFY_REJECT', // Price verification rejected
  RATING_BUILD_VERIFY_REQUEST: 'RATING_BUILD_VERIFY_REQUEST', // Request for review of the build rating
  RATING_BUILD_VERIFY: 'RATING_BUILD_VERIFY', // Build verification for usage rating
  RATING_BUILD_VERIFY_PASS: 'RATING_BUILD_VERIFY_PASS', // Usage rating build review completed
  RATING_BUILD_VERIFY_REJECT: 'RATING_BUILD_VERIFY_REJECT', // Build Verification Rejected for Usage Rating
  PRODUCT_DELETE_REQUEST: 'PRODUCT_DELETE_REQUEST', // Product deletion request
  PRODUCT_DELETE_REJECT: 'PRODUCT_DELETE_REJECT', // Product deletion request rejected
  PAGE_LIVE: 'PAGE_LIVE', // Apply the product page live
  DISCOUNT_LIVE: 'DISCOUNT_LIVE', // Apply discount live
  BUILD_LIVE: 'BUILD_LIVE', //  Apply build live
  PRE_PURCHASE: 'PRE_PURCHASE', // Pre-order has started.
  // Front End status
  PRODUCT_READY_TO_OPEN: 'PRODUCT_READY_TO_OPEN' // Ready to open
} as const;

export const PRODUCT_RELEASE_PLAN_PAGE_OPEN: ProductReleasePlanType[] = [
  PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY_REQUEST,
  PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY,
  PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY_PASS,
  PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY_REJECT,
  PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_LIVE,
  PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_OPEN,
  PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_DISPLAY_OFF,
  PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_READY_TO_OPEN
] as const;

export const PRODUCT_STATUS_MAP: { [key: string]: string } = {
  // REQUEST
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY_REQUEST]: CALENDAR_RELEASE_PLAN_STATUS.REQUEST,
  [PRODUCT_RELEASE_PLAN_TYPE.RELEASE_VERIFY_REQUEST]: CALENDAR_RELEASE_PLAN_STATUS.REQUEST,
  [PRODUCT_RELEASE_PLAN_TYPE.PRICE_VERIFY_REQUEST]: CALENDAR_RELEASE_PLAN_STATUS.REQUEST,
  [PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY_REQUEST]: CALENDAR_RELEASE_PLAN_STATUS.REQUEST,

  // INSPECTION
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY]: CALENDAR_RELEASE_PLAN_STATUS.VERIFY,
  [PRODUCT_RELEASE_PLAN_TYPE.RELEASE_VERIFY]: CALENDAR_RELEASE_PLAN_STATUS.VERIFY,
  [PRODUCT_RELEASE_PLAN_TYPE.PRICE_VERIFY]: CALENDAR_RELEASE_PLAN_STATUS.VERIFY,
  [PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY]: CALENDAR_RELEASE_PLAN_STATUS.VERIFY,

  // COMPLETE
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY_PASS]: CALENDAR_RELEASE_PLAN_STATUS.COMPLETE,
  [PRODUCT_RELEASE_PLAN_TYPE.RELEASE_VERIFY_PASS]: CALENDAR_RELEASE_PLAN_STATUS.COMPLETE,
  [PRODUCT_RELEASE_PLAN_TYPE.PRICE_VERIFY_PASS]: CALENDAR_RELEASE_PLAN_STATUS.COMPLETE,
  [PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY_PASS]: CALENDAR_RELEASE_PLAN_STATUS.COMPLETE,
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_READY_TO_OPEN]: CALENDAR_RELEASE_PLAN_STATUS.COMPLETE,

  // REJECT
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY_REJECT]: CALENDAR_RELEASE_PLAN_STATUS.REJECT,
  [PRODUCT_RELEASE_PLAN_TYPE.RELEASE_VERIFY_REJECT]: CALENDAR_RELEASE_PLAN_STATUS.REJECT,
  [PRODUCT_RELEASE_PLAN_TYPE.PRICE_VERIFY_REJECT]: CALENDAR_RELEASE_PLAN_STATUS.REJECT,
  [PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY_REJECT]: CALENDAR_RELEASE_PLAN_STATUS.REJECT,
  [PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_RUN]: CALENDAR_RELEASE_PLAN_STATUS.REJECT,
  [PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_PURCHASE]: CALENDAR_RELEASE_PLAN_STATUS.REJECT,
  [PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_DISPLAY]: CALENDAR_RELEASE_PLAN_STATUS.REJECT,
  [PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_RATING]: CALENDAR_RELEASE_PLAN_STATUS.REJECT,
  [PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_REPORT]: CALENDAR_RELEASE_PLAN_STATUS.REJECT,
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_DELETE_REJECT]: CALENDAR_RELEASE_PLAN_STATUS.REJECT,

  // LIVE
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_LIVE]: CALENDAR_RELEASE_PLAN_STATUS.LIVE,
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_OPEN]: CALENDAR_RELEASE_PLAN_STATUS.LIVE,
  [PRODUCT_RELEASE_PLAN_TYPE.RELEASE_LIVE]: CALENDAR_RELEASE_PLAN_STATUS.LIVE,
  [PRODUCT_RELEASE_PLAN_TYPE.PRE_PURCHASE]: CALENDAR_RELEASE_PLAN_STATUS.LIVE,
  [PRODUCT_RELEASE_PLAN_TYPE.RELEASE]: CALENDAR_RELEASE_PLAN_STATUS.LIVE,
  [PRODUCT_RELEASE_PLAN_TYPE.PAGE_LIVE]: CALENDAR_RELEASE_PLAN_STATUS.LIVE,
  [PRODUCT_RELEASE_PLAN_TYPE.DISCOUNT_LIVE]: CALENDAR_RELEASE_PLAN_STATUS.LIVE,
  [PRODUCT_RELEASE_PLAN_TYPE.BUILD_LIVE]: CALENDAR_RELEASE_PLAN_STATUS.LIVE,

  // STOP
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_DISPLAY_OFF]: CALENDAR_RELEASE_PLAN_STATUS.STOP,
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_SALE_OFF]: CALENDAR_RELEASE_PLAN_STATUS.STOP,
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_DELETE_REQUEST]: CALENDAR_RELEASE_PLAN_STATUS.STOP
};

export const PRODUCT_STATUS_MAP_LANGUAGE_KEY: { [key: string]: string } = {
  // FE STATUS
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_READY_TO_OPEN]:
    'studio.group.home.board_type5_stt_to_be_opened_guide',

  // PRODUCT_PAGE_VERIFY
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY_REQUEST]:
    'studio.prj_prod.this_prod.stt_product_page_review_requested',
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY]:
    'studio.prj_prod.this_prod.stt_product_page_in_review',
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY_PASS]:
    'studio.prj_prod.this_prod.stt_product_page_in_review_approved',
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY_REJECT]:
    'studio.prj_prod.this_prod.stt_product_page_in_review_declined',
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_LIVE]:
    'studio.prj_prod.this_prod.stt_product_page_upcoming',
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_OPEN]:
    'studio.prj_prod.this_prod.stt_product_page_released',

  // PRODUCT_DISPLAY
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_DISPLAY_OFF]:
    'studio.prj_prod.this_prod.stt_product_page_withdrawn',

  // PRICE_VERIFY
  [PRODUCT_RELEASE_PLAN_TYPE.PRICE_VERIFY_REQUEST]:
    'studio.prj_prod.this_prod.stt_price_review_requested',
  [PRODUCT_RELEASE_PLAN_TYPE.PRICE_VERIFY]: 'studio.prj_prod.this_prod.stt_price_in_review',
  [PRODUCT_RELEASE_PLAN_TYPE.PRICE_VERIFY_PASS]:
    'studio.prj_prod.this_prod.stt_price_review_approved',
  [PRODUCT_RELEASE_PLAN_TYPE.PRICE_VERIFY_REJECT]:
    'studio.prj_prod.this_prod.stt_price_review_declined',

  // RELEASE_VERIFY
  [PRODUCT_RELEASE_PLAN_TYPE.RELEASE_VERIFY_REQUEST]:
    'studio.prj_prod.this_prod.stt_release_review_requested',
  [PRODUCT_RELEASE_PLAN_TYPE.RELEASE_VERIFY]: 'studio.prj_prod.this_prod.stt_release_in_review',
  [PRODUCT_RELEASE_PLAN_TYPE.RELEASE_VERIFY_PASS]: 'studio.prj_prod.this_prod.stt_release_approved',
  [PRODUCT_RELEASE_PLAN_TYPE.RELEASE_VERIFY_REJECT]:
    'studio.prj_prod.this_prod.stt_release_declined',

  // RATING_BUILD_VERIFY
  [PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY_REQUEST]:
    'studio.prj_prod.this_prod.stt_build_rating_review_requested',
  [PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY]:
    'studio.prj_prod.this_prod.stt_build_rating_in_review',
  [PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY_PASS]:
    'studio.prj_prod.this_prod.stt_build_rating_review_approved',
  [PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY_REJECT]:
    'studio.prj_prod.this_prod.stt_build_rating_review_declined',

  // RELEASE
  [PRODUCT_RELEASE_PLAN_TYPE.RELEASE_LIVE]: 'studio.prj_prod.this_prod.stt_release_upcoming',
  [PRODUCT_RELEASE_PLAN_TYPE.RELEASE]: 'studio.prj_prod.this_prod.stt_official_released',

  // PRODUCT_SALE
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_SALE_OFF]:
    'studio.prj_prod.this_prod.stt_release_sale_suspended',

  // PRODUCT_DELETE
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_DELETE_REQUEST]:
    'studio.prj_prod.this_prod.stt_product_deletion_request',
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_DELETE_REJECT]:
    'studio.prj_prod.this_prod.stt_product_deletion_declined',

  // RESTRICT
  [PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_RATING]: 'studio.prj_prod_mngmt.review_restricted_case',
  [PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_RUN]: 'studio.prj_prod.this_prod.stt_restriction_install',
  [PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_PURCHASE]:
    'studio.prj_prod.this_prod.stt_restriction_for_sales',
  [PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_DISPLAY]:
    'studio.prj_prod.this_prod.stt_restriction_display_operator_stove',
  [PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_REPORT]:
    'studio.prj_prod.this_prod.stt_restriction_display_user_report',

  // LIVE AND PRE_PURCHASE
  [PRODUCT_RELEASE_PLAN_TYPE.PAGE_LIVE]: 'studio.prj_prod.this_prod.stt_prod_pg_go_live_y', // 상품 페이지 LIVE 적용
  [PRODUCT_RELEASE_PLAN_TYPE.DISCOUNT_LIVE]:
    'studio.prj_prod.this_prod.release_status_in_promotion',
  [PRODUCT_RELEASE_PLAN_TYPE.BUILD_LIVE]: 'studio.prj_prod.this_prod.stt_build_live', // 빌드 LIVE 적용
  [PRODUCT_RELEASE_PLAN_TYPE.PRE_PURCHASE]:
    'studio.prj_prod.this_prod.release_details_release_plan_release_timeline_pre_order'
};

export const PRODUCT_RELEASE_PLAN_PAGE_BUILD: { [key: string]: string } = {
  [PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY_REQUEST]:
    'studio.prj_prod.this_prod.stt_build_rating_review_requested',
  [PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY]:
    'studio.prj_prod.this_prod.stt_build_rating_in_review',
  [PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY_PASS]:
    'studio.prj_prod.this_prod.stt_build_rating_review_approved',
  [PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY_REJECT]:
    'studio.prj_prod.this_prod.stt_build_rating_review_declined',
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_DELETE_REJECT]:
    'studio.prj_prod.this_prod.stt_product_deletion_declined',
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_DELETE_REQUEST]:
    'studio.prj_prod.this_prod.stt_product_deletion_request',
  [PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_SALE_OFF]:
    'studio.prj_prod.this_prod.stt_release_sale_suspended',
  [PRODUCT_RELEASE_PLAN_TYPE.PRE_PURCHASE]:
    'studio.prj_prod.this_prod.release_details_release_plan_release_timeline_pre_order'
};

export const PRODUCT_FEAT_NAME = {
  BUILD: 'product-build',
  DISCOUNT: 'product-discount',
  EARLY_ACCESS: 'product-early-access',
  HOME: 'product-home',
  LAUNCH_SETTING: 'product-launch-setting',
  PAGES: 'product-pages',
  PURCHASE_CONDITION: 'product-purchase-condition',
  RATING: 'product-rating',
  RELEASE_PLAN: 'product-release-plan',
  SELLING_PRICE: 'product-selling-price',
  SETTINGS: 'product-settings',
  TERMS: 'product-terms'
};

export const CLAIM_TYPE = {
  VIOLATION_OF_LAW: 'A', // Violation of Law - Contains content that violates the laws of your jurisdiction.
  ADULT_CONTENT: 'B', // Adult Content - Contains adult content that does not conform to the display and rating requirements.
  DEFAMATION: 'C', // Defamation - Contains slanderous or defamatory content
  COPYRIGHT_INFRINGEMENT: 'D', // Copyright infringement - infringing on the copyright of another person's work
  CHILD_ABUSE: 'E', // Child Abuse - Contains content that abuses children in any way
  OPERATION_ERROR: 'F', // Operation error - The download or execution does not proceed normally on devices that do not meet the minimum system requirements.
  MALWARE: 'G', // Malware - This software is abnormally obtaining sensitive information.
  OTHER: 'X' // Other
} as const;

export const CLAIM_TYPE_LABEL: any = {
  [CLAIM_TYPE.COPYRIGHT_INFRINGEMENT]: 'studio.prj_prod.restriction_criteria_copyright_violation',
  [CLAIM_TYPE.VIOLATION_OF_LAW]: 'studio.prj_prod.restriction_criteria_policy_violation',
  [CLAIM_TYPE.ADULT_CONTENT]: 'studio.prj_prod.restriction_criteria_adult_content',
  [CLAIM_TYPE.CHILD_ABUSE]: 'studio.prj_prod.restriction_criteria_child_bully',
  [CLAIM_TYPE.DEFAMATION]: 'studio.prj_prod.restriction_criteria_defamation',
  [CLAIM_TYPE.OPERATION_ERROR]: 'studio.prj_prod.restriction_criteria_operation_error',
  [CLAIM_TYPE.MALWARE]: 'studio.prj_prod.restriction_criteria_malicious_code',
  [CLAIM_TYPE.OTHER]: 'studio.prj_prod.restriction_criteria_other'
} as const;

export const ROLE_ID = {
  PRODUCT_PAGE_MANAGEMENT: 'PRODUCT_PAGE_MANAGEMENT',
  PRODUCT_DATA_MANAGEMENT: 'PRODUCT_DATA_MANAGEMENT',
  PRODUCT_MARKETING_MANAGEMENT: 'PRODUCT_MARKETING_MANAGEMENT',
  RELEASE_PLAN: 'RELEASE_PLAN',
  PRODUCT_SETTING: 'PRODUCT_SETTING',
  PRODUCT_STORE_PAGE: 'PRODUCT_STORE_PAGE',
  PRODUCT_ALWAYS_ON_SALE_PRICE: 'PRODUCT_ALWAYS_ON_SALE_PRICE',
  PRODUCT_ALWAYS_ON_SALE_DISCOUNT: 'PRODUCT_ALWAYS_ON_SALE_DISCOUNT',
  PRODUCT_SALE_CONDITION: 'PRODUCT_SALE_CONDITION',
  PRODUCT_EARLY_ACCESS_TEXT_SETTING: 'PRODUCT_EARLY_ACCESS_TEXT_SETTING',
  PRODUCT_BUILD_MANAGEMENT: 'PRODUCT_BUILD_MANAGEMENT',
  PRODUCT_RUN_OPTION: 'PRODUCT_RUN_OPTION',
  PRODUCT_RATING_BUILD_VERIFICATION: 'PRODUCT_RATING_BUILD_VERIFICATION',
  PRODUCT_TERMS: 'PRODUCT_TERMS',
  PRODUCT_STAT: 'PRODUCT_STAT',
  PRODUCT_RANKING: 'PRODUCT_RANKING',
  PRODUCT_ACHIEVEMENT: 'PRODUCT_ACHIEVEMENT',
  PRODUCT_CLOUD_SAVING: 'PRODUCT_CLOUD_SAVING',
  PRODUCT_MATCH_MAKING: 'PRODUCT_MATCH_MAKING',
  PRODUCT_COUPON: 'PRODUCT_COUPON',
  PRODUCT_EVENT: 'PRODUCT_EVENT',
  PRODUCT_STATISTICS: 'PRODUCT_STATISTICS',
  PRODUCT_BILL_SETTLEMENT: 'PRODUCT_BILL_SETTLEMENT',
  PRODUCT_INGAME_MANAGEMENT: 'PRODUCT_INGAME_MANAGEMENT',
  INGAME_PURCHASE_SETTING: 'INGAME_PURCHASE_SETTING',
  INGAME_SHOP: 'INGAME_SHOP',
  INGAME_PRODUCT: 'INGAME_PRODUCT',
  INGAME_DISCOUNT_PRODUCT: 'INGAME_DISCOUNT_PRODUCT',
  INGAME_PURCHASE_HISTORY: 'INGAME_PURCHASE_HISTORY',
  BANNER_IMAGE: 'BANNER_IMAGE'
};

export const ROLE_MARKETER = 'MARKETER';
export const ROLE_OPERATOR = 'OPERATOR';
export const ROLE_DEVELOPER = 'DEVELOPER';
export const ROLE_PROJECT_MANAGER = 'PROJECT_MANAGER';
export const ROLE_OWNER = 'OWNER';

export const BASIC_ROLES_NAME = [
  ROLE_MARKETER,
  ROLE_OPERATOR,
  ROLE_DEVELOPER,
  ROLE_PROJECT_MANAGER,
  ROLE_OWNER
];

import { readonly, ref } from 'vue';

export function useReactiveLocalStorage<T = any>() {
  const storage = ref<Record<string, T>>({});
  const readonlyStorage = readonly(storage);

  const setLocalStorage = (key: string, value: any) => {
    storage.value[key] = value;
    localStorage.setItem(key, JSON.stringify(value));
  };

  const removeLocalStorage = (key: string) => {
    delete storage.value[key];
    localStorage.removeItem(key);
  };

  return {
    storage: readonlyStorage,
    setLocalStorage,
    removeLocalStorage
  };
}

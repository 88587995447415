import { MenuAuthorityIds } from '@/enums/common.enum';
import type { RedirectParams } from '@/types/common.type';
import type { LanguageModel } from '@/types/common/common.type';

export const CALLER_ID = {
  CLIENT: 'clientapp',
  INDIE: 'indie-web-studio'
};

export const VIEW_MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE'
};

export const PLATFORM = {
  PC: 'PC',
  WEB: 'WEB',
  MOBILE: 'MOBILE'
  // VR: 'VR'
} as const;

export const SERVICE_ID = {
  INDIEGAME: 'INDIEGAME'
};

export const SERVICE_TYPE = {
  STOVEGAMEID: 'stove.game_id',
  STOVEGAMENO: 'stove.game_no'
};

export const VIEW_AREA = {
  STDJOIN: 'STD_JOIN', // STORE_REGISTER
  PRODUCT_EULA: 'CLI_IG',
  STD_MAILAGREE: 'STD_MAILAGREE'
};

export const X_DEVICE_TYPE = 'P01';
export const DEFAULT_X_CLIENT_LANGUAGE = 'ko';
export const DEFAULT_RUN_TYPE = 'live';

export const BROWSER_NAME = {
  CHROME: 'Chrome',
  FIREFOX: 'Firefox',
  OPERA: 'Opera',
  SAFARI: 'Safari',
  EDGE: 'Microsoft Edge',
  IE: 'Internet Explorer',
  NAVER: 'NAVER Whale Browser'
};

export const MODE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  VIEW: 'VIEW'
};

export const BODY_CLASS = 'stove-studio studio-scrollbar-4';
export const GNB_HEIGHT = 68;

export const SORT_VALUE = {
  ASC: -1,
  DESC: 1,
  NONE: 0
};

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const TRANSLATE_LANGUAGES: LanguageModel[] = [
  {
    langCode: 'ko',
    langTitle: 'studio.gnb.common_language_ko'
  },
  {
    langCode: 'en',
    langTitle: 'studio.gnb.common_language_us'
  },
  {
    langCode: 'ja',
    langTitle: 'studio.gnb.common_language_ja'
  },
  {
    langCode: 'zh-cn',
    langTitle: 'studio.gnb.common_language_zh_cn'
  },
  {
    langCode: 'zh-tw',
    langTitle: 'studio.gnb.common_language_zh_tw'
  }
];

export const TRANSLATE_LANGUAGES_SETTINGS: LanguageModel[] = [
  {
    langCode: 'en',
    langTitle: 'studio.basic_lang_setting.us'
  },
  {
    langCode: 'ko',
    langTitle: 'studio.basic_lang_setting.ko'
  },
  {
    langCode: 'ja',
    langTitle: 'studio.basic_lang_setting.ja'
  },
  {
    langCode: 'zh-cn',
    langTitle: 'studio.basic_lang_setting.zh_cn'
  },
  {
    langCode: 'zh-tw',
    langTitle: 'studio.basic_lang_setting.zh_tw'
  }
];
export const DEBOUNCE_INPUT_TIME = 300;

export const DEBOUNCE_INPUT_ENTER_TIME = 100;

export const CONFIRM_TYPE = {
  DELETE: 'DELETE',
  RELEASE: 'RELEASE',
  SUSPENSE: 'SUSPENSE',
  SUSPEND: 'SUSPEND'
};

export const KAKAO_POSTAL_CODE_API =
  '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';

export const CS_STOVE_LINK = 'https://cs.onstove.com/';

export const DEFAULT_NATION = 'KR';

export const FORMAT_RESPONSE = {
  CUSTOM: 'custom',
  DEFAULT: 'default',
  MEMBER: 'member',
  OTHER: 'other'
} as const;

export const SERVICE_TYPES = {
  SERVICE_ID: 'stove.service_id',
  GAME_ID: 'stove.game_id'
};

export const START_DATE_TYPE = {
  NOW: 'NOW',
  SELECT: 'SELECT'
};

export const COMMON_RADIO_TYPE = {
  USED: 'USED',
  UNUSED: 'UNUSED'
};

export const ONSTOVE_URL = 'https://www.onstove.com';

export const AUTHORITY_URLS = {
  [MenuAuthorityIds.GROUP_HOME]: 'home',
  [MenuAuthorityIds.PROJECT_PRODUCT]: 'projects',
  [MenuAuthorityIds.COLLECTION]: 'collections',
  [MenuAuthorityIds.MEMBER_MANAGEMENT]: 'members',
  [MenuAuthorityIds.BIMS_BANK_SETTING]: 'business-bank'
};

export const NOTICE_REQUEST_ID = {
  CM: 'CM',
  ID: 'ID',
  TM: 'TM'
};

export const DEFAULT_LANG_CODE = 'ko';

export const NOTICE_ARTICLE_GROUP_CODE = {
  BOARD: 'BOARD'
};

export const INTERACTION_TYPE_CODE = {
  LIKE: 'LIKE',
  DISLIKE: 'DISLIKE',
  COMMENT: 'COMMENT',
  VIEW: 'VIEW'
};

export const DEFAULT_API_TIMEOUT = 5000;
export const STORAGE_API_TIMEOUT = 0;

export const SORT_BY = {
  NEWEST: 'NEWEST',
  OLDEST: 'OLDEST',
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING'
};

export const OPEN_TARGET_BLANK: RedirectParams = {
  open: {
    target: '_blank'
  }
};

export const OPEN_TARGET_BLANK_EXTERNAL: RedirectParams = {
  external: true,
  open: {
    target: '_blank'
  }
};

export const MAX_PRODUCTS_ON_LNB = 5;

export const COMMON_TOAST_DURATION = 1200;

export const ALL = 'ALL';

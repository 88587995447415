import { showMaxLengthFileNameDialog } from '@/composables/useDialog';
import {
  BYTES_TEXT,
  GB,
  GB_TEXT,
  IMAGE_MIMES_TYPE,
  IMAGE_SIZE,
  KB_TEXT,
  MAX_LENGTH_FILE_NAME,
  MB,
  MB_TEXT,
  SIZE_UNIT
} from '@/constants/file.const';
import type { OrNull } from '@/types/core.type';
import type { ImageDimension, ImageSize } from '@/types/image/image.type';

// Get image dimension of an image file or an image url
export const getImageDimension = (file: File | string): Promise<OrNull<ImageDimension>> => {
  // Create a new image object
  const image = new Image();
  // Set the source of the image to the file URL
  if (typeof file === 'string') {
    image.src = file;
  } else if (file instanceof Blob) {
    // Check if file is a Blob or File (since File inherits from Blob)
    image.src = URL.createObjectURL(file);
  } else {
    // console.error('file is neither a string URL nor a Blob/File object');
  }

  // Return a promise that resolves with the width and height of the image
  return new Promise((resolve: (value: OrNull<ImageDimension>) => void) => {
    // Wait for the image to load
    image.onload = () => {
      // Get the width and height of the image
      const width = image.naturalWidth;
      const height = image.naturalHeight;
      // Revoke the object URL to free memory
      URL.revokeObjectURL(image.src);
      // Resolve the promise with an object containing the width and height
      resolve({ width, height });
    };

    // Handle errors
    image.onerror = () => {
      // Revoke the object URL to free memory
      URL.revokeObjectURL(image.src);
      // Reject the promise with an error message
      resolve(null);
    };
  });
};

export const getVideoDimension = (file: File | string): Promise<OrNull<ImageDimension>> => {
  const video = document.createElement('video');
  if (typeof file === 'string') {
    video.src = file;
  } else if (file instanceof Blob) {
    video.src = URL.createObjectURL(file);
  } else {
    // console.error('file is neither a string URL nor a Blob/File object');
  }
  return new Promise((resolve: (value: OrNull<ImageDimension>) => void) => {
    video.onloadedmetadata = () => {
      const width = video.videoWidth;
      const height = video.videoHeight;
      URL.revokeObjectURL(video.src);
      resolve({ width, height });
    };
    video.onerror = () => {
      URL.revokeObjectURL(video.src);
      resolve(null);
    };
  });
};

export const drawBase64Image = async ({
  base64String,
  width,
  height,
  fillColor = null,
  isFlip = false,
  opacity = 1,
  rotate = 0
}: {
  base64String: string;
  width: number;
  height: number;
  fillColor?: string | null;
  isFlip?: boolean;
  opacity?: number;
  rotate?: number;
}): Promise<string> => {
  const img = new Image();
  img.src = base64String;
  let resizedBase64 = '';
  return new Promise((resolve: (value: string) => void) => {
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }
      // Set new dimensions
      canvas.width = width;
      canvas.height = height;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.save();
      ctx.translate(canvas.width / 2, canvas.height / 2);
      // works with images
      ctx.globalAlpha = opacity;

      if (fillColor) {
        ctx.fillStyle = fillColor;
        ctx.fillRect(-width / 2, -height / 2, width, height);
      }
      if (rotate) {
        ctx.rotate((rotate * Math.PI) / 180);
      }

      if (isFlip) {
        if (rotate > 0 && rotate % 360 !== 0) {
          ctx.scale(1, -1);
        } else {
          ctx.scale(-1, 1);
        }
      }
      // Resize image
      ctx.drawImage(img, -width / 2, -height / 2, width, height);

      // always clean up -- reset transformations to default
      // ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.restore();
      // Get resized image as base64
      resizedBase64 = canvas.toDataURL('image/png');
      URL.revokeObjectURL(img.src);
      resolve(resizedBase64);
    };
    img.onerror = () => {
      // Revoke the object URL to free memory
      URL.revokeObjectURL(img.src);
      // Reject the promise with an error message
      resolve('');
    };
  });
};

export const downLoadFile = async (url: string, fileName: string) => {
  try {
    const response = await fetch(url, {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate'
      },
      method: 'GET',
      mode: 'cors' // Ensure CORS is enabled
    });
    if (!response.ok) {
      // console.error('Network response was not ok.');
    }
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = blobUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    // Cleanup
    document.body.removeChild(a);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    // console.error('Error downloading file:', error);
  }
};

export const setMultiFileFormData = ({
  applicationNo,
  files,
  category,
  convertWebp
}: {
  applicationNo: number;
  files: File[];
  category: string;
  convertWebp?: boolean;
}): FormData | undefined => {
  const formData = new FormData();
  formData.append('user_type', 'application_no');
  formData.append('user_id', applicationNo.toString());
  formData.append('service_type', 'application_no');
  formData.append('service_id', applicationNo.toString());
  formData.append('category', category);
  formData.append('path', 'indie-studio-v3');
  formData.append('share', 'true');
  formData.append('overwrite', 'false');

  if (files && files.length > 0) {
    formData.append('file_cnt', files.length.toString());
    for (let i = 0; i < files.length; i++) {
      formData.append(`file_${i}`, files[i]);
    }
  }

  if (convertWebp) {
    formData.append('convert_format', 'webp');
  }

  return formData;
};

export const convertImageSize = (value: string): { width: number; height: number } => {
  const [width, height]: string[] = value.split('x');
  return { width: parseInt(width), height: parseInt(height) };
};

export const convertImageSizeToName = (value: string): string => {
  const [width, height]: string[] = value.split('x');
  return (
    IMAGE_SIZE.find(
      (size: ImageSize) => size.width === parseInt(width) && size.height === parseInt(height)
    )?.name ?? 'Unknown size'
  );
};

export const convertBase64ToFile = (
  base64: string,
  fileName: string,
  type: string = IMAGE_MIMES_TYPE.PNG
) => {
  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new File([ab], fileName, { type });
};

export const convertFileToBase64 = (file: File) => {
  return new Promise<string>((resolve: (s: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data as string);
    };
  });
};
// TODO: check CDN STORAGE
export const convertCdnToBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url, {
    method: 'GET'
  });
  if (!response.ok) {
    // console.error('Network response was not ok.');
  }
  const blob = await response.blob();
  return new Promise<string>((resolve: (s: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result as string;
      resolve(base64data);
    };
  });
};

export const formatBytes = (bytes: number, fractionDigits: number = 2) => {
  if (bytes < SIZE_UNIT) {
    return bytes.toFixed(2) + ' ' + BYTES_TEXT;
  }

  const units = [KB_TEXT, MB_TEXT, GB_TEXT];
  let value = bytes;

  for (let unitIndex = 0; unitIndex < units.length; unitIndex++) {
    value = value / SIZE_UNIT;

    if (value < SIZE_UNIT || unitIndex === units.length - 1) {
      return (Math.floor(value * 100) / 100).toFixed(fractionDigits) + ' ' + units[unitIndex];
    }
  }
};

export const formatFileSize = (sizeInBytes: number | string | null | undefined): string => {
  if (sizeInBytes === undefined || sizeInBytes === null) {
    return '-';
  }

  const bytes = typeof sizeInBytes === 'string' ? parseInt(sizeInBytes, 10) : sizeInBytes;
  if (typeof bytes !== 'number' || isNaN(bytes)) {
    return '-';
  }

  if (bytes < 1024) {
    return `${sizeInBytes} ${BYTES_TEXT}`;
  }

  if (bytes >= GB) {
    const sizeInGB = bytes / GB;
    return `${Math.round(sizeInGB * 100) / 100} ${GB_TEXT}`;
  }

  const sizeInMB = bytes / MB;
  if (sizeInMB > 1) {
    return `${Math.round(sizeInMB * 100) / 100} ${MB_TEXT}`;
  }
  return `${Math.ceil(sizeInMB * 10) / 10} MB`;
};

export const formatStorageDisplay = (sizeInBytes: number | string | null | undefined): string => {
  if (sizeInBytes === undefined || sizeInBytes === null) {
    return '-';
  }

  const bytes = typeof sizeInBytes === 'string' ? parseInt(sizeInBytes, 10) : sizeInBytes;

  if (typeof bytes !== 'number' || isNaN(bytes)) {
    return '-';
  }

  if (bytes >= GB) {
    const sizeInGB = bytes / GB;
    const value = Math.round(sizeInGB * 10) / 10;
    return `${Number.isInteger(value) ? value : value.toFixed(1)} ${GB_TEXT}`;
  } else {
    const sizeInMB = bytes / MB;
    const value = Math.round(sizeInMB * 10) / 10;
    return `${Number.isInteger(value) ? value : value.toFixed(1)} ${MB_TEXT}`;
  }
};

export const checkFileNameMaxLength = async (fileName: string): Promise<boolean> => {
  if (getFileNameWithoutExtension(fileName).length >= MAX_LENGTH_FILE_NAME) {
    await showMaxLengthFileNameDialog();
    return false;
  }
  return true;
};

export const getFileNameWithoutExtension = (fileName: string): string => {
  return fileName.substring(0, fileName.lastIndexOf('.'));
};

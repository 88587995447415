<template>
  <div>
    <s-dialog :open="true" to="confirm-dialog" :size="size ?? 'sm'">
      <s-dialog-overlay />
      <s-dialog-panel :class="panelClasses" class="min-h-[18.8rem]">
        <st-dialog-header v-if="isCloseButtonShown" @clickClose="$emit('close')">
          <s-text
            v-if="title"
            :as="titleTag ?? 'h1'"
            :role="titleRole ?? 'title3'"
            :class="`mb-4 text-${align ?? 'center'} font-bold`"
          >
            {{ title }}
          </s-text>
        </st-dialog-header>
        <s-dialog-content :class="contentClasses" class="mt-auto">
          <s-dialog-content-body>
            <s-text
              v-if="title && !isCloseButtonShown"
              :as="titleTag ?? 'h1'"
              :role="titleRole ?? 'title3'"
              :class="`mb-4 text-${align ?? 'center'} font-bold`"
            >
              {{ title }}
            </s-text>
            <s-icon v-if="iconInfo.icon" :icon="iconInfo.icon" :class="iconInfo.class" size="5xl" />
            <template v-if="Array.isArray(content)">
              <div
                v-for="(item, index) in content"
                :key="`dialog_${index}`"
                :class="`py-1 text-${align ?? 'center'}`"
              >
                <safe-html tag="p" :html="item" />
              </div>
            </template>
            <!-- string -->
            <safe-html v-else tag="p" :class="`text-${align ?? 'center'}`" :html="content" />
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button
            v-if="isCancelButtonVisible"
            :class="cancelClasses"
            :variant="cancelVariant"
            @click="$emit('close', false)"
          >
            {{ cancelLabel || $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button
            :severity="severity"
            :class="[confirmClasses]"
            :variant="confirmVariant"
            @click="$emit('close', true)"
          >
            {{ confirmLabel || $t('studio.common.popup_case_cf_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>
    <s-portal-target name="confirm-dialog" />
  </div>
</template>

<script setup lang="ts">
import type { AllIcons } from '@stove-ui/vue';
import { computed, toRefs } from 'vue';

import SafeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import type { SeverityType, VariantType } from '@/types/app.type';

const props = defineProps<{
  severity: SeverityType;
  content: string | string[];
  contentClasses?: string;
  panelClasses?: string;
  icon?: AllIcons;
  confirmLabel?: string;
  confirmClasses?: string;
  isCancelButtonVisible?: boolean;
  cancelLabel?: string;
  cancelClasses?: string;
  title?: string;
  confirmVariant?: VariantType;
  cancelVariant?: VariantType;
  align?: 'center' | 'left' | 'right';
  titleTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  titleRole?:
    | 'title1'
    | 'title2'
    | 'title3'
    | 'title4'
    | 'title5'
    | 'title6'
    | 'title7'
    | 'title8'
    | 'text1'
    | 'text2'
    | 'text3'
    | 'text4'
    | 'btn1'
    | 'btn2'
    | 'btn3'
    | 'cap1'
    | 'cap2';
  isCloseButtonShown?: boolean;
  size: 'sm' | 'md' | 'lg';
}>();

defineEmits<{
  close: [v?: boolean];
}>();

const {
  severity,
  content,
  contentClasses,
  icon,
  confirmLabel,
  confirmClasses,
  isCancelButtonVisible,
  cancelLabel,
  cancelClasses,
  title
} = toRefs(props);
const commonClasses = computed(() => {
  let icon = '';
  let color = '';

  switch (severity.value) {
    case 'danger':
      icon = 'ic-v2-state-info-circle-line';
      color = 'text-orange900';
      break;
    case 'warning':
      icon = 'ic-v2-state-info-circle-line';
      color = 'text-orange400';
      break;
    case 'success':
      icon = 'ic-v2-control-check-circle-line';
      color = 'text-navy500';
      break;

    default:
      icon = '';
      color = 'text-info';
      break;
  }

  return {
    icon,
    color
  };
});

const iconInfo = computed(() => {
  return {
    icon: icon?.value ?? (commonClasses.value.icon as AllIcons),
    class: 'mx-auto block text-8xl mb-8 ' + commonClasses.value.color
  };
});
</script>

import { default as bank_45listR2crP43aL7Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-list.vue?macro=true";
import { default as bank_45registerfZwZdsKoxTMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-register.vue?macro=true";
import { default as business_45list9ADky5juF7Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-list.vue?macro=true";
import { default as business_45registern6jErCPT4XMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-register.vue?macro=true";
import { default as indexkMkjtKISLhMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/index.vue?macro=true";
import { default as business_45bankoOuWOVJeWxMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank.vue?macro=true";
import { default as _91mode_93zdEaxOziNBMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/[mode].vue?macro=true";
import { default as indexrkJAI5TrwYMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/index.vue?macro=true";
import { default as discountIy6GV18yopMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount.vue?macro=true";
import { default as _91mode_931ttLzRO79BMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[mode].vue?macro=true";
import { default as indexEkIF65mYs4Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/index.vue?macro=true";
import { default as collectionsFAthRT6rdEMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections.vue?macro=true";
import { default as homeluYuH1WOqBMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/home.vue?macro=true";
import { default as indexkKv5NgYD07Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/index.vue?macro=true";
import { default as _91id_938qdkdues3NMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/[id].vue?macro=true";
import { default as indexYB7qgwmZpiMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/index.vue?macro=true";
import { default as invite_45historyozwdBjUG7zMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite-history.vue?macro=true";
import { default as invitewLIxfuFPQgMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite.vue?macro=true";
import { default as _91mode_932jWlpHxdFxMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/[mode].vue?macro=true";
import { default as indexOC4UC0HCBIMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/index.vue?macro=true";
import { default as wait_45listVCtguf2CtxMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/wait-list.vue?macro=true";
import { default as membersQSvU5dg3aaMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members.vue?macro=true";
import { default as indexzAqMn9T9MxMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/index.vue?macro=true";
import { default as livehu3drSQ5HXMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/live.vue?macro=true";
import { default as _91buildId_93SD9SKlZWROMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId].vue?macro=true";
import { default as indexEdlUa4zgOEMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/index.vue?macro=true";
import { default as buildB0ZOjRhfg4Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build.vue?macro=true";
import { default as _91discountId_937IiHSoBTVEMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/[discountId].vue?macro=true";
import { default as indexiOnLIBnpj9Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/index.vue?macro=true";
import { default as registerseVaz102vrMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/register.vue?macro=true";
import { default as discount7vspSFrvgrMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount.vue?macro=true";
import { default as early_45accessYWpHEZN6iiMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/early-access.vue?macro=true";
import { default as indexlsUkhnJiMEMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/index.vue?macro=true";
import { default as launch_45settingMT4E0WWTIiMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/launch-setting.vue?macro=true";
import { default as banner_45imageDR27uqBOz0Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/marketing-management/banner-image.vue?macro=true";
import { default as _91mode_93JPPqnAouWiMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/[mode].vue?macro=true";
import { default as indexBRGfUaqcxiMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/index.vue?macro=true";
import { default as product_45pagesLCqMKJKrfLMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages.vue?macro=true";
import { default as purchase_45conditionK3XckRfhLoMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/purchase-condition.vue?macro=true";
import { default as adjustment5iVjtprje2Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/adjustment.vue?macro=true";
import { default as cancelOJETfvWOkFMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/cancel.vue?macro=true";
import { default as grac_45input4q8emIm6TRMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/grac-input.vue?macro=true";
import { default as indexu8S83GQqVAMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/index.vue?macro=true";
import { default as detailyiutK40ahtMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail.vue?macro=true";
import { default as historyf5QtLr648UMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/history.vue?macro=true";
import { default as indexNtRSF10ctIMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/index.vue?macro=true";
import { default as reclassificationYFY383yHKEMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reclassification.vue?macro=true";
import { default as grac_45input6kXdR2bZihMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/grac-input.vue?macro=true";
import { default as indexdlJrcjbr8ZMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/index.vue?macro=true";
import { default as registervkCPeN9ReWMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register.vue?macro=true";
import { default as reject_45reasonXkFa3rBewqMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reject-reason.vue?macro=true";
import { default as rejectionlQCjroVCC0Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/rejection.vue?macro=true";
import { default as ratingc2cRWY5NFaMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating.vue?macro=true";
import { default as detailWVE89LL0yOMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/detail.vue?macro=true";
import { default as indexLS1Oo7qey4Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/index.vue?macro=true";
import { default as release_45planc5emQj8pP6Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan.vue?macro=true";
import { default as editmweUb6h5s0Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/edit.vue?macro=true";
import { default as indexMDoQJWxC9TMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/index.vue?macro=true";
import { default as register5pUDTzLhD2Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/register.vue?macro=true";
import { default as selling_45priceaItQqETfYQMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price.vue?macro=true";
import { default as settingsT2NY6WJQl3Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/settings.vue?macro=true";
import { default as editSp50nj3bNaMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/edit.vue?macro=true";
import { default as indexNDSsdBKRt4Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/index.vue?macro=true";
import { default as registergfOlj3ZCdVMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/register.vue?macro=true";
import { default as termsAza8XRdk45Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms.vue?macro=true";
import { default as _91productId_93ToD7TnxuXGMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId].vue?macro=true";
import { default as indexKUB2ino9kbMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/index.vue?macro=true";
import { default as registerYCPVGOTJ7GMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/register.vue?macro=true";
import { default as index0A7OKE0A8hMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/index.vue?macro=true";
import { default as register32S2WihcfoMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/register.vue?macro=true";
import { default as projectsOhTjRVWZ7jMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects.vue?macro=true";
import { default as _91groupId_937i5oTSd92jMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId].vue?macro=true";
import { default as brand1PUN7bEynbMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/brand.vue?macro=true";
import { default as errorHKkSNgN3xtMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/error.vue?macro=true";
import { default as indexmn9NmTFwE9Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group-transfer/business-registration/index.vue?macro=true";
import { default as registerfukUmsOjGCMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group/register.vue?macro=true";
import { default as groupP9X22i24B0Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group.vue?macro=true";
import { default as indexIx747zg3K8Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/index.vue?macro=true";
import { default as _91id_93QuxnbS3IBZMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/group/[id].vue?macro=true";
import { default as indexjBElbhxDD5Meta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/index.vue?macro=true";
import { default as my_45infozfkH6GRGuhMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info.vue?macro=true";
import { default as _91id_93EbMZs03N1pMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/[id].vue?macro=true";
import { default as indexeqOvZk8kLzMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/index.vue?macro=true";
import { default as notice6Srm5syewTMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice.vue?macro=true";
import { default as index5izNrYHQBrMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/index.vue?macro=true";
import { default as _91id_93QukokvZHEeMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/[id].vue?macro=true";
import { default as indexPkaDiqsgPOMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/index.vue?macro=true";
import { default as versionOjLNv7exyLMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/version.vue?macro=true";
import { default as publiccJvCf2VH8RMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public.vue?macro=true";
import { default as _91to_93a1dkWSfoAAMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/redirect-to/[to].vue?macro=true";
import { default as registerQ5QN6nmW8QMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/register.vue?macro=true";
import { default as service_45unavailableSwvEwsqTapMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/service-unavailable.vue?macro=true";
import { default as detaill6iC9UKfBHMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/detail.vue?macro=true";
import { default as indexz28cnVebPUMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/index.vue?macro=true";
import { default as updateugKdewHwvZMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/update.vue?macro=true";
import { default as terms58pUJyaS0EMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms.vue?macro=true";
import { default as indexHG7Qb2AphlMeta } from "/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/tutorial/index.vue?macro=true";
export default [
  {
    path: _91groupId_937i5oTSd92jMeta?.path ?? "/en/:groupId()",
    children: [
  {
    path: business_45bankoOuWOVJeWxMeta?.path ?? "business-bank",
    children: [
  {
    name: bank_45listR2crP43aL7Meta?.name ?? "groupId-business-bank-bank-list___en",
    path: bank_45listR2crP43aL7Meta?.path ?? "bank-list",
    meta: bank_45listR2crP43aL7Meta || {},
    alias: bank_45listR2crP43aL7Meta?.alias || [],
    redirect: bank_45listR2crP43aL7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-list.vue").then(m => m.default || m)
  },
  {
    name: bank_45registerfZwZdsKoxTMeta?.name ?? "groupId-business-bank-bank-register___en",
    path: bank_45registerfZwZdsKoxTMeta?.path ?? "bank-register",
    meta: bank_45registerfZwZdsKoxTMeta || {},
    alias: bank_45registerfZwZdsKoxTMeta?.alias || [],
    redirect: bank_45registerfZwZdsKoxTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-register.vue").then(m => m.default || m)
  },
  {
    name: business_45list9ADky5juF7Meta?.name ?? "groupId-business-bank-business-list___en",
    path: business_45list9ADky5juF7Meta?.path ?? "business-list",
    meta: business_45list9ADky5juF7Meta || {},
    alias: business_45list9ADky5juF7Meta?.alias || [],
    redirect: business_45list9ADky5juF7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-list.vue").then(m => m.default || m)
  },
  {
    name: business_45registern6jErCPT4XMeta?.name ?? "groupId-business-bank-business-register___en",
    path: business_45registern6jErCPT4XMeta?.path ?? "business-register",
    meta: business_45registern6jErCPT4XMeta || {},
    alias: business_45registern6jErCPT4XMeta?.alias || [],
    redirect: business_45registern6jErCPT4XMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-register.vue").then(m => m.default || m)
  },
  {
    name: indexkMkjtKISLhMeta?.name ?? "groupId-business-bank___en",
    path: indexkMkjtKISLhMeta?.path ?? "",
    meta: indexkMkjtKISLhMeta || {},
    alias: indexkMkjtKISLhMeta?.alias || [],
    redirect: indexkMkjtKISLhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/index.vue").then(m => m.default || m)
  }
],
    name: business_45bankoOuWOVJeWxMeta?.name ?? undefined,
    meta: business_45bankoOuWOVJeWxMeta || {},
    alias: business_45bankoOuWOVJeWxMeta?.alias || [],
    redirect: business_45bankoOuWOVJeWxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank.vue").then(m => m.default || m)
  },
  {
    path: collectionsFAthRT6rdEMeta?.path ?? "collections",
    children: [
  {
    path: discountIy6GV18yopMeta?.path ?? ":collectionId()/discount",
    children: [
  {
    name: _91mode_93zdEaxOziNBMeta?.name ?? "groupId-collections-collectionId-discount-mode___en",
    path: _91mode_93zdEaxOziNBMeta?.path ?? ":mode()",
    meta: _91mode_93zdEaxOziNBMeta || {},
    alias: _91mode_93zdEaxOziNBMeta?.alias || [],
    redirect: _91mode_93zdEaxOziNBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexrkJAI5TrwYMeta?.name ?? "groupId-collections-collectionId-discount___en",
    path: indexrkJAI5TrwYMeta?.path ?? "",
    meta: indexrkJAI5TrwYMeta || {},
    alias: indexrkJAI5TrwYMeta?.alias || [],
    redirect: indexrkJAI5TrwYMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/index.vue").then(m => m.default || m)
  }
],
    name: discountIy6GV18yopMeta?.name ?? undefined,
    meta: discountIy6GV18yopMeta || {},
    alias: discountIy6GV18yopMeta?.alias || [],
    redirect: discountIy6GV18yopMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount.vue").then(m => m.default || m)
  },
  {
    name: _91mode_931ttLzRO79BMeta?.name ?? "groupId-collections-mode___en",
    path: _91mode_931ttLzRO79BMeta?.path ?? ":mode()",
    meta: _91mode_931ttLzRO79BMeta || {},
    alias: _91mode_931ttLzRO79BMeta?.alias || [],
    redirect: _91mode_931ttLzRO79BMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexEkIF65mYs4Meta?.name ?? "groupId-collections___en",
    path: indexEkIF65mYs4Meta?.path ?? "",
    meta: indexEkIF65mYs4Meta || {},
    alias: indexEkIF65mYs4Meta?.alias || [],
    redirect: indexEkIF65mYs4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/index.vue").then(m => m.default || m)
  }
],
    name: collectionsFAthRT6rdEMeta?.name ?? undefined,
    meta: collectionsFAthRT6rdEMeta || {},
    alias: collectionsFAthRT6rdEMeta?.alias || [],
    redirect: collectionsFAthRT6rdEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections.vue").then(m => m.default || m)
  },
  {
    name: homeluYuH1WOqBMeta?.name ?? "groupId-home___en",
    path: homeluYuH1WOqBMeta?.path ?? "home",
    meta: homeluYuH1WOqBMeta || {},
    alias: homeluYuH1WOqBMeta?.alias || [],
    redirect: homeluYuH1WOqBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/home.vue").then(m => m.default || m)
  },
  {
    name: indexkKv5NgYD07Meta?.name ?? "groupId___en",
    path: indexkKv5NgYD07Meta?.path ?? "",
    meta: indexkKv5NgYD07Meta || {},
    alias: indexkKv5NgYD07Meta?.alias || [],
    redirect: indexkKv5NgYD07Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/index.vue").then(m => m.default || m)
  },
  {
    path: membersQSvU5dg3aaMeta?.path ?? "members",
    children: [
  {
    name: _91id_938qdkdues3NMeta?.name ?? "groupId-members-id___en",
    path: _91id_938qdkdues3NMeta?.path ?? ":id()",
    meta: _91id_938qdkdues3NMeta || {},
    alias: _91id_938qdkdues3NMeta?.alias || [],
    redirect: _91id_938qdkdues3NMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYB7qgwmZpiMeta?.name ?? "groupId-members___en",
    path: indexYB7qgwmZpiMeta?.path ?? "",
    meta: indexYB7qgwmZpiMeta || {},
    alias: indexYB7qgwmZpiMeta?.alias || [],
    redirect: indexYB7qgwmZpiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: invite_45historyozwdBjUG7zMeta?.name ?? "groupId-members-invite-history___en",
    path: invite_45historyozwdBjUG7zMeta?.path ?? "invite-history",
    meta: invite_45historyozwdBjUG7zMeta || {},
    alias: invite_45historyozwdBjUG7zMeta?.alias || [],
    redirect: invite_45historyozwdBjUG7zMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite-history.vue").then(m => m.default || m)
  },
  {
    name: invitewLIxfuFPQgMeta?.name ?? "groupId-members-invite___en",
    path: invitewLIxfuFPQgMeta?.path ?? "invite",
    meta: invitewLIxfuFPQgMeta || {},
    alias: invitewLIxfuFPQgMeta?.alias || [],
    redirect: invitewLIxfuFPQgMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite.vue").then(m => m.default || m)
  },
  {
    name: _91mode_932jWlpHxdFxMeta?.name ?? "groupId-members-roles-mode___en",
    path: _91mode_932jWlpHxdFxMeta?.path ?? "roles/:mode()",
    meta: _91mode_932jWlpHxdFxMeta || {},
    alias: _91mode_932jWlpHxdFxMeta?.alias || [],
    redirect: _91mode_932jWlpHxdFxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexOC4UC0HCBIMeta?.name ?? "groupId-members-roles___en",
    path: indexOC4UC0HCBIMeta?.path ?? "roles",
    meta: indexOC4UC0HCBIMeta || {},
    alias: indexOC4UC0HCBIMeta?.alias || [],
    redirect: indexOC4UC0HCBIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/index.vue").then(m => m.default || m)
  },
  {
    name: wait_45listVCtguf2CtxMeta?.name ?? "groupId-members-wait-list___en",
    path: wait_45listVCtguf2CtxMeta?.path ?? "wait-list",
    meta: wait_45listVCtguf2CtxMeta || {},
    alias: wait_45listVCtguf2CtxMeta?.alias || [],
    redirect: wait_45listVCtguf2CtxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/wait-list.vue").then(m => m.default || m)
  }
],
    name: membersQSvU5dg3aaMeta?.name ?? undefined,
    meta: membersQSvU5dg3aaMeta || {},
    alias: membersQSvU5dg3aaMeta?.alias || [],
    redirect: membersQSvU5dg3aaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members.vue").then(m => m.default || m)
  },
  {
    path: projectsOhTjRVWZ7jMeta?.path ?? "projects",
    children: [
  {
    path: _91productId_93ToD7TnxuXGMeta?.path ?? ":projectId()/products/:productId()",
    children: [
  {
    path: buildB0ZOjRhfg4Meta?.path ?? "build",
    children: [
  {
    path: _91buildId_93SD9SKlZWROMeta?.path ?? ":buildId()",
    children: [
  {
    name: indexzAqMn9T9MxMeta?.name ?? "groupId-projects-projectId-products-productId-build-buildId___en",
    path: indexzAqMn9T9MxMeta?.path ?? "",
    meta: indexzAqMn9T9MxMeta || {},
    alias: indexzAqMn9T9MxMeta?.alias || [],
    redirect: indexzAqMn9T9MxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/index.vue").then(m => m.default || m)
  },
  {
    name: livehu3drSQ5HXMeta?.name ?? "groupId-projects-projectId-products-productId-build-buildId-live___en",
    path: livehu3drSQ5HXMeta?.path ?? "live",
    meta: livehu3drSQ5HXMeta || {},
    alias: livehu3drSQ5HXMeta?.alias || [],
    redirect: livehu3drSQ5HXMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/live.vue").then(m => m.default || m)
  }
],
    name: _91buildId_93SD9SKlZWROMeta?.name ?? undefined,
    meta: _91buildId_93SD9SKlZWROMeta || {},
    alias: _91buildId_93SD9SKlZWROMeta?.alias || [],
    redirect: _91buildId_93SD9SKlZWROMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId].vue").then(m => m.default || m)
  },
  {
    name: indexEdlUa4zgOEMeta?.name ?? "groupId-projects-projectId-products-productId-build___en",
    path: indexEdlUa4zgOEMeta?.path ?? "",
    meta: indexEdlUa4zgOEMeta || {},
    alias: indexEdlUa4zgOEMeta?.alias || [],
    redirect: indexEdlUa4zgOEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/index.vue").then(m => m.default || m)
  }
],
    name: buildB0ZOjRhfg4Meta?.name ?? undefined,
    meta: buildB0ZOjRhfg4Meta || {},
    alias: buildB0ZOjRhfg4Meta?.alias || [],
    redirect: buildB0ZOjRhfg4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build.vue").then(m => m.default || m)
  },
  {
    path: discount7vspSFrvgrMeta?.path ?? "discount",
    children: [
  {
    name: _91discountId_937IiHSoBTVEMeta?.name ?? "groupId-projects-projectId-products-productId-discount-discountId___en",
    path: _91discountId_937IiHSoBTVEMeta?.path ?? ":discountId()",
    meta: _91discountId_937IiHSoBTVEMeta || {},
    alias: _91discountId_937IiHSoBTVEMeta?.alias || [],
    redirect: _91discountId_937IiHSoBTVEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/[discountId].vue").then(m => m.default || m)
  },
  {
    name: indexiOnLIBnpj9Meta?.name ?? "groupId-projects-projectId-products-productId-discount___en",
    path: indexiOnLIBnpj9Meta?.path ?? "",
    meta: indexiOnLIBnpj9Meta || {},
    alias: indexiOnLIBnpj9Meta?.alias || [],
    redirect: indexiOnLIBnpj9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/index.vue").then(m => m.default || m)
  },
  {
    name: registerseVaz102vrMeta?.name ?? "groupId-projects-projectId-products-productId-discount-register___en",
    path: registerseVaz102vrMeta?.path ?? "register",
    meta: registerseVaz102vrMeta || {},
    alias: registerseVaz102vrMeta?.alias || [],
    redirect: registerseVaz102vrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/register.vue").then(m => m.default || m)
  }
],
    name: discount7vspSFrvgrMeta?.name ?? undefined,
    meta: discount7vspSFrvgrMeta || {},
    alias: discount7vspSFrvgrMeta?.alias || [],
    redirect: discount7vspSFrvgrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount.vue").then(m => m.default || m)
  },
  {
    name: early_45accessYWpHEZN6iiMeta?.name ?? "groupId-projects-projectId-products-productId-early-access___en",
    path: early_45accessYWpHEZN6iiMeta?.path ?? "early-access",
    meta: early_45accessYWpHEZN6iiMeta || {},
    alias: early_45accessYWpHEZN6iiMeta?.alias || [],
    redirect: early_45accessYWpHEZN6iiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/early-access.vue").then(m => m.default || m)
  },
  {
    name: indexlsUkhnJiMEMeta?.name ?? "groupId-projects-projectId-products-productId___en",
    path: indexlsUkhnJiMEMeta?.path ?? "",
    meta: indexlsUkhnJiMEMeta || {},
    alias: indexlsUkhnJiMEMeta?.alias || [],
    redirect: indexlsUkhnJiMEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: launch_45settingMT4E0WWTIiMeta?.name ?? "groupId-projects-projectId-products-productId-launch-setting___en",
    path: launch_45settingMT4E0WWTIiMeta?.path ?? "launch-setting",
    meta: launch_45settingMT4E0WWTIiMeta || {},
    alias: launch_45settingMT4E0WWTIiMeta?.alias || [],
    redirect: launch_45settingMT4E0WWTIiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/launch-setting.vue").then(m => m.default || m)
  },
  {
    name: banner_45imageDR27uqBOz0Meta?.name ?? "groupId-projects-projectId-products-productId-marketing-management-banner-image___en",
    path: banner_45imageDR27uqBOz0Meta?.path ?? "marketing-management/banner-image",
    meta: banner_45imageDR27uqBOz0Meta || {},
    alias: banner_45imageDR27uqBOz0Meta?.alias || [],
    redirect: banner_45imageDR27uqBOz0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/marketing-management/banner-image.vue").then(m => m.default || m)
  },
  {
    path: product_45pagesLCqMKJKrfLMeta?.path ?? "product-pages",
    children: [
  {
    name: _91mode_93JPPqnAouWiMeta?.name ?? "groupId-projects-projectId-products-productId-product-pages-mode___en",
    path: _91mode_93JPPqnAouWiMeta?.path ?? ":mode()",
    meta: _91mode_93JPPqnAouWiMeta || {},
    alias: _91mode_93JPPqnAouWiMeta?.alias || [],
    redirect: _91mode_93JPPqnAouWiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexBRGfUaqcxiMeta?.name ?? "groupId-projects-projectId-products-productId-product-pages___en",
    path: indexBRGfUaqcxiMeta?.path ?? "",
    meta: indexBRGfUaqcxiMeta || {},
    alias: indexBRGfUaqcxiMeta?.alias || [],
    redirect: indexBRGfUaqcxiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/index.vue").then(m => m.default || m)
  }
],
    name: product_45pagesLCqMKJKrfLMeta?.name ?? undefined,
    meta: product_45pagesLCqMKJKrfLMeta || {},
    alias: product_45pagesLCqMKJKrfLMeta?.alias || [],
    redirect: product_45pagesLCqMKJKrfLMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages.vue").then(m => m.default || m)
  },
  {
    name: purchase_45conditionK3XckRfhLoMeta?.name ?? "groupId-projects-projectId-products-productId-purchase-condition___en",
    path: purchase_45conditionK3XckRfhLoMeta?.path ?? "purchase-condition",
    meta: purchase_45conditionK3XckRfhLoMeta || {},
    alias: purchase_45conditionK3XckRfhLoMeta?.alias || [],
    redirect: purchase_45conditionK3XckRfhLoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/purchase-condition.vue").then(m => m.default || m)
  },
  {
    path: ratingc2cRWY5NFaMeta?.path ?? "rating",
    children: [
  {
    name: adjustment5iVjtprje2Meta?.name ?? "groupId-projects-projectId-products-productId-rating-adjustment___en",
    path: adjustment5iVjtprje2Meta?.path ?? "adjustment",
    meta: adjustment5iVjtprje2Meta || {},
    alias: adjustment5iVjtprje2Meta?.alias || [],
    redirect: adjustment5iVjtprje2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/adjustment.vue").then(m => m.default || m)
  },
  {
    name: cancelOJETfvWOkFMeta?.name ?? "groupId-projects-projectId-products-productId-rating-cancel___en",
    path: cancelOJETfvWOkFMeta?.path ?? "cancel",
    meta: cancelOJETfvWOkFMeta || {},
    alias: cancelOJETfvWOkFMeta?.alias || [],
    redirect: cancelOJETfvWOkFMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/cancel.vue").then(m => m.default || m)
  },
  {
    path: detailyiutK40ahtMeta?.path ?? "detail",
    children: [
  {
    name: grac_45input4q8emIm6TRMeta?.name ?? "groupId-projects-projectId-products-productId-rating-detail-grac-input___en",
    path: grac_45input4q8emIm6TRMeta?.path ?? "grac-input",
    meta: grac_45input4q8emIm6TRMeta || {},
    alias: grac_45input4q8emIm6TRMeta?.alias || [],
    redirect: grac_45input4q8emIm6TRMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/grac-input.vue").then(m => m.default || m)
  },
  {
    name: indexu8S83GQqVAMeta?.name ?? "groupId-projects-projectId-products-productId-rating-detail___en",
    path: indexu8S83GQqVAMeta?.path ?? "",
    meta: indexu8S83GQqVAMeta || {},
    alias: indexu8S83GQqVAMeta?.alias || [],
    redirect: indexu8S83GQqVAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/index.vue").then(m => m.default || m)
  }
],
    name: detailyiutK40ahtMeta?.name ?? undefined,
    meta: detailyiutK40ahtMeta || {},
    alias: detailyiutK40ahtMeta?.alias || [],
    redirect: detailyiutK40ahtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail.vue").then(m => m.default || m)
  },
  {
    name: historyf5QtLr648UMeta?.name ?? "groupId-projects-projectId-products-productId-rating-history___en",
    path: historyf5QtLr648UMeta?.path ?? "history",
    meta: historyf5QtLr648UMeta || {},
    alias: historyf5QtLr648UMeta?.alias || [],
    redirect: historyf5QtLr648UMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/history.vue").then(m => m.default || m)
  },
  {
    name: indexNtRSF10ctIMeta?.name ?? "groupId-projects-projectId-products-productId-rating___en",
    path: indexNtRSF10ctIMeta?.path ?? "",
    meta: indexNtRSF10ctIMeta || {},
    alias: indexNtRSF10ctIMeta?.alias || [],
    redirect: indexNtRSF10ctIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/index.vue").then(m => m.default || m)
  },
  {
    name: reclassificationYFY383yHKEMeta?.name ?? "groupId-projects-projectId-products-productId-rating-reclassification___en",
    path: reclassificationYFY383yHKEMeta?.path ?? "reclassification",
    meta: reclassificationYFY383yHKEMeta || {},
    alias: reclassificationYFY383yHKEMeta?.alias || [],
    redirect: reclassificationYFY383yHKEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reclassification.vue").then(m => m.default || m)
  },
  {
    path: registervkCPeN9ReWMeta?.path ?? "register",
    children: [
  {
    name: grac_45input6kXdR2bZihMeta?.name ?? "groupId-projects-projectId-products-productId-rating-register-grac-input___en",
    path: grac_45input6kXdR2bZihMeta?.path ?? "grac-input",
    meta: grac_45input6kXdR2bZihMeta || {},
    alias: grac_45input6kXdR2bZihMeta?.alias || [],
    redirect: grac_45input6kXdR2bZihMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/grac-input.vue").then(m => m.default || m)
  },
  {
    name: indexdlJrcjbr8ZMeta?.name ?? "groupId-projects-projectId-products-productId-rating-register___en",
    path: indexdlJrcjbr8ZMeta?.path ?? "",
    meta: indexdlJrcjbr8ZMeta || {},
    alias: indexdlJrcjbr8ZMeta?.alias || [],
    redirect: indexdlJrcjbr8ZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/index.vue").then(m => m.default || m)
  }
],
    name: registervkCPeN9ReWMeta?.name ?? undefined,
    meta: registervkCPeN9ReWMeta || {},
    alias: registervkCPeN9ReWMeta?.alias || [],
    redirect: registervkCPeN9ReWMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register.vue").then(m => m.default || m)
  },
  {
    name: reject_45reasonXkFa3rBewqMeta?.name ?? "groupId-projects-projectId-products-productId-rating-reject-reason___en",
    path: reject_45reasonXkFa3rBewqMeta?.path ?? "reject-reason",
    meta: reject_45reasonXkFa3rBewqMeta || {},
    alias: reject_45reasonXkFa3rBewqMeta?.alias || [],
    redirect: reject_45reasonXkFa3rBewqMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reject-reason.vue").then(m => m.default || m)
  },
  {
    name: rejectionlQCjroVCC0Meta?.name ?? "groupId-projects-projectId-products-productId-rating-rejection___en",
    path: rejectionlQCjroVCC0Meta?.path ?? "rejection",
    meta: rejectionlQCjroVCC0Meta || {},
    alias: rejectionlQCjroVCC0Meta?.alias || [],
    redirect: rejectionlQCjroVCC0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/rejection.vue").then(m => m.default || m)
  }
],
    name: ratingc2cRWY5NFaMeta?.name ?? undefined,
    meta: ratingc2cRWY5NFaMeta || {},
    alias: ratingc2cRWY5NFaMeta?.alias || [],
    redirect: ratingc2cRWY5NFaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating.vue").then(m => m.default || m)
  },
  {
    path: release_45planc5emQj8pP6Meta?.path ?? "release-plan",
    children: [
  {
    name: detailWVE89LL0yOMeta?.name ?? "groupId-projects-projectId-products-productId-release-plan-detail___en",
    path: detailWVE89LL0yOMeta?.path ?? "detail",
    meta: detailWVE89LL0yOMeta || {},
    alias: detailWVE89LL0yOMeta?.alias || [],
    redirect: detailWVE89LL0yOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/detail.vue").then(m => m.default || m)
  },
  {
    name: indexLS1Oo7qey4Meta?.name ?? "groupId-projects-projectId-products-productId-release-plan___en",
    path: indexLS1Oo7qey4Meta?.path ?? "",
    meta: indexLS1Oo7qey4Meta || {},
    alias: indexLS1Oo7qey4Meta?.alias || [],
    redirect: indexLS1Oo7qey4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/index.vue").then(m => m.default || m)
  }
],
    name: release_45planc5emQj8pP6Meta?.name ?? undefined,
    meta: release_45planc5emQj8pP6Meta || {},
    alias: release_45planc5emQj8pP6Meta?.alias || [],
    redirect: release_45planc5emQj8pP6Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan.vue").then(m => m.default || m)
  },
  {
    path: selling_45priceaItQqETfYQMeta?.path ?? "selling-price",
    children: [
  {
    name: editmweUb6h5s0Meta?.name ?? "groupId-projects-projectId-products-productId-selling-price-edit___en",
    path: editmweUb6h5s0Meta?.path ?? "edit",
    meta: editmweUb6h5s0Meta || {},
    alias: editmweUb6h5s0Meta?.alias || [],
    redirect: editmweUb6h5s0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/edit.vue").then(m => m.default || m)
  },
  {
    name: indexMDoQJWxC9TMeta?.name ?? "groupId-projects-projectId-products-productId-selling-price___en",
    path: indexMDoQJWxC9TMeta?.path ?? "",
    meta: indexMDoQJWxC9TMeta || {},
    alias: indexMDoQJWxC9TMeta?.alias || [],
    redirect: indexMDoQJWxC9TMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/index.vue").then(m => m.default || m)
  },
  {
    name: register5pUDTzLhD2Meta?.name ?? "groupId-projects-projectId-products-productId-selling-price-register___en",
    path: register5pUDTzLhD2Meta?.path ?? "register",
    meta: register5pUDTzLhD2Meta || {},
    alias: register5pUDTzLhD2Meta?.alias || [],
    redirect: register5pUDTzLhD2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/register.vue").then(m => m.default || m)
  }
],
    name: selling_45priceaItQqETfYQMeta?.name ?? undefined,
    meta: selling_45priceaItQqETfYQMeta || {},
    alias: selling_45priceaItQqETfYQMeta?.alias || [],
    redirect: selling_45priceaItQqETfYQMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price.vue").then(m => m.default || m)
  },
  {
    name: settingsT2NY6WJQl3Meta?.name ?? "groupId-projects-projectId-products-productId-settings___en",
    path: settingsT2NY6WJQl3Meta?.path ?? "settings",
    meta: settingsT2NY6WJQl3Meta || {},
    alias: settingsT2NY6WJQl3Meta?.alias || [],
    redirect: settingsT2NY6WJQl3Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/settings.vue").then(m => m.default || m)
  },
  {
    path: termsAza8XRdk45Meta?.path ?? "terms",
    children: [
  {
    name: editSp50nj3bNaMeta?.name ?? "groupId-projects-projectId-products-productId-terms-edit___en",
    path: editSp50nj3bNaMeta?.path ?? "edit",
    meta: editSp50nj3bNaMeta || {},
    alias: editSp50nj3bNaMeta?.alias || [],
    redirect: editSp50nj3bNaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/edit.vue").then(m => m.default || m)
  },
  {
    name: indexNDSsdBKRt4Meta?.name ?? "groupId-projects-projectId-products-productId-terms___en",
    path: indexNDSsdBKRt4Meta?.path ?? "",
    meta: indexNDSsdBKRt4Meta || {},
    alias: indexNDSsdBKRt4Meta?.alias || [],
    redirect: indexNDSsdBKRt4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/index.vue").then(m => m.default || m)
  },
  {
    name: registergfOlj3ZCdVMeta?.name ?? "groupId-projects-projectId-products-productId-terms-register___en",
    path: registergfOlj3ZCdVMeta?.path ?? "register",
    meta: registergfOlj3ZCdVMeta || {},
    alias: registergfOlj3ZCdVMeta?.alias || [],
    redirect: registergfOlj3ZCdVMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/register.vue").then(m => m.default || m)
  }
],
    name: termsAza8XRdk45Meta?.name ?? undefined,
    meta: termsAza8XRdk45Meta || {},
    alias: termsAza8XRdk45Meta?.alias || [],
    redirect: termsAza8XRdk45Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms.vue").then(m => m.default || m)
  }
],
    name: _91productId_93ToD7TnxuXGMeta?.name ?? undefined,
    meta: _91productId_93ToD7TnxuXGMeta || {},
    alias: _91productId_93ToD7TnxuXGMeta?.alias || [],
    redirect: _91productId_93ToD7TnxuXGMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId].vue").then(m => m.default || m)
  },
  {
    name: indexKUB2ino9kbMeta?.name ?? "groupId-projects-projectId-products___en",
    path: indexKUB2ino9kbMeta?.path ?? ":projectId()/products",
    meta: indexKUB2ino9kbMeta || {},
    alias: indexKUB2ino9kbMeta?.alias || [],
    redirect: indexKUB2ino9kbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/index.vue").then(m => m.default || m)
  },
  {
    name: registerYCPVGOTJ7GMeta?.name ?? "groupId-projects-projectId-products-register___en",
    path: registerYCPVGOTJ7GMeta?.path ?? ":projectId()/products/register",
    meta: registerYCPVGOTJ7GMeta || {},
    alias: registerYCPVGOTJ7GMeta?.alias || [],
    redirect: registerYCPVGOTJ7GMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/register.vue").then(m => m.default || m)
  },
  {
    name: index0A7OKE0A8hMeta?.name ?? "groupId-projects___en",
    path: index0A7OKE0A8hMeta?.path ?? "",
    meta: index0A7OKE0A8hMeta || {},
    alias: index0A7OKE0A8hMeta?.alias || [],
    redirect: index0A7OKE0A8hMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/index.vue").then(m => m.default || m)
  },
  {
    name: register32S2WihcfoMeta?.name ?? "groupId-projects-register___en",
    path: register32S2WihcfoMeta?.path ?? "register",
    meta: register32S2WihcfoMeta || {},
    alias: register32S2WihcfoMeta?.alias || [],
    redirect: register32S2WihcfoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/register.vue").then(m => m.default || m)
  }
],
    name: projectsOhTjRVWZ7jMeta?.name ?? undefined,
    meta: projectsOhTjRVWZ7jMeta || {},
    alias: projectsOhTjRVWZ7jMeta?.alias || [],
    redirect: projectsOhTjRVWZ7jMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects.vue").then(m => m.default || m)
  }
],
    name: _91groupId_937i5oTSd92jMeta?.name ?? undefined,
    meta: _91groupId_937i5oTSd92jMeta || {},
    alias: _91groupId_937i5oTSd92jMeta?.alias || [],
    redirect: _91groupId_937i5oTSd92jMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId].vue").then(m => m.default || m)
  },
  {
    path: _91groupId_937i5oTSd92jMeta?.path ?? "/zh-cn/:groupId()",
    children: [
  {
    path: business_45bankoOuWOVJeWxMeta?.path ?? "business-bank",
    children: [
  {
    name: bank_45listR2crP43aL7Meta?.name ?? "groupId-business-bank-bank-list___zh-cn",
    path: bank_45listR2crP43aL7Meta?.path ?? "bank-list",
    meta: bank_45listR2crP43aL7Meta || {},
    alias: bank_45listR2crP43aL7Meta?.alias || [],
    redirect: bank_45listR2crP43aL7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-list.vue").then(m => m.default || m)
  },
  {
    name: bank_45registerfZwZdsKoxTMeta?.name ?? "groupId-business-bank-bank-register___zh-cn",
    path: bank_45registerfZwZdsKoxTMeta?.path ?? "bank-register",
    meta: bank_45registerfZwZdsKoxTMeta || {},
    alias: bank_45registerfZwZdsKoxTMeta?.alias || [],
    redirect: bank_45registerfZwZdsKoxTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-register.vue").then(m => m.default || m)
  },
  {
    name: business_45list9ADky5juF7Meta?.name ?? "groupId-business-bank-business-list___zh-cn",
    path: business_45list9ADky5juF7Meta?.path ?? "business-list",
    meta: business_45list9ADky5juF7Meta || {},
    alias: business_45list9ADky5juF7Meta?.alias || [],
    redirect: business_45list9ADky5juF7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-list.vue").then(m => m.default || m)
  },
  {
    name: business_45registern6jErCPT4XMeta?.name ?? "groupId-business-bank-business-register___zh-cn",
    path: business_45registern6jErCPT4XMeta?.path ?? "business-register",
    meta: business_45registern6jErCPT4XMeta || {},
    alias: business_45registern6jErCPT4XMeta?.alias || [],
    redirect: business_45registern6jErCPT4XMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-register.vue").then(m => m.default || m)
  },
  {
    name: indexkMkjtKISLhMeta?.name ?? "groupId-business-bank___zh-cn",
    path: indexkMkjtKISLhMeta?.path ?? "",
    meta: indexkMkjtKISLhMeta || {},
    alias: indexkMkjtKISLhMeta?.alias || [],
    redirect: indexkMkjtKISLhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/index.vue").then(m => m.default || m)
  }
],
    name: business_45bankoOuWOVJeWxMeta?.name ?? undefined,
    meta: business_45bankoOuWOVJeWxMeta || {},
    alias: business_45bankoOuWOVJeWxMeta?.alias || [],
    redirect: business_45bankoOuWOVJeWxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank.vue").then(m => m.default || m)
  },
  {
    path: collectionsFAthRT6rdEMeta?.path ?? "collections",
    children: [
  {
    path: discountIy6GV18yopMeta?.path ?? ":collectionId()/discount",
    children: [
  {
    name: _91mode_93zdEaxOziNBMeta?.name ?? "groupId-collections-collectionId-discount-mode___zh-cn",
    path: _91mode_93zdEaxOziNBMeta?.path ?? ":mode()",
    meta: _91mode_93zdEaxOziNBMeta || {},
    alias: _91mode_93zdEaxOziNBMeta?.alias || [],
    redirect: _91mode_93zdEaxOziNBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexrkJAI5TrwYMeta?.name ?? "groupId-collections-collectionId-discount___zh-cn",
    path: indexrkJAI5TrwYMeta?.path ?? "",
    meta: indexrkJAI5TrwYMeta || {},
    alias: indexrkJAI5TrwYMeta?.alias || [],
    redirect: indexrkJAI5TrwYMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/index.vue").then(m => m.default || m)
  }
],
    name: discountIy6GV18yopMeta?.name ?? undefined,
    meta: discountIy6GV18yopMeta || {},
    alias: discountIy6GV18yopMeta?.alias || [],
    redirect: discountIy6GV18yopMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount.vue").then(m => m.default || m)
  },
  {
    name: _91mode_931ttLzRO79BMeta?.name ?? "groupId-collections-mode___zh-cn",
    path: _91mode_931ttLzRO79BMeta?.path ?? ":mode()",
    meta: _91mode_931ttLzRO79BMeta || {},
    alias: _91mode_931ttLzRO79BMeta?.alias || [],
    redirect: _91mode_931ttLzRO79BMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexEkIF65mYs4Meta?.name ?? "groupId-collections___zh-cn",
    path: indexEkIF65mYs4Meta?.path ?? "",
    meta: indexEkIF65mYs4Meta || {},
    alias: indexEkIF65mYs4Meta?.alias || [],
    redirect: indexEkIF65mYs4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/index.vue").then(m => m.default || m)
  }
],
    name: collectionsFAthRT6rdEMeta?.name ?? undefined,
    meta: collectionsFAthRT6rdEMeta || {},
    alias: collectionsFAthRT6rdEMeta?.alias || [],
    redirect: collectionsFAthRT6rdEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections.vue").then(m => m.default || m)
  },
  {
    name: homeluYuH1WOqBMeta?.name ?? "groupId-home___zh-cn",
    path: homeluYuH1WOqBMeta?.path ?? "home",
    meta: homeluYuH1WOqBMeta || {},
    alias: homeluYuH1WOqBMeta?.alias || [],
    redirect: homeluYuH1WOqBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/home.vue").then(m => m.default || m)
  },
  {
    name: indexkKv5NgYD07Meta?.name ?? "groupId___zh-cn",
    path: indexkKv5NgYD07Meta?.path ?? "",
    meta: indexkKv5NgYD07Meta || {},
    alias: indexkKv5NgYD07Meta?.alias || [],
    redirect: indexkKv5NgYD07Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/index.vue").then(m => m.default || m)
  },
  {
    path: membersQSvU5dg3aaMeta?.path ?? "members",
    children: [
  {
    name: _91id_938qdkdues3NMeta?.name ?? "groupId-members-id___zh-cn",
    path: _91id_938qdkdues3NMeta?.path ?? ":id()",
    meta: _91id_938qdkdues3NMeta || {},
    alias: _91id_938qdkdues3NMeta?.alias || [],
    redirect: _91id_938qdkdues3NMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYB7qgwmZpiMeta?.name ?? "groupId-members___zh-cn",
    path: indexYB7qgwmZpiMeta?.path ?? "",
    meta: indexYB7qgwmZpiMeta || {},
    alias: indexYB7qgwmZpiMeta?.alias || [],
    redirect: indexYB7qgwmZpiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: invite_45historyozwdBjUG7zMeta?.name ?? "groupId-members-invite-history___zh-cn",
    path: invite_45historyozwdBjUG7zMeta?.path ?? "invite-history",
    meta: invite_45historyozwdBjUG7zMeta || {},
    alias: invite_45historyozwdBjUG7zMeta?.alias || [],
    redirect: invite_45historyozwdBjUG7zMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite-history.vue").then(m => m.default || m)
  },
  {
    name: invitewLIxfuFPQgMeta?.name ?? "groupId-members-invite___zh-cn",
    path: invitewLIxfuFPQgMeta?.path ?? "invite",
    meta: invitewLIxfuFPQgMeta || {},
    alias: invitewLIxfuFPQgMeta?.alias || [],
    redirect: invitewLIxfuFPQgMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite.vue").then(m => m.default || m)
  },
  {
    name: _91mode_932jWlpHxdFxMeta?.name ?? "groupId-members-roles-mode___zh-cn",
    path: _91mode_932jWlpHxdFxMeta?.path ?? "roles/:mode()",
    meta: _91mode_932jWlpHxdFxMeta || {},
    alias: _91mode_932jWlpHxdFxMeta?.alias || [],
    redirect: _91mode_932jWlpHxdFxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexOC4UC0HCBIMeta?.name ?? "groupId-members-roles___zh-cn",
    path: indexOC4UC0HCBIMeta?.path ?? "roles",
    meta: indexOC4UC0HCBIMeta || {},
    alias: indexOC4UC0HCBIMeta?.alias || [],
    redirect: indexOC4UC0HCBIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/index.vue").then(m => m.default || m)
  },
  {
    name: wait_45listVCtguf2CtxMeta?.name ?? "groupId-members-wait-list___zh-cn",
    path: wait_45listVCtguf2CtxMeta?.path ?? "wait-list",
    meta: wait_45listVCtguf2CtxMeta || {},
    alias: wait_45listVCtguf2CtxMeta?.alias || [],
    redirect: wait_45listVCtguf2CtxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/wait-list.vue").then(m => m.default || m)
  }
],
    name: membersQSvU5dg3aaMeta?.name ?? undefined,
    meta: membersQSvU5dg3aaMeta || {},
    alias: membersQSvU5dg3aaMeta?.alias || [],
    redirect: membersQSvU5dg3aaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members.vue").then(m => m.default || m)
  },
  {
    path: projectsOhTjRVWZ7jMeta?.path ?? "projects",
    children: [
  {
    path: _91productId_93ToD7TnxuXGMeta?.path ?? ":projectId()/products/:productId()",
    children: [
  {
    path: buildB0ZOjRhfg4Meta?.path ?? "build",
    children: [
  {
    path: _91buildId_93SD9SKlZWROMeta?.path ?? ":buildId()",
    children: [
  {
    name: indexzAqMn9T9MxMeta?.name ?? "groupId-projects-projectId-products-productId-build-buildId___zh-cn",
    path: indexzAqMn9T9MxMeta?.path ?? "",
    meta: indexzAqMn9T9MxMeta || {},
    alias: indexzAqMn9T9MxMeta?.alias || [],
    redirect: indexzAqMn9T9MxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/index.vue").then(m => m.default || m)
  },
  {
    name: livehu3drSQ5HXMeta?.name ?? "groupId-projects-projectId-products-productId-build-buildId-live___zh-cn",
    path: livehu3drSQ5HXMeta?.path ?? "live",
    meta: livehu3drSQ5HXMeta || {},
    alias: livehu3drSQ5HXMeta?.alias || [],
    redirect: livehu3drSQ5HXMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/live.vue").then(m => m.default || m)
  }
],
    name: _91buildId_93SD9SKlZWROMeta?.name ?? undefined,
    meta: _91buildId_93SD9SKlZWROMeta || {},
    alias: _91buildId_93SD9SKlZWROMeta?.alias || [],
    redirect: _91buildId_93SD9SKlZWROMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId].vue").then(m => m.default || m)
  },
  {
    name: indexEdlUa4zgOEMeta?.name ?? "groupId-projects-projectId-products-productId-build___zh-cn",
    path: indexEdlUa4zgOEMeta?.path ?? "",
    meta: indexEdlUa4zgOEMeta || {},
    alias: indexEdlUa4zgOEMeta?.alias || [],
    redirect: indexEdlUa4zgOEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/index.vue").then(m => m.default || m)
  }
],
    name: buildB0ZOjRhfg4Meta?.name ?? undefined,
    meta: buildB0ZOjRhfg4Meta || {},
    alias: buildB0ZOjRhfg4Meta?.alias || [],
    redirect: buildB0ZOjRhfg4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build.vue").then(m => m.default || m)
  },
  {
    path: discount7vspSFrvgrMeta?.path ?? "discount",
    children: [
  {
    name: _91discountId_937IiHSoBTVEMeta?.name ?? "groupId-projects-projectId-products-productId-discount-discountId___zh-cn",
    path: _91discountId_937IiHSoBTVEMeta?.path ?? ":discountId()",
    meta: _91discountId_937IiHSoBTVEMeta || {},
    alias: _91discountId_937IiHSoBTVEMeta?.alias || [],
    redirect: _91discountId_937IiHSoBTVEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/[discountId].vue").then(m => m.default || m)
  },
  {
    name: indexiOnLIBnpj9Meta?.name ?? "groupId-projects-projectId-products-productId-discount___zh-cn",
    path: indexiOnLIBnpj9Meta?.path ?? "",
    meta: indexiOnLIBnpj9Meta || {},
    alias: indexiOnLIBnpj9Meta?.alias || [],
    redirect: indexiOnLIBnpj9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/index.vue").then(m => m.default || m)
  },
  {
    name: registerseVaz102vrMeta?.name ?? "groupId-projects-projectId-products-productId-discount-register___zh-cn",
    path: registerseVaz102vrMeta?.path ?? "register",
    meta: registerseVaz102vrMeta || {},
    alias: registerseVaz102vrMeta?.alias || [],
    redirect: registerseVaz102vrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/register.vue").then(m => m.default || m)
  }
],
    name: discount7vspSFrvgrMeta?.name ?? undefined,
    meta: discount7vspSFrvgrMeta || {},
    alias: discount7vspSFrvgrMeta?.alias || [],
    redirect: discount7vspSFrvgrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount.vue").then(m => m.default || m)
  },
  {
    name: early_45accessYWpHEZN6iiMeta?.name ?? "groupId-projects-projectId-products-productId-early-access___zh-cn",
    path: early_45accessYWpHEZN6iiMeta?.path ?? "early-access",
    meta: early_45accessYWpHEZN6iiMeta || {},
    alias: early_45accessYWpHEZN6iiMeta?.alias || [],
    redirect: early_45accessYWpHEZN6iiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/early-access.vue").then(m => m.default || m)
  },
  {
    name: indexlsUkhnJiMEMeta?.name ?? "groupId-projects-projectId-products-productId___zh-cn",
    path: indexlsUkhnJiMEMeta?.path ?? "",
    meta: indexlsUkhnJiMEMeta || {},
    alias: indexlsUkhnJiMEMeta?.alias || [],
    redirect: indexlsUkhnJiMEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: launch_45settingMT4E0WWTIiMeta?.name ?? "groupId-projects-projectId-products-productId-launch-setting___zh-cn",
    path: launch_45settingMT4E0WWTIiMeta?.path ?? "launch-setting",
    meta: launch_45settingMT4E0WWTIiMeta || {},
    alias: launch_45settingMT4E0WWTIiMeta?.alias || [],
    redirect: launch_45settingMT4E0WWTIiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/launch-setting.vue").then(m => m.default || m)
  },
  {
    name: banner_45imageDR27uqBOz0Meta?.name ?? "groupId-projects-projectId-products-productId-marketing-management-banner-image___zh-cn",
    path: banner_45imageDR27uqBOz0Meta?.path ?? "marketing-management/banner-image",
    meta: banner_45imageDR27uqBOz0Meta || {},
    alias: banner_45imageDR27uqBOz0Meta?.alias || [],
    redirect: banner_45imageDR27uqBOz0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/marketing-management/banner-image.vue").then(m => m.default || m)
  },
  {
    path: product_45pagesLCqMKJKrfLMeta?.path ?? "product-pages",
    children: [
  {
    name: _91mode_93JPPqnAouWiMeta?.name ?? "groupId-projects-projectId-products-productId-product-pages-mode___zh-cn",
    path: _91mode_93JPPqnAouWiMeta?.path ?? ":mode()",
    meta: _91mode_93JPPqnAouWiMeta || {},
    alias: _91mode_93JPPqnAouWiMeta?.alias || [],
    redirect: _91mode_93JPPqnAouWiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexBRGfUaqcxiMeta?.name ?? "groupId-projects-projectId-products-productId-product-pages___zh-cn",
    path: indexBRGfUaqcxiMeta?.path ?? "",
    meta: indexBRGfUaqcxiMeta || {},
    alias: indexBRGfUaqcxiMeta?.alias || [],
    redirect: indexBRGfUaqcxiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/index.vue").then(m => m.default || m)
  }
],
    name: product_45pagesLCqMKJKrfLMeta?.name ?? undefined,
    meta: product_45pagesLCqMKJKrfLMeta || {},
    alias: product_45pagesLCqMKJKrfLMeta?.alias || [],
    redirect: product_45pagesLCqMKJKrfLMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages.vue").then(m => m.default || m)
  },
  {
    name: purchase_45conditionK3XckRfhLoMeta?.name ?? "groupId-projects-projectId-products-productId-purchase-condition___zh-cn",
    path: purchase_45conditionK3XckRfhLoMeta?.path ?? "purchase-condition",
    meta: purchase_45conditionK3XckRfhLoMeta || {},
    alias: purchase_45conditionK3XckRfhLoMeta?.alias || [],
    redirect: purchase_45conditionK3XckRfhLoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/purchase-condition.vue").then(m => m.default || m)
  },
  {
    path: ratingc2cRWY5NFaMeta?.path ?? "rating",
    children: [
  {
    name: adjustment5iVjtprje2Meta?.name ?? "groupId-projects-projectId-products-productId-rating-adjustment___zh-cn",
    path: adjustment5iVjtprje2Meta?.path ?? "adjustment",
    meta: adjustment5iVjtprje2Meta || {},
    alias: adjustment5iVjtprje2Meta?.alias || [],
    redirect: adjustment5iVjtprje2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/adjustment.vue").then(m => m.default || m)
  },
  {
    name: cancelOJETfvWOkFMeta?.name ?? "groupId-projects-projectId-products-productId-rating-cancel___zh-cn",
    path: cancelOJETfvWOkFMeta?.path ?? "cancel",
    meta: cancelOJETfvWOkFMeta || {},
    alias: cancelOJETfvWOkFMeta?.alias || [],
    redirect: cancelOJETfvWOkFMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/cancel.vue").then(m => m.default || m)
  },
  {
    path: detailyiutK40ahtMeta?.path ?? "detail",
    children: [
  {
    name: grac_45input4q8emIm6TRMeta?.name ?? "groupId-projects-projectId-products-productId-rating-detail-grac-input___zh-cn",
    path: grac_45input4q8emIm6TRMeta?.path ?? "grac-input",
    meta: grac_45input4q8emIm6TRMeta || {},
    alias: grac_45input4q8emIm6TRMeta?.alias || [],
    redirect: grac_45input4q8emIm6TRMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/grac-input.vue").then(m => m.default || m)
  },
  {
    name: indexu8S83GQqVAMeta?.name ?? "groupId-projects-projectId-products-productId-rating-detail___zh-cn",
    path: indexu8S83GQqVAMeta?.path ?? "",
    meta: indexu8S83GQqVAMeta || {},
    alias: indexu8S83GQqVAMeta?.alias || [],
    redirect: indexu8S83GQqVAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/index.vue").then(m => m.default || m)
  }
],
    name: detailyiutK40ahtMeta?.name ?? undefined,
    meta: detailyiutK40ahtMeta || {},
    alias: detailyiutK40ahtMeta?.alias || [],
    redirect: detailyiutK40ahtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail.vue").then(m => m.default || m)
  },
  {
    name: historyf5QtLr648UMeta?.name ?? "groupId-projects-projectId-products-productId-rating-history___zh-cn",
    path: historyf5QtLr648UMeta?.path ?? "history",
    meta: historyf5QtLr648UMeta || {},
    alias: historyf5QtLr648UMeta?.alias || [],
    redirect: historyf5QtLr648UMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/history.vue").then(m => m.default || m)
  },
  {
    name: indexNtRSF10ctIMeta?.name ?? "groupId-projects-projectId-products-productId-rating___zh-cn",
    path: indexNtRSF10ctIMeta?.path ?? "",
    meta: indexNtRSF10ctIMeta || {},
    alias: indexNtRSF10ctIMeta?.alias || [],
    redirect: indexNtRSF10ctIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/index.vue").then(m => m.default || m)
  },
  {
    name: reclassificationYFY383yHKEMeta?.name ?? "groupId-projects-projectId-products-productId-rating-reclassification___zh-cn",
    path: reclassificationYFY383yHKEMeta?.path ?? "reclassification",
    meta: reclassificationYFY383yHKEMeta || {},
    alias: reclassificationYFY383yHKEMeta?.alias || [],
    redirect: reclassificationYFY383yHKEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reclassification.vue").then(m => m.default || m)
  },
  {
    path: registervkCPeN9ReWMeta?.path ?? "register",
    children: [
  {
    name: grac_45input6kXdR2bZihMeta?.name ?? "groupId-projects-projectId-products-productId-rating-register-grac-input___zh-cn",
    path: grac_45input6kXdR2bZihMeta?.path ?? "grac-input",
    meta: grac_45input6kXdR2bZihMeta || {},
    alias: grac_45input6kXdR2bZihMeta?.alias || [],
    redirect: grac_45input6kXdR2bZihMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/grac-input.vue").then(m => m.default || m)
  },
  {
    name: indexdlJrcjbr8ZMeta?.name ?? "groupId-projects-projectId-products-productId-rating-register___zh-cn",
    path: indexdlJrcjbr8ZMeta?.path ?? "",
    meta: indexdlJrcjbr8ZMeta || {},
    alias: indexdlJrcjbr8ZMeta?.alias || [],
    redirect: indexdlJrcjbr8ZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/index.vue").then(m => m.default || m)
  }
],
    name: registervkCPeN9ReWMeta?.name ?? undefined,
    meta: registervkCPeN9ReWMeta || {},
    alias: registervkCPeN9ReWMeta?.alias || [],
    redirect: registervkCPeN9ReWMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register.vue").then(m => m.default || m)
  },
  {
    name: reject_45reasonXkFa3rBewqMeta?.name ?? "groupId-projects-projectId-products-productId-rating-reject-reason___zh-cn",
    path: reject_45reasonXkFa3rBewqMeta?.path ?? "reject-reason",
    meta: reject_45reasonXkFa3rBewqMeta || {},
    alias: reject_45reasonXkFa3rBewqMeta?.alias || [],
    redirect: reject_45reasonXkFa3rBewqMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reject-reason.vue").then(m => m.default || m)
  },
  {
    name: rejectionlQCjroVCC0Meta?.name ?? "groupId-projects-projectId-products-productId-rating-rejection___zh-cn",
    path: rejectionlQCjroVCC0Meta?.path ?? "rejection",
    meta: rejectionlQCjroVCC0Meta || {},
    alias: rejectionlQCjroVCC0Meta?.alias || [],
    redirect: rejectionlQCjroVCC0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/rejection.vue").then(m => m.default || m)
  }
],
    name: ratingc2cRWY5NFaMeta?.name ?? undefined,
    meta: ratingc2cRWY5NFaMeta || {},
    alias: ratingc2cRWY5NFaMeta?.alias || [],
    redirect: ratingc2cRWY5NFaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating.vue").then(m => m.default || m)
  },
  {
    path: release_45planc5emQj8pP6Meta?.path ?? "release-plan",
    children: [
  {
    name: detailWVE89LL0yOMeta?.name ?? "groupId-projects-projectId-products-productId-release-plan-detail___zh-cn",
    path: detailWVE89LL0yOMeta?.path ?? "detail",
    meta: detailWVE89LL0yOMeta || {},
    alias: detailWVE89LL0yOMeta?.alias || [],
    redirect: detailWVE89LL0yOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/detail.vue").then(m => m.default || m)
  },
  {
    name: indexLS1Oo7qey4Meta?.name ?? "groupId-projects-projectId-products-productId-release-plan___zh-cn",
    path: indexLS1Oo7qey4Meta?.path ?? "",
    meta: indexLS1Oo7qey4Meta || {},
    alias: indexLS1Oo7qey4Meta?.alias || [],
    redirect: indexLS1Oo7qey4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/index.vue").then(m => m.default || m)
  }
],
    name: release_45planc5emQj8pP6Meta?.name ?? undefined,
    meta: release_45planc5emQj8pP6Meta || {},
    alias: release_45planc5emQj8pP6Meta?.alias || [],
    redirect: release_45planc5emQj8pP6Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan.vue").then(m => m.default || m)
  },
  {
    path: selling_45priceaItQqETfYQMeta?.path ?? "selling-price",
    children: [
  {
    name: editmweUb6h5s0Meta?.name ?? "groupId-projects-projectId-products-productId-selling-price-edit___zh-cn",
    path: editmweUb6h5s0Meta?.path ?? "edit",
    meta: editmweUb6h5s0Meta || {},
    alias: editmweUb6h5s0Meta?.alias || [],
    redirect: editmweUb6h5s0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/edit.vue").then(m => m.default || m)
  },
  {
    name: indexMDoQJWxC9TMeta?.name ?? "groupId-projects-projectId-products-productId-selling-price___zh-cn",
    path: indexMDoQJWxC9TMeta?.path ?? "",
    meta: indexMDoQJWxC9TMeta || {},
    alias: indexMDoQJWxC9TMeta?.alias || [],
    redirect: indexMDoQJWxC9TMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/index.vue").then(m => m.default || m)
  },
  {
    name: register5pUDTzLhD2Meta?.name ?? "groupId-projects-projectId-products-productId-selling-price-register___zh-cn",
    path: register5pUDTzLhD2Meta?.path ?? "register",
    meta: register5pUDTzLhD2Meta || {},
    alias: register5pUDTzLhD2Meta?.alias || [],
    redirect: register5pUDTzLhD2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/register.vue").then(m => m.default || m)
  }
],
    name: selling_45priceaItQqETfYQMeta?.name ?? undefined,
    meta: selling_45priceaItQqETfYQMeta || {},
    alias: selling_45priceaItQqETfYQMeta?.alias || [],
    redirect: selling_45priceaItQqETfYQMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price.vue").then(m => m.default || m)
  },
  {
    name: settingsT2NY6WJQl3Meta?.name ?? "groupId-projects-projectId-products-productId-settings___zh-cn",
    path: settingsT2NY6WJQl3Meta?.path ?? "settings",
    meta: settingsT2NY6WJQl3Meta || {},
    alias: settingsT2NY6WJQl3Meta?.alias || [],
    redirect: settingsT2NY6WJQl3Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/settings.vue").then(m => m.default || m)
  },
  {
    path: termsAza8XRdk45Meta?.path ?? "terms",
    children: [
  {
    name: editSp50nj3bNaMeta?.name ?? "groupId-projects-projectId-products-productId-terms-edit___zh-cn",
    path: editSp50nj3bNaMeta?.path ?? "edit",
    meta: editSp50nj3bNaMeta || {},
    alias: editSp50nj3bNaMeta?.alias || [],
    redirect: editSp50nj3bNaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/edit.vue").then(m => m.default || m)
  },
  {
    name: indexNDSsdBKRt4Meta?.name ?? "groupId-projects-projectId-products-productId-terms___zh-cn",
    path: indexNDSsdBKRt4Meta?.path ?? "",
    meta: indexNDSsdBKRt4Meta || {},
    alias: indexNDSsdBKRt4Meta?.alias || [],
    redirect: indexNDSsdBKRt4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/index.vue").then(m => m.default || m)
  },
  {
    name: registergfOlj3ZCdVMeta?.name ?? "groupId-projects-projectId-products-productId-terms-register___zh-cn",
    path: registergfOlj3ZCdVMeta?.path ?? "register",
    meta: registergfOlj3ZCdVMeta || {},
    alias: registergfOlj3ZCdVMeta?.alias || [],
    redirect: registergfOlj3ZCdVMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/register.vue").then(m => m.default || m)
  }
],
    name: termsAza8XRdk45Meta?.name ?? undefined,
    meta: termsAza8XRdk45Meta || {},
    alias: termsAza8XRdk45Meta?.alias || [],
    redirect: termsAza8XRdk45Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms.vue").then(m => m.default || m)
  }
],
    name: _91productId_93ToD7TnxuXGMeta?.name ?? undefined,
    meta: _91productId_93ToD7TnxuXGMeta || {},
    alias: _91productId_93ToD7TnxuXGMeta?.alias || [],
    redirect: _91productId_93ToD7TnxuXGMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId].vue").then(m => m.default || m)
  },
  {
    name: indexKUB2ino9kbMeta?.name ?? "groupId-projects-projectId-products___zh-cn",
    path: indexKUB2ino9kbMeta?.path ?? ":projectId()/products",
    meta: indexKUB2ino9kbMeta || {},
    alias: indexKUB2ino9kbMeta?.alias || [],
    redirect: indexKUB2ino9kbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/index.vue").then(m => m.default || m)
  },
  {
    name: registerYCPVGOTJ7GMeta?.name ?? "groupId-projects-projectId-products-register___zh-cn",
    path: registerYCPVGOTJ7GMeta?.path ?? ":projectId()/products/register",
    meta: registerYCPVGOTJ7GMeta || {},
    alias: registerYCPVGOTJ7GMeta?.alias || [],
    redirect: registerYCPVGOTJ7GMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/register.vue").then(m => m.default || m)
  },
  {
    name: index0A7OKE0A8hMeta?.name ?? "groupId-projects___zh-cn",
    path: index0A7OKE0A8hMeta?.path ?? "",
    meta: index0A7OKE0A8hMeta || {},
    alias: index0A7OKE0A8hMeta?.alias || [],
    redirect: index0A7OKE0A8hMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/index.vue").then(m => m.default || m)
  },
  {
    name: register32S2WihcfoMeta?.name ?? "groupId-projects-register___zh-cn",
    path: register32S2WihcfoMeta?.path ?? "register",
    meta: register32S2WihcfoMeta || {},
    alias: register32S2WihcfoMeta?.alias || [],
    redirect: register32S2WihcfoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/register.vue").then(m => m.default || m)
  }
],
    name: projectsOhTjRVWZ7jMeta?.name ?? undefined,
    meta: projectsOhTjRVWZ7jMeta || {},
    alias: projectsOhTjRVWZ7jMeta?.alias || [],
    redirect: projectsOhTjRVWZ7jMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects.vue").then(m => m.default || m)
  }
],
    name: _91groupId_937i5oTSd92jMeta?.name ?? undefined,
    meta: _91groupId_937i5oTSd92jMeta || {},
    alias: _91groupId_937i5oTSd92jMeta?.alias || [],
    redirect: _91groupId_937i5oTSd92jMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId].vue").then(m => m.default || m)
  },
  {
    path: _91groupId_937i5oTSd92jMeta?.path ?? "/zh-tw/:groupId()",
    children: [
  {
    path: business_45bankoOuWOVJeWxMeta?.path ?? "business-bank",
    children: [
  {
    name: bank_45listR2crP43aL7Meta?.name ?? "groupId-business-bank-bank-list___zh-tw",
    path: bank_45listR2crP43aL7Meta?.path ?? "bank-list",
    meta: bank_45listR2crP43aL7Meta || {},
    alias: bank_45listR2crP43aL7Meta?.alias || [],
    redirect: bank_45listR2crP43aL7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-list.vue").then(m => m.default || m)
  },
  {
    name: bank_45registerfZwZdsKoxTMeta?.name ?? "groupId-business-bank-bank-register___zh-tw",
    path: bank_45registerfZwZdsKoxTMeta?.path ?? "bank-register",
    meta: bank_45registerfZwZdsKoxTMeta || {},
    alias: bank_45registerfZwZdsKoxTMeta?.alias || [],
    redirect: bank_45registerfZwZdsKoxTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-register.vue").then(m => m.default || m)
  },
  {
    name: business_45list9ADky5juF7Meta?.name ?? "groupId-business-bank-business-list___zh-tw",
    path: business_45list9ADky5juF7Meta?.path ?? "business-list",
    meta: business_45list9ADky5juF7Meta || {},
    alias: business_45list9ADky5juF7Meta?.alias || [],
    redirect: business_45list9ADky5juF7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-list.vue").then(m => m.default || m)
  },
  {
    name: business_45registern6jErCPT4XMeta?.name ?? "groupId-business-bank-business-register___zh-tw",
    path: business_45registern6jErCPT4XMeta?.path ?? "business-register",
    meta: business_45registern6jErCPT4XMeta || {},
    alias: business_45registern6jErCPT4XMeta?.alias || [],
    redirect: business_45registern6jErCPT4XMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-register.vue").then(m => m.default || m)
  },
  {
    name: indexkMkjtKISLhMeta?.name ?? "groupId-business-bank___zh-tw",
    path: indexkMkjtKISLhMeta?.path ?? "",
    meta: indexkMkjtKISLhMeta || {},
    alias: indexkMkjtKISLhMeta?.alias || [],
    redirect: indexkMkjtKISLhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/index.vue").then(m => m.default || m)
  }
],
    name: business_45bankoOuWOVJeWxMeta?.name ?? undefined,
    meta: business_45bankoOuWOVJeWxMeta || {},
    alias: business_45bankoOuWOVJeWxMeta?.alias || [],
    redirect: business_45bankoOuWOVJeWxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank.vue").then(m => m.default || m)
  },
  {
    path: collectionsFAthRT6rdEMeta?.path ?? "collections",
    children: [
  {
    path: discountIy6GV18yopMeta?.path ?? ":collectionId()/discount",
    children: [
  {
    name: _91mode_93zdEaxOziNBMeta?.name ?? "groupId-collections-collectionId-discount-mode___zh-tw",
    path: _91mode_93zdEaxOziNBMeta?.path ?? ":mode()",
    meta: _91mode_93zdEaxOziNBMeta || {},
    alias: _91mode_93zdEaxOziNBMeta?.alias || [],
    redirect: _91mode_93zdEaxOziNBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexrkJAI5TrwYMeta?.name ?? "groupId-collections-collectionId-discount___zh-tw",
    path: indexrkJAI5TrwYMeta?.path ?? "",
    meta: indexrkJAI5TrwYMeta || {},
    alias: indexrkJAI5TrwYMeta?.alias || [],
    redirect: indexrkJAI5TrwYMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/index.vue").then(m => m.default || m)
  }
],
    name: discountIy6GV18yopMeta?.name ?? undefined,
    meta: discountIy6GV18yopMeta || {},
    alias: discountIy6GV18yopMeta?.alias || [],
    redirect: discountIy6GV18yopMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount.vue").then(m => m.default || m)
  },
  {
    name: _91mode_931ttLzRO79BMeta?.name ?? "groupId-collections-mode___zh-tw",
    path: _91mode_931ttLzRO79BMeta?.path ?? ":mode()",
    meta: _91mode_931ttLzRO79BMeta || {},
    alias: _91mode_931ttLzRO79BMeta?.alias || [],
    redirect: _91mode_931ttLzRO79BMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexEkIF65mYs4Meta?.name ?? "groupId-collections___zh-tw",
    path: indexEkIF65mYs4Meta?.path ?? "",
    meta: indexEkIF65mYs4Meta || {},
    alias: indexEkIF65mYs4Meta?.alias || [],
    redirect: indexEkIF65mYs4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/index.vue").then(m => m.default || m)
  }
],
    name: collectionsFAthRT6rdEMeta?.name ?? undefined,
    meta: collectionsFAthRT6rdEMeta || {},
    alias: collectionsFAthRT6rdEMeta?.alias || [],
    redirect: collectionsFAthRT6rdEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections.vue").then(m => m.default || m)
  },
  {
    name: homeluYuH1WOqBMeta?.name ?? "groupId-home___zh-tw",
    path: homeluYuH1WOqBMeta?.path ?? "home",
    meta: homeluYuH1WOqBMeta || {},
    alias: homeluYuH1WOqBMeta?.alias || [],
    redirect: homeluYuH1WOqBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/home.vue").then(m => m.default || m)
  },
  {
    name: indexkKv5NgYD07Meta?.name ?? "groupId___zh-tw",
    path: indexkKv5NgYD07Meta?.path ?? "",
    meta: indexkKv5NgYD07Meta || {},
    alias: indexkKv5NgYD07Meta?.alias || [],
    redirect: indexkKv5NgYD07Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/index.vue").then(m => m.default || m)
  },
  {
    path: membersQSvU5dg3aaMeta?.path ?? "members",
    children: [
  {
    name: _91id_938qdkdues3NMeta?.name ?? "groupId-members-id___zh-tw",
    path: _91id_938qdkdues3NMeta?.path ?? ":id()",
    meta: _91id_938qdkdues3NMeta || {},
    alias: _91id_938qdkdues3NMeta?.alias || [],
    redirect: _91id_938qdkdues3NMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYB7qgwmZpiMeta?.name ?? "groupId-members___zh-tw",
    path: indexYB7qgwmZpiMeta?.path ?? "",
    meta: indexYB7qgwmZpiMeta || {},
    alias: indexYB7qgwmZpiMeta?.alias || [],
    redirect: indexYB7qgwmZpiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: invite_45historyozwdBjUG7zMeta?.name ?? "groupId-members-invite-history___zh-tw",
    path: invite_45historyozwdBjUG7zMeta?.path ?? "invite-history",
    meta: invite_45historyozwdBjUG7zMeta || {},
    alias: invite_45historyozwdBjUG7zMeta?.alias || [],
    redirect: invite_45historyozwdBjUG7zMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite-history.vue").then(m => m.default || m)
  },
  {
    name: invitewLIxfuFPQgMeta?.name ?? "groupId-members-invite___zh-tw",
    path: invitewLIxfuFPQgMeta?.path ?? "invite",
    meta: invitewLIxfuFPQgMeta || {},
    alias: invitewLIxfuFPQgMeta?.alias || [],
    redirect: invitewLIxfuFPQgMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite.vue").then(m => m.default || m)
  },
  {
    name: _91mode_932jWlpHxdFxMeta?.name ?? "groupId-members-roles-mode___zh-tw",
    path: _91mode_932jWlpHxdFxMeta?.path ?? "roles/:mode()",
    meta: _91mode_932jWlpHxdFxMeta || {},
    alias: _91mode_932jWlpHxdFxMeta?.alias || [],
    redirect: _91mode_932jWlpHxdFxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexOC4UC0HCBIMeta?.name ?? "groupId-members-roles___zh-tw",
    path: indexOC4UC0HCBIMeta?.path ?? "roles",
    meta: indexOC4UC0HCBIMeta || {},
    alias: indexOC4UC0HCBIMeta?.alias || [],
    redirect: indexOC4UC0HCBIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/index.vue").then(m => m.default || m)
  },
  {
    name: wait_45listVCtguf2CtxMeta?.name ?? "groupId-members-wait-list___zh-tw",
    path: wait_45listVCtguf2CtxMeta?.path ?? "wait-list",
    meta: wait_45listVCtguf2CtxMeta || {},
    alias: wait_45listVCtguf2CtxMeta?.alias || [],
    redirect: wait_45listVCtguf2CtxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/wait-list.vue").then(m => m.default || m)
  }
],
    name: membersQSvU5dg3aaMeta?.name ?? undefined,
    meta: membersQSvU5dg3aaMeta || {},
    alias: membersQSvU5dg3aaMeta?.alias || [],
    redirect: membersQSvU5dg3aaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members.vue").then(m => m.default || m)
  },
  {
    path: projectsOhTjRVWZ7jMeta?.path ?? "projects",
    children: [
  {
    path: _91productId_93ToD7TnxuXGMeta?.path ?? ":projectId()/products/:productId()",
    children: [
  {
    path: buildB0ZOjRhfg4Meta?.path ?? "build",
    children: [
  {
    path: _91buildId_93SD9SKlZWROMeta?.path ?? ":buildId()",
    children: [
  {
    name: indexzAqMn9T9MxMeta?.name ?? "groupId-projects-projectId-products-productId-build-buildId___zh-tw",
    path: indexzAqMn9T9MxMeta?.path ?? "",
    meta: indexzAqMn9T9MxMeta || {},
    alias: indexzAqMn9T9MxMeta?.alias || [],
    redirect: indexzAqMn9T9MxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/index.vue").then(m => m.default || m)
  },
  {
    name: livehu3drSQ5HXMeta?.name ?? "groupId-projects-projectId-products-productId-build-buildId-live___zh-tw",
    path: livehu3drSQ5HXMeta?.path ?? "live",
    meta: livehu3drSQ5HXMeta || {},
    alias: livehu3drSQ5HXMeta?.alias || [],
    redirect: livehu3drSQ5HXMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/live.vue").then(m => m.default || m)
  }
],
    name: _91buildId_93SD9SKlZWROMeta?.name ?? undefined,
    meta: _91buildId_93SD9SKlZWROMeta || {},
    alias: _91buildId_93SD9SKlZWROMeta?.alias || [],
    redirect: _91buildId_93SD9SKlZWROMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId].vue").then(m => m.default || m)
  },
  {
    name: indexEdlUa4zgOEMeta?.name ?? "groupId-projects-projectId-products-productId-build___zh-tw",
    path: indexEdlUa4zgOEMeta?.path ?? "",
    meta: indexEdlUa4zgOEMeta || {},
    alias: indexEdlUa4zgOEMeta?.alias || [],
    redirect: indexEdlUa4zgOEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/index.vue").then(m => m.default || m)
  }
],
    name: buildB0ZOjRhfg4Meta?.name ?? undefined,
    meta: buildB0ZOjRhfg4Meta || {},
    alias: buildB0ZOjRhfg4Meta?.alias || [],
    redirect: buildB0ZOjRhfg4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build.vue").then(m => m.default || m)
  },
  {
    path: discount7vspSFrvgrMeta?.path ?? "discount",
    children: [
  {
    name: _91discountId_937IiHSoBTVEMeta?.name ?? "groupId-projects-projectId-products-productId-discount-discountId___zh-tw",
    path: _91discountId_937IiHSoBTVEMeta?.path ?? ":discountId()",
    meta: _91discountId_937IiHSoBTVEMeta || {},
    alias: _91discountId_937IiHSoBTVEMeta?.alias || [],
    redirect: _91discountId_937IiHSoBTVEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/[discountId].vue").then(m => m.default || m)
  },
  {
    name: indexiOnLIBnpj9Meta?.name ?? "groupId-projects-projectId-products-productId-discount___zh-tw",
    path: indexiOnLIBnpj9Meta?.path ?? "",
    meta: indexiOnLIBnpj9Meta || {},
    alias: indexiOnLIBnpj9Meta?.alias || [],
    redirect: indexiOnLIBnpj9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/index.vue").then(m => m.default || m)
  },
  {
    name: registerseVaz102vrMeta?.name ?? "groupId-projects-projectId-products-productId-discount-register___zh-tw",
    path: registerseVaz102vrMeta?.path ?? "register",
    meta: registerseVaz102vrMeta || {},
    alias: registerseVaz102vrMeta?.alias || [],
    redirect: registerseVaz102vrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/register.vue").then(m => m.default || m)
  }
],
    name: discount7vspSFrvgrMeta?.name ?? undefined,
    meta: discount7vspSFrvgrMeta || {},
    alias: discount7vspSFrvgrMeta?.alias || [],
    redirect: discount7vspSFrvgrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount.vue").then(m => m.default || m)
  },
  {
    name: early_45accessYWpHEZN6iiMeta?.name ?? "groupId-projects-projectId-products-productId-early-access___zh-tw",
    path: early_45accessYWpHEZN6iiMeta?.path ?? "early-access",
    meta: early_45accessYWpHEZN6iiMeta || {},
    alias: early_45accessYWpHEZN6iiMeta?.alias || [],
    redirect: early_45accessYWpHEZN6iiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/early-access.vue").then(m => m.default || m)
  },
  {
    name: indexlsUkhnJiMEMeta?.name ?? "groupId-projects-projectId-products-productId___zh-tw",
    path: indexlsUkhnJiMEMeta?.path ?? "",
    meta: indexlsUkhnJiMEMeta || {},
    alias: indexlsUkhnJiMEMeta?.alias || [],
    redirect: indexlsUkhnJiMEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: launch_45settingMT4E0WWTIiMeta?.name ?? "groupId-projects-projectId-products-productId-launch-setting___zh-tw",
    path: launch_45settingMT4E0WWTIiMeta?.path ?? "launch-setting",
    meta: launch_45settingMT4E0WWTIiMeta || {},
    alias: launch_45settingMT4E0WWTIiMeta?.alias || [],
    redirect: launch_45settingMT4E0WWTIiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/launch-setting.vue").then(m => m.default || m)
  },
  {
    name: banner_45imageDR27uqBOz0Meta?.name ?? "groupId-projects-projectId-products-productId-marketing-management-banner-image___zh-tw",
    path: banner_45imageDR27uqBOz0Meta?.path ?? "marketing-management/banner-image",
    meta: banner_45imageDR27uqBOz0Meta || {},
    alias: banner_45imageDR27uqBOz0Meta?.alias || [],
    redirect: banner_45imageDR27uqBOz0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/marketing-management/banner-image.vue").then(m => m.default || m)
  },
  {
    path: product_45pagesLCqMKJKrfLMeta?.path ?? "product-pages",
    children: [
  {
    name: _91mode_93JPPqnAouWiMeta?.name ?? "groupId-projects-projectId-products-productId-product-pages-mode___zh-tw",
    path: _91mode_93JPPqnAouWiMeta?.path ?? ":mode()",
    meta: _91mode_93JPPqnAouWiMeta || {},
    alias: _91mode_93JPPqnAouWiMeta?.alias || [],
    redirect: _91mode_93JPPqnAouWiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexBRGfUaqcxiMeta?.name ?? "groupId-projects-projectId-products-productId-product-pages___zh-tw",
    path: indexBRGfUaqcxiMeta?.path ?? "",
    meta: indexBRGfUaqcxiMeta || {},
    alias: indexBRGfUaqcxiMeta?.alias || [],
    redirect: indexBRGfUaqcxiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/index.vue").then(m => m.default || m)
  }
],
    name: product_45pagesLCqMKJKrfLMeta?.name ?? undefined,
    meta: product_45pagesLCqMKJKrfLMeta || {},
    alias: product_45pagesLCqMKJKrfLMeta?.alias || [],
    redirect: product_45pagesLCqMKJKrfLMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages.vue").then(m => m.default || m)
  },
  {
    name: purchase_45conditionK3XckRfhLoMeta?.name ?? "groupId-projects-projectId-products-productId-purchase-condition___zh-tw",
    path: purchase_45conditionK3XckRfhLoMeta?.path ?? "purchase-condition",
    meta: purchase_45conditionK3XckRfhLoMeta || {},
    alias: purchase_45conditionK3XckRfhLoMeta?.alias || [],
    redirect: purchase_45conditionK3XckRfhLoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/purchase-condition.vue").then(m => m.default || m)
  },
  {
    path: ratingc2cRWY5NFaMeta?.path ?? "rating",
    children: [
  {
    name: adjustment5iVjtprje2Meta?.name ?? "groupId-projects-projectId-products-productId-rating-adjustment___zh-tw",
    path: adjustment5iVjtprje2Meta?.path ?? "adjustment",
    meta: adjustment5iVjtprje2Meta || {},
    alias: adjustment5iVjtprje2Meta?.alias || [],
    redirect: adjustment5iVjtprje2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/adjustment.vue").then(m => m.default || m)
  },
  {
    name: cancelOJETfvWOkFMeta?.name ?? "groupId-projects-projectId-products-productId-rating-cancel___zh-tw",
    path: cancelOJETfvWOkFMeta?.path ?? "cancel",
    meta: cancelOJETfvWOkFMeta || {},
    alias: cancelOJETfvWOkFMeta?.alias || [],
    redirect: cancelOJETfvWOkFMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/cancel.vue").then(m => m.default || m)
  },
  {
    path: detailyiutK40ahtMeta?.path ?? "detail",
    children: [
  {
    name: grac_45input4q8emIm6TRMeta?.name ?? "groupId-projects-projectId-products-productId-rating-detail-grac-input___zh-tw",
    path: grac_45input4q8emIm6TRMeta?.path ?? "grac-input",
    meta: grac_45input4q8emIm6TRMeta || {},
    alias: grac_45input4q8emIm6TRMeta?.alias || [],
    redirect: grac_45input4q8emIm6TRMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/grac-input.vue").then(m => m.default || m)
  },
  {
    name: indexu8S83GQqVAMeta?.name ?? "groupId-projects-projectId-products-productId-rating-detail___zh-tw",
    path: indexu8S83GQqVAMeta?.path ?? "",
    meta: indexu8S83GQqVAMeta || {},
    alias: indexu8S83GQqVAMeta?.alias || [],
    redirect: indexu8S83GQqVAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/index.vue").then(m => m.default || m)
  }
],
    name: detailyiutK40ahtMeta?.name ?? undefined,
    meta: detailyiutK40ahtMeta || {},
    alias: detailyiutK40ahtMeta?.alias || [],
    redirect: detailyiutK40ahtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail.vue").then(m => m.default || m)
  },
  {
    name: historyf5QtLr648UMeta?.name ?? "groupId-projects-projectId-products-productId-rating-history___zh-tw",
    path: historyf5QtLr648UMeta?.path ?? "history",
    meta: historyf5QtLr648UMeta || {},
    alias: historyf5QtLr648UMeta?.alias || [],
    redirect: historyf5QtLr648UMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/history.vue").then(m => m.default || m)
  },
  {
    name: indexNtRSF10ctIMeta?.name ?? "groupId-projects-projectId-products-productId-rating___zh-tw",
    path: indexNtRSF10ctIMeta?.path ?? "",
    meta: indexNtRSF10ctIMeta || {},
    alias: indexNtRSF10ctIMeta?.alias || [],
    redirect: indexNtRSF10ctIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/index.vue").then(m => m.default || m)
  },
  {
    name: reclassificationYFY383yHKEMeta?.name ?? "groupId-projects-projectId-products-productId-rating-reclassification___zh-tw",
    path: reclassificationYFY383yHKEMeta?.path ?? "reclassification",
    meta: reclassificationYFY383yHKEMeta || {},
    alias: reclassificationYFY383yHKEMeta?.alias || [],
    redirect: reclassificationYFY383yHKEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reclassification.vue").then(m => m.default || m)
  },
  {
    path: registervkCPeN9ReWMeta?.path ?? "register",
    children: [
  {
    name: grac_45input6kXdR2bZihMeta?.name ?? "groupId-projects-projectId-products-productId-rating-register-grac-input___zh-tw",
    path: grac_45input6kXdR2bZihMeta?.path ?? "grac-input",
    meta: grac_45input6kXdR2bZihMeta || {},
    alias: grac_45input6kXdR2bZihMeta?.alias || [],
    redirect: grac_45input6kXdR2bZihMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/grac-input.vue").then(m => m.default || m)
  },
  {
    name: indexdlJrcjbr8ZMeta?.name ?? "groupId-projects-projectId-products-productId-rating-register___zh-tw",
    path: indexdlJrcjbr8ZMeta?.path ?? "",
    meta: indexdlJrcjbr8ZMeta || {},
    alias: indexdlJrcjbr8ZMeta?.alias || [],
    redirect: indexdlJrcjbr8ZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/index.vue").then(m => m.default || m)
  }
],
    name: registervkCPeN9ReWMeta?.name ?? undefined,
    meta: registervkCPeN9ReWMeta || {},
    alias: registervkCPeN9ReWMeta?.alias || [],
    redirect: registervkCPeN9ReWMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register.vue").then(m => m.default || m)
  },
  {
    name: reject_45reasonXkFa3rBewqMeta?.name ?? "groupId-projects-projectId-products-productId-rating-reject-reason___zh-tw",
    path: reject_45reasonXkFa3rBewqMeta?.path ?? "reject-reason",
    meta: reject_45reasonXkFa3rBewqMeta || {},
    alias: reject_45reasonXkFa3rBewqMeta?.alias || [],
    redirect: reject_45reasonXkFa3rBewqMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reject-reason.vue").then(m => m.default || m)
  },
  {
    name: rejectionlQCjroVCC0Meta?.name ?? "groupId-projects-projectId-products-productId-rating-rejection___zh-tw",
    path: rejectionlQCjroVCC0Meta?.path ?? "rejection",
    meta: rejectionlQCjroVCC0Meta || {},
    alias: rejectionlQCjroVCC0Meta?.alias || [],
    redirect: rejectionlQCjroVCC0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/rejection.vue").then(m => m.default || m)
  }
],
    name: ratingc2cRWY5NFaMeta?.name ?? undefined,
    meta: ratingc2cRWY5NFaMeta || {},
    alias: ratingc2cRWY5NFaMeta?.alias || [],
    redirect: ratingc2cRWY5NFaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating.vue").then(m => m.default || m)
  },
  {
    path: release_45planc5emQj8pP6Meta?.path ?? "release-plan",
    children: [
  {
    name: detailWVE89LL0yOMeta?.name ?? "groupId-projects-projectId-products-productId-release-plan-detail___zh-tw",
    path: detailWVE89LL0yOMeta?.path ?? "detail",
    meta: detailWVE89LL0yOMeta || {},
    alias: detailWVE89LL0yOMeta?.alias || [],
    redirect: detailWVE89LL0yOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/detail.vue").then(m => m.default || m)
  },
  {
    name: indexLS1Oo7qey4Meta?.name ?? "groupId-projects-projectId-products-productId-release-plan___zh-tw",
    path: indexLS1Oo7qey4Meta?.path ?? "",
    meta: indexLS1Oo7qey4Meta || {},
    alias: indexLS1Oo7qey4Meta?.alias || [],
    redirect: indexLS1Oo7qey4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/index.vue").then(m => m.default || m)
  }
],
    name: release_45planc5emQj8pP6Meta?.name ?? undefined,
    meta: release_45planc5emQj8pP6Meta || {},
    alias: release_45planc5emQj8pP6Meta?.alias || [],
    redirect: release_45planc5emQj8pP6Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan.vue").then(m => m.default || m)
  },
  {
    path: selling_45priceaItQqETfYQMeta?.path ?? "selling-price",
    children: [
  {
    name: editmweUb6h5s0Meta?.name ?? "groupId-projects-projectId-products-productId-selling-price-edit___zh-tw",
    path: editmweUb6h5s0Meta?.path ?? "edit",
    meta: editmweUb6h5s0Meta || {},
    alias: editmweUb6h5s0Meta?.alias || [],
    redirect: editmweUb6h5s0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/edit.vue").then(m => m.default || m)
  },
  {
    name: indexMDoQJWxC9TMeta?.name ?? "groupId-projects-projectId-products-productId-selling-price___zh-tw",
    path: indexMDoQJWxC9TMeta?.path ?? "",
    meta: indexMDoQJWxC9TMeta || {},
    alias: indexMDoQJWxC9TMeta?.alias || [],
    redirect: indexMDoQJWxC9TMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/index.vue").then(m => m.default || m)
  },
  {
    name: register5pUDTzLhD2Meta?.name ?? "groupId-projects-projectId-products-productId-selling-price-register___zh-tw",
    path: register5pUDTzLhD2Meta?.path ?? "register",
    meta: register5pUDTzLhD2Meta || {},
    alias: register5pUDTzLhD2Meta?.alias || [],
    redirect: register5pUDTzLhD2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/register.vue").then(m => m.default || m)
  }
],
    name: selling_45priceaItQqETfYQMeta?.name ?? undefined,
    meta: selling_45priceaItQqETfYQMeta || {},
    alias: selling_45priceaItQqETfYQMeta?.alias || [],
    redirect: selling_45priceaItQqETfYQMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price.vue").then(m => m.default || m)
  },
  {
    name: settingsT2NY6WJQl3Meta?.name ?? "groupId-projects-projectId-products-productId-settings___zh-tw",
    path: settingsT2NY6WJQl3Meta?.path ?? "settings",
    meta: settingsT2NY6WJQl3Meta || {},
    alias: settingsT2NY6WJQl3Meta?.alias || [],
    redirect: settingsT2NY6WJQl3Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/settings.vue").then(m => m.default || m)
  },
  {
    path: termsAza8XRdk45Meta?.path ?? "terms",
    children: [
  {
    name: editSp50nj3bNaMeta?.name ?? "groupId-projects-projectId-products-productId-terms-edit___zh-tw",
    path: editSp50nj3bNaMeta?.path ?? "edit",
    meta: editSp50nj3bNaMeta || {},
    alias: editSp50nj3bNaMeta?.alias || [],
    redirect: editSp50nj3bNaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/edit.vue").then(m => m.default || m)
  },
  {
    name: indexNDSsdBKRt4Meta?.name ?? "groupId-projects-projectId-products-productId-terms___zh-tw",
    path: indexNDSsdBKRt4Meta?.path ?? "",
    meta: indexNDSsdBKRt4Meta || {},
    alias: indexNDSsdBKRt4Meta?.alias || [],
    redirect: indexNDSsdBKRt4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/index.vue").then(m => m.default || m)
  },
  {
    name: registergfOlj3ZCdVMeta?.name ?? "groupId-projects-projectId-products-productId-terms-register___zh-tw",
    path: registergfOlj3ZCdVMeta?.path ?? "register",
    meta: registergfOlj3ZCdVMeta || {},
    alias: registergfOlj3ZCdVMeta?.alias || [],
    redirect: registergfOlj3ZCdVMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/register.vue").then(m => m.default || m)
  }
],
    name: termsAza8XRdk45Meta?.name ?? undefined,
    meta: termsAza8XRdk45Meta || {},
    alias: termsAza8XRdk45Meta?.alias || [],
    redirect: termsAza8XRdk45Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms.vue").then(m => m.default || m)
  }
],
    name: _91productId_93ToD7TnxuXGMeta?.name ?? undefined,
    meta: _91productId_93ToD7TnxuXGMeta || {},
    alias: _91productId_93ToD7TnxuXGMeta?.alias || [],
    redirect: _91productId_93ToD7TnxuXGMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId].vue").then(m => m.default || m)
  },
  {
    name: indexKUB2ino9kbMeta?.name ?? "groupId-projects-projectId-products___zh-tw",
    path: indexKUB2ino9kbMeta?.path ?? ":projectId()/products",
    meta: indexKUB2ino9kbMeta || {},
    alias: indexKUB2ino9kbMeta?.alias || [],
    redirect: indexKUB2ino9kbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/index.vue").then(m => m.default || m)
  },
  {
    name: registerYCPVGOTJ7GMeta?.name ?? "groupId-projects-projectId-products-register___zh-tw",
    path: registerYCPVGOTJ7GMeta?.path ?? ":projectId()/products/register",
    meta: registerYCPVGOTJ7GMeta || {},
    alias: registerYCPVGOTJ7GMeta?.alias || [],
    redirect: registerYCPVGOTJ7GMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/register.vue").then(m => m.default || m)
  },
  {
    name: index0A7OKE0A8hMeta?.name ?? "groupId-projects___zh-tw",
    path: index0A7OKE0A8hMeta?.path ?? "",
    meta: index0A7OKE0A8hMeta || {},
    alias: index0A7OKE0A8hMeta?.alias || [],
    redirect: index0A7OKE0A8hMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/index.vue").then(m => m.default || m)
  },
  {
    name: register32S2WihcfoMeta?.name ?? "groupId-projects-register___zh-tw",
    path: register32S2WihcfoMeta?.path ?? "register",
    meta: register32S2WihcfoMeta || {},
    alias: register32S2WihcfoMeta?.alias || [],
    redirect: register32S2WihcfoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/register.vue").then(m => m.default || m)
  }
],
    name: projectsOhTjRVWZ7jMeta?.name ?? undefined,
    meta: projectsOhTjRVWZ7jMeta || {},
    alias: projectsOhTjRVWZ7jMeta?.alias || [],
    redirect: projectsOhTjRVWZ7jMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects.vue").then(m => m.default || m)
  }
],
    name: _91groupId_937i5oTSd92jMeta?.name ?? undefined,
    meta: _91groupId_937i5oTSd92jMeta || {},
    alias: _91groupId_937i5oTSd92jMeta?.alias || [],
    redirect: _91groupId_937i5oTSd92jMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId].vue").then(m => m.default || m)
  },
  {
    path: _91groupId_937i5oTSd92jMeta?.path ?? "/ja/:groupId()",
    children: [
  {
    path: business_45bankoOuWOVJeWxMeta?.path ?? "business-bank",
    children: [
  {
    name: bank_45listR2crP43aL7Meta?.name ?? "groupId-business-bank-bank-list___ja",
    path: bank_45listR2crP43aL7Meta?.path ?? "bank-list",
    meta: bank_45listR2crP43aL7Meta || {},
    alias: bank_45listR2crP43aL7Meta?.alias || [],
    redirect: bank_45listR2crP43aL7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-list.vue").then(m => m.default || m)
  },
  {
    name: bank_45registerfZwZdsKoxTMeta?.name ?? "groupId-business-bank-bank-register___ja",
    path: bank_45registerfZwZdsKoxTMeta?.path ?? "bank-register",
    meta: bank_45registerfZwZdsKoxTMeta || {},
    alias: bank_45registerfZwZdsKoxTMeta?.alias || [],
    redirect: bank_45registerfZwZdsKoxTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-register.vue").then(m => m.default || m)
  },
  {
    name: business_45list9ADky5juF7Meta?.name ?? "groupId-business-bank-business-list___ja",
    path: business_45list9ADky5juF7Meta?.path ?? "business-list",
    meta: business_45list9ADky5juF7Meta || {},
    alias: business_45list9ADky5juF7Meta?.alias || [],
    redirect: business_45list9ADky5juF7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-list.vue").then(m => m.default || m)
  },
  {
    name: business_45registern6jErCPT4XMeta?.name ?? "groupId-business-bank-business-register___ja",
    path: business_45registern6jErCPT4XMeta?.path ?? "business-register",
    meta: business_45registern6jErCPT4XMeta || {},
    alias: business_45registern6jErCPT4XMeta?.alias || [],
    redirect: business_45registern6jErCPT4XMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-register.vue").then(m => m.default || m)
  },
  {
    name: indexkMkjtKISLhMeta?.name ?? "groupId-business-bank___ja",
    path: indexkMkjtKISLhMeta?.path ?? "",
    meta: indexkMkjtKISLhMeta || {},
    alias: indexkMkjtKISLhMeta?.alias || [],
    redirect: indexkMkjtKISLhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/index.vue").then(m => m.default || m)
  }
],
    name: business_45bankoOuWOVJeWxMeta?.name ?? undefined,
    meta: business_45bankoOuWOVJeWxMeta || {},
    alias: business_45bankoOuWOVJeWxMeta?.alias || [],
    redirect: business_45bankoOuWOVJeWxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank.vue").then(m => m.default || m)
  },
  {
    path: collectionsFAthRT6rdEMeta?.path ?? "collections",
    children: [
  {
    path: discountIy6GV18yopMeta?.path ?? ":collectionId()/discount",
    children: [
  {
    name: _91mode_93zdEaxOziNBMeta?.name ?? "groupId-collections-collectionId-discount-mode___ja",
    path: _91mode_93zdEaxOziNBMeta?.path ?? ":mode()",
    meta: _91mode_93zdEaxOziNBMeta || {},
    alias: _91mode_93zdEaxOziNBMeta?.alias || [],
    redirect: _91mode_93zdEaxOziNBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexrkJAI5TrwYMeta?.name ?? "groupId-collections-collectionId-discount___ja",
    path: indexrkJAI5TrwYMeta?.path ?? "",
    meta: indexrkJAI5TrwYMeta || {},
    alias: indexrkJAI5TrwYMeta?.alias || [],
    redirect: indexrkJAI5TrwYMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/index.vue").then(m => m.default || m)
  }
],
    name: discountIy6GV18yopMeta?.name ?? undefined,
    meta: discountIy6GV18yopMeta || {},
    alias: discountIy6GV18yopMeta?.alias || [],
    redirect: discountIy6GV18yopMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount.vue").then(m => m.default || m)
  },
  {
    name: _91mode_931ttLzRO79BMeta?.name ?? "groupId-collections-mode___ja",
    path: _91mode_931ttLzRO79BMeta?.path ?? ":mode()",
    meta: _91mode_931ttLzRO79BMeta || {},
    alias: _91mode_931ttLzRO79BMeta?.alias || [],
    redirect: _91mode_931ttLzRO79BMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexEkIF65mYs4Meta?.name ?? "groupId-collections___ja",
    path: indexEkIF65mYs4Meta?.path ?? "",
    meta: indexEkIF65mYs4Meta || {},
    alias: indexEkIF65mYs4Meta?.alias || [],
    redirect: indexEkIF65mYs4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/index.vue").then(m => m.default || m)
  }
],
    name: collectionsFAthRT6rdEMeta?.name ?? undefined,
    meta: collectionsFAthRT6rdEMeta || {},
    alias: collectionsFAthRT6rdEMeta?.alias || [],
    redirect: collectionsFAthRT6rdEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections.vue").then(m => m.default || m)
  },
  {
    name: homeluYuH1WOqBMeta?.name ?? "groupId-home___ja",
    path: homeluYuH1WOqBMeta?.path ?? "home",
    meta: homeluYuH1WOqBMeta || {},
    alias: homeluYuH1WOqBMeta?.alias || [],
    redirect: homeluYuH1WOqBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/home.vue").then(m => m.default || m)
  },
  {
    name: indexkKv5NgYD07Meta?.name ?? "groupId___ja",
    path: indexkKv5NgYD07Meta?.path ?? "",
    meta: indexkKv5NgYD07Meta || {},
    alias: indexkKv5NgYD07Meta?.alias || [],
    redirect: indexkKv5NgYD07Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/index.vue").then(m => m.default || m)
  },
  {
    path: membersQSvU5dg3aaMeta?.path ?? "members",
    children: [
  {
    name: _91id_938qdkdues3NMeta?.name ?? "groupId-members-id___ja",
    path: _91id_938qdkdues3NMeta?.path ?? ":id()",
    meta: _91id_938qdkdues3NMeta || {},
    alias: _91id_938qdkdues3NMeta?.alias || [],
    redirect: _91id_938qdkdues3NMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYB7qgwmZpiMeta?.name ?? "groupId-members___ja",
    path: indexYB7qgwmZpiMeta?.path ?? "",
    meta: indexYB7qgwmZpiMeta || {},
    alias: indexYB7qgwmZpiMeta?.alias || [],
    redirect: indexYB7qgwmZpiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: invite_45historyozwdBjUG7zMeta?.name ?? "groupId-members-invite-history___ja",
    path: invite_45historyozwdBjUG7zMeta?.path ?? "invite-history",
    meta: invite_45historyozwdBjUG7zMeta || {},
    alias: invite_45historyozwdBjUG7zMeta?.alias || [],
    redirect: invite_45historyozwdBjUG7zMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite-history.vue").then(m => m.default || m)
  },
  {
    name: invitewLIxfuFPQgMeta?.name ?? "groupId-members-invite___ja",
    path: invitewLIxfuFPQgMeta?.path ?? "invite",
    meta: invitewLIxfuFPQgMeta || {},
    alias: invitewLIxfuFPQgMeta?.alias || [],
    redirect: invitewLIxfuFPQgMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite.vue").then(m => m.default || m)
  },
  {
    name: _91mode_932jWlpHxdFxMeta?.name ?? "groupId-members-roles-mode___ja",
    path: _91mode_932jWlpHxdFxMeta?.path ?? "roles/:mode()",
    meta: _91mode_932jWlpHxdFxMeta || {},
    alias: _91mode_932jWlpHxdFxMeta?.alias || [],
    redirect: _91mode_932jWlpHxdFxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexOC4UC0HCBIMeta?.name ?? "groupId-members-roles___ja",
    path: indexOC4UC0HCBIMeta?.path ?? "roles",
    meta: indexOC4UC0HCBIMeta || {},
    alias: indexOC4UC0HCBIMeta?.alias || [],
    redirect: indexOC4UC0HCBIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/index.vue").then(m => m.default || m)
  },
  {
    name: wait_45listVCtguf2CtxMeta?.name ?? "groupId-members-wait-list___ja",
    path: wait_45listVCtguf2CtxMeta?.path ?? "wait-list",
    meta: wait_45listVCtguf2CtxMeta || {},
    alias: wait_45listVCtguf2CtxMeta?.alias || [],
    redirect: wait_45listVCtguf2CtxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/wait-list.vue").then(m => m.default || m)
  }
],
    name: membersQSvU5dg3aaMeta?.name ?? undefined,
    meta: membersQSvU5dg3aaMeta || {},
    alias: membersQSvU5dg3aaMeta?.alias || [],
    redirect: membersQSvU5dg3aaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members.vue").then(m => m.default || m)
  },
  {
    path: projectsOhTjRVWZ7jMeta?.path ?? "projects",
    children: [
  {
    path: _91productId_93ToD7TnxuXGMeta?.path ?? ":projectId()/products/:productId()",
    children: [
  {
    path: buildB0ZOjRhfg4Meta?.path ?? "build",
    children: [
  {
    path: _91buildId_93SD9SKlZWROMeta?.path ?? ":buildId()",
    children: [
  {
    name: indexzAqMn9T9MxMeta?.name ?? "groupId-projects-projectId-products-productId-build-buildId___ja",
    path: indexzAqMn9T9MxMeta?.path ?? "",
    meta: indexzAqMn9T9MxMeta || {},
    alias: indexzAqMn9T9MxMeta?.alias || [],
    redirect: indexzAqMn9T9MxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/index.vue").then(m => m.default || m)
  },
  {
    name: livehu3drSQ5HXMeta?.name ?? "groupId-projects-projectId-products-productId-build-buildId-live___ja",
    path: livehu3drSQ5HXMeta?.path ?? "live",
    meta: livehu3drSQ5HXMeta || {},
    alias: livehu3drSQ5HXMeta?.alias || [],
    redirect: livehu3drSQ5HXMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/live.vue").then(m => m.default || m)
  }
],
    name: _91buildId_93SD9SKlZWROMeta?.name ?? undefined,
    meta: _91buildId_93SD9SKlZWROMeta || {},
    alias: _91buildId_93SD9SKlZWROMeta?.alias || [],
    redirect: _91buildId_93SD9SKlZWROMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId].vue").then(m => m.default || m)
  },
  {
    name: indexEdlUa4zgOEMeta?.name ?? "groupId-projects-projectId-products-productId-build___ja",
    path: indexEdlUa4zgOEMeta?.path ?? "",
    meta: indexEdlUa4zgOEMeta || {},
    alias: indexEdlUa4zgOEMeta?.alias || [],
    redirect: indexEdlUa4zgOEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/index.vue").then(m => m.default || m)
  }
],
    name: buildB0ZOjRhfg4Meta?.name ?? undefined,
    meta: buildB0ZOjRhfg4Meta || {},
    alias: buildB0ZOjRhfg4Meta?.alias || [],
    redirect: buildB0ZOjRhfg4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build.vue").then(m => m.default || m)
  },
  {
    path: discount7vspSFrvgrMeta?.path ?? "discount",
    children: [
  {
    name: _91discountId_937IiHSoBTVEMeta?.name ?? "groupId-projects-projectId-products-productId-discount-discountId___ja",
    path: _91discountId_937IiHSoBTVEMeta?.path ?? ":discountId()",
    meta: _91discountId_937IiHSoBTVEMeta || {},
    alias: _91discountId_937IiHSoBTVEMeta?.alias || [],
    redirect: _91discountId_937IiHSoBTVEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/[discountId].vue").then(m => m.default || m)
  },
  {
    name: indexiOnLIBnpj9Meta?.name ?? "groupId-projects-projectId-products-productId-discount___ja",
    path: indexiOnLIBnpj9Meta?.path ?? "",
    meta: indexiOnLIBnpj9Meta || {},
    alias: indexiOnLIBnpj9Meta?.alias || [],
    redirect: indexiOnLIBnpj9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/index.vue").then(m => m.default || m)
  },
  {
    name: registerseVaz102vrMeta?.name ?? "groupId-projects-projectId-products-productId-discount-register___ja",
    path: registerseVaz102vrMeta?.path ?? "register",
    meta: registerseVaz102vrMeta || {},
    alias: registerseVaz102vrMeta?.alias || [],
    redirect: registerseVaz102vrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/register.vue").then(m => m.default || m)
  }
],
    name: discount7vspSFrvgrMeta?.name ?? undefined,
    meta: discount7vspSFrvgrMeta || {},
    alias: discount7vspSFrvgrMeta?.alias || [],
    redirect: discount7vspSFrvgrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount.vue").then(m => m.default || m)
  },
  {
    name: early_45accessYWpHEZN6iiMeta?.name ?? "groupId-projects-projectId-products-productId-early-access___ja",
    path: early_45accessYWpHEZN6iiMeta?.path ?? "early-access",
    meta: early_45accessYWpHEZN6iiMeta || {},
    alias: early_45accessYWpHEZN6iiMeta?.alias || [],
    redirect: early_45accessYWpHEZN6iiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/early-access.vue").then(m => m.default || m)
  },
  {
    name: indexlsUkhnJiMEMeta?.name ?? "groupId-projects-projectId-products-productId___ja",
    path: indexlsUkhnJiMEMeta?.path ?? "",
    meta: indexlsUkhnJiMEMeta || {},
    alias: indexlsUkhnJiMEMeta?.alias || [],
    redirect: indexlsUkhnJiMEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: launch_45settingMT4E0WWTIiMeta?.name ?? "groupId-projects-projectId-products-productId-launch-setting___ja",
    path: launch_45settingMT4E0WWTIiMeta?.path ?? "launch-setting",
    meta: launch_45settingMT4E0WWTIiMeta || {},
    alias: launch_45settingMT4E0WWTIiMeta?.alias || [],
    redirect: launch_45settingMT4E0WWTIiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/launch-setting.vue").then(m => m.default || m)
  },
  {
    name: banner_45imageDR27uqBOz0Meta?.name ?? "groupId-projects-projectId-products-productId-marketing-management-banner-image___ja",
    path: banner_45imageDR27uqBOz0Meta?.path ?? "marketing-management/banner-image",
    meta: banner_45imageDR27uqBOz0Meta || {},
    alias: banner_45imageDR27uqBOz0Meta?.alias || [],
    redirect: banner_45imageDR27uqBOz0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/marketing-management/banner-image.vue").then(m => m.default || m)
  },
  {
    path: product_45pagesLCqMKJKrfLMeta?.path ?? "product-pages",
    children: [
  {
    name: _91mode_93JPPqnAouWiMeta?.name ?? "groupId-projects-projectId-products-productId-product-pages-mode___ja",
    path: _91mode_93JPPqnAouWiMeta?.path ?? ":mode()",
    meta: _91mode_93JPPqnAouWiMeta || {},
    alias: _91mode_93JPPqnAouWiMeta?.alias || [],
    redirect: _91mode_93JPPqnAouWiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexBRGfUaqcxiMeta?.name ?? "groupId-projects-projectId-products-productId-product-pages___ja",
    path: indexBRGfUaqcxiMeta?.path ?? "",
    meta: indexBRGfUaqcxiMeta || {},
    alias: indexBRGfUaqcxiMeta?.alias || [],
    redirect: indexBRGfUaqcxiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/index.vue").then(m => m.default || m)
  }
],
    name: product_45pagesLCqMKJKrfLMeta?.name ?? undefined,
    meta: product_45pagesLCqMKJKrfLMeta || {},
    alias: product_45pagesLCqMKJKrfLMeta?.alias || [],
    redirect: product_45pagesLCqMKJKrfLMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages.vue").then(m => m.default || m)
  },
  {
    name: purchase_45conditionK3XckRfhLoMeta?.name ?? "groupId-projects-projectId-products-productId-purchase-condition___ja",
    path: purchase_45conditionK3XckRfhLoMeta?.path ?? "purchase-condition",
    meta: purchase_45conditionK3XckRfhLoMeta || {},
    alias: purchase_45conditionK3XckRfhLoMeta?.alias || [],
    redirect: purchase_45conditionK3XckRfhLoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/purchase-condition.vue").then(m => m.default || m)
  },
  {
    path: ratingc2cRWY5NFaMeta?.path ?? "rating",
    children: [
  {
    name: adjustment5iVjtprje2Meta?.name ?? "groupId-projects-projectId-products-productId-rating-adjustment___ja",
    path: adjustment5iVjtprje2Meta?.path ?? "adjustment",
    meta: adjustment5iVjtprje2Meta || {},
    alias: adjustment5iVjtprje2Meta?.alias || [],
    redirect: adjustment5iVjtprje2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/adjustment.vue").then(m => m.default || m)
  },
  {
    name: cancelOJETfvWOkFMeta?.name ?? "groupId-projects-projectId-products-productId-rating-cancel___ja",
    path: cancelOJETfvWOkFMeta?.path ?? "cancel",
    meta: cancelOJETfvWOkFMeta || {},
    alias: cancelOJETfvWOkFMeta?.alias || [],
    redirect: cancelOJETfvWOkFMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/cancel.vue").then(m => m.default || m)
  },
  {
    path: detailyiutK40ahtMeta?.path ?? "detail",
    children: [
  {
    name: grac_45input4q8emIm6TRMeta?.name ?? "groupId-projects-projectId-products-productId-rating-detail-grac-input___ja",
    path: grac_45input4q8emIm6TRMeta?.path ?? "grac-input",
    meta: grac_45input4q8emIm6TRMeta || {},
    alias: grac_45input4q8emIm6TRMeta?.alias || [],
    redirect: grac_45input4q8emIm6TRMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/grac-input.vue").then(m => m.default || m)
  },
  {
    name: indexu8S83GQqVAMeta?.name ?? "groupId-projects-projectId-products-productId-rating-detail___ja",
    path: indexu8S83GQqVAMeta?.path ?? "",
    meta: indexu8S83GQqVAMeta || {},
    alias: indexu8S83GQqVAMeta?.alias || [],
    redirect: indexu8S83GQqVAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/index.vue").then(m => m.default || m)
  }
],
    name: detailyiutK40ahtMeta?.name ?? undefined,
    meta: detailyiutK40ahtMeta || {},
    alias: detailyiutK40ahtMeta?.alias || [],
    redirect: detailyiutK40ahtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail.vue").then(m => m.default || m)
  },
  {
    name: historyf5QtLr648UMeta?.name ?? "groupId-projects-projectId-products-productId-rating-history___ja",
    path: historyf5QtLr648UMeta?.path ?? "history",
    meta: historyf5QtLr648UMeta || {},
    alias: historyf5QtLr648UMeta?.alias || [],
    redirect: historyf5QtLr648UMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/history.vue").then(m => m.default || m)
  },
  {
    name: indexNtRSF10ctIMeta?.name ?? "groupId-projects-projectId-products-productId-rating___ja",
    path: indexNtRSF10ctIMeta?.path ?? "",
    meta: indexNtRSF10ctIMeta || {},
    alias: indexNtRSF10ctIMeta?.alias || [],
    redirect: indexNtRSF10ctIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/index.vue").then(m => m.default || m)
  },
  {
    name: reclassificationYFY383yHKEMeta?.name ?? "groupId-projects-projectId-products-productId-rating-reclassification___ja",
    path: reclassificationYFY383yHKEMeta?.path ?? "reclassification",
    meta: reclassificationYFY383yHKEMeta || {},
    alias: reclassificationYFY383yHKEMeta?.alias || [],
    redirect: reclassificationYFY383yHKEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reclassification.vue").then(m => m.default || m)
  },
  {
    path: registervkCPeN9ReWMeta?.path ?? "register",
    children: [
  {
    name: grac_45input6kXdR2bZihMeta?.name ?? "groupId-projects-projectId-products-productId-rating-register-grac-input___ja",
    path: grac_45input6kXdR2bZihMeta?.path ?? "grac-input",
    meta: grac_45input6kXdR2bZihMeta || {},
    alias: grac_45input6kXdR2bZihMeta?.alias || [],
    redirect: grac_45input6kXdR2bZihMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/grac-input.vue").then(m => m.default || m)
  },
  {
    name: indexdlJrcjbr8ZMeta?.name ?? "groupId-projects-projectId-products-productId-rating-register___ja",
    path: indexdlJrcjbr8ZMeta?.path ?? "",
    meta: indexdlJrcjbr8ZMeta || {},
    alias: indexdlJrcjbr8ZMeta?.alias || [],
    redirect: indexdlJrcjbr8ZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/index.vue").then(m => m.default || m)
  }
],
    name: registervkCPeN9ReWMeta?.name ?? undefined,
    meta: registervkCPeN9ReWMeta || {},
    alias: registervkCPeN9ReWMeta?.alias || [],
    redirect: registervkCPeN9ReWMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register.vue").then(m => m.default || m)
  },
  {
    name: reject_45reasonXkFa3rBewqMeta?.name ?? "groupId-projects-projectId-products-productId-rating-reject-reason___ja",
    path: reject_45reasonXkFa3rBewqMeta?.path ?? "reject-reason",
    meta: reject_45reasonXkFa3rBewqMeta || {},
    alias: reject_45reasonXkFa3rBewqMeta?.alias || [],
    redirect: reject_45reasonXkFa3rBewqMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reject-reason.vue").then(m => m.default || m)
  },
  {
    name: rejectionlQCjroVCC0Meta?.name ?? "groupId-projects-projectId-products-productId-rating-rejection___ja",
    path: rejectionlQCjroVCC0Meta?.path ?? "rejection",
    meta: rejectionlQCjroVCC0Meta || {},
    alias: rejectionlQCjroVCC0Meta?.alias || [],
    redirect: rejectionlQCjroVCC0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/rejection.vue").then(m => m.default || m)
  }
],
    name: ratingc2cRWY5NFaMeta?.name ?? undefined,
    meta: ratingc2cRWY5NFaMeta || {},
    alias: ratingc2cRWY5NFaMeta?.alias || [],
    redirect: ratingc2cRWY5NFaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating.vue").then(m => m.default || m)
  },
  {
    path: release_45planc5emQj8pP6Meta?.path ?? "release-plan",
    children: [
  {
    name: detailWVE89LL0yOMeta?.name ?? "groupId-projects-projectId-products-productId-release-plan-detail___ja",
    path: detailWVE89LL0yOMeta?.path ?? "detail",
    meta: detailWVE89LL0yOMeta || {},
    alias: detailWVE89LL0yOMeta?.alias || [],
    redirect: detailWVE89LL0yOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/detail.vue").then(m => m.default || m)
  },
  {
    name: indexLS1Oo7qey4Meta?.name ?? "groupId-projects-projectId-products-productId-release-plan___ja",
    path: indexLS1Oo7qey4Meta?.path ?? "",
    meta: indexLS1Oo7qey4Meta || {},
    alias: indexLS1Oo7qey4Meta?.alias || [],
    redirect: indexLS1Oo7qey4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/index.vue").then(m => m.default || m)
  }
],
    name: release_45planc5emQj8pP6Meta?.name ?? undefined,
    meta: release_45planc5emQj8pP6Meta || {},
    alias: release_45planc5emQj8pP6Meta?.alias || [],
    redirect: release_45planc5emQj8pP6Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan.vue").then(m => m.default || m)
  },
  {
    path: selling_45priceaItQqETfYQMeta?.path ?? "selling-price",
    children: [
  {
    name: editmweUb6h5s0Meta?.name ?? "groupId-projects-projectId-products-productId-selling-price-edit___ja",
    path: editmweUb6h5s0Meta?.path ?? "edit",
    meta: editmweUb6h5s0Meta || {},
    alias: editmweUb6h5s0Meta?.alias || [],
    redirect: editmweUb6h5s0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/edit.vue").then(m => m.default || m)
  },
  {
    name: indexMDoQJWxC9TMeta?.name ?? "groupId-projects-projectId-products-productId-selling-price___ja",
    path: indexMDoQJWxC9TMeta?.path ?? "",
    meta: indexMDoQJWxC9TMeta || {},
    alias: indexMDoQJWxC9TMeta?.alias || [],
    redirect: indexMDoQJWxC9TMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/index.vue").then(m => m.default || m)
  },
  {
    name: register5pUDTzLhD2Meta?.name ?? "groupId-projects-projectId-products-productId-selling-price-register___ja",
    path: register5pUDTzLhD2Meta?.path ?? "register",
    meta: register5pUDTzLhD2Meta || {},
    alias: register5pUDTzLhD2Meta?.alias || [],
    redirect: register5pUDTzLhD2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/register.vue").then(m => m.default || m)
  }
],
    name: selling_45priceaItQqETfYQMeta?.name ?? undefined,
    meta: selling_45priceaItQqETfYQMeta || {},
    alias: selling_45priceaItQqETfYQMeta?.alias || [],
    redirect: selling_45priceaItQqETfYQMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price.vue").then(m => m.default || m)
  },
  {
    name: settingsT2NY6WJQl3Meta?.name ?? "groupId-projects-projectId-products-productId-settings___ja",
    path: settingsT2NY6WJQl3Meta?.path ?? "settings",
    meta: settingsT2NY6WJQl3Meta || {},
    alias: settingsT2NY6WJQl3Meta?.alias || [],
    redirect: settingsT2NY6WJQl3Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/settings.vue").then(m => m.default || m)
  },
  {
    path: termsAza8XRdk45Meta?.path ?? "terms",
    children: [
  {
    name: editSp50nj3bNaMeta?.name ?? "groupId-projects-projectId-products-productId-terms-edit___ja",
    path: editSp50nj3bNaMeta?.path ?? "edit",
    meta: editSp50nj3bNaMeta || {},
    alias: editSp50nj3bNaMeta?.alias || [],
    redirect: editSp50nj3bNaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/edit.vue").then(m => m.default || m)
  },
  {
    name: indexNDSsdBKRt4Meta?.name ?? "groupId-projects-projectId-products-productId-terms___ja",
    path: indexNDSsdBKRt4Meta?.path ?? "",
    meta: indexNDSsdBKRt4Meta || {},
    alias: indexNDSsdBKRt4Meta?.alias || [],
    redirect: indexNDSsdBKRt4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/index.vue").then(m => m.default || m)
  },
  {
    name: registergfOlj3ZCdVMeta?.name ?? "groupId-projects-projectId-products-productId-terms-register___ja",
    path: registergfOlj3ZCdVMeta?.path ?? "register",
    meta: registergfOlj3ZCdVMeta || {},
    alias: registergfOlj3ZCdVMeta?.alias || [],
    redirect: registergfOlj3ZCdVMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/register.vue").then(m => m.default || m)
  }
],
    name: termsAza8XRdk45Meta?.name ?? undefined,
    meta: termsAza8XRdk45Meta || {},
    alias: termsAza8XRdk45Meta?.alias || [],
    redirect: termsAza8XRdk45Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms.vue").then(m => m.default || m)
  }
],
    name: _91productId_93ToD7TnxuXGMeta?.name ?? undefined,
    meta: _91productId_93ToD7TnxuXGMeta || {},
    alias: _91productId_93ToD7TnxuXGMeta?.alias || [],
    redirect: _91productId_93ToD7TnxuXGMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId].vue").then(m => m.default || m)
  },
  {
    name: indexKUB2ino9kbMeta?.name ?? "groupId-projects-projectId-products___ja",
    path: indexKUB2ino9kbMeta?.path ?? ":projectId()/products",
    meta: indexKUB2ino9kbMeta || {},
    alias: indexKUB2ino9kbMeta?.alias || [],
    redirect: indexKUB2ino9kbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/index.vue").then(m => m.default || m)
  },
  {
    name: registerYCPVGOTJ7GMeta?.name ?? "groupId-projects-projectId-products-register___ja",
    path: registerYCPVGOTJ7GMeta?.path ?? ":projectId()/products/register",
    meta: registerYCPVGOTJ7GMeta || {},
    alias: registerYCPVGOTJ7GMeta?.alias || [],
    redirect: registerYCPVGOTJ7GMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/register.vue").then(m => m.default || m)
  },
  {
    name: index0A7OKE0A8hMeta?.name ?? "groupId-projects___ja",
    path: index0A7OKE0A8hMeta?.path ?? "",
    meta: index0A7OKE0A8hMeta || {},
    alias: index0A7OKE0A8hMeta?.alias || [],
    redirect: index0A7OKE0A8hMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/index.vue").then(m => m.default || m)
  },
  {
    name: register32S2WihcfoMeta?.name ?? "groupId-projects-register___ja",
    path: register32S2WihcfoMeta?.path ?? "register",
    meta: register32S2WihcfoMeta || {},
    alias: register32S2WihcfoMeta?.alias || [],
    redirect: register32S2WihcfoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/register.vue").then(m => m.default || m)
  }
],
    name: projectsOhTjRVWZ7jMeta?.name ?? undefined,
    meta: projectsOhTjRVWZ7jMeta || {},
    alias: projectsOhTjRVWZ7jMeta?.alias || [],
    redirect: projectsOhTjRVWZ7jMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects.vue").then(m => m.default || m)
  }
],
    name: _91groupId_937i5oTSd92jMeta?.name ?? undefined,
    meta: _91groupId_937i5oTSd92jMeta || {},
    alias: _91groupId_937i5oTSd92jMeta?.alias || [],
    redirect: _91groupId_937i5oTSd92jMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId].vue").then(m => m.default || m)
  },
  {
    path: _91groupId_937i5oTSd92jMeta?.path ?? "/:groupId()",
    children: [
  {
    path: business_45bankoOuWOVJeWxMeta?.path ?? "business-bank",
    children: [
  {
    name: bank_45listR2crP43aL7Meta?.name ?? "groupId-business-bank-bank-list___ko___default",
    path: bank_45listR2crP43aL7Meta?.path ?? "bank-list",
    meta: bank_45listR2crP43aL7Meta || {},
    alias: bank_45listR2crP43aL7Meta?.alias || [],
    redirect: bank_45listR2crP43aL7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-list.vue").then(m => m.default || m)
  },
  {
    name: bank_45registerfZwZdsKoxTMeta?.name ?? "groupId-business-bank-bank-register___ko___default",
    path: bank_45registerfZwZdsKoxTMeta?.path ?? "bank-register",
    meta: bank_45registerfZwZdsKoxTMeta || {},
    alias: bank_45registerfZwZdsKoxTMeta?.alias || [],
    redirect: bank_45registerfZwZdsKoxTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-register.vue").then(m => m.default || m)
  },
  {
    name: business_45list9ADky5juF7Meta?.name ?? "groupId-business-bank-business-list___ko___default",
    path: business_45list9ADky5juF7Meta?.path ?? "business-list",
    meta: business_45list9ADky5juF7Meta || {},
    alias: business_45list9ADky5juF7Meta?.alias || [],
    redirect: business_45list9ADky5juF7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-list.vue").then(m => m.default || m)
  },
  {
    name: business_45registern6jErCPT4XMeta?.name ?? "groupId-business-bank-business-register___ko___default",
    path: business_45registern6jErCPT4XMeta?.path ?? "business-register",
    meta: business_45registern6jErCPT4XMeta || {},
    alias: business_45registern6jErCPT4XMeta?.alias || [],
    redirect: business_45registern6jErCPT4XMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-register.vue").then(m => m.default || m)
  },
  {
    name: indexkMkjtKISLhMeta?.name ?? "groupId-business-bank___ko___default",
    path: indexkMkjtKISLhMeta?.path ?? "",
    meta: indexkMkjtKISLhMeta || {},
    alias: indexkMkjtKISLhMeta?.alias || [],
    redirect: indexkMkjtKISLhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/index.vue").then(m => m.default || m)
  }
],
    name: business_45bankoOuWOVJeWxMeta?.name ?? undefined,
    meta: business_45bankoOuWOVJeWxMeta || {},
    alias: business_45bankoOuWOVJeWxMeta?.alias || [],
    redirect: business_45bankoOuWOVJeWxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank.vue").then(m => m.default || m)
  },
  {
    path: collectionsFAthRT6rdEMeta?.path ?? "collections",
    children: [
  {
    path: discountIy6GV18yopMeta?.path ?? ":collectionId()/discount",
    children: [
  {
    name: _91mode_93zdEaxOziNBMeta?.name ?? "groupId-collections-collectionId-discount-mode___ko___default",
    path: _91mode_93zdEaxOziNBMeta?.path ?? ":mode()",
    meta: _91mode_93zdEaxOziNBMeta || {},
    alias: _91mode_93zdEaxOziNBMeta?.alias || [],
    redirect: _91mode_93zdEaxOziNBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexrkJAI5TrwYMeta?.name ?? "groupId-collections-collectionId-discount___ko___default",
    path: indexrkJAI5TrwYMeta?.path ?? "",
    meta: indexrkJAI5TrwYMeta || {},
    alias: indexrkJAI5TrwYMeta?.alias || [],
    redirect: indexrkJAI5TrwYMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/index.vue").then(m => m.default || m)
  }
],
    name: discountIy6GV18yopMeta?.name ?? undefined,
    meta: discountIy6GV18yopMeta || {},
    alias: discountIy6GV18yopMeta?.alias || [],
    redirect: discountIy6GV18yopMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount.vue").then(m => m.default || m)
  },
  {
    name: _91mode_931ttLzRO79BMeta?.name ?? "groupId-collections-mode___ko___default",
    path: _91mode_931ttLzRO79BMeta?.path ?? ":mode()",
    meta: _91mode_931ttLzRO79BMeta || {},
    alias: _91mode_931ttLzRO79BMeta?.alias || [],
    redirect: _91mode_931ttLzRO79BMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexEkIF65mYs4Meta?.name ?? "groupId-collections___ko___default",
    path: indexEkIF65mYs4Meta?.path ?? "",
    meta: indexEkIF65mYs4Meta || {},
    alias: indexEkIF65mYs4Meta?.alias || [],
    redirect: indexEkIF65mYs4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/index.vue").then(m => m.default || m)
  }
],
    name: collectionsFAthRT6rdEMeta?.name ?? undefined,
    meta: collectionsFAthRT6rdEMeta || {},
    alias: collectionsFAthRT6rdEMeta?.alias || [],
    redirect: collectionsFAthRT6rdEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections.vue").then(m => m.default || m)
  },
  {
    name: homeluYuH1WOqBMeta?.name ?? "groupId-home___ko___default",
    path: homeluYuH1WOqBMeta?.path ?? "home",
    meta: homeluYuH1WOqBMeta || {},
    alias: homeluYuH1WOqBMeta?.alias || [],
    redirect: homeluYuH1WOqBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/home.vue").then(m => m.default || m)
  },
  {
    name: indexkKv5NgYD07Meta?.name ?? "groupId___ko___default",
    path: indexkKv5NgYD07Meta?.path ?? "",
    meta: indexkKv5NgYD07Meta || {},
    alias: indexkKv5NgYD07Meta?.alias || [],
    redirect: indexkKv5NgYD07Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/index.vue").then(m => m.default || m)
  },
  {
    path: membersQSvU5dg3aaMeta?.path ?? "members",
    children: [
  {
    name: _91id_938qdkdues3NMeta?.name ?? "groupId-members-id___ko___default",
    path: _91id_938qdkdues3NMeta?.path ?? ":id()",
    meta: _91id_938qdkdues3NMeta || {},
    alias: _91id_938qdkdues3NMeta?.alias || [],
    redirect: _91id_938qdkdues3NMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYB7qgwmZpiMeta?.name ?? "groupId-members___ko___default",
    path: indexYB7qgwmZpiMeta?.path ?? "",
    meta: indexYB7qgwmZpiMeta || {},
    alias: indexYB7qgwmZpiMeta?.alias || [],
    redirect: indexYB7qgwmZpiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: invite_45historyozwdBjUG7zMeta?.name ?? "groupId-members-invite-history___ko___default",
    path: invite_45historyozwdBjUG7zMeta?.path ?? "invite-history",
    meta: invite_45historyozwdBjUG7zMeta || {},
    alias: invite_45historyozwdBjUG7zMeta?.alias || [],
    redirect: invite_45historyozwdBjUG7zMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite-history.vue").then(m => m.default || m)
  },
  {
    name: invitewLIxfuFPQgMeta?.name ?? "groupId-members-invite___ko___default",
    path: invitewLIxfuFPQgMeta?.path ?? "invite",
    meta: invitewLIxfuFPQgMeta || {},
    alias: invitewLIxfuFPQgMeta?.alias || [],
    redirect: invitewLIxfuFPQgMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite.vue").then(m => m.default || m)
  },
  {
    name: _91mode_932jWlpHxdFxMeta?.name ?? "groupId-members-roles-mode___ko___default",
    path: _91mode_932jWlpHxdFxMeta?.path ?? "roles/:mode()",
    meta: _91mode_932jWlpHxdFxMeta || {},
    alias: _91mode_932jWlpHxdFxMeta?.alias || [],
    redirect: _91mode_932jWlpHxdFxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexOC4UC0HCBIMeta?.name ?? "groupId-members-roles___ko___default",
    path: indexOC4UC0HCBIMeta?.path ?? "roles",
    meta: indexOC4UC0HCBIMeta || {},
    alias: indexOC4UC0HCBIMeta?.alias || [],
    redirect: indexOC4UC0HCBIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/index.vue").then(m => m.default || m)
  },
  {
    name: wait_45listVCtguf2CtxMeta?.name ?? "groupId-members-wait-list___ko___default",
    path: wait_45listVCtguf2CtxMeta?.path ?? "wait-list",
    meta: wait_45listVCtguf2CtxMeta || {},
    alias: wait_45listVCtguf2CtxMeta?.alias || [],
    redirect: wait_45listVCtguf2CtxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/wait-list.vue").then(m => m.default || m)
  }
],
    name: membersQSvU5dg3aaMeta?.name ?? undefined,
    meta: membersQSvU5dg3aaMeta || {},
    alias: membersQSvU5dg3aaMeta?.alias || [],
    redirect: membersQSvU5dg3aaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members.vue").then(m => m.default || m)
  },
  {
    path: projectsOhTjRVWZ7jMeta?.path ?? "projects",
    children: [
  {
    path: _91productId_93ToD7TnxuXGMeta?.path ?? ":projectId()/products/:productId()",
    children: [
  {
    path: buildB0ZOjRhfg4Meta?.path ?? "build",
    children: [
  {
    path: _91buildId_93SD9SKlZWROMeta?.path ?? ":buildId()",
    children: [
  {
    name: indexzAqMn9T9MxMeta?.name ?? "groupId-projects-projectId-products-productId-build-buildId___ko___default",
    path: indexzAqMn9T9MxMeta?.path ?? "",
    meta: indexzAqMn9T9MxMeta || {},
    alias: indexzAqMn9T9MxMeta?.alias || [],
    redirect: indexzAqMn9T9MxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/index.vue").then(m => m.default || m)
  },
  {
    name: livehu3drSQ5HXMeta?.name ?? "groupId-projects-projectId-products-productId-build-buildId-live___ko___default",
    path: livehu3drSQ5HXMeta?.path ?? "live",
    meta: livehu3drSQ5HXMeta || {},
    alias: livehu3drSQ5HXMeta?.alias || [],
    redirect: livehu3drSQ5HXMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/live.vue").then(m => m.default || m)
  }
],
    name: _91buildId_93SD9SKlZWROMeta?.name ?? undefined,
    meta: _91buildId_93SD9SKlZWROMeta || {},
    alias: _91buildId_93SD9SKlZWROMeta?.alias || [],
    redirect: _91buildId_93SD9SKlZWROMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId].vue").then(m => m.default || m)
  },
  {
    name: indexEdlUa4zgOEMeta?.name ?? "groupId-projects-projectId-products-productId-build___ko___default",
    path: indexEdlUa4zgOEMeta?.path ?? "",
    meta: indexEdlUa4zgOEMeta || {},
    alias: indexEdlUa4zgOEMeta?.alias || [],
    redirect: indexEdlUa4zgOEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/index.vue").then(m => m.default || m)
  }
],
    name: buildB0ZOjRhfg4Meta?.name ?? undefined,
    meta: buildB0ZOjRhfg4Meta || {},
    alias: buildB0ZOjRhfg4Meta?.alias || [],
    redirect: buildB0ZOjRhfg4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build.vue").then(m => m.default || m)
  },
  {
    path: discount7vspSFrvgrMeta?.path ?? "discount",
    children: [
  {
    name: _91discountId_937IiHSoBTVEMeta?.name ?? "groupId-projects-projectId-products-productId-discount-discountId___ko___default",
    path: _91discountId_937IiHSoBTVEMeta?.path ?? ":discountId()",
    meta: _91discountId_937IiHSoBTVEMeta || {},
    alias: _91discountId_937IiHSoBTVEMeta?.alias || [],
    redirect: _91discountId_937IiHSoBTVEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/[discountId].vue").then(m => m.default || m)
  },
  {
    name: indexiOnLIBnpj9Meta?.name ?? "groupId-projects-projectId-products-productId-discount___ko___default",
    path: indexiOnLIBnpj9Meta?.path ?? "",
    meta: indexiOnLIBnpj9Meta || {},
    alias: indexiOnLIBnpj9Meta?.alias || [],
    redirect: indexiOnLIBnpj9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/index.vue").then(m => m.default || m)
  },
  {
    name: registerseVaz102vrMeta?.name ?? "groupId-projects-projectId-products-productId-discount-register___ko___default",
    path: registerseVaz102vrMeta?.path ?? "register",
    meta: registerseVaz102vrMeta || {},
    alias: registerseVaz102vrMeta?.alias || [],
    redirect: registerseVaz102vrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/register.vue").then(m => m.default || m)
  }
],
    name: discount7vspSFrvgrMeta?.name ?? undefined,
    meta: discount7vspSFrvgrMeta || {},
    alias: discount7vspSFrvgrMeta?.alias || [],
    redirect: discount7vspSFrvgrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount.vue").then(m => m.default || m)
  },
  {
    name: early_45accessYWpHEZN6iiMeta?.name ?? "groupId-projects-projectId-products-productId-early-access___ko___default",
    path: early_45accessYWpHEZN6iiMeta?.path ?? "early-access",
    meta: early_45accessYWpHEZN6iiMeta || {},
    alias: early_45accessYWpHEZN6iiMeta?.alias || [],
    redirect: early_45accessYWpHEZN6iiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/early-access.vue").then(m => m.default || m)
  },
  {
    name: indexlsUkhnJiMEMeta?.name ?? "groupId-projects-projectId-products-productId___ko___default",
    path: indexlsUkhnJiMEMeta?.path ?? "",
    meta: indexlsUkhnJiMEMeta || {},
    alias: indexlsUkhnJiMEMeta?.alias || [],
    redirect: indexlsUkhnJiMEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: launch_45settingMT4E0WWTIiMeta?.name ?? "groupId-projects-projectId-products-productId-launch-setting___ko___default",
    path: launch_45settingMT4E0WWTIiMeta?.path ?? "launch-setting",
    meta: launch_45settingMT4E0WWTIiMeta || {},
    alias: launch_45settingMT4E0WWTIiMeta?.alias || [],
    redirect: launch_45settingMT4E0WWTIiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/launch-setting.vue").then(m => m.default || m)
  },
  {
    name: banner_45imageDR27uqBOz0Meta?.name ?? "groupId-projects-projectId-products-productId-marketing-management-banner-image___ko___default",
    path: banner_45imageDR27uqBOz0Meta?.path ?? "marketing-management/banner-image",
    meta: banner_45imageDR27uqBOz0Meta || {},
    alias: banner_45imageDR27uqBOz0Meta?.alias || [],
    redirect: banner_45imageDR27uqBOz0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/marketing-management/banner-image.vue").then(m => m.default || m)
  },
  {
    path: product_45pagesLCqMKJKrfLMeta?.path ?? "product-pages",
    children: [
  {
    name: _91mode_93JPPqnAouWiMeta?.name ?? "groupId-projects-projectId-products-productId-product-pages-mode___ko___default",
    path: _91mode_93JPPqnAouWiMeta?.path ?? ":mode()",
    meta: _91mode_93JPPqnAouWiMeta || {},
    alias: _91mode_93JPPqnAouWiMeta?.alias || [],
    redirect: _91mode_93JPPqnAouWiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexBRGfUaqcxiMeta?.name ?? "groupId-projects-projectId-products-productId-product-pages___ko___default",
    path: indexBRGfUaqcxiMeta?.path ?? "",
    meta: indexBRGfUaqcxiMeta || {},
    alias: indexBRGfUaqcxiMeta?.alias || [],
    redirect: indexBRGfUaqcxiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/index.vue").then(m => m.default || m)
  }
],
    name: product_45pagesLCqMKJKrfLMeta?.name ?? undefined,
    meta: product_45pagesLCqMKJKrfLMeta || {},
    alias: product_45pagesLCqMKJKrfLMeta?.alias || [],
    redirect: product_45pagesLCqMKJKrfLMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages.vue").then(m => m.default || m)
  },
  {
    name: purchase_45conditionK3XckRfhLoMeta?.name ?? "groupId-projects-projectId-products-productId-purchase-condition___ko___default",
    path: purchase_45conditionK3XckRfhLoMeta?.path ?? "purchase-condition",
    meta: purchase_45conditionK3XckRfhLoMeta || {},
    alias: purchase_45conditionK3XckRfhLoMeta?.alias || [],
    redirect: purchase_45conditionK3XckRfhLoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/purchase-condition.vue").then(m => m.default || m)
  },
  {
    path: ratingc2cRWY5NFaMeta?.path ?? "rating",
    children: [
  {
    name: adjustment5iVjtprje2Meta?.name ?? "groupId-projects-projectId-products-productId-rating-adjustment___ko___default",
    path: adjustment5iVjtprje2Meta?.path ?? "adjustment",
    meta: adjustment5iVjtprje2Meta || {},
    alias: adjustment5iVjtprje2Meta?.alias || [],
    redirect: adjustment5iVjtprje2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/adjustment.vue").then(m => m.default || m)
  },
  {
    name: cancelOJETfvWOkFMeta?.name ?? "groupId-projects-projectId-products-productId-rating-cancel___ko___default",
    path: cancelOJETfvWOkFMeta?.path ?? "cancel",
    meta: cancelOJETfvWOkFMeta || {},
    alias: cancelOJETfvWOkFMeta?.alias || [],
    redirect: cancelOJETfvWOkFMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/cancel.vue").then(m => m.default || m)
  },
  {
    path: detailyiutK40ahtMeta?.path ?? "detail",
    children: [
  {
    name: grac_45input4q8emIm6TRMeta?.name ?? "groupId-projects-projectId-products-productId-rating-detail-grac-input___ko___default",
    path: grac_45input4q8emIm6TRMeta?.path ?? "grac-input",
    meta: grac_45input4q8emIm6TRMeta || {},
    alias: grac_45input4q8emIm6TRMeta?.alias || [],
    redirect: grac_45input4q8emIm6TRMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/grac-input.vue").then(m => m.default || m)
  },
  {
    name: indexu8S83GQqVAMeta?.name ?? "groupId-projects-projectId-products-productId-rating-detail___ko___default",
    path: indexu8S83GQqVAMeta?.path ?? "",
    meta: indexu8S83GQqVAMeta || {},
    alias: indexu8S83GQqVAMeta?.alias || [],
    redirect: indexu8S83GQqVAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/index.vue").then(m => m.default || m)
  }
],
    name: detailyiutK40ahtMeta?.name ?? undefined,
    meta: detailyiutK40ahtMeta || {},
    alias: detailyiutK40ahtMeta?.alias || [],
    redirect: detailyiutK40ahtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail.vue").then(m => m.default || m)
  },
  {
    name: historyf5QtLr648UMeta?.name ?? "groupId-projects-projectId-products-productId-rating-history___ko___default",
    path: historyf5QtLr648UMeta?.path ?? "history",
    meta: historyf5QtLr648UMeta || {},
    alias: historyf5QtLr648UMeta?.alias || [],
    redirect: historyf5QtLr648UMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/history.vue").then(m => m.default || m)
  },
  {
    name: indexNtRSF10ctIMeta?.name ?? "groupId-projects-projectId-products-productId-rating___ko___default",
    path: indexNtRSF10ctIMeta?.path ?? "",
    meta: indexNtRSF10ctIMeta || {},
    alias: indexNtRSF10ctIMeta?.alias || [],
    redirect: indexNtRSF10ctIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/index.vue").then(m => m.default || m)
  },
  {
    name: reclassificationYFY383yHKEMeta?.name ?? "groupId-projects-projectId-products-productId-rating-reclassification___ko___default",
    path: reclassificationYFY383yHKEMeta?.path ?? "reclassification",
    meta: reclassificationYFY383yHKEMeta || {},
    alias: reclassificationYFY383yHKEMeta?.alias || [],
    redirect: reclassificationYFY383yHKEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reclassification.vue").then(m => m.default || m)
  },
  {
    path: registervkCPeN9ReWMeta?.path ?? "register",
    children: [
  {
    name: grac_45input6kXdR2bZihMeta?.name ?? "groupId-projects-projectId-products-productId-rating-register-grac-input___ko___default",
    path: grac_45input6kXdR2bZihMeta?.path ?? "grac-input",
    meta: grac_45input6kXdR2bZihMeta || {},
    alias: grac_45input6kXdR2bZihMeta?.alias || [],
    redirect: grac_45input6kXdR2bZihMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/grac-input.vue").then(m => m.default || m)
  },
  {
    name: indexdlJrcjbr8ZMeta?.name ?? "groupId-projects-projectId-products-productId-rating-register___ko___default",
    path: indexdlJrcjbr8ZMeta?.path ?? "",
    meta: indexdlJrcjbr8ZMeta || {},
    alias: indexdlJrcjbr8ZMeta?.alias || [],
    redirect: indexdlJrcjbr8ZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/index.vue").then(m => m.default || m)
  }
],
    name: registervkCPeN9ReWMeta?.name ?? undefined,
    meta: registervkCPeN9ReWMeta || {},
    alias: registervkCPeN9ReWMeta?.alias || [],
    redirect: registervkCPeN9ReWMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register.vue").then(m => m.default || m)
  },
  {
    name: reject_45reasonXkFa3rBewqMeta?.name ?? "groupId-projects-projectId-products-productId-rating-reject-reason___ko___default",
    path: reject_45reasonXkFa3rBewqMeta?.path ?? "reject-reason",
    meta: reject_45reasonXkFa3rBewqMeta || {},
    alias: reject_45reasonXkFa3rBewqMeta?.alias || [],
    redirect: reject_45reasonXkFa3rBewqMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reject-reason.vue").then(m => m.default || m)
  },
  {
    name: rejectionlQCjroVCC0Meta?.name ?? "groupId-projects-projectId-products-productId-rating-rejection___ko___default",
    path: rejectionlQCjroVCC0Meta?.path ?? "rejection",
    meta: rejectionlQCjroVCC0Meta || {},
    alias: rejectionlQCjroVCC0Meta?.alias || [],
    redirect: rejectionlQCjroVCC0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/rejection.vue").then(m => m.default || m)
  }
],
    name: ratingc2cRWY5NFaMeta?.name ?? undefined,
    meta: ratingc2cRWY5NFaMeta || {},
    alias: ratingc2cRWY5NFaMeta?.alias || [],
    redirect: ratingc2cRWY5NFaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating.vue").then(m => m.default || m)
  },
  {
    path: release_45planc5emQj8pP6Meta?.path ?? "release-plan",
    children: [
  {
    name: detailWVE89LL0yOMeta?.name ?? "groupId-projects-projectId-products-productId-release-plan-detail___ko___default",
    path: detailWVE89LL0yOMeta?.path ?? "detail",
    meta: detailWVE89LL0yOMeta || {},
    alias: detailWVE89LL0yOMeta?.alias || [],
    redirect: detailWVE89LL0yOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/detail.vue").then(m => m.default || m)
  },
  {
    name: indexLS1Oo7qey4Meta?.name ?? "groupId-projects-projectId-products-productId-release-plan___ko___default",
    path: indexLS1Oo7qey4Meta?.path ?? "",
    meta: indexLS1Oo7qey4Meta || {},
    alias: indexLS1Oo7qey4Meta?.alias || [],
    redirect: indexLS1Oo7qey4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/index.vue").then(m => m.default || m)
  }
],
    name: release_45planc5emQj8pP6Meta?.name ?? undefined,
    meta: release_45planc5emQj8pP6Meta || {},
    alias: release_45planc5emQj8pP6Meta?.alias || [],
    redirect: release_45planc5emQj8pP6Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan.vue").then(m => m.default || m)
  },
  {
    path: selling_45priceaItQqETfYQMeta?.path ?? "selling-price",
    children: [
  {
    name: editmweUb6h5s0Meta?.name ?? "groupId-projects-projectId-products-productId-selling-price-edit___ko___default",
    path: editmweUb6h5s0Meta?.path ?? "edit",
    meta: editmweUb6h5s0Meta || {},
    alias: editmweUb6h5s0Meta?.alias || [],
    redirect: editmweUb6h5s0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/edit.vue").then(m => m.default || m)
  },
  {
    name: indexMDoQJWxC9TMeta?.name ?? "groupId-projects-projectId-products-productId-selling-price___ko___default",
    path: indexMDoQJWxC9TMeta?.path ?? "",
    meta: indexMDoQJWxC9TMeta || {},
    alias: indexMDoQJWxC9TMeta?.alias || [],
    redirect: indexMDoQJWxC9TMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/index.vue").then(m => m.default || m)
  },
  {
    name: register5pUDTzLhD2Meta?.name ?? "groupId-projects-projectId-products-productId-selling-price-register___ko___default",
    path: register5pUDTzLhD2Meta?.path ?? "register",
    meta: register5pUDTzLhD2Meta || {},
    alias: register5pUDTzLhD2Meta?.alias || [],
    redirect: register5pUDTzLhD2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/register.vue").then(m => m.default || m)
  }
],
    name: selling_45priceaItQqETfYQMeta?.name ?? undefined,
    meta: selling_45priceaItQqETfYQMeta || {},
    alias: selling_45priceaItQqETfYQMeta?.alias || [],
    redirect: selling_45priceaItQqETfYQMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price.vue").then(m => m.default || m)
  },
  {
    name: settingsT2NY6WJQl3Meta?.name ?? "groupId-projects-projectId-products-productId-settings___ko___default",
    path: settingsT2NY6WJQl3Meta?.path ?? "settings",
    meta: settingsT2NY6WJQl3Meta || {},
    alias: settingsT2NY6WJQl3Meta?.alias || [],
    redirect: settingsT2NY6WJQl3Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/settings.vue").then(m => m.default || m)
  },
  {
    path: termsAza8XRdk45Meta?.path ?? "terms",
    children: [
  {
    name: editSp50nj3bNaMeta?.name ?? "groupId-projects-projectId-products-productId-terms-edit___ko___default",
    path: editSp50nj3bNaMeta?.path ?? "edit",
    meta: editSp50nj3bNaMeta || {},
    alias: editSp50nj3bNaMeta?.alias || [],
    redirect: editSp50nj3bNaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/edit.vue").then(m => m.default || m)
  },
  {
    name: indexNDSsdBKRt4Meta?.name ?? "groupId-projects-projectId-products-productId-terms___ko___default",
    path: indexNDSsdBKRt4Meta?.path ?? "",
    meta: indexNDSsdBKRt4Meta || {},
    alias: indexNDSsdBKRt4Meta?.alias || [],
    redirect: indexNDSsdBKRt4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/index.vue").then(m => m.default || m)
  },
  {
    name: registergfOlj3ZCdVMeta?.name ?? "groupId-projects-projectId-products-productId-terms-register___ko___default",
    path: registergfOlj3ZCdVMeta?.path ?? "register",
    meta: registergfOlj3ZCdVMeta || {},
    alias: registergfOlj3ZCdVMeta?.alias || [],
    redirect: registergfOlj3ZCdVMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/register.vue").then(m => m.default || m)
  }
],
    name: termsAza8XRdk45Meta?.name ?? undefined,
    meta: termsAza8XRdk45Meta || {},
    alias: termsAza8XRdk45Meta?.alias || [],
    redirect: termsAza8XRdk45Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms.vue").then(m => m.default || m)
  }
],
    name: _91productId_93ToD7TnxuXGMeta?.name ?? undefined,
    meta: _91productId_93ToD7TnxuXGMeta || {},
    alias: _91productId_93ToD7TnxuXGMeta?.alias || [],
    redirect: _91productId_93ToD7TnxuXGMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId].vue").then(m => m.default || m)
  },
  {
    name: indexKUB2ino9kbMeta?.name ?? "groupId-projects-projectId-products___ko___default",
    path: indexKUB2ino9kbMeta?.path ?? ":projectId()/products",
    meta: indexKUB2ino9kbMeta || {},
    alias: indexKUB2ino9kbMeta?.alias || [],
    redirect: indexKUB2ino9kbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/index.vue").then(m => m.default || m)
  },
  {
    name: registerYCPVGOTJ7GMeta?.name ?? "groupId-projects-projectId-products-register___ko___default",
    path: registerYCPVGOTJ7GMeta?.path ?? ":projectId()/products/register",
    meta: registerYCPVGOTJ7GMeta || {},
    alias: registerYCPVGOTJ7GMeta?.alias || [],
    redirect: registerYCPVGOTJ7GMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/register.vue").then(m => m.default || m)
  },
  {
    name: index0A7OKE0A8hMeta?.name ?? "groupId-projects___ko___default",
    path: index0A7OKE0A8hMeta?.path ?? "",
    meta: index0A7OKE0A8hMeta || {},
    alias: index0A7OKE0A8hMeta?.alias || [],
    redirect: index0A7OKE0A8hMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/index.vue").then(m => m.default || m)
  },
  {
    name: register32S2WihcfoMeta?.name ?? "groupId-projects-register___ko___default",
    path: register32S2WihcfoMeta?.path ?? "register",
    meta: register32S2WihcfoMeta || {},
    alias: register32S2WihcfoMeta?.alias || [],
    redirect: register32S2WihcfoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/register.vue").then(m => m.default || m)
  }
],
    name: projectsOhTjRVWZ7jMeta?.name ?? undefined,
    meta: projectsOhTjRVWZ7jMeta || {},
    alias: projectsOhTjRVWZ7jMeta?.alias || [],
    redirect: projectsOhTjRVWZ7jMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects.vue").then(m => m.default || m)
  }
],
    name: _91groupId_937i5oTSd92jMeta?.name ?? undefined,
    meta: _91groupId_937i5oTSd92jMeta || {},
    alias: _91groupId_937i5oTSd92jMeta?.alias || [],
    redirect: _91groupId_937i5oTSd92jMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId].vue").then(m => m.default || m)
  },
  {
    path: _91groupId_937i5oTSd92jMeta?.path ?? "/ko/:groupId()",
    children: [
  {
    path: business_45bankoOuWOVJeWxMeta?.path ?? "business-bank",
    children: [
  {
    name: bank_45listR2crP43aL7Meta?.name ?? "groupId-business-bank-bank-list___ko",
    path: bank_45listR2crP43aL7Meta?.path ?? "bank-list",
    meta: bank_45listR2crP43aL7Meta || {},
    alias: bank_45listR2crP43aL7Meta?.alias || [],
    redirect: bank_45listR2crP43aL7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-list.vue").then(m => m.default || m)
  },
  {
    name: bank_45registerfZwZdsKoxTMeta?.name ?? "groupId-business-bank-bank-register___ko",
    path: bank_45registerfZwZdsKoxTMeta?.path ?? "bank-register",
    meta: bank_45registerfZwZdsKoxTMeta || {},
    alias: bank_45registerfZwZdsKoxTMeta?.alias || [],
    redirect: bank_45registerfZwZdsKoxTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/bank-register.vue").then(m => m.default || m)
  },
  {
    name: business_45list9ADky5juF7Meta?.name ?? "groupId-business-bank-business-list___ko",
    path: business_45list9ADky5juF7Meta?.path ?? "business-list",
    meta: business_45list9ADky5juF7Meta || {},
    alias: business_45list9ADky5juF7Meta?.alias || [],
    redirect: business_45list9ADky5juF7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-list.vue").then(m => m.default || m)
  },
  {
    name: business_45registern6jErCPT4XMeta?.name ?? "groupId-business-bank-business-register___ko",
    path: business_45registern6jErCPT4XMeta?.path ?? "business-register",
    meta: business_45registern6jErCPT4XMeta || {},
    alias: business_45registern6jErCPT4XMeta?.alias || [],
    redirect: business_45registern6jErCPT4XMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/business-register.vue").then(m => m.default || m)
  },
  {
    name: indexkMkjtKISLhMeta?.name ?? "groupId-business-bank___ko",
    path: indexkMkjtKISLhMeta?.path ?? "",
    meta: indexkMkjtKISLhMeta || {},
    alias: indexkMkjtKISLhMeta?.alias || [],
    redirect: indexkMkjtKISLhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank/index.vue").then(m => m.default || m)
  }
],
    name: business_45bankoOuWOVJeWxMeta?.name ?? undefined,
    meta: business_45bankoOuWOVJeWxMeta || {},
    alias: business_45bankoOuWOVJeWxMeta?.alias || [],
    redirect: business_45bankoOuWOVJeWxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/business-bank.vue").then(m => m.default || m)
  },
  {
    path: collectionsFAthRT6rdEMeta?.path ?? "collections",
    children: [
  {
    path: discountIy6GV18yopMeta?.path ?? ":collectionId()/discount",
    children: [
  {
    name: _91mode_93zdEaxOziNBMeta?.name ?? "groupId-collections-collectionId-discount-mode___ko",
    path: _91mode_93zdEaxOziNBMeta?.path ?? ":mode()",
    meta: _91mode_93zdEaxOziNBMeta || {},
    alias: _91mode_93zdEaxOziNBMeta?.alias || [],
    redirect: _91mode_93zdEaxOziNBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexrkJAI5TrwYMeta?.name ?? "groupId-collections-collectionId-discount___ko",
    path: indexrkJAI5TrwYMeta?.path ?? "",
    meta: indexrkJAI5TrwYMeta || {},
    alias: indexrkJAI5TrwYMeta?.alias || [],
    redirect: indexrkJAI5TrwYMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount/index.vue").then(m => m.default || m)
  }
],
    name: discountIy6GV18yopMeta?.name ?? undefined,
    meta: discountIy6GV18yopMeta || {},
    alias: discountIy6GV18yopMeta?.alias || [],
    redirect: discountIy6GV18yopMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[collectionId]/discount.vue").then(m => m.default || m)
  },
  {
    name: _91mode_931ttLzRO79BMeta?.name ?? "groupId-collections-mode___ko",
    path: _91mode_931ttLzRO79BMeta?.path ?? ":mode()",
    meta: _91mode_931ttLzRO79BMeta || {},
    alias: _91mode_931ttLzRO79BMeta?.alias || [],
    redirect: _91mode_931ttLzRO79BMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexEkIF65mYs4Meta?.name ?? "groupId-collections___ko",
    path: indexEkIF65mYs4Meta?.path ?? "",
    meta: indexEkIF65mYs4Meta || {},
    alias: indexEkIF65mYs4Meta?.alias || [],
    redirect: indexEkIF65mYs4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections/index.vue").then(m => m.default || m)
  }
],
    name: collectionsFAthRT6rdEMeta?.name ?? undefined,
    meta: collectionsFAthRT6rdEMeta || {},
    alias: collectionsFAthRT6rdEMeta?.alias || [],
    redirect: collectionsFAthRT6rdEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/collections.vue").then(m => m.default || m)
  },
  {
    name: homeluYuH1WOqBMeta?.name ?? "groupId-home___ko",
    path: homeluYuH1WOqBMeta?.path ?? "home",
    meta: homeluYuH1WOqBMeta || {},
    alias: homeluYuH1WOqBMeta?.alias || [],
    redirect: homeluYuH1WOqBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/home.vue").then(m => m.default || m)
  },
  {
    name: indexkKv5NgYD07Meta?.name ?? "groupId___ko",
    path: indexkKv5NgYD07Meta?.path ?? "",
    meta: indexkKv5NgYD07Meta || {},
    alias: indexkKv5NgYD07Meta?.alias || [],
    redirect: indexkKv5NgYD07Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/index.vue").then(m => m.default || m)
  },
  {
    path: membersQSvU5dg3aaMeta?.path ?? "members",
    children: [
  {
    name: _91id_938qdkdues3NMeta?.name ?? "groupId-members-id___ko",
    path: _91id_938qdkdues3NMeta?.path ?? ":id()",
    meta: _91id_938qdkdues3NMeta || {},
    alias: _91id_938qdkdues3NMeta?.alias || [],
    redirect: _91id_938qdkdues3NMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYB7qgwmZpiMeta?.name ?? "groupId-members___ko",
    path: indexYB7qgwmZpiMeta?.path ?? "",
    meta: indexYB7qgwmZpiMeta || {},
    alias: indexYB7qgwmZpiMeta?.alias || [],
    redirect: indexYB7qgwmZpiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: invite_45historyozwdBjUG7zMeta?.name ?? "groupId-members-invite-history___ko",
    path: invite_45historyozwdBjUG7zMeta?.path ?? "invite-history",
    meta: invite_45historyozwdBjUG7zMeta || {},
    alias: invite_45historyozwdBjUG7zMeta?.alias || [],
    redirect: invite_45historyozwdBjUG7zMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite-history.vue").then(m => m.default || m)
  },
  {
    name: invitewLIxfuFPQgMeta?.name ?? "groupId-members-invite___ko",
    path: invitewLIxfuFPQgMeta?.path ?? "invite",
    meta: invitewLIxfuFPQgMeta || {},
    alias: invitewLIxfuFPQgMeta?.alias || [],
    redirect: invitewLIxfuFPQgMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/invite.vue").then(m => m.default || m)
  },
  {
    name: _91mode_932jWlpHxdFxMeta?.name ?? "groupId-members-roles-mode___ko",
    path: _91mode_932jWlpHxdFxMeta?.path ?? "roles/:mode()",
    meta: _91mode_932jWlpHxdFxMeta || {},
    alias: _91mode_932jWlpHxdFxMeta?.alias || [],
    redirect: _91mode_932jWlpHxdFxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexOC4UC0HCBIMeta?.name ?? "groupId-members-roles___ko",
    path: indexOC4UC0HCBIMeta?.path ?? "roles",
    meta: indexOC4UC0HCBIMeta || {},
    alias: indexOC4UC0HCBIMeta?.alias || [],
    redirect: indexOC4UC0HCBIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/roles/index.vue").then(m => m.default || m)
  },
  {
    name: wait_45listVCtguf2CtxMeta?.name ?? "groupId-members-wait-list___ko",
    path: wait_45listVCtguf2CtxMeta?.path ?? "wait-list",
    meta: wait_45listVCtguf2CtxMeta || {},
    alias: wait_45listVCtguf2CtxMeta?.alias || [],
    redirect: wait_45listVCtguf2CtxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members/wait-list.vue").then(m => m.default || m)
  }
],
    name: membersQSvU5dg3aaMeta?.name ?? undefined,
    meta: membersQSvU5dg3aaMeta || {},
    alias: membersQSvU5dg3aaMeta?.alias || [],
    redirect: membersQSvU5dg3aaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/members.vue").then(m => m.default || m)
  },
  {
    path: projectsOhTjRVWZ7jMeta?.path ?? "projects",
    children: [
  {
    path: _91productId_93ToD7TnxuXGMeta?.path ?? ":projectId()/products/:productId()",
    children: [
  {
    path: buildB0ZOjRhfg4Meta?.path ?? "build",
    children: [
  {
    path: _91buildId_93SD9SKlZWROMeta?.path ?? ":buildId()",
    children: [
  {
    name: indexzAqMn9T9MxMeta?.name ?? "groupId-projects-projectId-products-productId-build-buildId___ko",
    path: indexzAqMn9T9MxMeta?.path ?? "",
    meta: indexzAqMn9T9MxMeta || {},
    alias: indexzAqMn9T9MxMeta?.alias || [],
    redirect: indexzAqMn9T9MxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/index.vue").then(m => m.default || m)
  },
  {
    name: livehu3drSQ5HXMeta?.name ?? "groupId-projects-projectId-products-productId-build-buildId-live___ko",
    path: livehu3drSQ5HXMeta?.path ?? "live",
    meta: livehu3drSQ5HXMeta || {},
    alias: livehu3drSQ5HXMeta?.alias || [],
    redirect: livehu3drSQ5HXMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId]/live.vue").then(m => m.default || m)
  }
],
    name: _91buildId_93SD9SKlZWROMeta?.name ?? undefined,
    meta: _91buildId_93SD9SKlZWROMeta || {},
    alias: _91buildId_93SD9SKlZWROMeta?.alias || [],
    redirect: _91buildId_93SD9SKlZWROMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/[buildId].vue").then(m => m.default || m)
  },
  {
    name: indexEdlUa4zgOEMeta?.name ?? "groupId-projects-projectId-products-productId-build___ko",
    path: indexEdlUa4zgOEMeta?.path ?? "",
    meta: indexEdlUa4zgOEMeta || {},
    alias: indexEdlUa4zgOEMeta?.alias || [],
    redirect: indexEdlUa4zgOEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build/index.vue").then(m => m.default || m)
  }
],
    name: buildB0ZOjRhfg4Meta?.name ?? undefined,
    meta: buildB0ZOjRhfg4Meta || {},
    alias: buildB0ZOjRhfg4Meta?.alias || [],
    redirect: buildB0ZOjRhfg4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/build.vue").then(m => m.default || m)
  },
  {
    path: discount7vspSFrvgrMeta?.path ?? "discount",
    children: [
  {
    name: _91discountId_937IiHSoBTVEMeta?.name ?? "groupId-projects-projectId-products-productId-discount-discountId___ko",
    path: _91discountId_937IiHSoBTVEMeta?.path ?? ":discountId()",
    meta: _91discountId_937IiHSoBTVEMeta || {},
    alias: _91discountId_937IiHSoBTVEMeta?.alias || [],
    redirect: _91discountId_937IiHSoBTVEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/[discountId].vue").then(m => m.default || m)
  },
  {
    name: indexiOnLIBnpj9Meta?.name ?? "groupId-projects-projectId-products-productId-discount___ko",
    path: indexiOnLIBnpj9Meta?.path ?? "",
    meta: indexiOnLIBnpj9Meta || {},
    alias: indexiOnLIBnpj9Meta?.alias || [],
    redirect: indexiOnLIBnpj9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/index.vue").then(m => m.default || m)
  },
  {
    name: registerseVaz102vrMeta?.name ?? "groupId-projects-projectId-products-productId-discount-register___ko",
    path: registerseVaz102vrMeta?.path ?? "register",
    meta: registerseVaz102vrMeta || {},
    alias: registerseVaz102vrMeta?.alias || [],
    redirect: registerseVaz102vrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount/register.vue").then(m => m.default || m)
  }
],
    name: discount7vspSFrvgrMeta?.name ?? undefined,
    meta: discount7vspSFrvgrMeta || {},
    alias: discount7vspSFrvgrMeta?.alias || [],
    redirect: discount7vspSFrvgrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/discount.vue").then(m => m.default || m)
  },
  {
    name: early_45accessYWpHEZN6iiMeta?.name ?? "groupId-projects-projectId-products-productId-early-access___ko",
    path: early_45accessYWpHEZN6iiMeta?.path ?? "early-access",
    meta: early_45accessYWpHEZN6iiMeta || {},
    alias: early_45accessYWpHEZN6iiMeta?.alias || [],
    redirect: early_45accessYWpHEZN6iiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/early-access.vue").then(m => m.default || m)
  },
  {
    name: indexlsUkhnJiMEMeta?.name ?? "groupId-projects-projectId-products-productId___ko",
    path: indexlsUkhnJiMEMeta?.path ?? "",
    meta: indexlsUkhnJiMEMeta || {},
    alias: indexlsUkhnJiMEMeta?.alias || [],
    redirect: indexlsUkhnJiMEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: launch_45settingMT4E0WWTIiMeta?.name ?? "groupId-projects-projectId-products-productId-launch-setting___ko",
    path: launch_45settingMT4E0WWTIiMeta?.path ?? "launch-setting",
    meta: launch_45settingMT4E0WWTIiMeta || {},
    alias: launch_45settingMT4E0WWTIiMeta?.alias || [],
    redirect: launch_45settingMT4E0WWTIiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/launch-setting.vue").then(m => m.default || m)
  },
  {
    name: banner_45imageDR27uqBOz0Meta?.name ?? "groupId-projects-projectId-products-productId-marketing-management-banner-image___ko",
    path: banner_45imageDR27uqBOz0Meta?.path ?? "marketing-management/banner-image",
    meta: banner_45imageDR27uqBOz0Meta || {},
    alias: banner_45imageDR27uqBOz0Meta?.alias || [],
    redirect: banner_45imageDR27uqBOz0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/marketing-management/banner-image.vue").then(m => m.default || m)
  },
  {
    path: product_45pagesLCqMKJKrfLMeta?.path ?? "product-pages",
    children: [
  {
    name: _91mode_93JPPqnAouWiMeta?.name ?? "groupId-projects-projectId-products-productId-product-pages-mode___ko",
    path: _91mode_93JPPqnAouWiMeta?.path ?? ":mode()",
    meta: _91mode_93JPPqnAouWiMeta || {},
    alias: _91mode_93JPPqnAouWiMeta?.alias || [],
    redirect: _91mode_93JPPqnAouWiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/[mode].vue").then(m => m.default || m)
  },
  {
    name: indexBRGfUaqcxiMeta?.name ?? "groupId-projects-projectId-products-productId-product-pages___ko",
    path: indexBRGfUaqcxiMeta?.path ?? "",
    meta: indexBRGfUaqcxiMeta || {},
    alias: indexBRGfUaqcxiMeta?.alias || [],
    redirect: indexBRGfUaqcxiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages/index.vue").then(m => m.default || m)
  }
],
    name: product_45pagesLCqMKJKrfLMeta?.name ?? undefined,
    meta: product_45pagesLCqMKJKrfLMeta || {},
    alias: product_45pagesLCqMKJKrfLMeta?.alias || [],
    redirect: product_45pagesLCqMKJKrfLMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/product-pages.vue").then(m => m.default || m)
  },
  {
    name: purchase_45conditionK3XckRfhLoMeta?.name ?? "groupId-projects-projectId-products-productId-purchase-condition___ko",
    path: purchase_45conditionK3XckRfhLoMeta?.path ?? "purchase-condition",
    meta: purchase_45conditionK3XckRfhLoMeta || {},
    alias: purchase_45conditionK3XckRfhLoMeta?.alias || [],
    redirect: purchase_45conditionK3XckRfhLoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/purchase-condition.vue").then(m => m.default || m)
  },
  {
    path: ratingc2cRWY5NFaMeta?.path ?? "rating",
    children: [
  {
    name: adjustment5iVjtprje2Meta?.name ?? "groupId-projects-projectId-products-productId-rating-adjustment___ko",
    path: adjustment5iVjtprje2Meta?.path ?? "adjustment",
    meta: adjustment5iVjtprje2Meta || {},
    alias: adjustment5iVjtprje2Meta?.alias || [],
    redirect: adjustment5iVjtprje2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/adjustment.vue").then(m => m.default || m)
  },
  {
    name: cancelOJETfvWOkFMeta?.name ?? "groupId-projects-projectId-products-productId-rating-cancel___ko",
    path: cancelOJETfvWOkFMeta?.path ?? "cancel",
    meta: cancelOJETfvWOkFMeta || {},
    alias: cancelOJETfvWOkFMeta?.alias || [],
    redirect: cancelOJETfvWOkFMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/cancel.vue").then(m => m.default || m)
  },
  {
    path: detailyiutK40ahtMeta?.path ?? "detail",
    children: [
  {
    name: grac_45input4q8emIm6TRMeta?.name ?? "groupId-projects-projectId-products-productId-rating-detail-grac-input___ko",
    path: grac_45input4q8emIm6TRMeta?.path ?? "grac-input",
    meta: grac_45input4q8emIm6TRMeta || {},
    alias: grac_45input4q8emIm6TRMeta?.alias || [],
    redirect: grac_45input4q8emIm6TRMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/grac-input.vue").then(m => m.default || m)
  },
  {
    name: indexu8S83GQqVAMeta?.name ?? "groupId-projects-projectId-products-productId-rating-detail___ko",
    path: indexu8S83GQqVAMeta?.path ?? "",
    meta: indexu8S83GQqVAMeta || {},
    alias: indexu8S83GQqVAMeta?.alias || [],
    redirect: indexu8S83GQqVAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail/index.vue").then(m => m.default || m)
  }
],
    name: detailyiutK40ahtMeta?.name ?? undefined,
    meta: detailyiutK40ahtMeta || {},
    alias: detailyiutK40ahtMeta?.alias || [],
    redirect: detailyiutK40ahtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/detail.vue").then(m => m.default || m)
  },
  {
    name: historyf5QtLr648UMeta?.name ?? "groupId-projects-projectId-products-productId-rating-history___ko",
    path: historyf5QtLr648UMeta?.path ?? "history",
    meta: historyf5QtLr648UMeta || {},
    alias: historyf5QtLr648UMeta?.alias || [],
    redirect: historyf5QtLr648UMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/history.vue").then(m => m.default || m)
  },
  {
    name: indexNtRSF10ctIMeta?.name ?? "groupId-projects-projectId-products-productId-rating___ko",
    path: indexNtRSF10ctIMeta?.path ?? "",
    meta: indexNtRSF10ctIMeta || {},
    alias: indexNtRSF10ctIMeta?.alias || [],
    redirect: indexNtRSF10ctIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/index.vue").then(m => m.default || m)
  },
  {
    name: reclassificationYFY383yHKEMeta?.name ?? "groupId-projects-projectId-products-productId-rating-reclassification___ko",
    path: reclassificationYFY383yHKEMeta?.path ?? "reclassification",
    meta: reclassificationYFY383yHKEMeta || {},
    alias: reclassificationYFY383yHKEMeta?.alias || [],
    redirect: reclassificationYFY383yHKEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reclassification.vue").then(m => m.default || m)
  },
  {
    path: registervkCPeN9ReWMeta?.path ?? "register",
    children: [
  {
    name: grac_45input6kXdR2bZihMeta?.name ?? "groupId-projects-projectId-products-productId-rating-register-grac-input___ko",
    path: grac_45input6kXdR2bZihMeta?.path ?? "grac-input",
    meta: grac_45input6kXdR2bZihMeta || {},
    alias: grac_45input6kXdR2bZihMeta?.alias || [],
    redirect: grac_45input6kXdR2bZihMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/grac-input.vue").then(m => m.default || m)
  },
  {
    name: indexdlJrcjbr8ZMeta?.name ?? "groupId-projects-projectId-products-productId-rating-register___ko",
    path: indexdlJrcjbr8ZMeta?.path ?? "",
    meta: indexdlJrcjbr8ZMeta || {},
    alias: indexdlJrcjbr8ZMeta?.alias || [],
    redirect: indexdlJrcjbr8ZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register/index.vue").then(m => m.default || m)
  }
],
    name: registervkCPeN9ReWMeta?.name ?? undefined,
    meta: registervkCPeN9ReWMeta || {},
    alias: registervkCPeN9ReWMeta?.alias || [],
    redirect: registervkCPeN9ReWMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/register.vue").then(m => m.default || m)
  },
  {
    name: reject_45reasonXkFa3rBewqMeta?.name ?? "groupId-projects-projectId-products-productId-rating-reject-reason___ko",
    path: reject_45reasonXkFa3rBewqMeta?.path ?? "reject-reason",
    meta: reject_45reasonXkFa3rBewqMeta || {},
    alias: reject_45reasonXkFa3rBewqMeta?.alias || [],
    redirect: reject_45reasonXkFa3rBewqMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/reject-reason.vue").then(m => m.default || m)
  },
  {
    name: rejectionlQCjroVCC0Meta?.name ?? "groupId-projects-projectId-products-productId-rating-rejection___ko",
    path: rejectionlQCjroVCC0Meta?.path ?? "rejection",
    meta: rejectionlQCjroVCC0Meta || {},
    alias: rejectionlQCjroVCC0Meta?.alias || [],
    redirect: rejectionlQCjroVCC0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating/rejection.vue").then(m => m.default || m)
  }
],
    name: ratingc2cRWY5NFaMeta?.name ?? undefined,
    meta: ratingc2cRWY5NFaMeta || {},
    alias: ratingc2cRWY5NFaMeta?.alias || [],
    redirect: ratingc2cRWY5NFaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/rating.vue").then(m => m.default || m)
  },
  {
    path: release_45planc5emQj8pP6Meta?.path ?? "release-plan",
    children: [
  {
    name: detailWVE89LL0yOMeta?.name ?? "groupId-projects-projectId-products-productId-release-plan-detail___ko",
    path: detailWVE89LL0yOMeta?.path ?? "detail",
    meta: detailWVE89LL0yOMeta || {},
    alias: detailWVE89LL0yOMeta?.alias || [],
    redirect: detailWVE89LL0yOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/detail.vue").then(m => m.default || m)
  },
  {
    name: indexLS1Oo7qey4Meta?.name ?? "groupId-projects-projectId-products-productId-release-plan___ko",
    path: indexLS1Oo7qey4Meta?.path ?? "",
    meta: indexLS1Oo7qey4Meta || {},
    alias: indexLS1Oo7qey4Meta?.alias || [],
    redirect: indexLS1Oo7qey4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan/index.vue").then(m => m.default || m)
  }
],
    name: release_45planc5emQj8pP6Meta?.name ?? undefined,
    meta: release_45planc5emQj8pP6Meta || {},
    alias: release_45planc5emQj8pP6Meta?.alias || [],
    redirect: release_45planc5emQj8pP6Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/release-plan.vue").then(m => m.default || m)
  },
  {
    path: selling_45priceaItQqETfYQMeta?.path ?? "selling-price",
    children: [
  {
    name: editmweUb6h5s0Meta?.name ?? "groupId-projects-projectId-products-productId-selling-price-edit___ko",
    path: editmweUb6h5s0Meta?.path ?? "edit",
    meta: editmweUb6h5s0Meta || {},
    alias: editmweUb6h5s0Meta?.alias || [],
    redirect: editmweUb6h5s0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/edit.vue").then(m => m.default || m)
  },
  {
    name: indexMDoQJWxC9TMeta?.name ?? "groupId-projects-projectId-products-productId-selling-price___ko",
    path: indexMDoQJWxC9TMeta?.path ?? "",
    meta: indexMDoQJWxC9TMeta || {},
    alias: indexMDoQJWxC9TMeta?.alias || [],
    redirect: indexMDoQJWxC9TMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/index.vue").then(m => m.default || m)
  },
  {
    name: register5pUDTzLhD2Meta?.name ?? "groupId-projects-projectId-products-productId-selling-price-register___ko",
    path: register5pUDTzLhD2Meta?.path ?? "register",
    meta: register5pUDTzLhD2Meta || {},
    alias: register5pUDTzLhD2Meta?.alias || [],
    redirect: register5pUDTzLhD2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price/register.vue").then(m => m.default || m)
  }
],
    name: selling_45priceaItQqETfYQMeta?.name ?? undefined,
    meta: selling_45priceaItQqETfYQMeta || {},
    alias: selling_45priceaItQqETfYQMeta?.alias || [],
    redirect: selling_45priceaItQqETfYQMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/selling-price.vue").then(m => m.default || m)
  },
  {
    name: settingsT2NY6WJQl3Meta?.name ?? "groupId-projects-projectId-products-productId-settings___ko",
    path: settingsT2NY6WJQl3Meta?.path ?? "settings",
    meta: settingsT2NY6WJQl3Meta || {},
    alias: settingsT2NY6WJQl3Meta?.alias || [],
    redirect: settingsT2NY6WJQl3Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/settings.vue").then(m => m.default || m)
  },
  {
    path: termsAza8XRdk45Meta?.path ?? "terms",
    children: [
  {
    name: editSp50nj3bNaMeta?.name ?? "groupId-projects-projectId-products-productId-terms-edit___ko",
    path: editSp50nj3bNaMeta?.path ?? "edit",
    meta: editSp50nj3bNaMeta || {},
    alias: editSp50nj3bNaMeta?.alias || [],
    redirect: editSp50nj3bNaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/edit.vue").then(m => m.default || m)
  },
  {
    name: indexNDSsdBKRt4Meta?.name ?? "groupId-projects-projectId-products-productId-terms___ko",
    path: indexNDSsdBKRt4Meta?.path ?? "",
    meta: indexNDSsdBKRt4Meta || {},
    alias: indexNDSsdBKRt4Meta?.alias || [],
    redirect: indexNDSsdBKRt4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/index.vue").then(m => m.default || m)
  },
  {
    name: registergfOlj3ZCdVMeta?.name ?? "groupId-projects-projectId-products-productId-terms-register___ko",
    path: registergfOlj3ZCdVMeta?.path ?? "register",
    meta: registergfOlj3ZCdVMeta || {},
    alias: registergfOlj3ZCdVMeta?.alias || [],
    redirect: registergfOlj3ZCdVMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms/register.vue").then(m => m.default || m)
  }
],
    name: termsAza8XRdk45Meta?.name ?? undefined,
    meta: termsAza8XRdk45Meta || {},
    alias: termsAza8XRdk45Meta?.alias || [],
    redirect: termsAza8XRdk45Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId]/terms.vue").then(m => m.default || m)
  }
],
    name: _91productId_93ToD7TnxuXGMeta?.name ?? undefined,
    meta: _91productId_93ToD7TnxuXGMeta || {},
    alias: _91productId_93ToD7TnxuXGMeta?.alias || [],
    redirect: _91productId_93ToD7TnxuXGMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/[productId].vue").then(m => m.default || m)
  },
  {
    name: indexKUB2ino9kbMeta?.name ?? "groupId-projects-projectId-products___ko",
    path: indexKUB2ino9kbMeta?.path ?? ":projectId()/products",
    meta: indexKUB2ino9kbMeta || {},
    alias: indexKUB2ino9kbMeta?.alias || [],
    redirect: indexKUB2ino9kbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/index.vue").then(m => m.default || m)
  },
  {
    name: registerYCPVGOTJ7GMeta?.name ?? "groupId-projects-projectId-products-register___ko",
    path: registerYCPVGOTJ7GMeta?.path ?? ":projectId()/products/register",
    meta: registerYCPVGOTJ7GMeta || {},
    alias: registerYCPVGOTJ7GMeta?.alias || [],
    redirect: registerYCPVGOTJ7GMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/[projectId]/products/register.vue").then(m => m.default || m)
  },
  {
    name: index0A7OKE0A8hMeta?.name ?? "groupId-projects___ko",
    path: index0A7OKE0A8hMeta?.path ?? "",
    meta: index0A7OKE0A8hMeta || {},
    alias: index0A7OKE0A8hMeta?.alias || [],
    redirect: index0A7OKE0A8hMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/index.vue").then(m => m.default || m)
  },
  {
    name: register32S2WihcfoMeta?.name ?? "groupId-projects-register___ko",
    path: register32S2WihcfoMeta?.path ?? "register",
    meta: register32S2WihcfoMeta || {},
    alias: register32S2WihcfoMeta?.alias || [],
    redirect: register32S2WihcfoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects/register.vue").then(m => m.default || m)
  }
],
    name: projectsOhTjRVWZ7jMeta?.name ?? undefined,
    meta: projectsOhTjRVWZ7jMeta || {},
    alias: projectsOhTjRVWZ7jMeta?.alias || [],
    redirect: projectsOhTjRVWZ7jMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId]/projects.vue").then(m => m.default || m)
  }
],
    name: _91groupId_937i5oTSd92jMeta?.name ?? undefined,
    meta: _91groupId_937i5oTSd92jMeta || {},
    alias: _91groupId_937i5oTSd92jMeta?.alias || [],
    redirect: _91groupId_937i5oTSd92jMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/[groupId].vue").then(m => m.default || m)
  },
  {
    name: brand1PUN7bEynbMeta?.name ?? "brand___en",
    path: brand1PUN7bEynbMeta?.path ?? "/en/brand",
    meta: brand1PUN7bEynbMeta || {},
    alias: brand1PUN7bEynbMeta?.alias || [],
    redirect: brand1PUN7bEynbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/brand.vue").then(m => m.default || m)
  },
  {
    name: brand1PUN7bEynbMeta?.name ?? "brand___zh-cn",
    path: brand1PUN7bEynbMeta?.path ?? "/zh-cn/brand",
    meta: brand1PUN7bEynbMeta || {},
    alias: brand1PUN7bEynbMeta?.alias || [],
    redirect: brand1PUN7bEynbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/brand.vue").then(m => m.default || m)
  },
  {
    name: brand1PUN7bEynbMeta?.name ?? "brand___zh-tw",
    path: brand1PUN7bEynbMeta?.path ?? "/zh-tw/brand",
    meta: brand1PUN7bEynbMeta || {},
    alias: brand1PUN7bEynbMeta?.alias || [],
    redirect: brand1PUN7bEynbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/brand.vue").then(m => m.default || m)
  },
  {
    name: brand1PUN7bEynbMeta?.name ?? "brand___ja",
    path: brand1PUN7bEynbMeta?.path ?? "/ja/brand",
    meta: brand1PUN7bEynbMeta || {},
    alias: brand1PUN7bEynbMeta?.alias || [],
    redirect: brand1PUN7bEynbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/brand.vue").then(m => m.default || m)
  },
  {
    name: brand1PUN7bEynbMeta?.name ?? "brand___ko___default",
    path: brand1PUN7bEynbMeta?.path ?? "/brand",
    meta: brand1PUN7bEynbMeta || {},
    alias: brand1PUN7bEynbMeta?.alias || [],
    redirect: brand1PUN7bEynbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/brand.vue").then(m => m.default || m)
  },
  {
    name: brand1PUN7bEynbMeta?.name ?? "brand___ko",
    path: brand1PUN7bEynbMeta?.path ?? "/ko/brand",
    meta: brand1PUN7bEynbMeta || {},
    alias: brand1PUN7bEynbMeta?.alias || [],
    redirect: brand1PUN7bEynbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/brand.vue").then(m => m.default || m)
  },
  {
    name: errorHKkSNgN3xtMeta?.name ?? "error___en",
    path: errorHKkSNgN3xtMeta?.path ?? "/en/error",
    meta: errorHKkSNgN3xtMeta || {},
    alias: errorHKkSNgN3xtMeta?.alias || [],
    redirect: errorHKkSNgN3xtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorHKkSNgN3xtMeta?.name ?? "error___zh-cn",
    path: errorHKkSNgN3xtMeta?.path ?? "/zh-cn/error",
    meta: errorHKkSNgN3xtMeta || {},
    alias: errorHKkSNgN3xtMeta?.alias || [],
    redirect: errorHKkSNgN3xtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorHKkSNgN3xtMeta?.name ?? "error___zh-tw",
    path: errorHKkSNgN3xtMeta?.path ?? "/zh-tw/error",
    meta: errorHKkSNgN3xtMeta || {},
    alias: errorHKkSNgN3xtMeta?.alias || [],
    redirect: errorHKkSNgN3xtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorHKkSNgN3xtMeta?.name ?? "error___ja",
    path: errorHKkSNgN3xtMeta?.path ?? "/ja/error",
    meta: errorHKkSNgN3xtMeta || {},
    alias: errorHKkSNgN3xtMeta?.alias || [],
    redirect: errorHKkSNgN3xtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorHKkSNgN3xtMeta?.name ?? "error___ko___default",
    path: errorHKkSNgN3xtMeta?.path ?? "/error",
    meta: errorHKkSNgN3xtMeta || {},
    alias: errorHKkSNgN3xtMeta?.alias || [],
    redirect: errorHKkSNgN3xtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorHKkSNgN3xtMeta?.name ?? "error___ko",
    path: errorHKkSNgN3xtMeta?.path ?? "/ko/error",
    meta: errorHKkSNgN3xtMeta || {},
    alias: errorHKkSNgN3xtMeta?.alias || [],
    redirect: errorHKkSNgN3xtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: indexmn9NmTFwE9Meta?.name ?? "group-transfer-business-registration___en",
    path: indexmn9NmTFwE9Meta?.path ?? "/en/group-transfer/business-registration",
    meta: indexmn9NmTFwE9Meta || {},
    alias: indexmn9NmTFwE9Meta?.alias || [],
    redirect: indexmn9NmTFwE9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group-transfer/business-registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexmn9NmTFwE9Meta?.name ?? "group-transfer-business-registration___zh-cn",
    path: indexmn9NmTFwE9Meta?.path ?? "/zh-cn/group-transfer/business-registration",
    meta: indexmn9NmTFwE9Meta || {},
    alias: indexmn9NmTFwE9Meta?.alias || [],
    redirect: indexmn9NmTFwE9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group-transfer/business-registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexmn9NmTFwE9Meta?.name ?? "group-transfer-business-registration___zh-tw",
    path: indexmn9NmTFwE9Meta?.path ?? "/zh-tw/group-transfer/business-registration",
    meta: indexmn9NmTFwE9Meta || {},
    alias: indexmn9NmTFwE9Meta?.alias || [],
    redirect: indexmn9NmTFwE9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group-transfer/business-registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexmn9NmTFwE9Meta?.name ?? "group-transfer-business-registration___ja",
    path: indexmn9NmTFwE9Meta?.path ?? "/ja/group-transfer/business-registration",
    meta: indexmn9NmTFwE9Meta || {},
    alias: indexmn9NmTFwE9Meta?.alias || [],
    redirect: indexmn9NmTFwE9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group-transfer/business-registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexmn9NmTFwE9Meta?.name ?? "group-transfer-business-registration___ko___default",
    path: indexmn9NmTFwE9Meta?.path ?? "/group-transfer/business-registration",
    meta: indexmn9NmTFwE9Meta || {},
    alias: indexmn9NmTFwE9Meta?.alias || [],
    redirect: indexmn9NmTFwE9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group-transfer/business-registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexmn9NmTFwE9Meta?.name ?? "group-transfer-business-registration___ko",
    path: indexmn9NmTFwE9Meta?.path ?? "/ko/group-transfer/business-registration",
    meta: indexmn9NmTFwE9Meta || {},
    alias: indexmn9NmTFwE9Meta?.alias || [],
    redirect: indexmn9NmTFwE9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group-transfer/business-registration/index.vue").then(m => m.default || m)
  },
  {
    name: groupP9X22i24B0Meta?.name ?? "group___en",
    path: groupP9X22i24B0Meta?.path ?? "/en/group",
    children: [
  {
    name: registerfukUmsOjGCMeta?.name ?? "group-register___en",
    path: registerfukUmsOjGCMeta?.path ?? "register",
    meta: registerfukUmsOjGCMeta || {},
    alias: registerfukUmsOjGCMeta?.alias || [],
    redirect: registerfukUmsOjGCMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group/register.vue").then(m => m.default || m)
  }
],
    meta: groupP9X22i24B0Meta || {},
    alias: groupP9X22i24B0Meta?.alias || [],
    redirect: groupP9X22i24B0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group.vue").then(m => m.default || m)
  },
  {
    name: groupP9X22i24B0Meta?.name ?? "group___zh-cn",
    path: groupP9X22i24B0Meta?.path ?? "/zh-cn/group",
    children: [
  {
    name: registerfukUmsOjGCMeta?.name ?? "group-register___zh-cn",
    path: registerfukUmsOjGCMeta?.path ?? "register",
    meta: registerfukUmsOjGCMeta || {},
    alias: registerfukUmsOjGCMeta?.alias || [],
    redirect: registerfukUmsOjGCMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group/register.vue").then(m => m.default || m)
  }
],
    meta: groupP9X22i24B0Meta || {},
    alias: groupP9X22i24B0Meta?.alias || [],
    redirect: groupP9X22i24B0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group.vue").then(m => m.default || m)
  },
  {
    name: groupP9X22i24B0Meta?.name ?? "group___zh-tw",
    path: groupP9X22i24B0Meta?.path ?? "/zh-tw/group",
    children: [
  {
    name: registerfukUmsOjGCMeta?.name ?? "group-register___zh-tw",
    path: registerfukUmsOjGCMeta?.path ?? "register",
    meta: registerfukUmsOjGCMeta || {},
    alias: registerfukUmsOjGCMeta?.alias || [],
    redirect: registerfukUmsOjGCMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group/register.vue").then(m => m.default || m)
  }
],
    meta: groupP9X22i24B0Meta || {},
    alias: groupP9X22i24B0Meta?.alias || [],
    redirect: groupP9X22i24B0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group.vue").then(m => m.default || m)
  },
  {
    name: groupP9X22i24B0Meta?.name ?? "group___ja",
    path: groupP9X22i24B0Meta?.path ?? "/ja/group",
    children: [
  {
    name: registerfukUmsOjGCMeta?.name ?? "group-register___ja",
    path: registerfukUmsOjGCMeta?.path ?? "register",
    meta: registerfukUmsOjGCMeta || {},
    alias: registerfukUmsOjGCMeta?.alias || [],
    redirect: registerfukUmsOjGCMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group/register.vue").then(m => m.default || m)
  }
],
    meta: groupP9X22i24B0Meta || {},
    alias: groupP9X22i24B0Meta?.alias || [],
    redirect: groupP9X22i24B0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group.vue").then(m => m.default || m)
  },
  {
    name: groupP9X22i24B0Meta?.name ?? "group___ko___default",
    path: groupP9X22i24B0Meta?.path ?? "/group",
    children: [
  {
    name: registerfukUmsOjGCMeta?.name ?? "group-register___ko___default",
    path: registerfukUmsOjGCMeta?.path ?? "register",
    meta: registerfukUmsOjGCMeta || {},
    alias: registerfukUmsOjGCMeta?.alias || [],
    redirect: registerfukUmsOjGCMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group/register.vue").then(m => m.default || m)
  }
],
    meta: groupP9X22i24B0Meta || {},
    alias: groupP9X22i24B0Meta?.alias || [],
    redirect: groupP9X22i24B0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group.vue").then(m => m.default || m)
  },
  {
    name: groupP9X22i24B0Meta?.name ?? "group___ko",
    path: groupP9X22i24B0Meta?.path ?? "/ko/group",
    children: [
  {
    name: registerfukUmsOjGCMeta?.name ?? "group-register___ko",
    path: registerfukUmsOjGCMeta?.path ?? "register",
    meta: registerfukUmsOjGCMeta || {},
    alias: registerfukUmsOjGCMeta?.alias || [],
    redirect: registerfukUmsOjGCMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group/register.vue").then(m => m.default || m)
  }
],
    meta: groupP9X22i24B0Meta || {},
    alias: groupP9X22i24B0Meta?.alias || [],
    redirect: groupP9X22i24B0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/group.vue").then(m => m.default || m)
  },
  {
    name: indexIx747zg3K8Meta?.name ?? "index___en",
    path: indexIx747zg3K8Meta?.path ?? "/en",
    meta: indexIx747zg3K8Meta || {},
    alias: indexIx747zg3K8Meta?.alias || [],
    redirect: indexIx747zg3K8Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexIx747zg3K8Meta?.name ?? "index___zh-cn",
    path: indexIx747zg3K8Meta?.path ?? "/zh-cn",
    meta: indexIx747zg3K8Meta || {},
    alias: indexIx747zg3K8Meta?.alias || [],
    redirect: indexIx747zg3K8Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexIx747zg3K8Meta?.name ?? "index___zh-tw",
    path: indexIx747zg3K8Meta?.path ?? "/zh-tw",
    meta: indexIx747zg3K8Meta || {},
    alias: indexIx747zg3K8Meta?.alias || [],
    redirect: indexIx747zg3K8Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexIx747zg3K8Meta?.name ?? "index___ja",
    path: indexIx747zg3K8Meta?.path ?? "/ja",
    meta: indexIx747zg3K8Meta || {},
    alias: indexIx747zg3K8Meta?.alias || [],
    redirect: indexIx747zg3K8Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexIx747zg3K8Meta?.name ?? "index___ko___default",
    path: indexIx747zg3K8Meta?.path ?? "/",
    meta: indexIx747zg3K8Meta || {},
    alias: indexIx747zg3K8Meta?.alias || [],
    redirect: indexIx747zg3K8Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexIx747zg3K8Meta?.name ?? "index___ko",
    path: indexIx747zg3K8Meta?.path ?? "/ko",
    meta: indexIx747zg3K8Meta || {},
    alias: indexIx747zg3K8Meta?.alias || [],
    redirect: indexIx747zg3K8Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/index.vue").then(m => m.default || m)
  },
  {
    path: my_45infozfkH6GRGuhMeta?.path ?? "/en/my-info",
    children: [
  {
    name: _91id_93QuxnbS3IBZMeta?.name ?? "my-info-group-id___en",
    path: _91id_93QuxnbS3IBZMeta?.path ?? "group/:id()",
    meta: _91id_93QuxnbS3IBZMeta || {},
    alias: _91id_93QuxnbS3IBZMeta?.alias || [],
    redirect: _91id_93QuxnbS3IBZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/group/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjBElbhxDD5Meta?.name ?? "my-info___en",
    path: indexjBElbhxDD5Meta?.path ?? "",
    meta: indexjBElbhxDD5Meta || {},
    alias: indexjBElbhxDD5Meta?.alias || [],
    redirect: indexjBElbhxDD5Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/index.vue").then(m => m.default || m)
  }
],
    name: my_45infozfkH6GRGuhMeta?.name ?? undefined,
    meta: my_45infozfkH6GRGuhMeta || {},
    alias: my_45infozfkH6GRGuhMeta?.alias || [],
    redirect: my_45infozfkH6GRGuhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info.vue").then(m => m.default || m)
  },
  {
    path: my_45infozfkH6GRGuhMeta?.path ?? "/zh-cn/my-info",
    children: [
  {
    name: _91id_93QuxnbS3IBZMeta?.name ?? "my-info-group-id___zh-cn",
    path: _91id_93QuxnbS3IBZMeta?.path ?? "group/:id()",
    meta: _91id_93QuxnbS3IBZMeta || {},
    alias: _91id_93QuxnbS3IBZMeta?.alias || [],
    redirect: _91id_93QuxnbS3IBZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/group/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjBElbhxDD5Meta?.name ?? "my-info___zh-cn",
    path: indexjBElbhxDD5Meta?.path ?? "",
    meta: indexjBElbhxDD5Meta || {},
    alias: indexjBElbhxDD5Meta?.alias || [],
    redirect: indexjBElbhxDD5Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/index.vue").then(m => m.default || m)
  }
],
    name: my_45infozfkH6GRGuhMeta?.name ?? undefined,
    meta: my_45infozfkH6GRGuhMeta || {},
    alias: my_45infozfkH6GRGuhMeta?.alias || [],
    redirect: my_45infozfkH6GRGuhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info.vue").then(m => m.default || m)
  },
  {
    path: my_45infozfkH6GRGuhMeta?.path ?? "/zh-tw/my-info",
    children: [
  {
    name: _91id_93QuxnbS3IBZMeta?.name ?? "my-info-group-id___zh-tw",
    path: _91id_93QuxnbS3IBZMeta?.path ?? "group/:id()",
    meta: _91id_93QuxnbS3IBZMeta || {},
    alias: _91id_93QuxnbS3IBZMeta?.alias || [],
    redirect: _91id_93QuxnbS3IBZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/group/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjBElbhxDD5Meta?.name ?? "my-info___zh-tw",
    path: indexjBElbhxDD5Meta?.path ?? "",
    meta: indexjBElbhxDD5Meta || {},
    alias: indexjBElbhxDD5Meta?.alias || [],
    redirect: indexjBElbhxDD5Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/index.vue").then(m => m.default || m)
  }
],
    name: my_45infozfkH6GRGuhMeta?.name ?? undefined,
    meta: my_45infozfkH6GRGuhMeta || {},
    alias: my_45infozfkH6GRGuhMeta?.alias || [],
    redirect: my_45infozfkH6GRGuhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info.vue").then(m => m.default || m)
  },
  {
    path: my_45infozfkH6GRGuhMeta?.path ?? "/ja/my-info",
    children: [
  {
    name: _91id_93QuxnbS3IBZMeta?.name ?? "my-info-group-id___ja",
    path: _91id_93QuxnbS3IBZMeta?.path ?? "group/:id()",
    meta: _91id_93QuxnbS3IBZMeta || {},
    alias: _91id_93QuxnbS3IBZMeta?.alias || [],
    redirect: _91id_93QuxnbS3IBZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/group/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjBElbhxDD5Meta?.name ?? "my-info___ja",
    path: indexjBElbhxDD5Meta?.path ?? "",
    meta: indexjBElbhxDD5Meta || {},
    alias: indexjBElbhxDD5Meta?.alias || [],
    redirect: indexjBElbhxDD5Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/index.vue").then(m => m.default || m)
  }
],
    name: my_45infozfkH6GRGuhMeta?.name ?? undefined,
    meta: my_45infozfkH6GRGuhMeta || {},
    alias: my_45infozfkH6GRGuhMeta?.alias || [],
    redirect: my_45infozfkH6GRGuhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info.vue").then(m => m.default || m)
  },
  {
    path: my_45infozfkH6GRGuhMeta?.path ?? "/my-info",
    children: [
  {
    name: _91id_93QuxnbS3IBZMeta?.name ?? "my-info-group-id___ko___default",
    path: _91id_93QuxnbS3IBZMeta?.path ?? "group/:id()",
    meta: _91id_93QuxnbS3IBZMeta || {},
    alias: _91id_93QuxnbS3IBZMeta?.alias || [],
    redirect: _91id_93QuxnbS3IBZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/group/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjBElbhxDD5Meta?.name ?? "my-info___ko___default",
    path: indexjBElbhxDD5Meta?.path ?? "",
    meta: indexjBElbhxDD5Meta || {},
    alias: indexjBElbhxDD5Meta?.alias || [],
    redirect: indexjBElbhxDD5Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/index.vue").then(m => m.default || m)
  }
],
    name: my_45infozfkH6GRGuhMeta?.name ?? undefined,
    meta: my_45infozfkH6GRGuhMeta || {},
    alias: my_45infozfkH6GRGuhMeta?.alias || [],
    redirect: my_45infozfkH6GRGuhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info.vue").then(m => m.default || m)
  },
  {
    path: my_45infozfkH6GRGuhMeta?.path ?? "/ko/my-info",
    children: [
  {
    name: _91id_93QuxnbS3IBZMeta?.name ?? "my-info-group-id___ko",
    path: _91id_93QuxnbS3IBZMeta?.path ?? "group/:id()",
    meta: _91id_93QuxnbS3IBZMeta || {},
    alias: _91id_93QuxnbS3IBZMeta?.alias || [],
    redirect: _91id_93QuxnbS3IBZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/group/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjBElbhxDD5Meta?.name ?? "my-info___ko",
    path: indexjBElbhxDD5Meta?.path ?? "",
    meta: indexjBElbhxDD5Meta || {},
    alias: indexjBElbhxDD5Meta?.alias || [],
    redirect: indexjBElbhxDD5Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info/index.vue").then(m => m.default || m)
  }
],
    name: my_45infozfkH6GRGuhMeta?.name ?? undefined,
    meta: my_45infozfkH6GRGuhMeta || {},
    alias: my_45infozfkH6GRGuhMeta?.alias || [],
    redirect: my_45infozfkH6GRGuhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/my-info.vue").then(m => m.default || m)
  },
  {
    path: notice6Srm5syewTMeta?.path ?? "/en/notice",
    children: [
  {
    name: _91id_93EbMZs03N1pMeta?.name ?? "notice-id___en",
    path: _91id_93EbMZs03N1pMeta?.path ?? ":id()",
    meta: _91id_93EbMZs03N1pMeta || {},
    alias: _91id_93EbMZs03N1pMeta?.alias || [],
    redirect: _91id_93EbMZs03N1pMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexeqOvZk8kLzMeta?.name ?? "notice___en",
    path: indexeqOvZk8kLzMeta?.path ?? "",
    meta: indexeqOvZk8kLzMeta || {},
    alias: indexeqOvZk8kLzMeta?.alias || [],
    redirect: indexeqOvZk8kLzMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/index.vue").then(m => m.default || m)
  }
],
    name: notice6Srm5syewTMeta?.name ?? undefined,
    meta: notice6Srm5syewTMeta || {},
    alias: notice6Srm5syewTMeta?.alias || [],
    redirect: notice6Srm5syewTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice.vue").then(m => m.default || m)
  },
  {
    path: notice6Srm5syewTMeta?.path ?? "/zh-cn/notice",
    children: [
  {
    name: _91id_93EbMZs03N1pMeta?.name ?? "notice-id___zh-cn",
    path: _91id_93EbMZs03N1pMeta?.path ?? ":id()",
    meta: _91id_93EbMZs03N1pMeta || {},
    alias: _91id_93EbMZs03N1pMeta?.alias || [],
    redirect: _91id_93EbMZs03N1pMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexeqOvZk8kLzMeta?.name ?? "notice___zh-cn",
    path: indexeqOvZk8kLzMeta?.path ?? "",
    meta: indexeqOvZk8kLzMeta || {},
    alias: indexeqOvZk8kLzMeta?.alias || [],
    redirect: indexeqOvZk8kLzMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/index.vue").then(m => m.default || m)
  }
],
    name: notice6Srm5syewTMeta?.name ?? undefined,
    meta: notice6Srm5syewTMeta || {},
    alias: notice6Srm5syewTMeta?.alias || [],
    redirect: notice6Srm5syewTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice.vue").then(m => m.default || m)
  },
  {
    path: notice6Srm5syewTMeta?.path ?? "/zh-tw/notice",
    children: [
  {
    name: _91id_93EbMZs03N1pMeta?.name ?? "notice-id___zh-tw",
    path: _91id_93EbMZs03N1pMeta?.path ?? ":id()",
    meta: _91id_93EbMZs03N1pMeta || {},
    alias: _91id_93EbMZs03N1pMeta?.alias || [],
    redirect: _91id_93EbMZs03N1pMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexeqOvZk8kLzMeta?.name ?? "notice___zh-tw",
    path: indexeqOvZk8kLzMeta?.path ?? "",
    meta: indexeqOvZk8kLzMeta || {},
    alias: indexeqOvZk8kLzMeta?.alias || [],
    redirect: indexeqOvZk8kLzMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/index.vue").then(m => m.default || m)
  }
],
    name: notice6Srm5syewTMeta?.name ?? undefined,
    meta: notice6Srm5syewTMeta || {},
    alias: notice6Srm5syewTMeta?.alias || [],
    redirect: notice6Srm5syewTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice.vue").then(m => m.default || m)
  },
  {
    path: notice6Srm5syewTMeta?.path ?? "/ja/notice",
    children: [
  {
    name: _91id_93EbMZs03N1pMeta?.name ?? "notice-id___ja",
    path: _91id_93EbMZs03N1pMeta?.path ?? ":id()",
    meta: _91id_93EbMZs03N1pMeta || {},
    alias: _91id_93EbMZs03N1pMeta?.alias || [],
    redirect: _91id_93EbMZs03N1pMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexeqOvZk8kLzMeta?.name ?? "notice___ja",
    path: indexeqOvZk8kLzMeta?.path ?? "",
    meta: indexeqOvZk8kLzMeta || {},
    alias: indexeqOvZk8kLzMeta?.alias || [],
    redirect: indexeqOvZk8kLzMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/index.vue").then(m => m.default || m)
  }
],
    name: notice6Srm5syewTMeta?.name ?? undefined,
    meta: notice6Srm5syewTMeta || {},
    alias: notice6Srm5syewTMeta?.alias || [],
    redirect: notice6Srm5syewTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice.vue").then(m => m.default || m)
  },
  {
    path: notice6Srm5syewTMeta?.path ?? "/notice",
    children: [
  {
    name: _91id_93EbMZs03N1pMeta?.name ?? "notice-id___ko___default",
    path: _91id_93EbMZs03N1pMeta?.path ?? ":id()",
    meta: _91id_93EbMZs03N1pMeta || {},
    alias: _91id_93EbMZs03N1pMeta?.alias || [],
    redirect: _91id_93EbMZs03N1pMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexeqOvZk8kLzMeta?.name ?? "notice___ko___default",
    path: indexeqOvZk8kLzMeta?.path ?? "",
    meta: indexeqOvZk8kLzMeta || {},
    alias: indexeqOvZk8kLzMeta?.alias || [],
    redirect: indexeqOvZk8kLzMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/index.vue").then(m => m.default || m)
  }
],
    name: notice6Srm5syewTMeta?.name ?? undefined,
    meta: notice6Srm5syewTMeta || {},
    alias: notice6Srm5syewTMeta?.alias || [],
    redirect: notice6Srm5syewTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice.vue").then(m => m.default || m)
  },
  {
    path: notice6Srm5syewTMeta?.path ?? "/ko/notice",
    children: [
  {
    name: _91id_93EbMZs03N1pMeta?.name ?? "notice-id___ko",
    path: _91id_93EbMZs03N1pMeta?.path ?? ":id()",
    meta: _91id_93EbMZs03N1pMeta || {},
    alias: _91id_93EbMZs03N1pMeta?.alias || [],
    redirect: _91id_93EbMZs03N1pMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexeqOvZk8kLzMeta?.name ?? "notice___ko",
    path: indexeqOvZk8kLzMeta?.path ?? "",
    meta: indexeqOvZk8kLzMeta || {},
    alias: indexeqOvZk8kLzMeta?.alias || [],
    redirect: indexeqOvZk8kLzMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice/index.vue").then(m => m.default || m)
  }
],
    name: notice6Srm5syewTMeta?.name ?? undefined,
    meta: notice6Srm5syewTMeta || {},
    alias: notice6Srm5syewTMeta?.alias || [],
    redirect: notice6Srm5syewTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/notice.vue").then(m => m.default || m)
  },
  {
    path: publiccJvCf2VH8RMeta?.path ?? "/en/public",
    children: [
  {
    name: index5izNrYHQBrMeta?.name ?? "public___en",
    path: index5izNrYHQBrMeta?.path ?? "",
    meta: index5izNrYHQBrMeta || {},
    alias: index5izNrYHQBrMeta?.alias || [],
    redirect: index5izNrYHQBrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QukokvZHEeMeta?.name ?? "public-notice-id___en",
    path: _91id_93QukokvZHEeMeta?.path ?? "notice/:id()",
    meta: _91id_93QukokvZHEeMeta || {},
    alias: _91id_93QukokvZHEeMeta?.alias || [],
    redirect: _91id_93QukokvZHEeMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPkaDiqsgPOMeta?.name ?? "public-notice___en",
    path: indexPkaDiqsgPOMeta?.path ?? "notice",
    meta: indexPkaDiqsgPOMeta || {},
    alias: indexPkaDiqsgPOMeta?.alias || [],
    redirect: indexPkaDiqsgPOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/index.vue").then(m => m.default || m)
  },
  {
    name: versionOjLNv7exyLMeta?.name ?? "public-version___en",
    path: versionOjLNv7exyLMeta?.path ?? "version",
    meta: versionOjLNv7exyLMeta || {},
    alias: versionOjLNv7exyLMeta?.alias || [],
    redirect: versionOjLNv7exyLMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/version.vue").then(m => m.default || m)
  }
],
    name: publiccJvCf2VH8RMeta?.name ?? undefined,
    meta: publiccJvCf2VH8RMeta || {},
    alias: publiccJvCf2VH8RMeta?.alias || [],
    redirect: publiccJvCf2VH8RMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public.vue").then(m => m.default || m)
  },
  {
    path: publiccJvCf2VH8RMeta?.path ?? "/zh-cn/public",
    children: [
  {
    name: index5izNrYHQBrMeta?.name ?? "public___zh-cn",
    path: index5izNrYHQBrMeta?.path ?? "",
    meta: index5izNrYHQBrMeta || {},
    alias: index5izNrYHQBrMeta?.alias || [],
    redirect: index5izNrYHQBrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QukokvZHEeMeta?.name ?? "public-notice-id___zh-cn",
    path: _91id_93QukokvZHEeMeta?.path ?? "notice/:id()",
    meta: _91id_93QukokvZHEeMeta || {},
    alias: _91id_93QukokvZHEeMeta?.alias || [],
    redirect: _91id_93QukokvZHEeMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPkaDiqsgPOMeta?.name ?? "public-notice___zh-cn",
    path: indexPkaDiqsgPOMeta?.path ?? "notice",
    meta: indexPkaDiqsgPOMeta || {},
    alias: indexPkaDiqsgPOMeta?.alias || [],
    redirect: indexPkaDiqsgPOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/index.vue").then(m => m.default || m)
  },
  {
    name: versionOjLNv7exyLMeta?.name ?? "public-version___zh-cn",
    path: versionOjLNv7exyLMeta?.path ?? "version",
    meta: versionOjLNv7exyLMeta || {},
    alias: versionOjLNv7exyLMeta?.alias || [],
    redirect: versionOjLNv7exyLMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/version.vue").then(m => m.default || m)
  }
],
    name: publiccJvCf2VH8RMeta?.name ?? undefined,
    meta: publiccJvCf2VH8RMeta || {},
    alias: publiccJvCf2VH8RMeta?.alias || [],
    redirect: publiccJvCf2VH8RMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public.vue").then(m => m.default || m)
  },
  {
    path: publiccJvCf2VH8RMeta?.path ?? "/zh-tw/public",
    children: [
  {
    name: index5izNrYHQBrMeta?.name ?? "public___zh-tw",
    path: index5izNrYHQBrMeta?.path ?? "",
    meta: index5izNrYHQBrMeta || {},
    alias: index5izNrYHQBrMeta?.alias || [],
    redirect: index5izNrYHQBrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QukokvZHEeMeta?.name ?? "public-notice-id___zh-tw",
    path: _91id_93QukokvZHEeMeta?.path ?? "notice/:id()",
    meta: _91id_93QukokvZHEeMeta || {},
    alias: _91id_93QukokvZHEeMeta?.alias || [],
    redirect: _91id_93QukokvZHEeMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPkaDiqsgPOMeta?.name ?? "public-notice___zh-tw",
    path: indexPkaDiqsgPOMeta?.path ?? "notice",
    meta: indexPkaDiqsgPOMeta || {},
    alias: indexPkaDiqsgPOMeta?.alias || [],
    redirect: indexPkaDiqsgPOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/index.vue").then(m => m.default || m)
  },
  {
    name: versionOjLNv7exyLMeta?.name ?? "public-version___zh-tw",
    path: versionOjLNv7exyLMeta?.path ?? "version",
    meta: versionOjLNv7exyLMeta || {},
    alias: versionOjLNv7exyLMeta?.alias || [],
    redirect: versionOjLNv7exyLMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/version.vue").then(m => m.default || m)
  }
],
    name: publiccJvCf2VH8RMeta?.name ?? undefined,
    meta: publiccJvCf2VH8RMeta || {},
    alias: publiccJvCf2VH8RMeta?.alias || [],
    redirect: publiccJvCf2VH8RMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public.vue").then(m => m.default || m)
  },
  {
    path: publiccJvCf2VH8RMeta?.path ?? "/ja/public",
    children: [
  {
    name: index5izNrYHQBrMeta?.name ?? "public___ja",
    path: index5izNrYHQBrMeta?.path ?? "",
    meta: index5izNrYHQBrMeta || {},
    alias: index5izNrYHQBrMeta?.alias || [],
    redirect: index5izNrYHQBrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QukokvZHEeMeta?.name ?? "public-notice-id___ja",
    path: _91id_93QukokvZHEeMeta?.path ?? "notice/:id()",
    meta: _91id_93QukokvZHEeMeta || {},
    alias: _91id_93QukokvZHEeMeta?.alias || [],
    redirect: _91id_93QukokvZHEeMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPkaDiqsgPOMeta?.name ?? "public-notice___ja",
    path: indexPkaDiqsgPOMeta?.path ?? "notice",
    meta: indexPkaDiqsgPOMeta || {},
    alias: indexPkaDiqsgPOMeta?.alias || [],
    redirect: indexPkaDiqsgPOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/index.vue").then(m => m.default || m)
  },
  {
    name: versionOjLNv7exyLMeta?.name ?? "public-version___ja",
    path: versionOjLNv7exyLMeta?.path ?? "version",
    meta: versionOjLNv7exyLMeta || {},
    alias: versionOjLNv7exyLMeta?.alias || [],
    redirect: versionOjLNv7exyLMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/version.vue").then(m => m.default || m)
  }
],
    name: publiccJvCf2VH8RMeta?.name ?? undefined,
    meta: publiccJvCf2VH8RMeta || {},
    alias: publiccJvCf2VH8RMeta?.alias || [],
    redirect: publiccJvCf2VH8RMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public.vue").then(m => m.default || m)
  },
  {
    path: publiccJvCf2VH8RMeta?.path ?? "/public",
    children: [
  {
    name: index5izNrYHQBrMeta?.name ?? "public___ko___default",
    path: index5izNrYHQBrMeta?.path ?? "",
    meta: index5izNrYHQBrMeta || {},
    alias: index5izNrYHQBrMeta?.alias || [],
    redirect: index5izNrYHQBrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QukokvZHEeMeta?.name ?? "public-notice-id___ko___default",
    path: _91id_93QukokvZHEeMeta?.path ?? "notice/:id()",
    meta: _91id_93QukokvZHEeMeta || {},
    alias: _91id_93QukokvZHEeMeta?.alias || [],
    redirect: _91id_93QukokvZHEeMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPkaDiqsgPOMeta?.name ?? "public-notice___ko___default",
    path: indexPkaDiqsgPOMeta?.path ?? "notice",
    meta: indexPkaDiqsgPOMeta || {},
    alias: indexPkaDiqsgPOMeta?.alias || [],
    redirect: indexPkaDiqsgPOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/index.vue").then(m => m.default || m)
  },
  {
    name: versionOjLNv7exyLMeta?.name ?? "public-version___ko___default",
    path: versionOjLNv7exyLMeta?.path ?? "version",
    meta: versionOjLNv7exyLMeta || {},
    alias: versionOjLNv7exyLMeta?.alias || [],
    redirect: versionOjLNv7exyLMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/version.vue").then(m => m.default || m)
  }
],
    name: publiccJvCf2VH8RMeta?.name ?? undefined,
    meta: publiccJvCf2VH8RMeta || {},
    alias: publiccJvCf2VH8RMeta?.alias || [],
    redirect: publiccJvCf2VH8RMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public.vue").then(m => m.default || m)
  },
  {
    path: publiccJvCf2VH8RMeta?.path ?? "/ko/public",
    children: [
  {
    name: index5izNrYHQBrMeta?.name ?? "public___ko",
    path: index5izNrYHQBrMeta?.path ?? "",
    meta: index5izNrYHQBrMeta || {},
    alias: index5izNrYHQBrMeta?.alias || [],
    redirect: index5izNrYHQBrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QukokvZHEeMeta?.name ?? "public-notice-id___ko",
    path: _91id_93QukokvZHEeMeta?.path ?? "notice/:id()",
    meta: _91id_93QukokvZHEeMeta || {},
    alias: _91id_93QukokvZHEeMeta?.alias || [],
    redirect: _91id_93QukokvZHEeMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPkaDiqsgPOMeta?.name ?? "public-notice___ko",
    path: indexPkaDiqsgPOMeta?.path ?? "notice",
    meta: indexPkaDiqsgPOMeta || {},
    alias: indexPkaDiqsgPOMeta?.alias || [],
    redirect: indexPkaDiqsgPOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/notice/index.vue").then(m => m.default || m)
  },
  {
    name: versionOjLNv7exyLMeta?.name ?? "public-version___ko",
    path: versionOjLNv7exyLMeta?.path ?? "version",
    meta: versionOjLNv7exyLMeta || {},
    alias: versionOjLNv7exyLMeta?.alias || [],
    redirect: versionOjLNv7exyLMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public/version.vue").then(m => m.default || m)
  }
],
    name: publiccJvCf2VH8RMeta?.name ?? undefined,
    meta: publiccJvCf2VH8RMeta || {},
    alias: publiccJvCf2VH8RMeta?.alias || [],
    redirect: publiccJvCf2VH8RMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/public.vue").then(m => m.default || m)
  },
  {
    name: _91to_93a1dkWSfoAAMeta?.name ?? "redirect-to-to___en",
    path: _91to_93a1dkWSfoAAMeta?.path ?? "/en/redirect-to/:to()",
    meta: _91to_93a1dkWSfoAAMeta || {},
    alias: _91to_93a1dkWSfoAAMeta?.alias || [],
    redirect: _91to_93a1dkWSfoAAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/redirect-to/[to].vue").then(m => m.default || m)
  },
  {
    name: _91to_93a1dkWSfoAAMeta?.name ?? "redirect-to-to___zh-cn",
    path: _91to_93a1dkWSfoAAMeta?.path ?? "/zh-cn/redirect-to/:to()",
    meta: _91to_93a1dkWSfoAAMeta || {},
    alias: _91to_93a1dkWSfoAAMeta?.alias || [],
    redirect: _91to_93a1dkWSfoAAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/redirect-to/[to].vue").then(m => m.default || m)
  },
  {
    name: _91to_93a1dkWSfoAAMeta?.name ?? "redirect-to-to___zh-tw",
    path: _91to_93a1dkWSfoAAMeta?.path ?? "/zh-tw/redirect-to/:to()",
    meta: _91to_93a1dkWSfoAAMeta || {},
    alias: _91to_93a1dkWSfoAAMeta?.alias || [],
    redirect: _91to_93a1dkWSfoAAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/redirect-to/[to].vue").then(m => m.default || m)
  },
  {
    name: _91to_93a1dkWSfoAAMeta?.name ?? "redirect-to-to___ja",
    path: _91to_93a1dkWSfoAAMeta?.path ?? "/ja/redirect-to/:to()",
    meta: _91to_93a1dkWSfoAAMeta || {},
    alias: _91to_93a1dkWSfoAAMeta?.alias || [],
    redirect: _91to_93a1dkWSfoAAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/redirect-to/[to].vue").then(m => m.default || m)
  },
  {
    name: _91to_93a1dkWSfoAAMeta?.name ?? "redirect-to-to___ko___default",
    path: _91to_93a1dkWSfoAAMeta?.path ?? "/redirect-to/:to()",
    meta: _91to_93a1dkWSfoAAMeta || {},
    alias: _91to_93a1dkWSfoAAMeta?.alias || [],
    redirect: _91to_93a1dkWSfoAAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/redirect-to/[to].vue").then(m => m.default || m)
  },
  {
    name: _91to_93a1dkWSfoAAMeta?.name ?? "redirect-to-to___ko",
    path: _91to_93a1dkWSfoAAMeta?.path ?? "/ko/redirect-to/:to()",
    meta: _91to_93a1dkWSfoAAMeta || {},
    alias: _91to_93a1dkWSfoAAMeta?.alias || [],
    redirect: _91to_93a1dkWSfoAAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/redirect-to/[to].vue").then(m => m.default || m)
  },
  {
    name: registerQ5QN6nmW8QMeta?.name ?? "register___en",
    path: registerQ5QN6nmW8QMeta?.path ?? "/en/register",
    meta: registerQ5QN6nmW8QMeta || {},
    alias: registerQ5QN6nmW8QMeta?.alias || [],
    redirect: registerQ5QN6nmW8QMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQ5QN6nmW8QMeta?.name ?? "register___zh-cn",
    path: registerQ5QN6nmW8QMeta?.path ?? "/zh-cn/register",
    meta: registerQ5QN6nmW8QMeta || {},
    alias: registerQ5QN6nmW8QMeta?.alias || [],
    redirect: registerQ5QN6nmW8QMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQ5QN6nmW8QMeta?.name ?? "register___zh-tw",
    path: registerQ5QN6nmW8QMeta?.path ?? "/zh-tw/register",
    meta: registerQ5QN6nmW8QMeta || {},
    alias: registerQ5QN6nmW8QMeta?.alias || [],
    redirect: registerQ5QN6nmW8QMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQ5QN6nmW8QMeta?.name ?? "register___ja",
    path: registerQ5QN6nmW8QMeta?.path ?? "/ja/register",
    meta: registerQ5QN6nmW8QMeta || {},
    alias: registerQ5QN6nmW8QMeta?.alias || [],
    redirect: registerQ5QN6nmW8QMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQ5QN6nmW8QMeta?.name ?? "register___ko___default",
    path: registerQ5QN6nmW8QMeta?.path ?? "/register",
    meta: registerQ5QN6nmW8QMeta || {},
    alias: registerQ5QN6nmW8QMeta?.alias || [],
    redirect: registerQ5QN6nmW8QMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQ5QN6nmW8QMeta?.name ?? "register___ko",
    path: registerQ5QN6nmW8QMeta?.path ?? "/ko/register",
    meta: registerQ5QN6nmW8QMeta || {},
    alias: registerQ5QN6nmW8QMeta?.alias || [],
    redirect: registerQ5QN6nmW8QMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: service_45unavailableSwvEwsqTapMeta?.name ?? "service-unavailable___en",
    path: service_45unavailableSwvEwsqTapMeta?.path ?? "/en/service-unavailable",
    meta: service_45unavailableSwvEwsqTapMeta || {},
    alias: service_45unavailableSwvEwsqTapMeta?.alias || [],
    redirect: service_45unavailableSwvEwsqTapMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/service-unavailable.vue").then(m => m.default || m)
  },
  {
    name: service_45unavailableSwvEwsqTapMeta?.name ?? "service-unavailable___zh-cn",
    path: service_45unavailableSwvEwsqTapMeta?.path ?? "/zh-cn/service-unavailable",
    meta: service_45unavailableSwvEwsqTapMeta || {},
    alias: service_45unavailableSwvEwsqTapMeta?.alias || [],
    redirect: service_45unavailableSwvEwsqTapMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/service-unavailable.vue").then(m => m.default || m)
  },
  {
    name: service_45unavailableSwvEwsqTapMeta?.name ?? "service-unavailable___zh-tw",
    path: service_45unavailableSwvEwsqTapMeta?.path ?? "/zh-tw/service-unavailable",
    meta: service_45unavailableSwvEwsqTapMeta || {},
    alias: service_45unavailableSwvEwsqTapMeta?.alias || [],
    redirect: service_45unavailableSwvEwsqTapMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/service-unavailable.vue").then(m => m.default || m)
  },
  {
    name: service_45unavailableSwvEwsqTapMeta?.name ?? "service-unavailable___ja",
    path: service_45unavailableSwvEwsqTapMeta?.path ?? "/ja/service-unavailable",
    meta: service_45unavailableSwvEwsqTapMeta || {},
    alias: service_45unavailableSwvEwsqTapMeta?.alias || [],
    redirect: service_45unavailableSwvEwsqTapMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/service-unavailable.vue").then(m => m.default || m)
  },
  {
    name: service_45unavailableSwvEwsqTapMeta?.name ?? "service-unavailable___ko___default",
    path: service_45unavailableSwvEwsqTapMeta?.path ?? "/service-unavailable",
    meta: service_45unavailableSwvEwsqTapMeta || {},
    alias: service_45unavailableSwvEwsqTapMeta?.alias || [],
    redirect: service_45unavailableSwvEwsqTapMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/service-unavailable.vue").then(m => m.default || m)
  },
  {
    name: service_45unavailableSwvEwsqTapMeta?.name ?? "service-unavailable___ko",
    path: service_45unavailableSwvEwsqTapMeta?.path ?? "/ko/service-unavailable",
    meta: service_45unavailableSwvEwsqTapMeta || {},
    alias: service_45unavailableSwvEwsqTapMeta?.alias || [],
    redirect: service_45unavailableSwvEwsqTapMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/service-unavailable.vue").then(m => m.default || m)
  },
  {
    path: terms58pUJyaS0EMeta?.path ?? "/en/terms",
    children: [
  {
    name: detaill6iC9UKfBHMeta?.name ?? "terms-detail___en",
    path: detaill6iC9UKfBHMeta?.path ?? "detail",
    meta: detaill6iC9UKfBHMeta || {},
    alias: detaill6iC9UKfBHMeta?.alias || [],
    redirect: detaill6iC9UKfBHMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/detail.vue").then(m => m.default || m)
  },
  {
    name: indexz28cnVebPUMeta?.name ?? "terms___en",
    path: indexz28cnVebPUMeta?.path ?? "",
    meta: indexz28cnVebPUMeta || {},
    alias: indexz28cnVebPUMeta?.alias || [],
    redirect: indexz28cnVebPUMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: updateugKdewHwvZMeta?.name ?? "terms-update___en",
    path: updateugKdewHwvZMeta?.path ?? "update",
    meta: updateugKdewHwvZMeta || {},
    alias: updateugKdewHwvZMeta?.alias || [],
    redirect: updateugKdewHwvZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/update.vue").then(m => m.default || m)
  }
],
    name: terms58pUJyaS0EMeta?.name ?? undefined,
    meta: terms58pUJyaS0EMeta || {},
    alias: terms58pUJyaS0EMeta?.alias || [],
    redirect: terms58pUJyaS0EMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms.vue").then(m => m.default || m)
  },
  {
    path: terms58pUJyaS0EMeta?.path ?? "/zh-cn/terms",
    children: [
  {
    name: detaill6iC9UKfBHMeta?.name ?? "terms-detail___zh-cn",
    path: detaill6iC9UKfBHMeta?.path ?? "detail",
    meta: detaill6iC9UKfBHMeta || {},
    alias: detaill6iC9UKfBHMeta?.alias || [],
    redirect: detaill6iC9UKfBHMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/detail.vue").then(m => m.default || m)
  },
  {
    name: indexz28cnVebPUMeta?.name ?? "terms___zh-cn",
    path: indexz28cnVebPUMeta?.path ?? "",
    meta: indexz28cnVebPUMeta || {},
    alias: indexz28cnVebPUMeta?.alias || [],
    redirect: indexz28cnVebPUMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: updateugKdewHwvZMeta?.name ?? "terms-update___zh-cn",
    path: updateugKdewHwvZMeta?.path ?? "update",
    meta: updateugKdewHwvZMeta || {},
    alias: updateugKdewHwvZMeta?.alias || [],
    redirect: updateugKdewHwvZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/update.vue").then(m => m.default || m)
  }
],
    name: terms58pUJyaS0EMeta?.name ?? undefined,
    meta: terms58pUJyaS0EMeta || {},
    alias: terms58pUJyaS0EMeta?.alias || [],
    redirect: terms58pUJyaS0EMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms.vue").then(m => m.default || m)
  },
  {
    path: terms58pUJyaS0EMeta?.path ?? "/zh-tw/terms",
    children: [
  {
    name: detaill6iC9UKfBHMeta?.name ?? "terms-detail___zh-tw",
    path: detaill6iC9UKfBHMeta?.path ?? "detail",
    meta: detaill6iC9UKfBHMeta || {},
    alias: detaill6iC9UKfBHMeta?.alias || [],
    redirect: detaill6iC9UKfBHMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/detail.vue").then(m => m.default || m)
  },
  {
    name: indexz28cnVebPUMeta?.name ?? "terms___zh-tw",
    path: indexz28cnVebPUMeta?.path ?? "",
    meta: indexz28cnVebPUMeta || {},
    alias: indexz28cnVebPUMeta?.alias || [],
    redirect: indexz28cnVebPUMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: updateugKdewHwvZMeta?.name ?? "terms-update___zh-tw",
    path: updateugKdewHwvZMeta?.path ?? "update",
    meta: updateugKdewHwvZMeta || {},
    alias: updateugKdewHwvZMeta?.alias || [],
    redirect: updateugKdewHwvZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/update.vue").then(m => m.default || m)
  }
],
    name: terms58pUJyaS0EMeta?.name ?? undefined,
    meta: terms58pUJyaS0EMeta || {},
    alias: terms58pUJyaS0EMeta?.alias || [],
    redirect: terms58pUJyaS0EMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms.vue").then(m => m.default || m)
  },
  {
    path: terms58pUJyaS0EMeta?.path ?? "/ja/terms",
    children: [
  {
    name: detaill6iC9UKfBHMeta?.name ?? "terms-detail___ja",
    path: detaill6iC9UKfBHMeta?.path ?? "detail",
    meta: detaill6iC9UKfBHMeta || {},
    alias: detaill6iC9UKfBHMeta?.alias || [],
    redirect: detaill6iC9UKfBHMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/detail.vue").then(m => m.default || m)
  },
  {
    name: indexz28cnVebPUMeta?.name ?? "terms___ja",
    path: indexz28cnVebPUMeta?.path ?? "",
    meta: indexz28cnVebPUMeta || {},
    alias: indexz28cnVebPUMeta?.alias || [],
    redirect: indexz28cnVebPUMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: updateugKdewHwvZMeta?.name ?? "terms-update___ja",
    path: updateugKdewHwvZMeta?.path ?? "update",
    meta: updateugKdewHwvZMeta || {},
    alias: updateugKdewHwvZMeta?.alias || [],
    redirect: updateugKdewHwvZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/update.vue").then(m => m.default || m)
  }
],
    name: terms58pUJyaS0EMeta?.name ?? undefined,
    meta: terms58pUJyaS0EMeta || {},
    alias: terms58pUJyaS0EMeta?.alias || [],
    redirect: terms58pUJyaS0EMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms.vue").then(m => m.default || m)
  },
  {
    path: terms58pUJyaS0EMeta?.path ?? "/terms",
    children: [
  {
    name: detaill6iC9UKfBHMeta?.name ?? "terms-detail___ko___default",
    path: detaill6iC9UKfBHMeta?.path ?? "detail",
    meta: detaill6iC9UKfBHMeta || {},
    alias: detaill6iC9UKfBHMeta?.alias || [],
    redirect: detaill6iC9UKfBHMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/detail.vue").then(m => m.default || m)
  },
  {
    name: indexz28cnVebPUMeta?.name ?? "terms___ko___default",
    path: indexz28cnVebPUMeta?.path ?? "",
    meta: indexz28cnVebPUMeta || {},
    alias: indexz28cnVebPUMeta?.alias || [],
    redirect: indexz28cnVebPUMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: updateugKdewHwvZMeta?.name ?? "terms-update___ko___default",
    path: updateugKdewHwvZMeta?.path ?? "update",
    meta: updateugKdewHwvZMeta || {},
    alias: updateugKdewHwvZMeta?.alias || [],
    redirect: updateugKdewHwvZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/update.vue").then(m => m.default || m)
  }
],
    name: terms58pUJyaS0EMeta?.name ?? undefined,
    meta: terms58pUJyaS0EMeta || {},
    alias: terms58pUJyaS0EMeta?.alias || [],
    redirect: terms58pUJyaS0EMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms.vue").then(m => m.default || m)
  },
  {
    path: terms58pUJyaS0EMeta?.path ?? "/ko/terms",
    children: [
  {
    name: detaill6iC9UKfBHMeta?.name ?? "terms-detail___ko",
    path: detaill6iC9UKfBHMeta?.path ?? "detail",
    meta: detaill6iC9UKfBHMeta || {},
    alias: detaill6iC9UKfBHMeta?.alias || [],
    redirect: detaill6iC9UKfBHMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/detail.vue").then(m => m.default || m)
  },
  {
    name: indexz28cnVebPUMeta?.name ?? "terms___ko",
    path: indexz28cnVebPUMeta?.path ?? "",
    meta: indexz28cnVebPUMeta || {},
    alias: indexz28cnVebPUMeta?.alias || [],
    redirect: indexz28cnVebPUMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: updateugKdewHwvZMeta?.name ?? "terms-update___ko",
    path: updateugKdewHwvZMeta?.path ?? "update",
    meta: updateugKdewHwvZMeta || {},
    alias: updateugKdewHwvZMeta?.alias || [],
    redirect: updateugKdewHwvZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms/update.vue").then(m => m.default || m)
  }
],
    name: terms58pUJyaS0EMeta?.name ?? undefined,
    meta: terms58pUJyaS0EMeta || {},
    alias: terms58pUJyaS0EMeta?.alias || [],
    redirect: terms58pUJyaS0EMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: indexHG7Qb2AphlMeta?.name ?? "tutorial___en",
    path: indexHG7Qb2AphlMeta?.path ?? "/en/tutorial",
    meta: indexHG7Qb2AphlMeta || {},
    alias: indexHG7Qb2AphlMeta?.alias || [],
    redirect: indexHG7Qb2AphlMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/tutorial/index.vue").then(m => m.default || m)
  },
  {
    name: indexHG7Qb2AphlMeta?.name ?? "tutorial___zh-cn",
    path: indexHG7Qb2AphlMeta?.path ?? "/zh-cn/tutorial",
    meta: indexHG7Qb2AphlMeta || {},
    alias: indexHG7Qb2AphlMeta?.alias || [],
    redirect: indexHG7Qb2AphlMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/tutorial/index.vue").then(m => m.default || m)
  },
  {
    name: indexHG7Qb2AphlMeta?.name ?? "tutorial___zh-tw",
    path: indexHG7Qb2AphlMeta?.path ?? "/zh-tw/tutorial",
    meta: indexHG7Qb2AphlMeta || {},
    alias: indexHG7Qb2AphlMeta?.alias || [],
    redirect: indexHG7Qb2AphlMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/tutorial/index.vue").then(m => m.default || m)
  },
  {
    name: indexHG7Qb2AphlMeta?.name ?? "tutorial___ja",
    path: indexHG7Qb2AphlMeta?.path ?? "/ja/tutorial",
    meta: indexHG7Qb2AphlMeta || {},
    alias: indexHG7Qb2AphlMeta?.alias || [],
    redirect: indexHG7Qb2AphlMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/tutorial/index.vue").then(m => m.default || m)
  },
  {
    name: indexHG7Qb2AphlMeta?.name ?? "tutorial___ko___default",
    path: indexHG7Qb2AphlMeta?.path ?? "/tutorial",
    meta: indexHG7Qb2AphlMeta || {},
    alias: indexHG7Qb2AphlMeta?.alias || [],
    redirect: indexHG7Qb2AphlMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/tutorial/index.vue").then(m => m.default || m)
  },
  {
    name: indexHG7Qb2AphlMeta?.name ?? "tutorial___ko",
    path: indexHG7Qb2AphlMeta?.path ?? "/ko/tutorial",
    meta: indexHG7Qb2AphlMeta || {},
    alias: indexHG7Qb2AphlMeta?.alias || [],
    redirect: indexHG7Qb2AphlMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/pages/tutorial/index.vue").then(m => m.default || m)
  }
]
import * as _ from 'lodash-es';
import type { ResponseNormalization } from 'seed-core';

import {
  PRODUCT_DETAIL_TYPE,
  PRODUCT_TYPE_DETAIL,
  SERVER_PRODUCT_TYPE
} from '@/constants/products.const';
import { Confirmation } from '@/enums/common.enum';
import type {
  MappedProductRelation,
  ProductRelationResponse,
  ProjectProductRelation
} from '@/types/product/product-response.type';
import { sortStrings } from '@/utils/common.util';

// export const fetchProductsInfoMapper = (res: ResponseNormalization<ProductsInfoResponse>): ResponseNormalization<ProductsInfoModel> => {
//   const data : ProductsInfoModel = {
//     status: res.data?.status ?? '',
//     productId: res.data?.productId ?? '',
//     productType: res.data?.productType ?? '',
//     detailType: res.data?.detailType ?? '',
//     basicInformation: res.data?.basicInformation?.map((item: ProductBasicInfoSettingResponse) => ({
//       langCode: item.langCode,
//       default: item.default,
//       productName: item.productName,
//       developer: item.developer,
//       publisher: item.publisher
//     })) ?? [],
//     planCalendar: cloneDeep(res.data?.planCalendar) ?? [],
//     genreList: cloneDeep(res.data?.genreList) ?? [],
//     topLinkageProduct: {
//       productType: res.data?.topLinkageProduct?.productType ?? '',
//       data: {
//         productName: res.data?.topLinkageProduct?.productName ?? '',
//         status: res.data?.topLinkageProduct?.status ?? '',
//         id: res.data?.topLinkageProduct?.productId ?? ''
//       }
//     }

//   };
//   return {
//     ...res,
//     data
//   };
// };

const sortProduct = (a: ProductRelationResponse, b: ProductRelationResponse) => {
  const sortByDetailType = a.productDetailTypeMapped.localeCompare(b.productDetailTypeMapped);
  return sortByDetailType || sortStrings(a.productName, b.productName);
};

const groupProductsByParent = (products: ProductRelationResponse[]): ProjectProductRelation[] => {
  const groupedByProject = _.groupBy(products, 'projectId');

  const result = Object.keys(groupedByProject).map((projectId: string) => {
    const projectProducts = groupedByProject[projectId];
    const groupedByParent = _.groupBy(projectProducts, 'parentProductNo');

    const buildHierarchy = (parentProductNo: number | null): MappedProductRelation[] => {
      return (groupedByParent[parentProductNo ?? 'null'] || []).map(
        (product: ProductRelationResponse): MappedProductRelation => ({
          ...product,
          children: buildHierarchy(product.productNo).sort(sortProduct)
        })
      );
    };

    return {
      id: projectId,
      name: projectProducts[0].projectName,
      products: buildHierarchy(null).sort(sortProduct)
    };
  });
  return result;
};

export const productDetailTypeMapper = (
  productType: string,
  demoYn: Confirmation,
  productDetailType: string
) => {
  if (productType.toLowerCase() === SERVER_PRODUCT_TYPE.GAME.toLowerCase()) {
    if (demoYn === Confirmation.YES) {
      return PRODUCT_TYPE_DETAIL.GAME_DEMO;
    }
    if (productDetailType === PRODUCT_DETAIL_TYPE.DLC) {
      return PRODUCT_TYPE_DETAIL.GAME_DLC;
    }
    return PRODUCT_TYPE_DETAIL.GAME_BASIC;
  }
  if (productType.toLowerCase() === SERVER_PRODUCT_TYPE.UTILITY.toLowerCase()) {
    if (demoYn === Confirmation.YES) {
      return PRODUCT_TYPE_DETAIL.UTILITY_DEMO;
    }
    if (productDetailType === PRODUCT_DETAIL_TYPE.DLC) {
      return PRODUCT_TYPE_DETAIL.UTILITY_DLC;
    }
    return PRODUCT_TYPE_DETAIL.UTILITY_BASIC;
  }
  if (productType.toLowerCase() === SERVER_PRODUCT_TYPE.CONTENTS.toLowerCase()) {
    if (demoYn === Confirmation.YES) {
      return PRODUCT_TYPE_DETAIL.CONTENT_DEMO;
    }
    if (productDetailType === PRODUCT_DETAIL_TYPE.DLC) {
      return PRODUCT_TYPE_DETAIL.CONTENT_DLC;
    }
    return PRODUCT_TYPE_DETAIL.CONTENT_BASIC;
  }
  return '';
};

export const fetchProductsRelationMapper = (
  res: ResponseNormalization<{ products: ProductRelationResponse[] }>
): ResponseNormalization<ProjectProductRelation[]> => {
  if (!res.data || !res.data?.products) {
    return { ...res, data: [] };
  }
  const products = res.data?.products.map((value: ProductRelationResponse) => {
    const productDetailTypeMapped = productDetailTypeMapper(
      value.productType,
      value.demoYn as Confirmation,
      value.productDetailType ?? ''
    );
    return {
      ...value,
      productDetailTypeMapped
    };
  });
  const projectProductRelations = groupProductsByParent(products).sort(
    (a: ProjectProductRelation, b: ProjectProductRelation) => sortStrings(a.name, b.name)
  );

  return {
    ...res,
    data: projectProductRelations
  };
};

import type { PublicRunTypeModel } from '@/types/run-type.type';

import dev from './dev.config';
import dev2 from './dev2.config';
import live from './live.config';
import local from './local.config';
import qa from './qa.config';
import qa2 from './qa2.config';
import sandbox from './sandbox.config';

type RunTypeObject = Record<string, object>;

const configs: RunTypeObject = {
  local,
  dev,
  dev2,
  qa,
  qa2,
  sandbox,
  live
};

/**
 * Retrieve variable values based on the RUN_TYPE environment
 *
 * @param {string} runType
 * @param {string} [defaultRunType="pc"]
 * @returns {PublicRunTypeModel}
 */
const getRunTypeConfig = (runType: string, defaultRunType: string = 'pc'): PublicRunTypeModel => {
  return <PublicRunTypeModel>configs[runType] || configs[defaultRunType] || {};
};

export { getRunTypeConfig };

import { defineNuxtPlugin, useNuxtApp } from 'nuxt/app';

import { INVALID_TOKEN_STATUS_CODES, STATUS_CODE } from '@/constants/error.const';

export default defineNuxtPlugin(async () => {
  const nuxtApp = useNuxtApp();
  nuxtApp.hook('vue:error', (..._args: any[]) => {
    // console.log('vue:error');
    // console.log(..._args);
  });
  nuxtApp.hook('app:error', async (..._args: any[]) => {
    const errorResponse = _args[0].error?.response;
    // console.log('errorResponse :>> ', errorResponse);
    if (
      INVALID_TOKEN_STATUS_CODES.includes(errorResponse?.data?.code) ||
      errorResponse?.status === STATUS_CODE.INVALID_TOKEN_401
    ) {
      // logout();
      // location.reload();
    }
  });
  nuxtApp.vueApp.config.errorHandler = (..._args: any[]) => {
    // console.log('global error handler');
    // if (process.client) {
    //   // console.log(..._args)
    // }
  };
});

import { defineNuxtPlugin } from 'nuxt/app';
import type { DirectiveBinding } from 'nuxt/dist/app/compat/capi';
import type { App } from 'vue';

// threshold for end-scroll event
const threshold = 1;
export default defineNuxtPlugin(({ vueApp }: { vueApp: App }) => {
  vueApp.directive('end-scroll', {
    beforeMount(el: any, binding: DirectiveBinding<any>) {
      el.endScrollEvent = () => {
        if (el.offsetHeight + el.scrollTop >= el.scrollHeight - threshold) {
          binding.value();
        }
      };
      el.addEventListener('scroll', el.endScrollEvent);
    },
    unmounted(el: any) {
      document.removeEventListener('scroll', el.endScrollEvent);
    }
  });
});

import { COOKIE_KEYS } from 'seed-core';

import { useCookieWithDomain } from '@/composables/useCookieWithDomain';
import { DEFAULT_COVERAGES, DEFAULT_LOCALE } from '@/constants/locale.const';
import { LOCALE_REGEX } from '@/constants/regex.const';
import { LanguageCode } from '@/enums/language-code.enum';

const handleChineseLocale = (locale: string) => {
  const localeMap: { [key: string]: string } = {
    zh: 'zh-cn',
    'zh-hk': 'zh-tw'
  };
  return localeMap[locale] || locale;
};

const parseAcceptLanguage = (acceptLanguage: string) => {
  const browserLocale = acceptLanguage.split(',')[0] as LanguageCode;
  const browserLang = browserLocale.split('-')[0];
  return [LanguageCode.ZhCn, LanguageCode.ZhTw, LanguageCode.ZhHk].includes(browserLocale)
    ? browserLocale
    : browserLang;
};

export const getFinialLocale = (url: string) => {
  const localeRegex = LOCALE_REGEX;
  const matchFullPath = url.match(localeRegex);
  const urlLocale =
    matchFullPath && DEFAULT_COVERAGES.includes(matchFullPath[1]) ? matchFullPath[1] : '';
  const cookieLocale = useCookieWithDomain(COOKIE_KEYS.LOCALE || '');
  const browserLocale = parseAcceptLanguage(navigator.language.toLowerCase());
  return (
    [
      urlLocale.toLowerCase(),
      cookieLocale.value?.toLowerCase(),
      handleChineseLocale(browserLocale)
    ].find((locale: string | undefined) => locale && DEFAULT_COVERAGES.includes(locale)) ||
    DEFAULT_LOCALE
  );
};

export default {
  RUN_TYPE: 'dev2',
  API_BASE_URL: 'https://api-dev2.onstove.com',
  STORE_URL: 'https://store-dev2.onstove.com',
  STUDIO_V2_URL: 'https://pre-studio-dev2.onstove.com',
  SEED_CORE: {
    AUTH_URL: 'https://accounts-dev2.onstove.com', // same value with LOGIN_URL
    SESSION_URL: 'https://auth-dev2.onstove.com',
    MEMBER_URL: 'https://member-dev2.onstove.com',
    DOMAIN: '.onstove.com',
    API_URL: 'https://api-dev2.onstove.com',
    ENABLE_CORS: false,
    ENABLE_GDS: true,
    CAllER_ID: 'indie-web-studio'
  },
  GNB_SCRIPT: 'https://js-cdn-dev2.onstove.com/libs/common-gnb/latest/cp-header.js',
  TRANSLATION_BASE_URL: 'https://d2eq8hyjn4yxr6.cloudfront.net/dev/application_no/STATIC/indie',
  TRANSLATION_FOLDER_NAME: 'indie-studio.3.0',
  TRANSLATION_FILE_NAME: '{locale}.properties',
  TERM_URL: 'https://clause-dev.onstove.com',
  FROALA_KEY: 'FSC2H-9B1A8A1B3D3D2E2sGXh1WWTDSGXYOUKc1KINLe1OC1c1D-17D2E2F2A1E4F1B2B8E7D6==',
  UPLOADER_DOWNLOAD_URL:
    'https://dl-dev2.onstove.com/tools/UploadTool/v2/STOVEUploader_DEV2_setup.exe',
  APPLICATION_NO: 53058,
  LOUNGE_URL: 'https://lounge-dev2.onstove.com/',
  INDIE_CHANNEL_SEQ: 11235,
  INDIE_BOARD_SEQ: 103088,
  META_DATA_SERVICE_ID: 53042,
  POLICY_STUDIO_BIRTH_URL: 'https://policy-dev2.onstove.com/studio-birth',
  DEVELOPERS_DOMAIN: 'https://developers-beta.gate8.com',
  ADMIN_DEVELOPER_API_URL: 'https://admin-developers-api-dev.onstove.com',
  LAUNCHER_URL: 'sgupdev2://util/login',
  STOVE_URL: 'https://www-dev.onstove.com',
  STUDIO_URL: 'https://new-studio-dev2.onstove.com/',
  SENTRY_DSN: 'https://4a740c1c78914866bb74739497018e9d@sentry-dev.onstove.com/75',
  SENTRY_PROJECT_NAME: 'studio-v3-dev2'
};

import { useRequest } from '@/composables/useRequest';
import { ApiMethod, HeaderTypes } from '@/enums/api.enum';
import {
  groupDetailMapper,
  myInformationMapper,
  productsProjectMapper
} from '@/mappers/my-info.mapper';
import type { GroupDetailModel, MyInformationModel } from '@/types/my-info/my-info-model.type';
import type {
  AgreedTermsRequest,
  UpdateMyInformationRequest
} from '@/types/my-info/my-info-request.type';
import type {
  AgreedTermResponse,
  GroupDetailResponse,
  MyInformationResponse,
  SimpleMyInformationResponse
} from '@/types/my-info/my-info-response.type';
import type { ProductsProjectModel } from '@/types/project/project-model.type';
import type { ProductsProjectResponse } from '@/types/project/project-response.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';

// https://wiki.smilegate.net/pages/viewpage.action?pageId=427850059
export const fetchInvitedGroupDetailApi = async (
  groupId: string,
  invitedId: string
): Promise<GroupDetailModel | undefined> => {
  const { data } = await useRequest<GroupDetailModel>(
    `studio/v1/member/invited/${groupId}`,
    {
      headers: { ...generateHeader('X-Lang') },
      method: ApiMethod.Get,
      data: camelToSnake({
        invitedId
      })
    },
    groupDetailMapper
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=427850059
export const fetchGroupJoinedDetailApi = async (
  groupId: string
): Promise<GroupDetailResponse | undefined> => {
  const { data } = await useRequest<GroupDetailResponse>(`studio/v1/member/joined/${groupId}`, {
    headers: { ...generateHeader('X-Lang') },
    method: ApiMethod.Get
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=427834854#4f9a28c5-9cc6-4f54-9ea1-e0830d864999-427834858
export const fetchMyInformationApi = async (
  showCommonError: boolean = true
): Promise<MyInformationModel | undefined> => {
  const { data } = await useRequest<MyInformationModel, MyInformationResponse>(
    'studio/v1/member',
    {
      headers: { ...generateHeader('X-Lang') },
      method: ApiMethod.Get,
      showCommonError
    },
    myInformationMapper
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=427834854#4f9a28c5-9cc6-4f54-9ea1-e0830d864999-432309129
export const updateMyInformationApi = async (
  request: UpdateMyInformationRequest
): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>('studio/v1/members', {
    headers: { ...generateHeader('X-Lang') },
    method: ApiMethod.Put,
    showCommonError: false,
    data: camelToSnake(request)
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=427846849
export const fetchProductsByProjectIdApi = async (
  groupId: string,
  projectId: string
): Promise<ProductsProjectModel | undefined> => {
  const { data } = await useRequest<ProductsProjectModel, ProductsProjectResponse>(
    `studio/v1/member/joined/${groupId}/${projectId}`,
    {
      headers: { ...generateHeader('X-Lang') },
      method: ApiMethod.Get
    },
    productsProjectMapper
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=440995457
export const leaveGroupApi = async (groupId: string): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(`studio/v1/member/joined/${groupId}`, {
    method: ApiMethod.Delete,
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435544173
export const acceptGroupApi = async (
  groupId: string
): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(
    `studio/v1/group-members/${groupId}/members/approve-invitation`,
    {
      method: ApiMethod.Post
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435544180
export const rejectGroupApi = async (
  groupId: string
): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(
    `studio/v1/group-members/${groupId}/members/reject-invitation`,
    {
      method: ApiMethod.Post
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=447763566
export const fetchSimpleMyInformationApi = async (): Promise<
  SimpleMyInformationResponse | undefined
> => {
  const { data } = await useRequest<SimpleMyInformationResponse>('studio/v1/member/simple', {
    headers: { ...generateHeader('X-Lang') },
    method: ApiMethod.Get,
    showCommonError: false,
    cancelAfter: 0
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=441377420
export const fetchTermsAgreedByUserApi = async (
  params: AgreedTermsRequest
): Promise<AgreedTermResponse[] | undefined> => {
  const { data } = await useRequest<{ list: AgreedTermResponse[] }>(
    'stove-terms/v3.0/agree/detail',
    {
      headers: { ...generateHeader(HeaderTypes.CallerId, HeaderTypes.CallerDetail) },
      method: ApiMethod.Get,
      params: camelToSnake(params),
      showCommonError: false
    }
  );

  return data?.list;
};

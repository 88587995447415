import type { FieldValidationMetaInfo } from '@vee-validate/i18n';
import {
  between,
  dimensions,
  email,
  ext,
  max,
  min,
  regex,
  required,
  size,
  url
} from '@vee-validate/rules';
import { DateTime } from 'luxon';

import { NO_SPECIAL_CHAR_REGEX, ONLY_NUMBER_REGEX } from '@/constants/regex.const';
import type { RuleNames } from '@/enums/validation.enum';
import type { RuleParam, RuleValue } from '@/types/validation.type';
import { parseFormattedNumber } from '@/utils/currency.util';

export const checkSpecialCharacter = (value: string) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  if (!NO_SPECIAL_CHAR_REGEX.test(value)) {
    return false;
  }
  return true;
};

export const checkNumber = (value: string) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  if (!ONLY_NUMBER_REGEX.test(value)) {
    return false;
  }
  return true;
};

export const checkSpace = (value: string) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.includes(' ')) {
    return false;
  }
  return true;
};

export const checkMaxDate = (value: Date, maxDate: Date) => {
  // Field is empty, should pass
  if (!value) {
    return true;
  }
  if (DateTime.fromJSDate(value).startOf('day') > DateTime.fromJSDate(maxDate).startOf('day')) {
    return false;
  }
  return true;
};

export const checkMinDate = (value: Date, minDate: Date) => {
  // Field is empty, should pass
  if (!value) {
    return true;
  }
  if (DateTime.fromJSDate(value) < DateTime.fromJSDate(minDate)) {
    return false;
  }
  return true;
};

export const checkRegex = (value: string, regexValue: string | RegExp) => {
  if (!value || !value.length) {
    return true;
  }
  return regex(value, [regexValue]);
};

export const getRuleValues = <T>(params: RuleParam, fieldRequire: number = 0) => {
  // fieldRequire is the number of required fields without message
  const values = [] as T extends Array<RuleValue> ? T : any;
  let message = '';
  if (Array.isArray(params)) {
    for (let i = 0; i < fieldRequire; i++) {
      values.push(params[i]);
    }
    message = params.length > fieldRequire ? params[params.length - 1]?.toString() ?? '' : '';
    return { values, message };
  }
  for (const key in params) {
    if (key === 'message') {
      continue;
    }
    const item = params[key as keyof typeof params];
    if (item === undefined || item === null) {
      continue;
    }
    if (Array.isArray(item)) {
      for (let i = 0; i < item.length; i++) {
        values.push(item[i]);
      }
      continue;
    }

    values.push(item);
  }
  message = params.message?.toString() ?? '';
  return { values, message };
};

export const generateErrorMsg = (message: string, rule?: `${RuleNames}`) => {
  return JSON.stringify({
    message,
    rule
  });
};

// Define url rule
export const urlRule = (value: string, params: RuleParam, { rule }: FieldValidationMetaInfo) => {
  const { message } = getRuleValues(params);
  const result = url(value, {});

  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define required rule
export const requiredRule = (
  value: string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message } = getRuleValues(params);
  const result = required(value);
  if (result) {
    return result;
  }
  return generateErrorMsg(
    message || 'studio.common.def_key.required_n',
    rule?.name as `${RuleNames}`
  );
};

// Define email rule
export const emailRule = (value: string, params: RuleParam, { rule }: FieldValidationMetaInfo) => {
  const { message } = getRuleValues(params);
  const result = email(value);
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

export const lessThanCurrentTimeRule = (
  value: string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message } = getRuleValues(params);
  const result = checkMinDate(new Date(value), new Date());
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define no special character rule
export const noSpecialCharacterRule = (
  value: string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message } = getRuleValues(params);
  const result = checkSpecialCharacter(value);
  if (result) {
    return result;
  }
  return generateErrorMsg(
    message || 'studio.common.def_key.special_character_n',
    rule?.name as `${RuleNames}`
  );
};

// Define number rule
export const numberRule = (value: string, params: RuleParam, { rule }: FieldValidationMetaInfo) => {
  const { message } = getRuleValues(params);
  const result = checkNumber(value);
  if (result) {
    return result;
  }
  return generateErrorMsg(
    message || 'studio.common.def_key.number_only_y',
    rule?.name as `${RuleNames}`
  );
};

// Define string no space rule
export const noSpaceRule = (
  value: string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message } = getRuleValues(params);
  const result = checkSpace(value);
  if (result) {
    return result;
  }
  return generateErrorMsg(message || 'studio.common.def_key.blank_n', rule?.name as `${RuleNames}`);
};

// Define between rule
export const betweenRule = (
  value: string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message, values: betweenParams } = getRuleValues<[string | number, string | number]>(
    params,
    2
  );
  const result = between(parseFormattedNumber(value), betweenParams);

  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define string min length rule
export const minLengthRule = (
  value: string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message, values: minParams } = getRuleValues<[string | number]>(params, 1);

  const result = min(value, minParams);
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define string max length rule
export const maxLengthRule = (
  value: string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message, values: lengthParams } = getRuleValues<[string | number]>(params, 1);

  const result = max(value, lengthParams);
  if (result) {
    return result;
  }
  return generateErrorMsg(message || 'studio.common.def_key.exceed', rule?.name as `${RuleNames}`);
};

// Define max char Froala editor rule
export const maxCharCountRule = (
  _value: string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message, values } = getRuleValues<[number, number]>(params, 1);

  const result = values[0] >= values[1];
  if (result) {
    return result;
  }
  return generateErrorMsg(message || 'studio.common.def_key.exceed', rule?.name as `${RuleNames}`);
};

// Define like rule
export const likeRule = (value: string, params: RuleParam, { rule }: FieldValidationMetaInfo) => {
  const { message, values } = getRuleValues<[string]>(params, 1);

  const result = !value || !value.length || value.includes(values[0]) || values[0].includes(value);
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define less than or equal to rule
export const lteRule = (value: string, params: RuleParam, { rule }: FieldValidationMetaInfo) => {
  const { message, values } = getRuleValues<[string | number]>(params, 1);

  const result = !value || !value.length || value <= values[0];
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define less than rule
export const ltRule = (value: string, params: RuleParam, { rule }: FieldValidationMetaInfo) => {
  const { message, values } = getRuleValues<[string | number]>(params, 1);

  const result = !value || !value.length || value < values[0];
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define greater than or equal to rule
export const gteRule = (value: string, params: RuleParam, { rule }: FieldValidationMetaInfo) => {
  const { message, values } = getRuleValues<[string | number]>(params, 1);

  const result = !value || !value.length || value >= values[0];
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define greater than rule
export const gtRule = (value: string, params: RuleParam, { rule }: FieldValidationMetaInfo) => {
  const { message, values } = getRuleValues<[string | number]>(params, 1);

  const result = !value || !value.length || value > values[0];
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define string equal rule
export const equalRule = (value: string, params: RuleParam, { rule }: FieldValidationMetaInfo) => {
  const { message, values } = getRuleValues<[string | number]>(params, 1);

  const result = !value || !value.length || value === values[0];
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define string not equal rule
export const notEqualRule = (
  value: string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message, values } = getRuleValues<[string | number]>(params, 1);

  const result = !value || !value.length || value !== values[0];
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define min date rule
export const minDateRule = (value: Date, params: RuleParam, { rule }: FieldValidationMetaInfo) => {
  const { message, values: minDateParams } = getRuleValues<[Date]>(params, 1);

  const result = checkMinDate(value, minDateParams[0]);
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define max date rule
export const maxDateRule = (value: Date, params: RuleParam, { rule }: FieldValidationMetaInfo) => {
  const { message, values: maxDateParams } = getRuleValues<[Date]>(params, 1);

  const result = checkMaxDate(value, maxDateParams[0]);
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define regex rule
export const regexRule = (value: string, params: RuleParam, { rule }: FieldValidationMetaInfo) => {
  const { message, values: regex } = getRuleValues<[string | RegExp]>(params, 1);

  const result = checkRegex(value, regex[0]);
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define file max size rule
export const maxSizeRule = (
  value: File | File[] | string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message, values: fileSize } = getRuleValues<[number | string]>(params);
  const inValidFiles: string[] = [];
  let result = true;
  if (!value || typeof value === 'string') {
    return result;
  }
  if (Array.isArray(value)) {
    for (let i = 0; i < value?.length; i++) {
      if (!size(value[i], fileSize)) {
        result = false;
        inValidFiles.push(value[i].name);
      }
    }
  } else {
    result = size(value, fileSize);
    if (!result) {
      inValidFiles.push(value.name);
    }
  }
  if (result) {
    return result;
  }
  return generateErrorMsg(
    message || `These files are invalid ${inValidFiles.join(', ')}`,
    rule?.name as `${RuleNames}`
  );
};

export const extensionRule = (
  value: File | File[] | string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message, values: extension } = getRuleValues<[string]>(params);
  const inValidFiles: string[] = [];
  let result = true;
  if (!value || typeof value === 'string') {
    return result;
  }
  if (Array.isArray(value)) {
    for (let i = 0; i < value?.length; i++) {
      if (!ext(value[i], extension)) {
        result = false;
        inValidFiles.push(value[i].name);
      }
    }
  } else {
    result = ext(value, extension);
    if (!result) {
      inValidFiles.push(value.name);
    }
  }
  if (result) {
    return result;
  }
  return generateErrorMsg(
    message || `These files are invalid ${inValidFiles.join(', ')}`,
    rule?.name as `${RuleNames}`
  );
};

// Define file extension rule
// export const extensionRule = (
//   value: File[],
//   params: RuleParam,
//   { rule }: FieldValidationMetaInfo
// ) => {
//   const { message, values: extension } = getRuleValues<[string]>(params);

//   let result = true;
//   const inValidFiles: string[] = [];
//   for (let i = 0; i < value?.length; i++) {
//     if (!ext(value[i], extension)) {
//       result = false;
//       inValidFiles.push(value[i].name);
//     }
//   }

//   if (result) {
//     return result;
//   }

//   return generateErrorMsg(message || `These files are invalid ${inValidFiles.join(', ')}`, rule?.name as `${RuleNames}`);
// };

// Define max file rule

export const fileCountRule = (
  value: File | File[] | string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message, values: fileCount } = getRuleValues<[number]>(params);
  let result = true;
  if (!value || typeof value === 'string') {
    return result;
  }
  if (Array.isArray(value)) {
    result = value.length <= fileCount[0];
  } else {
    result = fileCount[0] >= 1;
  }
  if (result) {
    return result;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

// Define dimensions rule
export const dimensionRule = async (
  value: File[],
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const {
    message,
    values: [width, height]
  }: { message: string; values: [number, number] } = getRuleValues<[number, number]>(params, 1);

  let result = true;
  const inValidFiles: string[] = [];
  for (let i = 0; i < value.length; i++) {
    const dimension = await dimensions(value[i], { width, height });
    result = dimension;
    if (!result) {
      inValidFiles.push(value[i].name);
    }
  }

  if (result) {
    return result;
  }
  return generateErrorMsg(
    message || `The image field must be ${width}x${height}`,
    rule?.name as `${RuleNames}`
  );
};

export const asyncRule = async (
  value: string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message, values } = getRuleValues<[(v: string) => Promise<boolean>]>(params);
  const callback = values[0];
  if (!callback) {
    return false;
  }
  const data = await callback(value);
  if (data) {
    return data;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

export const checkDuplicate = async (_value: string, _params: RuleParam) => {
  // const {
  //   message,
  //   values: [api, apiParams, callback]
  // }: { message: string; values: [string, string, () => void] } = getRuleValues<
  //   [string, string, Function]
  // >(params, 1);

  return true;
  // TODO: Implement check duplicate api request
  // const result = await useRequest(api, {
  //   headers: generateHeader(
  //     HeaderTypes.XNation,
  //     HeaderTypes.XLang,
  //     HeaderTypes.XClientLang,
  //     HeaderTypes.CallerId
  //   ),
  //   params: {
  //     [apiParams]: value
  //   }
  // });

  // if (result) {
  //   return result;
  // }

  // TODO: remove this line after implementing the api request
  return true;

  // if (callback) {
  //   callback();
  // }

  // return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

export const checkMultiplyByTen = (
  value: string,
  params: RuleParam,
  { rule }: FieldValidationMetaInfo
) => {
  const { message } = getRuleValues(params);

  if (Number(value) % 10 === 0) {
    return true;
  }
  return generateErrorMsg(message, rule?.name as `${RuleNames}`);
};

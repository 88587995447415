import { ApprovalStatus } from '@/enums/common.enum';

export const GROUP_TYPE = {
  BE_INVITED: 'BeInvited',
  BE_JOINED: 'BeJoined'
};

export const INVITE_STATE = {
  REFUSED: ApprovalStatus.REJECT,
  ACCEPTED: ApprovalStatus.APPROVE,
  CANCELLED: 'DISCARD',
  APPLY: 'APPLY'
};

export const MEMBER_INVITE_HISTORY_STATUS = {
  JOIN: 'JOIN',
  REJECT: 'REJECT',
  MODIFY: 'MODIFY',
  LEAVE: 'LEAVE',
  ALL: 'ALL'
};

export const MENU_ID = {
  GROUP_HOME: 'GROUP_HOME',
  PROJECT_PRODUCT: 'PROJECT_PRODUCT',
  COLLECTION: 'COLLECTION',
  STORE_COMMUNITY: 'STORE_COMMUNITY',
  MEMBER_MANAGEMENT: 'MEMBER_MANAGEMENT',
  BILL_SETTLEMENT: 'BILL_SETTLEMENT',
  STATISTICS: 'STATISTICS',
  BIMS_BANK_SETTING: 'BIMS_BANK_SETTING',
  GAME_MAINTENANCE: 'GAME_MAINTENANCE'
};

import { PLATFORM } from '@/constants/common.const';
import { ROLE_ID } from '@/constants/permission.const';
import { PRODUCT_TYPE_DETAIL } from '@/constants/products.const';
import { RELEASE_METHODS } from '@/constants/release-plan.const';
import {
  PRODUCT_ACHIEVEMENTS_PAGE_URL,
  PRODUCT_BANNER_IMG_MNG_PAGE_URL,
  PRODUCT_BUILD_PAGE_URL,
  PRODUCT_CLOUD_SAVING_PAGE_URL,
  PRODUCT_DISCOUNT_PAGE_URL,
  PRODUCT_EARLY_ACCESS_PAGE_URL,
  PRODUCT_IN_GAME_PAYMENT_DISCOUNT_PAGE_URL,
  PRODUCT_IN_GAME_PAYMENT_HISTORY_PAGE_URL,
  PRODUCT_IN_GAME_PAYMENT_PRODUCTS_PAGE_URL,
  PRODUCT_IN_GAME_PAYMENT_SETTING_PAGE_URL,
  PRODUCT_IN_GAME_PAYMENT_SHOPS_PAGE_URL,
  PRODUCT_LAUNCH_SETTING_PAGE_URL,
  PRODUCT_LEADER_BOARD_PAGE_URL,
  PRODUCT_MATCH_MAKING_PAGE_URL,
  PRODUCT_PAGES_PAGE_URL,
  PRODUCT_PURCHASE_CONDITION_PAGE_URL,
  PRODUCT_RATING_URL,
  PRODUCT_RELEASE_PLAN_PAGE_URL,
  PRODUCT_SELLING_PRICE_PAGE_URL,
  PRODUCT_SETTING_PAGE_URL,
  PRODUCT_STATS_PAGE_URL,
  PRODUCT_TERMS_PAGE_URL
} from '@/constants/url.const';
import type { ProductFeat } from '@/types/product/product-model.type';

export const PRODUCT_FEAT_GROUP_HOME = 'product-home';
const PRODUCT_FEAT_GROUP_PAGE_MNG = 'product-page-mng';
const PRODUCT_FEAT_GROUP_DATA_MNG = 'product-data-mng';
const PRODUCT_FEAT_GROUP_INGAME_PAYMENT_MNG = 'ingame-payment-mng';
const PRODUCT_FEAT_GROUP_MARKETING_MNG = 'product-marketing-mng';

// Game basic web/mobile, Game demo web/mobile, Utility basic web/mobile, Utility demo web/mobile
export const disableCondition1 = [
  { platform: PLATFORM.WEB, detailType: PRODUCT_TYPE_DETAIL.GAME_BASIC },
  { platform: PLATFORM.MOBILE, detailType: PRODUCT_TYPE_DETAIL.GAME_BASIC },
  { platform: PLATFORM.MOBILE, detailType: PRODUCT_TYPE_DETAIL.GAME_DEMO },
  { platform: PLATFORM.WEB, detailType: PRODUCT_TYPE_DETAIL.GAME_DEMO },
  { platform: PLATFORM.WEB, detailType: PRODUCT_TYPE_DETAIL.UTILITY_BASIC },
  { platform: PLATFORM.MOBILE, detailType: PRODUCT_TYPE_DETAIL.UTILITY_BASIC },
  { platform: PLATFORM.WEB, detailType: PRODUCT_TYPE_DETAIL.UTILITY_DEMO },
  { platform: PLATFORM.MOBILE, detailType: PRODUCT_TYPE_DETAIL.UTILITY_DEMO }
];
// Game basic mobile, Game demo mobile
const disableCondition2 = [
  { platform: PLATFORM.MOBILE, detailType: PRODUCT_TYPE_DETAIL.GAME_BASIC },
  { platform: PLATFORM.MOBILE, detailType: PRODUCT_TYPE_DETAIL.GAME_DEMO }
];

export const PRODUCT_TITLE = {
  RELEASE_PLAN: 'studio.gnb.common_2depth_plan',
  SETTINGS: 'studio.gnb.common_2depth_setting',
  PRODUCT_PAGES: 'studio.gnb.common_2depth_product_page',
  SELLING_PRICE: 'studio.gnb.common_2depth_price',
  DISCOUNT: 'studio.gnb.common_2depth_discount',
  PURCHASE_CONDITION: 'studio.gnb.common_2depth_sale',
  EARLY_ACCESS: 'studio.gnb.common_2depth_early_access',
  BUILD: 'studio.gnb.common_2depth_build',
  LAUNCH_SETTING: 'studio.gnb.common_2depth_execution',
  RATING: 'studio.gnb.common_2depth_tier_review',
  TERMS: 'studio.gnb.common_2depth_condition',
  STATS: 'studio.gnb.common_2depth_stats',
  BANNER_IMG: 'studio.gnb.common_2depth_banner'
};

export const PRODUCT_FEATS: ProductFeat[] = [
  {
    id: PRODUCT_FEAT_GROUP_HOME,
    name: 'studio.gnb.common_1depth_product_home',
    children: [
      {
        url: PRODUCT_RELEASE_PLAN_PAGE_URL,
        name: PRODUCT_TITLE.RELEASE_PLAN,
        internal: true,
        accessibleProductTypes: Object.values(PRODUCT_TYPE_DETAIL),
        showPlace: 'gnb'
      }
    ]
  },
  {
    id: PRODUCT_FEAT_GROUP_PAGE_MNG,
    name: 'studio.gnb.common_1depth_product_management',
    children: [
      {
        url: PRODUCT_SETTING_PAGE_URL,
        name: PRODUCT_TITLE.SETTINGS,
        internal: true,
        requireInMethods: [RELEASE_METHODS.ONLY_PRODUCT_PAGE, RELEASE_METHODS.OFFICIAL_RELEASE],
        accessibleProductTypes: Object.values(PRODUCT_TYPE_DETAIL),
        description: 'studio.prj_prod.this_prod.home_setting_guide'
      },
      {
        url: PRODUCT_PAGES_PAGE_URL,
        name: PRODUCT_TITLE.PRODUCT_PAGES,
        description: 'studio.prj_prod.this_prod.home_product_page_guide',
        internal: true,
        requireInMethods: [RELEASE_METHODS.ONLY_PRODUCT_PAGE, RELEASE_METHODS.OFFICIAL_RELEASE],
        accessibleProductTypes: Object.values(PRODUCT_TYPE_DETAIL)
      },
      {
        url: PRODUCT_SELLING_PRICE_PAGE_URL,
        description: 'studio.prj_prod.this_prod.home_price_guide',
        name: PRODUCT_TITLE.SELLING_PRICE,
        requireInMethods: [RELEASE_METHODS.OFFICIAL_RELEASE],
        internal: true,
        accessibleProductTypes: [
          PRODUCT_TYPE_DETAIL.UTILITY_BASIC,
          PRODUCT_TYPE_DETAIL.UTILITY_DLC,
          PRODUCT_TYPE_DETAIL.GAME_DLC,
          PRODUCT_TYPE_DETAIL.CONTENT_BASIC,
          PRODUCT_TYPE_DETAIL.GAME_BASIC
        ]
      },
      {
        url: PRODUCT_DISCOUNT_PAGE_URL,
        name: PRODUCT_TITLE.DISCOUNT,
        internal: true,
        description: 'studio.prj_prod.this_prod.home_discount_guide',
        accessibleProductTypes: [
          PRODUCT_TYPE_DETAIL.GAME_DLC,
          PRODUCT_TYPE_DETAIL.GAME_BASIC,
          PRODUCT_TYPE_DETAIL.UTILITY_BASIC,
          PRODUCT_TYPE_DETAIL.UTILITY_DLC,
          PRODUCT_TYPE_DETAIL.CONTENT_DEMO
        ]
      },
      {
        url: PRODUCT_PURCHASE_CONDITION_PAGE_URL,
        description: 'studio.prj_prod.this_prod.home_sale_guide',
        name: PRODUCT_TITLE.PURCHASE_CONDITION,
        internal: true,
        accessibleProductTypes: Object.values(PRODUCT_TYPE_DETAIL)
      },
      {
        url: PRODUCT_EARLY_ACCESS_PAGE_URL,
        name: PRODUCT_TITLE.EARLY_ACCESS,
        description: 'studio.prj_prod.this_prod.home_early_access_guide',
        internal: true,
        accessibleProductTypes: [
          PRODUCT_TYPE_DETAIL.GAME_BASIC,
          PRODUCT_TYPE_DETAIL.GAME_DLC,
          PRODUCT_TYPE_DETAIL.UTILITY_BASIC,
          PRODUCT_TYPE_DETAIL.UTILITY_DLC
        ]
      }
    ]
  },
  {
    id: PRODUCT_FEAT_GROUP_DATA_MNG,
    name: 'studio.gnb.common_1depth_product_data',
    children: [
      {
        url: PRODUCT_BUILD_PAGE_URL,
        name: PRODUCT_TITLE.BUILD,
        description: 'studio.prj_prod.this_prod.home_build_guide',
        internal: true,
        requireInMethods: [RELEASE_METHODS.OFFICIAL_RELEASE],
        accessibleProductTypes: Object.values(PRODUCT_TYPE_DETAIL),
        disableConditions: [...disableCondition1]
      },
      {
        url: PRODUCT_LAUNCH_SETTING_PAGE_URL,
        description: 'studio.prj_prod.this_prod.home_execution_guide',
        name: PRODUCT_TITLE.LAUNCH_SETTING,
        internal: true,
        requireInMethods: [RELEASE_METHODS.OFFICIAL_RELEASE],
        accessibleProductTypes: Object.values(PRODUCT_TYPE_DETAIL)
      },
      {
        name: PRODUCT_TITLE.RATING,
        description: 'studio.prj_prod.this_prod.home_tier_review_guide',
        url: PRODUCT_RATING_URL,
        internal: true,
        requireInMethods: [RELEASE_METHODS.OFFICIAL_RELEASE],
        accessibleProductTypes: [
          PRODUCT_TYPE_DETAIL.GAME_DEMO,
          PRODUCT_TYPE_DETAIL.GAME_DLC,
          PRODUCT_TYPE_DETAIL.GAME_BASIC
        ]
      },
      {
        url: '',
        typeMenu: 'developer-center',
        name: 'STOVE PC SDK',
        description: 'studio.prj_prod.this_prod.home_stove_pc_sdk_guide',
        internal: false,
        icon: 'ic-v2-control-web-link-line',
        target: '_blank',
        accessibleProductTypes: [
          PRODUCT_TYPE_DETAIL.GAME_BASIC,
          PRODUCT_TYPE_DETAIL.GAME_DEMO,
          PRODUCT_TYPE_DETAIL.GAME_DLC,
          PRODUCT_TYPE_DETAIL.UTILITY_BASIC,
          PRODUCT_TYPE_DETAIL.UTILITY_DEMO,
          PRODUCT_TYPE_DETAIL.UTILITY_DLC
        ],
        showPlace: 'product-home'
      },
      {
        url: '',
        description: 'studio.prj_prod.this_prod.home_client_test_guide',
        name: 'studio.prod_home.component_client_test',
        target: '_blank',
        icon: 'ic-v2-object-download-line',
        internal: false,
        accessibleProductTypes: Object.values(PRODUCT_TYPE_DETAIL),
        showPlace: 'product-home',
        typeMenu: 'launcher'
      },
      {
        url: PRODUCT_TERMS_PAGE_URL,
        name: PRODUCT_TITLE.TERMS,
        description: 'studio.prj_prod.this_prod.home_condition_guide',
        internal: true,
        accessibleProductTypes: Object.values(PRODUCT_TYPE_DETAIL)
      },
      {
        name: 'studio.gnb.common_2depth_stats',
        url: PRODUCT_STATS_PAGE_URL,
        description: 'studio.prj_prod.this_prod.home_stats_guide',
        roleAuthorityId: ROLE_ID.PRODUCT_STAT,
        internal: false,
        accessibleProductTypes: [
          PRODUCT_TYPE_DETAIL.GAME_DEMO,
          PRODUCT_TYPE_DETAIL.UTILITY_BASIC,
          PRODUCT_TYPE_DETAIL.UTILITY_DEMO,
          PRODUCT_TYPE_DETAIL.GAME_BASIC
        ],
        disableConditions: [...disableCondition1]
      },
      {
        name: 'studio.gnb.common_2depth_ranking',
        url: PRODUCT_LEADER_BOARD_PAGE_URL,
        description: 'studio.prj_prod.this_prod.home_ranking_guide',
        roleAuthorityId: ROLE_ID.PRODUCT_RANKING,
        internal: false,
        accessibleProductTypes: [
          PRODUCT_TYPE_DETAIL.GAME_BASIC,
          PRODUCT_TYPE_DETAIL.GAME_DEMO,
          PRODUCT_TYPE_DETAIL.UTILITY_BASIC,
          PRODUCT_TYPE_DETAIL.UTILITY_DEMO
        ],
        disableConditions: [...disableCondition1]
      },
      {
        name: 'studio.gnb.common_2depth_challenges',
        url: PRODUCT_ACHIEVEMENTS_PAGE_URL,
        roleAuthorityId: ROLE_ID.PRODUCT_ACHIEVEMENT,
        internal: false,
        description: 'studio.prj_prod.this_prod.home_challenges_guide',
        accessibleProductTypes: [
          PRODUCT_TYPE_DETAIL.GAME_BASIC,
          PRODUCT_TYPE_DETAIL.GAME_DEMO,
          PRODUCT_TYPE_DETAIL.UTILITY_BASIC,
          PRODUCT_TYPE_DETAIL.UTILITY_DEMO
        ],
        disableConditions: [...disableCondition1]
      },
      {
        name: 'studio.gnb.common_2depth_cloud',
        url: PRODUCT_CLOUD_SAVING_PAGE_URL,
        roleAuthorityId: ROLE_ID.PRODUCT_CLOUD_SAVING,
        description: 'studio.prj_prod.this_prod.home_cloud_guide',
        internal: false,
        accessibleProductTypes: [
          PRODUCT_TYPE_DETAIL.GAME_BASIC,
          PRODUCT_TYPE_DETAIL.GAME_DEMO,
          PRODUCT_TYPE_DETAIL.UTILITY_BASIC,
          PRODUCT_TYPE_DETAIL.UTILITY_DEMO
        ],
        disableConditions: [...disableCondition1]
      },
      {
        name: 'studio.gnb.common_2depth_match',
        url: PRODUCT_MATCH_MAKING_PAGE_URL,
        roleAuthorityId: ROLE_ID.PRODUCT_MATCH_MAKING,
        description: 'studio.prj_prod.this_prod.home_match_guide',
        internal: false,
        accessibleProductTypes: [PRODUCT_TYPE_DETAIL.GAME_DEMO, PRODUCT_TYPE_DETAIL.GAME_BASIC],
        disableConditions: [...disableCondition1]
      }
    ]
  },
  {
    id: PRODUCT_FEAT_GROUP_INGAME_PAYMENT_MNG,
    name: 'studio.gnb.common_1depth_in_app_purchase',
    children: [
      {
        name: 'studio.gnb.common_2depth_in_app_setting',
        url: PRODUCT_IN_GAME_PAYMENT_SETTING_PAGE_URL,
        roleAuthorityId: ROLE_ID.INGAME_PURCHASE_SETTING,
        internal: false,
        accessibleProductTypes: [PRODUCT_TYPE_DETAIL.GAME_DEMO, PRODUCT_TYPE_DETAIL.GAME_BASIC],
        description: 'studio.prj_prod.this_prod.home_in_app_setting_guide',
        disableConditions: [...disableCondition2]
      },
      {
        name: 'studio.gnb.common_2depth_in_app_shop',
        url: PRODUCT_IN_GAME_PAYMENT_SHOPS_PAGE_URL,
        roleAuthorityId: ROLE_ID.INGAME_SHOP,
        internal: false,
        accessibleProductTypes: [PRODUCT_TYPE_DETAIL.GAME_DEMO, PRODUCT_TYPE_DETAIL.GAME_BASIC],
        description: 'studio.prj_prod.this_prod.home_in_app_shop_guide',
        disableConditions: [...disableCondition2]
      },
      {
        name: 'studio.gnb.common_2depth_in_app_product',
        url: PRODUCT_IN_GAME_PAYMENT_PRODUCTS_PAGE_URL,
        roleAuthorityId: ROLE_ID.INGAME_PRODUCT,
        internal: false,
        accessibleProductTypes: [PRODUCT_TYPE_DETAIL.GAME_DEMO, PRODUCT_TYPE_DETAIL.GAME_BASIC],
        description: 'studio.prj_prod.this_prod.home_in_app_product_guide',
        disableConditions: [...disableCondition2]
      },
      {
        name: 'studio.gnb.common_2depth_in_app_discount',
        roleAuthorityId: ROLE_ID.INGAME_DISCOUNT_PRODUCT,
        url: PRODUCT_IN_GAME_PAYMENT_DISCOUNT_PAGE_URL,
        internal: false,
        accessibleProductTypes: [PRODUCT_TYPE_DETAIL.GAME_DEMO, PRODUCT_TYPE_DETAIL.GAME_BASIC],
        description: 'studio.prj_prod.this_prod.home_in_app_discount_guide',
        disableConditions: [...disableCondition2]
      },
      {
        name: 'studio.gnb.common_2depth_in_app_payment_history',
        roleAuthorityId: ROLE_ID.INGAME_PURCHASE_HISTORY,
        url: PRODUCT_IN_GAME_PAYMENT_HISTORY_PAGE_URL,
        internal: false,
        accessibleProductTypes: [PRODUCT_TYPE_DETAIL.GAME_DEMO, PRODUCT_TYPE_DETAIL.GAME_BASIC],
        description: 'studio.prj_prod.this_prod.home_in_app_payment_history_guide',
        disableConditions: [...disableCondition2]
      }
    ]
  },
  {
    id: PRODUCT_FEAT_GROUP_MARKETING_MNG,
    name: 'studio.gnb.common_1depth_marketing',
    children: [
      {
        url: PRODUCT_BANNER_IMG_MNG_PAGE_URL,
        name: PRODUCT_TITLE.BANNER_IMG,
        internal: true,
        accessibleProductTypes: Object.values(PRODUCT_TYPE_DETAIL),
        description: 'studio.prj_prod.this_prod.home_banner_guide'
      }
    ]
  }
];

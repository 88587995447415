import { defineNuxtPlugin } from 'nuxt/app';
import type { DirectiveBinding } from 'nuxt/dist/app/compat/capi';
import type { App } from 'vue';

export default defineNuxtPlugin(({ vueApp }: { vueApp: App }) => {
  vueApp.directive('click-outside', {
    beforeMount(el: any, binding: DirectiveBinding<any>) {
      el.clickOutsideEvent = (event: Event) => {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value();
        }
      };
      document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el: any) {
      document.removeEventListener('click', el.clickOutsideEvent);
    }
  });
});

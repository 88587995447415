export default {
  RUN_TYPE: 'qa2',
  API_BASE_URL: 'https://api-qa2.onstove.com',
  STORE_URL: 'https://store-qa2.onstove.com',
  STUDIO_V2_URL: 'https://pre-studio-qa2.onstove.com',
  SEED_CORE: {
    AUTH_URL: 'https://accounts-qa2.onstove.com',
    SESSION_URL: 'https://auth-qa2.onstove.com',
    MEMBER_URL: 'https://member-qa2.onstove.com',
    DOMAIN: '.onstove.com',
    API_URL: 'https://api-qa2.onstove.com',
    ENABLE_CORS: true,
    ENABLE_GDS: true,
    CAllER_ID: 'indie-web-studio'
  },
  GNB_SCRIPT: 'https://js-cdn-qa2.onstove.com/libs/common-gnb/latest/cp-header.js',
  TRANSLATION_BASE_URL: 'https://d21zm6x6sd0z6.cloudfront.net/qa2/application_no/STATIC/indie',
  TRANSLATION_FOLDER_NAME: 'indie-studio.3.0',
  TRANSLATION_FILE_NAME: '{locale}.properties',
  TERM_URL: 'https://clause-qa2.onstove.com',
  FROALA_KEY: 'FSC2H-9B1A8A1B3D3D2E2sGXh1WWTDSGXYOUKc1KINLe1OC1c1D-17D2E2F2A1E4F1B2B8E7D6==',
  UPLOADER_DOWNLOAD_URL:
    'https://dl-qa2.onstove.com/tools/UploadTool/v2/STOVEUploader_QA2_setup.exe',
  APPLICATION_NO: 464018,
  LOUNGE_URL: 'https://lounge-qa2.onstove.com/',
  META_DATA_SERVICE_ID: 409018,
  INDIE_CHANNEL_SEQ: 90305,
  INDIE_BOARD_SEQ: 124817,
  POLICY_STUDIO_BIRTH_URL: 'https://policy-qa2.onstove.com/studio-birth',
  DEVELOPERS_DOMAIN: 'https://developers-beta.gate8.com',
  ADMIN_DEVELOPER_API_URL: 'https://admin-developers-api.gate8.com',
  LAUNCHER_URL: 'sgupqa2://util/login',
  STOVE_URL: 'https://www-qa2.onstove.com',
  STUDIO_URL: 'https://new-studio-qa2.onstove.com/',
  SENTRY_DSN: 'https://4a740c1c78914866bb74739497018e9d@sentry-dev.onstove.com/75',
  SENTRY_PROJECT_NAME: 'studio-v3-qa2'
};

export const generateUUID = (): string => {
  // Template for UUID v4
  const template: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  return template.replace(/[xy]/g, function(character: string) {
    const random: number = (Math.random() * 16) | 0;
    const value: number = character === 'x' ? random : (random & 0x3) | 0x8;
    return value.toString(16);
  });
};

export const generateRandomNumber = (): number => {
  const template = 'xxxxxxxxxxxx';
  const randomNumberText = template.replace(/[x]/g, () => {
    const random = (Math.random() * 10) | 0;
    return random.toString();
  });

  return parseInt(randomNumberText, 10);
};


import { updateAppConfig } from '#app'
import { defuFn } from '/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "nuxt": {
    "buildId": "54cce827-b4fa-44b6-ab84-67f4e16ac7ca"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /* #__PURE__ */ defuFn(inlineConfig)

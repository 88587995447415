import { defineNuxtPlugin } from 'nuxt/app';
import type { App, DirectiveBinding } from 'vue';

import { getImageDimension } from '@/utils/file.util';

export default defineNuxtPlugin(({ vueApp }: { vueApp: App }) => {
  vueApp.directive('uploadable', {
    beforeMount(el: Element, binding: DirectiveBinding<{ accept: string | string[] }>) {
      el.addEventListener('click', () => {
        const file = document.createElement('input');
        file.setAttribute('type', 'file');
        file.setAttribute('id', 'input-file');
        file.style.opacity = '0';
        if (binding.value && binding.value.accept) {
          const accept = Array.isArray(binding.value.accept)
            ? binding.value.accept.join(',')
            : binding.value.accept;
          file.setAttribute('accept', accept);
        }
        file.onchange = (ev: Event) => {
          const target = ev.target as HTMLInputElement;
          const files = target.files;
          if (files) {
            const file = target.files?.[0];
            if (!file) {
              return;
            }
            const reader = new FileReader();
            reader.onload = async (e: ProgressEvent<FileReader>) => {
              const result = e.target?.result as string;

              const dimension = await getImageDimension(result);

              const event = new CustomEvent('fileSelected', {
                detail: {
                  image: result,
                  dimension: dimension ?? { width: 1, height: 1 },
                  name: file.name,
                  file
                }
              });
              el.dispatchEvent(event);
            };
            reader.readAsDataURL(file);
            return;
          }
          document.getElementById('input-file')?.remove();
        };
        file.click();
      });
    },
    unmounted() {
      document.getElementById('input-file')?.remove();
    }
  });
});

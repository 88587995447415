<template>
  <div class="flex flex-col min-w-[128rem] max-w-[136rem] min-h-[100vh] mx-auto pt-[6.8rem]">
    <div class="flex-1 pt-40 px-24 pb-[12rem]">
      <section class="flex flex-col gap-40 fixed w-full h-full top-0 left-0 items-center justify-center">
        <div class="flex flex-col gap-20 items-center">
          <div class="w-[27.4rem] h-200 shrink-0">
            <div ref="animationContainer"></div>
          </div>
          <div class="flex flex-col gap-4 items-center">
            <s-text as="h1" role="title4" class="!font-bold text-on-surface-elevation-2">
              {{ $t('studio.page_not_found.msg3') }}
            </s-text>
            <s-text as="p" role="text1" class="text-on-surface-elevation-3">
              {{ $t('studio.page_not_found.msg4') }}
            </s-text>
          </div>
        </div>
        <div class="flex flex-col gap-20 items-center">
          <div class="flex gap-8">
            <s-button v-if="hasPreviousPage" variant="outline" size="md" class="!min-w-120" @click="gotoPreviousPage">{{ $t('studio.page_not_found.msg2') }}</s-button>
            <s-button v-if="readableGroupId !== ''" size="md" class="!min-w-120" @click="goHome">{{ $t('studio.gnb.group_myinfo.my_group.detailed_group_per_home') }}</s-button>
          </div>
          <s-text v-if="hasPreviousPage" as="p" role="text4" class="text-on-surface-elevation-4">
            {{ $t('studio.page_not_found.home_dir_btn') }}
          </s-text>
        </div>
      </section>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useHead } from '@unhead/vue';
import lottie from 'lottie-web';
import { onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { BODY_CLASS } from '@/constants/common.const';
import { HOME_PAGE_URL } from '@/constants/url.const';
import { useUserStore } from '@/stores/user.store';
import { redirectTo } from '@/utils/common.util';
import { delayTime } from '@/utils/time.util';

useHead({
  bodyAttrs: {
    class: BODY_CLASS
  }
});
const userStore = useUserStore();

const readableGroupId = ref('');

const hasPreviousPage = window.history.length > 2;

const gotoPreviousPage = () => {
  window.history.back();
};
const { locale } = useI18n();
const goHome = () => {
  redirectTo(`/${locale.value}${HOME_PAGE_URL}`, { groupId: readableGroupId.value, external: true });
};

const goToPrevPageAfter5Sec = async () => {
  if (!hasPreviousPage) { return; }
  await delayTime(5000);
  gotoPreviousPage();
};

const init = async () => {
  readableGroupId.value = await userStore.findReadableGroupHome();
};

goToPrevPageAfter5Sec();
init();

let animation:any = null;
const earthAnimation = ref<any>(null);
const animationContainer = ref<any>(null);

onMounted(async () => {
  // Animation Earth
  try {
    earthAnimation.value = await import('@/assets/images/common/error.json');

    if (earthAnimation.value) {
      animation = lottie.loadAnimation({
        container: animationContainer.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: earthAnimation.value.default
      });
    }
  } catch (error) {

    // console.error('Failed to load animation:', error);
  }
});
onUnmounted(() => {
  if (animation) {
    animation.destroy();
  }
});
</script>

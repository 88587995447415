import { useCookieWithDomain } from '@/composables/useCookieWithDomain';
import { CALLER_ID, DEFAULT_X_CLIENT_LANGUAGE } from '@/constants/common.const';
import {
  COOKIE_KEYS,
  DEFAULT_COUNTRY,
  DEFAULT_TIMEZONE,
  DEFAULT_UTC_OFFSET
} from '@/constants/cookie.const';
import { DEFAULT_COVERAGES, DEFAULT_LOCALE } from '@/constants/locale.const';
import { HeaderTypes } from '@/enums/api.enum';
import type { HeaderType, UseRequestModel } from '@/types/common/common.type';

export const snakeToCamel = (
  obj: any,
  mapValueFunction?: (value: any, key: string) => any
): any => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item: any) => snakeToCamel(item, mapValueFunction));
  }

  return Object.keys(obj).reduce((acc: any, key: any) => {
    const camelKey = key.replace(/_(\w)/g, (_: string, letter: string) => letter.toUpperCase());
    const value = obj[key];
    acc[camelKey] = snakeToCamel(value, mapValueFunction);

    if (mapValueFunction) {
      acc[camelKey] = mapValueFunction(acc[camelKey], camelKey);
    }

    return acc;
  }, {} as any);
};

export const stringCamelToSnake = (str: string): string => {
  return str.replace(/[A-Z]/g, (match: string) => `_${match.toLowerCase()}`);
};

export const camelToSnake = (
  obj: any,
  mapValueFunction?: (value: any, key: string) => any,
  exclusiveArray: string[] = []
): any => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item: any) => camelToSnake(item, mapValueFunction, exclusiveArray));
  }

  return Object.keys(obj).reduce((acc: any, key: any) => {
    let snakeCaseKey;
    if (exclusiveArray.includes(key)) {
      snakeCaseKey = key;
    } else {
      snakeCaseKey = key.replace(/[A-Z]/g, (match: string) => `_${match.toLowerCase()}`);
    } // Convert camel case to snake case

    const value = obj[key];
    acc[snakeCaseKey] = camelToSnake(value, mapValueFunction, exclusiveArray);

    if (mapValueFunction) {
      acc[snakeCaseKey] = mapValueFunction(acc[snakeCaseKey], snakeCaseKey);
    }
    return acc;
  }, {} as any);
};

export const getXClientLang = (): string => {
  let lang: string = window.navigator.language || (window.navigator as any).userLanguage;

  if (lang.includes('-')) {
    const firstLang: string = lang.split('-')[0];
    lang = firstLang === 'zh' ? lang : firstLang;
  }

  return lang;
};

export const getXLang = (): string => {
  const path = window.location.pathname;
  const urlLocale = path.split('/')[1];
  return DEFAULT_COVERAGES.includes(urlLocale) ? urlLocale : DEFAULT_LOCALE;
};

export const generateHeader = (...attrs: HeaderType[]) => {
  const xLang = getXLang();

  const result: { [key in HeaderType]?: string } = {};
  for (const attr of attrs) {
    let value = '';
    switch (attr) {
      case HeaderTypes.XNation:
        value = useCookieWithDomain(COOKIE_KEYS.NNTO || '').value || DEFAULT_COUNTRY;
        break;
      case HeaderTypes.XTimezone:
        value = useCookieWithDomain(COOKIE_KEYS.TIMEZONE || '').value || DEFAULT_TIMEZONE;
        break;
      case HeaderTypes.XUtcOffset:
        value =
          useCookieWithDomain(COOKIE_KEYS.UTC_OFFSET || '').value ||
          (DEFAULT_UTC_OFFSET.toString() as string);
        break;
      case HeaderTypes.XLang:
        value = xLang;
        break;
      case HeaderTypes.XClientLang:
        value = process.client ? getXClientLang() : DEFAULT_X_CLIENT_LANGUAGE;
        break;
      case HeaderTypes.XUuId:
        value = useCookieWithDomain(COOKIE_KEYS.UUID || '').value || '';
        break;
      case HeaderTypes.CallerId:
        value = CALLER_ID.INDIE;
        break;
      case HeaderTypes.CallerDetail:
        value = useCookieWithDomain(COOKIE_KEYS.UUID || '').value || '';
        break;
      default:
        break;
    }
    if (value !== '') {
      result[attr] = value;
    }
  }
  return result;
};

export const authHeader = (options: UseRequestModel = {}) => {
  const accessToken = useCookieWithDomain(COOKIE_KEYS.ACCESS_TOKEN || '');
  if (accessToken.value) {
    options.headers = { ...options.headers, Authorization: `Bearer ${accessToken.value}` };
  } else {
    options.headers = { ...options.headers };
  }
  options.headers['caller-id'] = CALLER_ID.INDIE;
  return options;
};

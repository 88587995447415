export default {
  RUN_TYPE: 'sandbox',
  API_BASE_URL: 'https://api.gate8.com',
  STORE_URL: 'https://store.gate8.com',
  STUDIO_V2_URL: 'https://pre-studio.gate8.com',
  SEED_CORE: {
    AUTH_URL: 'https://accounts.gate8.com',
    SESSION_URL: 'https://auth.gate8.com',
    MEMBER_URL: 'https://member.gate8.com',
    DOMAIN: '.gate8.com',
    API_URL: 'https://api.gate8.com',
    ENABLE_CORS: true,
    ENABLE_GDS: true,
    CAllER_ID: 'indie-web-studio'
  },
  GNB_SCRIPT: 'https://js-cdn.gate8.com/libs/common-gnb/latest/cp-header.js',
  TRANSLATION_BASE_URL: 'https://d2x8kymwjom7h7.cloudfront.net/sandbox/application_no/STATIC/indie',
  TRANSLATION_FOLDER_NAME: 'indie-studio.3.0',
  TRANSLATION_FILE_NAME: '{locale}.properties',
  TERM_URL: 'https://clause.gate8.com',
  FROALA_KEY: 'FSC2H-9B1A8A1B3D3D2E2sGXh1WWTDSGXYOUKc1KINLe1OC1c1D-17D2E2F2A1E4F1B2B8E7D6==',
  UPLOADER_DOWNLOAD_URL: 'https://dl.gate8.com/tools/UploadTool/v2/STOVEUploader_GATE8_setup.exe',
  APPLICATION_NO: 57363,
  LOUNGE_URL: 'https://lounge.gate8.com/',
  META_DATA_SERVICE_ID: 57218,
  INDIE_CHANNEL_SEQ: 11261,
  INDIE_BOARD_SEQ: 135706,
  POLICY_STUDIO_BIRTH_URL: 'https://policy.gate8.com/studio-birth',
  DEVELOPERS_DOMAIN: 'https://developers-beta.gate8.com',
  ADMIN_DEVELOPER_API_URL: 'https://admin-developers-api.gate8.com',
  LAUNCHER_URL: 'sgupgate8://util/login',
  STOVE_URL: 'https://www.gate8.com',
  STUDIO_URL: 'https://new-studio.gate8.com/',
  SENTRY_DSN: 'https://4a740c1c78914866bb74739497018e9d@sentry-dev.onstove.com/75',
  SENTRY_PROJECT_NAME: 'studio-v3-sandbox'
};

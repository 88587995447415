
// @ts-nocheck


export const localeCodes =  [
  "en",
  "zh-cn",
  "zh-tw",
  "ja",
  "ko"
]

export const localeLoaders = {
  "en": [{ key: "../i18n/default/en.ts", load: () => import("../i18n/default/en.ts?hash=c2cd3e61&locale=en" /* webpackChunkName: "locale__var_lib_jenkins_jobs_FRONT_FRONT_INDIE_STUDIO_V3_i18n_default_en_ts" */), cache: false }],
  "zh-cn": [{ key: "../i18n/default/zh-cn.ts", load: () => import("../i18n/default/zh-cn.ts?hash=b861537b&locale=zh-cn" /* webpackChunkName: "locale__var_lib_jenkins_jobs_FRONT_FRONT_INDIE_STUDIO_V3_i18n_default_zh_cn_ts" */), cache: false }],
  "zh-tw": [{ key: "../i18n/default/zh-tw.ts", load: () => import("../i18n/default/zh-tw.ts?hash=b0d0a02e&locale=zh-tw" /* webpackChunkName: "locale__var_lib_jenkins_jobs_FRONT_FRONT_INDIE_STUDIO_V3_i18n_default_zh_tw_ts" */), cache: false }],
  "ja": [{ key: "../i18n/default/ja.ts", load: () => import("../i18n/default/ja.ts?hash=f6f312de&locale=ja" /* webpackChunkName: "locale__var_lib_jenkins_jobs_FRONT_FRONT_INDIE_STUDIO_V3_i18n_default_ja_ts" */), cache: false }],
  "ko": [{ key: "../i18n/default/ko.ts", load: () => import("../i18n/default/ko.ts?hash=ca3824c2&locale=ko" /* webpackChunkName: "locale__var_lib_jenkins_jobs_FRONT_FRONT_INDIE_STUDIO_V3_i18n_default_ko_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": true
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English (US)",
      "iso": "en-US",
      "files": [
        "i18n/default/en.ts"
      ]
    },
    {
      "code": "zh-cn",
      "name": "Chinese (Traditional) - 中文（繁體)",
      "iso": "zh-cn",
      "files": [
        "i18n/default/zh-cn.ts"
      ]
    },
    {
      "code": "zh-tw",
      "name": "中文 (繁體)",
      "iso": "zh-tw",
      "files": [
        "i18n/default/zh-tw.ts"
      ]
    },
    {
      "code": "ja",
      "name": "日本語",
      "iso": "ja",
      "files": [
        "i18n/default/ja.ts"
      ]
    },
    {
      "code": "ko",
      "name": "한국어",
      "iso": "ko-KR",
      "files": [
        "i18n/default/ko.ts"
      ]
    }
  ],
  "defaultLocale": "ko",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "./i18n/default",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English (US)",
    "iso": "en-US",
    "files": [
      {
        "path": "i18n/default/en.ts"
      }
    ]
  },
  {
    "code": "zh-cn",
    "name": "Chinese (Traditional) - 中文（繁體)",
    "iso": "zh-cn",
    "files": [
      {
        "path": "i18n/default/zh-cn.ts"
      }
    ]
  },
  {
    "code": "zh-tw",
    "name": "中文 (繁體)",
    "iso": "zh-tw",
    "files": [
      {
        "path": "i18n/default/zh-tw.ts"
      }
    ]
  },
  {
    "code": "ja",
    "name": "日本語",
    "iso": "ja",
    "files": [
      {
        "path": "i18n/default/ja.ts"
      }
    ]
  },
  {
    "code": "ko",
    "name": "한국어",
    "iso": "ko-KR",
    "files": [
      {
        "path": "i18n/default/ko.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"

import { useRequest } from '@/composables/useRequest';
import { API_SUCCESS_STATUS_CODE, API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod } from '@/enums/api.enum';
import type {
  SelectedProductHistoryResponse,
  SelectedProductListHistoryResponse
} from '@/types/selected-products-history/selected-products-history-response.type';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+selection+history+%7C+Get+list+product+selection+history
export const fetchSelectedProductListHistoryApi = async (
  groupId: string
): Promise<Array<SelectedProductHistoryResponse>> => {
  const { data } = await useRequest<SelectedProductListHistoryResponse>(
    `${apiUrl}/group/${groupId}/product/selection/history`
  );
  if (data && Array.isArray(data.list)) {
    return data.list;
  }
  return [];
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+selection+history+%7C+Update+product+selection+history
export const updateSelectedProductListHistoryApi = async (
  groupId: string,
  productNos: Array<number>
): Promise<boolean> => {
  const data = await useRequest<{ statusText: string; status: number }>(
    `${apiUrl}/group/${groupId}/product/selection/history`,
    {
      method: ApiMethod.Put,
      data: { product_nos: productNos }
    }
  );
  return data?.status === API_SUCCESS_STATUS_CODE;
};

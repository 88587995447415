import { type CookieOptions, type CookieRef, useCookie } from 'nuxt/app';

import { getRuntimeOptions } from '@/configs/runtime/runtime.config';

export const useCookieWithDomain = (
  key: string,
  options?: CookieOptions
): CookieRef<string | null | undefined> => {
  const RUN_TYPE = process.env.RUN_TYPE || 'dev';
  const { SEED_CORE } = getRuntimeOptions({ RUN_TYPE }).public.runTypeConfig;

  if (!options?.domain) {
    options = {
      domain: SEED_CORE?.DOMAIN,
      ...options
    };
  }

  return useCookie(key, options);
};

import { cloneDeep } from 'lodash';
import type { ResponseNormalization } from 'seed-core';

import type { SubscriptionTermsAndConditionModel } from '@/types/terms/terms-model.type';
import type {
  SubscriptionTermsAndConditionResponse,
  TermsResponse,
  TosSingleContent
} from '@/types/terms/terms-response.type';

export const termsMapper = (item: TosSingleContent) => {
  return {
    contentsNo: item.contentsNo,
    termsTypeNo: item.termsTypeNo,
    title: item.title,
    text: item.text,
    lang: item.lang,
    required: true,
    checked: false,
    content: '',
    subCategory: item.subCategory
  };
};

export const termsListMapper = (
  termsContent: ResponseNormalization<TermsResponse[]>
): ResponseNormalization<TermsResponse[]> => {
  const data: TermsResponse[] = termsContent.data || [];

  return { data };
};

export const subscriptionTermsAndConditionMapper = (
  res: ResponseNormalization<SubscriptionTermsAndConditionResponse>
): ResponseNormalization<SubscriptionTermsAndConditionModel> => {
  const data: SubscriptionTermsAndConditionModel = cloneDeep(
    res.data
  ) as SubscriptionTermsAndConditionModel;
  return {
    ...res,
    data
  };
};

import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import { COOKIE_KEYS } from 'seed-core';
import type { RouteLocationNormalized } from 'vue-router';

import { useCookieWithDomain } from '@/composables/useCookieWithDomain';
import { getFinialLocale } from '@/composables/useLocale';
import { DEFAULT_COVERAGES } from '@/constants/locale.const';
import { useUserStore } from '@/stores/user.store';
import type { SimpleGroupResponse } from '@/types/my-info/my-info-response.type';

const goToValidLocale = async (url: string) => {
  return await navigateTo(`/${getFinialLocale('')}${url}`, { external: true });
};

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized) => {
  let toName = to.name?.toString() ?? '';
  const { params } = to;

  if (!toName) {
    // This is the case when the route is not found or the case when user input the wrong locale
    // We need to redirect to the default locale
    const found = DEFAULT_COVERAGES.find((coverage: string) =>
      to.fullPath.startsWith(`/${coverage}/`)
    );
    if (!found) {
      return await goToValidLocale(to.fullPath);
    }
    // return;
  }

  if (!toName.includes('___default')) {
    // This is the case when the route include the locale but not the default
    // so we don't need to do anything
    return;
  }

  if (params.groupId) {
    // This is the case when the route is a group route
    // We need to check if the group is valid
    // If it is group index page, and the groupId is not valid, we need to redirect to the default locale
    const userStore = await useUserStore();
    const { groupId } = to.params;
    const { joinedGroups } = storeToRefs(userStore);
    const isGroupValid = joinedGroups.value?.find(
      (item: SimpleGroupResponse) => item.groupId === groupId
    );
    if (!isGroupValid) {
      return await goToValidLocale(to.fullPath);
    }
  }

  const cookieLocale = useCookieWithDomain(COOKIE_KEYS.LOCALE || '');
  const finalLocale = getFinialLocale(to.fullPath);
  cookieLocale.value = finalLocale.toUpperCase();

  toName = toName.replace('___default', '');

  for (const coverage of DEFAULT_COVERAGES) {
    if (toName.includes(coverage)) {
      toName = toName.replace(`___${coverage}`, `___${finalLocale}`);
      break;
    }
  }
  return await navigateTo({
    name: toName,
    query: to.query,
    params: to.params
  });
});

import type { CookieKeys } from '@/types/common/cookie.type';

export const COOKIE_KEYS: CookieKeys = {
  ACCESS_TOKEN: 'SUAT',
  REFRESH_TOKEN: 'SURT',
  SDK_ACCESS_TOKEN: 'authorization',
  SDK_REFRESH_TOKEN: 'authorizationr',
  // (구) 토큰
  HEADER: 'HD',
  PAYLOAD: 'PLD',
  SIGN: 'SIGN',
  OLD_REFRESH_TOKEN: 'RFT',

  FOREVER: 'FOREVER',
  LOCALE: 'LOCALE',
  LANGUAGE: 'PRM',
  NNTO: 'NNTO',
  COUNTRY: 'NNTO',
  TIMEZONE: 'TZ',
  UTC_OFFSET: 'TZ_OFFSET',
  COVERAGES: 'COVERAGES',
  UUID: 'sgs_da_uuid',
  ISSDK: 'ISSDK',
  REGULATION: 'REGULATION'
};
export const DEFAULT_UTC_OFFSET: number = 540;

export const DEFAULT_TIMEZONE: string = 'Asia/Seoul';

export const DEFAULT_COUNTRY: string = 'KR';

export const DEFAULT_LANGUAGE: string = 'en';

<template>
  <NuxtLoadingIndicator />

  <NuxtLayout>
    <NuxtPage />
    <AppDialogs />
    <loading :show="isLoading" />
  </NuxtLayout>
  <st-footer />
  <div class="fixed right-40 bottom-40 flex gap-12 items-center justify-center z-sticky">
    <s-top-button class="relative bottom-0 right-0" />
    <tutorial-video-guide v-if="showTutorialGuide" />
  </div>
</template>

<script lang="ts" setup>
import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';

import AppDialogs from '@/components/app/dialog/app-dialogs.vue';
import Loading from '@/components/app/loading.vue';
import StFooter from '@/components/app/st-footer.vue';
import TutorialVideoGuide from '@/components/tutorial-video/tutorial-video-guide.vue';
import { useApp } from '@/composables/useApp';
import { closeAllDialogs } from '@/composables/useDialog';
import { BODY_CLASS } from '@/constants/common.const';
import { useAppStore } from '@/stores/app.store';

const { locale } = useI18n();
const { showTutorialGuide } = useApp();

useHead({
  bodyAttrs: {
    class: BODY_CLASS
  },
  htmlAttrs: {
    lang: locale
  }
});

window.onpageshow = function(event: PageTransitionEvent) {
  if (event.persisted) {
    location.reload();
  }
};

const appStore = useAppStore();

const { isLoading } = storeToRefs(appStore);
const onBeforeNavigate = () => {
  closeAllDialogs();
};
onMounted(() => {
  window.addEventListener('popstate', onBeforeNavigate);
});
onUnmounted(() => {
  window.removeEventListener('popstate', onBeforeNavigate);
});
</script>
<style lang="scss" src="~~/assets/css/custom-gnb.scss" />

export default {
  RUN_TYPE: 'live',
  API_BASE_URL: 'https://api.onstove.com',
  STORE_URL: 'https://store.onstove.com',
  STUDIO_V2_URL: 'https://pre-studio.onstove.com',
  SEED_CORE: {
    AUTH_URL: 'https://accounts.onstove.com',
    SESSION_URL: 'https://auth.onstove.com',
    MEMBER_URL: 'https://member.onstove.com',
    DOMAIN: '.onstove.com',
    API_URL: 'https://api.onstove.com',
    ENABLE_CORS: true,
    ENABLE_GDS: true,
    CAllER_ID: 'indie-web-studio'
  },
  GNB_SCRIPT: 'https://js-cdn.onstove.com/libs/common-gnb/latest/cp-header.js',
  TRANSLATION_BASE_URL: 'https://d2x8kymwjom7h7.cloudfront.net/live/application_no/STATIC/indie',
  TRANSLATION_FOLDER_NAME: 'indie-studio.3.0',
  TRANSLATION_FILE_NAME: '{locale}.properties',
  TERM_URL: 'https://clause.onstove.com',
  FROALA_KEY: 'FSC2H-9B1A8A1B3D3D2E2sGXh1WWTDSGXYOUKc1KINLe1OC1c1D-17D2E2F2A1E4F1B2B8E7D6==',
  UPLOADER_DOWNLOAD_URL: 'https://dl.onstove.com/tools/UploadTool/v2/STOVEUploader_setup.exe',
  APPLICATION_NO: 119001,
  LOUNGE_URL: 'https://lounge.onstove.com/',
  INDIE_CHANNEL_SEQ: 11030,
  INDIE_BOARD_SEQ: 132162,
  META_DATA_SERVICE_ID: 96001,
  POLICY_STUDIO_BIRTH_URL: 'https://policy.onstove.com/studio-birth',
  DEVELOPERS_DOMAIN: 'https://developers-beta.onstove.com',
  ADMIN_DEVELOPER_API_URL: 'https://admin-developers-api.onstove.com',
  LAUNCHER_URL: 'sgup://util/login',
  STOVE_URL: 'https://www.onstove.com',
  STUDIO_URL: 'https://studio.onstove.com/',
  SENTRY_DSN: 'https://7c7aba38bdd04d12995593a9a28f6ef3@sentry.onstove.com/28',
  SENTRY_PROJECT_NAME: 'studio-v3-live'
};

<!-- TutorialVideoGuide.vue -->
<template>
  <div>
    <div
      v-if="showBlueGuide"
      class="fixed right-40 bottom-[9.2rem] min-w-[21.1rem] max-w-312 flex gap-8 items-start bg-brand-primary text-white rounded-xl px-12 py-8 z-sticky"
    >
      <div class="flex-1 flex flex-col gap-4">
        <s-text as="h4" role="text4" class="font-bold">
          {{ $t('studio.common.tutorial_video.first_entry_guide1') }}
        </s-text>
        <safe-html
          tag="p"
          class="stds-text text-cap1 leading-cap1 font-cap1"
          :html="$t('studio.common.tutorial_video.first_entry_guide2')"
        />
      </div>
      <button type="button" class="w-16 h-16" @click="closeBlueGuide">
        <s-icon srOnlyText="닫기" icon="ic-v2-control-close-line" size="xl" />
      </button>
    </div>
    <div
      v-if="isVideoOpen"
      class="fixed right-40 bottom-[10.4rem] w-[40rem] aspect-video rounded-2xl overflow-hidden z-sticky shadow-[0px_8px_16px_0px_rgba(0,0,0,0.16)]"
    >
      <video
        controls
        :src="videoUrl"
        class="w-full h-full bg-contain bg-on-surface-elevation-1"
        playsinline
        webkit-playsinline
        preload="metadata"
        controlsList="nodownload"
        x-webkit-airplay="deny"
        disablePictureInPicture
        autoplay
      ></video>
    </div>
    <button
      v-if="showBlueGuide || isVideoOpen"
      class="flex h-48 w-48 items-center justify-center rounded-full bg-surface-fab text-3xl text-on-surface-elevation-3 shadow-4 active:bg-surface-fab-pressed active:after:content-none"
      @click="toggleVideo"
    >
      <close-tutorial-icon v-if="isVideoOpen" />
      <tutorial-icon v-else color="#666666" />
    </button>
    <s-tooltip
      v-else
      arrow
      :content="$t('studio.common.tutorial_video.tooltip')"
      :duration="0"
      useFlip
      flipOnUpdate
      placement="top"
      trigger="mouseenter focus"
      :zIndex="600"
      distance="8"
    >
      <template #target>
        <button
          class="flex h-48 w-48 items-center justify-center rounded-full bg-surface-fab text-3xl text-on-surface-elevation-3 shadow-4 active:bg-surface-fab-pressed active:after:content-none"
          @click="toggleVideo"
        >
          <tutorial-icon color="#666666" />
        </button>
      </template>
    </s-tooltip>
  </div>
</template>
<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import SafeHtml from '@/components/common/safe-html.vue';
import CloseTutorialIcon from '@/components/tutorial-video/close-tutorial-icon.vue';
import TutorialIcon from '@/components/tutorial-video/tutorial-icon.vue';
import { INTRO_VIDEO_URLS } from '@/constants/videos.const';
import { LanguageCode } from '@/enums/language-code.enum';

const { locale } = useI18n();

const isVideoOpen = ref(false);
const showBlueGuide = ref(true);
const storedName = 'blue-guide-showed';

const videoUrl = computed(() => {
  return INTRO_VIDEO_URLS[locale.value] || INTRO_VIDEO_URLS[LanguageCode.Ko];
});

const toggleVideo = () => {
  closeBlueGuide();
  isVideoOpen.value = !isVideoOpen.value;
};

const closeBlueGuide = () => {
  if (!showBlueGuide.value) {
    return;
  }
  showBlueGuide.value = false;
  localStorage.setItem(storedName, 1);
};

onBeforeMount(() => {
  const storedValue = localStorage.getItem(storedName);

  if (storedValue) {
    showBlueGuide.value = false;
  }
});
</script>

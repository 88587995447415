import { useRequest } from '@/composables/useRequest';
import { API_SUCCESS_STATUS_CODE } from '@/constants/api.const';
import { DEFAULT_PAGE_SIZE_50 } from '@/constants/table.const';
import { ApiMethod } from '@/enums/api.enum';
import { type ApprovalStatus, Permission } from '@/enums/common.enum';
import { roleDetailModelMapper } from '@/mappers/member-mng.mapper';
import type { Pagination } from '@/types/common/pagination.type';
import type {
  MemberAuthorityFormModel,
  MemberInfoModel,
  MemberListModel,
  ProjectsByTeamId,
  ProjectWithMemberModel,
  RoleDetailModel
} from '@/types/member/member-model.type';
import type {
  AdvanceInvitationLinkSettingRequest,
  MemberInfoUpdateRolesRequest,
  MenuRole
} from '@/types/member/member-request.type';
import type {
  ApproveRejectMemberResponse,
  InvitationLinkResponse,
  MemberHistoryResponse,
  MemberProductRoleResponse,
  MemberSecondaryEmailResponse,
  MemberVerifyCodeResponse,
  MenuAuthResponse,
  RoleDetailResponse,
  WaitListResponse
} from '@/types/member/member-response.type';
import type { RoleType } from '@/types/role/role.request.type';
import { camelToSnake, generateHeader, stringCamelToSnake } from '@/utils/api.util';

// TODO: new indie-studio-v3
export const fetchMenuList = async (): Promise<MenuRole[]> => {
  const res = await useRequest<MenuRole[]>(
    'dev/application_no/STATIC/new-studio-mock-data/group/menu-list.json',
    {
      headers: {
        ...generateHeader(
          'X-Nation',
          'X-Timezone',
          'X-Utc-Offset',
          'X-Lang',
          'X-Client-Lang',
          'X-UUID',
          'caller-id'
        ),
        'Content-Type': 'application/json;charset=UTF-8'
      },
      baseURL: 'https://static-resource-dev.onstove.com'
    }
  );

  return res?.data ?? [];
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435542708#5d3ccbd5-07cc-43ad-bd92-44cb4ea0b33a-435543725
export const fetchMemberListApi = async (
  groupId: string,
  page: number = 1,
  options: { keyword?: string; sortKey?: string; direction?: string } = {
    keyword: '',
    sortKey: '',
    direction: ''
  }
): Promise<MemberListModel> => {
  const { keyword, sortKey, direction } = options;
  const key = sortKey ? stringCamelToSnake(sortKey ?? '').toUpperCase() : undefined;

  const { data } = await useRequest<MemberListModel>(`studio/v1/group-members/${groupId}`, {
    params: {
      page,
      q: keyword,
      sort: direction ? key : undefined,
      direction,
      size: DEFAULT_PAGE_SIZE_50
    }
  });
  if (!data) {
    throw new Error('Failed to fetch member list');
  }
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435542708#5d3ccbd5-07cc-43ad-bd92-44cb4ea0b33a-435542709
export const fetchMemberDetailApi = async (params: {
  groupId: string;
  memberId: number;
}): Promise<MemberInfoModel> => {
  const { data } = await useRequest<MemberInfoModel>(
    `studio/v1/group-members/${params.groupId}/${params.memberId}`
  );
  if (!data) {
    throw new Error('Failed to fetch member detail');
  }

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435534121#99aed61e-b96e-4334-a955-f9963a75bd90-435534255
export const fetchRoleListApi = async (
  groupId: string,
  params: {
    page?: number;
    size?: number;
  } = { page: 1, size: 10 }
): Promise<Pagination<RoleType[]> | undefined> => {
  const { page, size } = params;

  const res = await useRequest<Pagination<RoleType[]>>(`studio/v1/member-roles/${groupId}`, {
    params: { page, size }
  });

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435542708#5d3ccbd5-07cc-43ad-bd92-44cb4ea0b33a-435543606
export const kickMemberApi = async (params: {
  groupId: string;
  memberNo: string;
}): Promise<boolean | void> => {
  const res = await useRequest<boolean>(
    `studio/v1/group-members/${params.groupId}/kick/${params.memberNo}`,
    {
      method: ApiMethod.Delete,
      showCommonError: false
    }
  );

  if (!res) {
    return;
  }

  return res.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435542708#5d3ccbd5-07cc-43ad-bd92-44cb4ea0b33a-435543551
export const editMemberDetailApi = async (params: {
  groupId: string;
  memberId: string;
  data: MemberInfoUpdateRolesRequest;
}) => {
  const res = await useRequest<boolean>(
    `studio/v1/group-members/${params.groupId}/${params.memberId}`,
    {
      method: ApiMethod.Put,
      showCommonError: false,
      data: camelToSnake(params.data),
      cancelAfter: 0
    }
  );

  if (!res) {
    return;
  }

  return res.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435542708#5d3ccbd5-07cc-43ad-bd92-44cb4ea0b33a-435543780
export const fetchProjectsByTeamIdApi = async (
  groupId: string,
  page: number = 1,
  options: {
    sortKey?: string;
    direction?: string;
    q?: string;
  } = { sortKey: undefined, direction: '', q: '' }
): Promise<Pagination<ProjectsByTeamId[]>> => {
  const { sortKey, direction, q } = options;

  const { data } = await useRequest<Pagination<ProjectsByTeamId[]>>(
    `studio/v1/group-members/${groupId}/projects`,
    {
      params: {
        page,
        sort: direction ? sortKey : undefined,
        direction,
        q: q !== '' ? q : undefined,
        size: DEFAULT_PAGE_SIZE_50
      }
    }
  );
  if (!data) {
    throw new Error('Failed to fetch projects list');
  }
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435542708#5d3ccbd5-07cc-43ad-bd92-44cb4ea0b33a-435543860
export const fetchMemberDetailsByProjectApi = async (params: {
  groupId: string;
  projectId: string;
  keyword?: string;
  sortKey?: string;
  direction?: string;
}): Promise<ProjectWithMemberModel | undefined> => {
  const { keyword, sortKey, direction } = params;
  const key = sortKey ? stringCamelToSnake(sortKey ?? '').toUpperCase() : undefined;

  const res = await useRequest<ProjectWithMemberModel>(
    `studio/v1/group-members/${params.groupId}/projects/${params.projectId}`,
    {
      showCommonError: false,
      params: {
        q: keyword !== '' ? keyword : undefined,
        sort: direction ? key : undefined,
        direction
      }
    }
  );
  if (!res) {
    return;
  }

  return res.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435535743
export const fetchRoleDetailApi = async (params: {
  groupId: string;
  roleNo: number;
}): Promise<RoleDetailModel> => {
  const { data } = await useRequest<RoleDetailResponse>(
    `studio/v1/member-roles/${params.groupId}/${params.roleNo}`
  );
  if (!data) {
    throw new Error('Failed to fetch projects with role info');
  }

  return roleDetailModelMapper(data);
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435537909
export const deleteRoleApi = async (params: {
  groupId: string;
  roleId: string;
}): Promise<{ success: boolean }> => {
  const { data } = await useRequest<{ success: boolean }>(
    `studio/v1/member-roles/${params.groupId}/${params.roleId}`,
    {
      method: ApiMethod.Delete
    }
  );
  if (!data) {
    throw new Error('Failed to delete role');
  }

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435537847
export const modifyRoleApi = async (params: {
  groupId: string;
  roleId: string;
  data: MemberAuthorityFormModel;
}): Promise<void> => {
  await useRequest(`studio/v1/member-roles/${params.groupId}/${params.roleId}`, {
    data: camelToSnake(params.data),
    method: ApiMethod.Put
  });
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435534123
export const createRoleApi = async (params: {
  groupId: string;
  data: MemberAuthorityFormModel;
}) => {
  const res = await useRequest(`studio/v1/member-roles/${params.groupId}`, {
    data: camelToSnake(params.data),
    method: ApiMethod.Post
  });

  return res;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435542708#5d3ccbd5-07cc-43ad-bd92-44cb4ea0b33a-435544150
export const cancelInviteApi = async (params: {
  groupId: string;
  data: { memberNo: number; email: string };
}) => {
  await useRequest(`studio/v1/group-members/${params.groupId}/members/invitation`, {
    data: camelToSnake(params.data),
    method: ApiMethod.Delete
  });
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435542708#5d3ccbd5-07cc-43ad-bd92-44cb4ea0b33a-435544132
export const resendInviteApi = async (params: {
  groupId: string;
  data: { memberNo: number; email: string };
}) => {
  await useRequest(`studio/v1/group-members/${params.groupId}/members/retry-invitation-email`, {
    data: camelToSnake(params.data),
    method: ApiMethod.Post
  });
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=427834849
export const signUpMemberApi = async (email: string) => {
  await useRequest('studio/v1/members', {
    data: { email },
    method: ApiMethod.Post
  });
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435544066
export const fetchInvitationLinkApi = async (
  groupId: string
): Promise<InvitationLinkResponse | undefined> => {
  const res = await useRequest<InvitationLinkResponse>(
    `studio/v1/group-members/${groupId}/invitation-link`
  );

  if (!res) {
    return;
  }

  return res.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=449995730
export const regenerateInvitationApi = async ({
  groupId,
  invitationLinkId
}: {
  groupId: string;
  invitationLinkId: string;
}): Promise<InvitationLinkResponse | undefined> => {
  const res = await useRequest<InvitationLinkResponse>(
    `studio/v1/group-members/${groupId}/invitation-link/${invitationLinkId}`,
    { method: ApiMethod.Put }
  );

  if (!res) {
    return;
  }

  return res.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=449995733
export const applyInvitationApi = async ({
  groupId,
  invitationLinkId,
  data
}: {
  groupId: string;
  invitationLinkId: string;
  data: AdvanceInvitationLinkSettingRequest;
}): Promise<InvitationLinkResponse | undefined> => {
  const res = await useRequest<InvitationLinkResponse>(
    `studio/v1/group-members/${groupId}/invitation-link/${invitationLinkId}/advanced-configuration`,
    { method: ApiMethod.Put, data: camelToSnake(data) }
  );

  if (!res) {
    return;
  }

  return res.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=449995746
export const fetchWaitListApi = async (
  groupId: string,
  options: {
    page?: number;
  } = { page: 1 }
): Promise<Pagination<WaitListResponse[]> | undefined> => {
  const res = await useRequest<Pagination<WaitListResponse[]>>(
    `studio/v1/group-members/${groupId}/applied`,
    {
      method: ApiMethod.Get,
      params: { page: options.page, size: DEFAULT_PAGE_SIZE_50 },
      showCommonError: false
    }
  );

  return res?.data;
};

export const fetchMenuAuthApi = async (params: {
  groupId: string;
  menuAuthorityId: string;
  isOwner: boolean;
}): Promise<{
  isReadable: boolean;
  isWritable: boolean;
}> => {
  if (params.isOwner) {
    return {
      isReadable: true,
      isWritable: true
    };
  }
  const { data } = await useRequest<MenuAuthResponse>(
    `studio/v1/group-members/${params.groupId}/menu-auth`,
    {
      method: ApiMethod.Get,
      params: camelToSnake({ menuAuthorityId: params.menuAuthorityId }),
      showCommonError: false,
      showLoading: false
    }
  );
  if (data) {
    return {
      isReadable: data.permission.includes(Permission.READ),
      isWritable: data.permission.includes(Permission.WRITE)
    };
  }
  return {
    isReadable: false,
    isWritable: false
  };
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435544173
export const approveRejectMemberRequestApi = async ({
  groupId,
  groupMemberNos,
  requestType
}: {
  groupId: string;
  groupMemberNos: number[];
  requestType: ApprovalStatus;
}): Promise<ApproveRejectMemberResponse | undefined> => {
  const res = await useRequest<ApproveRejectMemberResponse>(
    `studio/v1/group-members/${groupId}/approve-reject`,
    { method: ApiMethod.Post, data: camelToSnake({ groupMemberNos, requestType }) }
  );

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435544073
export const fetchMemberHistoryApi = async (
  groupId: string,
  options: {
    page?: number;
    sortKey?: string;
    nicknameQuery?: string;
    historyTypes?: string;
  } = { page: 1, sortKey: undefined, nicknameQuery: undefined, historyTypes: '' }
): Promise<Pagination<MemberHistoryResponse[]> | undefined> => {
  const res = await useRequest<Pagination<MemberHistoryResponse[]>>(
    `studio/v1/group-members/${groupId}/history`,
    {
      method: ApiMethod.Get,
      params: {
        created_at: options.sortKey,
        page: options.page,
        nickname_query: options.nicknameQuery !== '' ? options.nicknameQuery : undefined,
        history_types: options.historyTypes,
        size: DEFAULT_PAGE_SIZE_50
      }
    }
  );

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=375426966
export const sendVerifyCodeToMemberSecondaryEmailApi = async (
  email: string
): Promise<MemberVerifyCodeResponse | undefined> => {
  const res = await useRequest<MemberVerifyCodeResponse>('auth-secure/v1.0/email/session', {
    method: ApiMethod.Post,
    data: camelToSnake({
      verifyType: 'CODE',
      useType: 'EMAIL_CONTACT_CREATE',
      email
    }),
    headers: {
      ...generateHeader('caller-id'),
      'Content-Type': 'application/json'
    }
  });

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=376916479
export const verifyCodeFromMemberSecondaryEmailApi = async ({
  session,
  code
}: {
  session: string;
  code: string;
}): Promise<MemberVerifyCodeResponse | undefined> => {
  const res = await useRequest<MemberVerifyCodeResponse>('auth-secure/v1.0/email/session/verify', {
    method: ApiMethod.Post,
    data: camelToSnake({
      verifyType: 'CODE',
      verifyData: {
        code
      },
      session
    }),
    headers: {
      ...generateHeader('caller-id'),
      'Content-Type': 'application/json'
    }
  });

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=411554267
export const registerMemberSecondaryEmailBySessionApi = async (
  session: string
): Promise<boolean> => {
  const res = await useRequest<{ statusText: string; status: number }>(
    'auth-secure/v1.0/contacts/email',
    {
      method: ApiMethod.Post,
      data: {
        session
      },
      headers: {
        ...generateHeader('caller-id'),
        'Content-Type': 'application/json'
      }
    }
  );

  return res?.status === API_SUCCESS_STATUS_CODE;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=411554267
export const getMemberSecondaryEmailApi = async (): Promise<
  MemberSecondaryEmailResponse | undefined
> => {
  const res = await useRequest<MemberSecondaryEmailResponse>('auth-secure/v1.0/contacts/email', {
    method: ApiMethod.Get,
    headers: {
      ...generateHeader('caller-id'),
      'Content-Type': 'application/json'
    }
  });

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435544027
export const getMemberProductRoleApi = async (params: {
  groupId: string;
  productNo: number;
}): Promise<MemberProductRoleResponse | undefined> => {
  const res = await useRequest<MemberProductRoleResponse>(
    `studio/v1/group-members/${params.groupId}/product-role/${params.productNo}`,
    { method: ApiMethod.Get, showCommonError: false }
  );

  return res?.data;
};

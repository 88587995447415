import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { fetchProductsRelationApi } from '@/apis/products.api';
import {
  fetchSelectedProductListHistoryApi,
  updateSelectedProductListHistoryApi
} from '@/apis/selected-products-history.api';
import { getFinialLocale } from '@/composables/useLocale';
import { useReactiveLocalStorage } from '@/composables/useReactiveLocalStorage';
import { MAX_PRODUCTS_ON_LNB } from '@/constants/common.const';
import type { Confirmation } from '@/enums/common.enum';
import { useUserStore } from '@/stores/user.store';
import type { Language } from '@/types/common/common.type';
import type { ProjectProductRelation } from '@/types/product/product-response.type';
import type { SelectedProductHistory } from '@/types/selected-products-history/selected-products-history-model.type';
import type { SelectedProductHistoryResponse } from '@/types/selected-products-history/selected-products-history-response.type';
import { type GDSInfo, getGDSInfo } from '@/utils/gds.util';
import { getProductDetailTypeLabel } from '@/utils/product.util';

export const useAppStore = defineStore('appStore', () => {
  const { storage, setLocalStorage, removeLocalStorage } = useReactiveLocalStorage();
  const isProcessingState = ref<boolean>(false);
  const hasApiError = ref<boolean>(false);

  const userStore = useUserStore();

  const { selectedGroupId } = storeToRefs(userStore);

  const projectProductItems = ref<ProjectProductRelation[]>();

  const gdsInfo = ref<GDSInfo>({
    nation: '',
    timezone: '',
    utcOffset: '',
    lang: '',
    locale: '',
    uuid: ''
  });
  const languages = ref<Language[]>([]);
  const loadingApis = ref<number[]>([]);
  const isFirstEntryHome = ref<boolean>(false);
  const isMenuWritable = ref<boolean>(true);
  const lnbSelectedProductListHistory = ref<SelectedProductHistory[]>([]);
  const isAlreadyFetchSelectedProductListHistory = ref<boolean>(false);
  const isGroupAlreadyAddedToHistory = ref<boolean>(false);

  const startLoading = (apiId: number) => {
    loadingApis.value.push(apiId);
  };

  const endLoading = (apiId: number) => {
    loadingApis.value.splice(loadingApis.value.indexOf(apiId), 1);
  };

  const isLoading = computed(() => {
    return loadingApis.value.length > 0;
  });

  const isProcessing = computed(() => {
    return isProcessingState.value;
  });

  const addProductToSelectedProductListHistory = (product: SelectedProductHistory) => {
    const lnbProductList = lnbSelectedProductListHistory.value;
    let newLnbProductList: SelectedProductHistory[] = [];
    if (lnbProductList.length) {
      newLnbProductList = lnbProductList.filter(
        (item: SelectedProductHistory) => item.productNo !== product.productNo
      );
    }
    newLnbProductList.unshift(product);
    if (newLnbProductList.length > MAX_PRODUCTS_ON_LNB) {
      newLnbProductList.pop();
    }
    lnbSelectedProductListHistory.value = newLnbProductList;
    updateSelectedProductListHistoryApi(product.groupId, [product.productNo]);
  };

  const addProductsToSelectedProductListHistory = (
    groupId: string,
    products: Array<SelectedProductHistory>
  ) => {
    const lnbProductList = [...products.reverse(), ...lnbSelectedProductListHistory.value].slice(
      0,
      MAX_PRODUCTS_ON_LNB
    );
    lnbSelectedProductListHistory.value = lnbProductList;
    const productNos = products.map((product: SelectedProductHistory) => product.productNo);
    updateSelectedProductListHistoryApi(groupId, productNos);
  };

  const fetchSelectedProductListHistory = async (groupId: string) => {
    const selectedProductListHistoryResponse: Array<SelectedProductHistoryResponse> =
      await fetchSelectedProductListHistoryApi(groupId);
    let lnbProductList: Array<SelectedProductHistory> = [];
    if (selectedProductListHistoryResponse.length) {
      lnbProductList = selectedProductListHistoryResponse.map(
        (item: SelectedProductHistoryResponse) => ({
          productName: item.productName,
          productNo: item.productNo,
          projectId: item.projectId,
          groupId: item.groupId,
          productType: item.productType,
          productDetailType: item.productDetailType,
          productDetailLabel: getProductDetailTypeLabel(
            item.productType,
            item.demoYn as Confirmation,
            item.productDetailType
          )
        })
      );
    }
    lnbSelectedProductListHistory.value = lnbProductList;
    isAlreadyFetchSelectedProductListHistory.value = true;
  };

  const deleteProductFromSelectedProductListHistory = (productNo: number) => {
    const lnbProductList = lnbSelectedProductListHistory.value;
    const newLnbProductList = lnbProductList.filter(
      (item: SelectedProductHistory) => item.productNo !== productNo
    );
    lnbSelectedProductListHistory.value = newLnbProductList;
  };

  const resetSelectedProductListHistory = () => {
    lnbSelectedProductListHistory.value = [];
  };

  const setAlreadyFetchSelectedProductListHistoryOrNot = (isAlreadyFetch: boolean) => {
    isAlreadyFetchSelectedProductListHistory.value = isAlreadyFetch;
  };

  const updateProductFromSelectedProductListHistory = (
    productNo: number,
    productName: string,
    demoYn: Confirmation,
    productDetailType: string
  ) => {
    const product = lnbSelectedProductListHistory.value.find(
      (item: SelectedProductHistory) => item.productNo === productNo
    );
    if (product) {
      product.productName = productName;
      product.productDetailType = productDetailType;
      product.productDetailLabel = getProductDetailTypeLabel(
        product.productType,
        demoYn,
        productDetailType
      );
      lnbSelectedProductListHistory.value = [...lnbSelectedProductListHistory.value];
    }
  };

  const isSelectedProductHistoryOnTop = (productNo: number): boolean => {
    if (lnbSelectedProductListHistory.value.length === 0) {
      return false;
    }
    return lnbSelectedProductListHistory.value[0].productNo === productNo;
  };

  const setGroupAddedToHistoryOrNot = (isAdded: boolean) => {
    isGroupAlreadyAddedToHistory.value = isAdded;
  };
  const fetchLanguages = async () => {
    languages.value = [
      {
        id: '1',
        name: '영어',
        value: 'en'
      },
      {
        id: '2',
        name: '한국어',
        value: 'ko'
      },
      {
        id: '3',
        name: '일본어',
        value: 'ja'
      },
      {
        id: '4',
        name: '중국어(간체)',
        value: 'zh-CN'
      },
      {
        id: '5',
        name: '중국어(번체)',
        value: 'zh-TW'
      }
    ];
  };

  const fetchGDSInfo = () => {
    const info = getGDSInfo();
    const finalLocale = getFinialLocale(window.location.pathname);
    gdsInfo.value = {
      ...info,
      lang: finalLocale,
      locale: finalLocale
    };
    return info;
  };

  const fetchProjectProductItems = async () => {
    projectProductItems.value = await fetchProductsRelationApi(selectedGroupId.value);
  };

  return {
    languages,
    fetchLanguages,
    isLoading,
    isFirstEntryHome,
    gdsInfo,
    fetchGDSInfo,
    isProcessing,
    loadingApis,
    startLoading,
    endLoading,
    storage,
    setLocalStorage,
    removeLocalStorage,
    isMenuWritable,
    lnbSelectedProductListHistory,
    addProductToSelectedProductListHistory,
    addProductsToSelectedProductListHistory,
    fetchSelectedProductListHistory,
    deleteProductFromSelectedProductListHistory,
    updateProductFromSelectedProductListHistory,
    isSelectedProductHistoryOnTop,
    hasApiError,
    isAlreadyFetchSelectedProductListHistory,
    setGroupAddedToHistoryOrNot,
    resetSelectedProductListHistory,
    setAlreadyFetchSelectedProductListHistoryOrNot,
    isGroupAlreadyAddedToHistory,
    fetchProjectProductItems,
    projectProductItems
  };
});

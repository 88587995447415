import { useAsyncData } from 'nuxt/app';
import type { _Transform } from 'nuxt/dist/app/composables/asyncData';
import type { ResponseNormalization } from 'seed-core';

import { useSeedCore } from '@/composables/useSeedCore';
import { DEFAULT_API_TIMEOUT } from '@/constants/common.const';
import {
  COMMON_ERROR_GROUP_TRANSFER_IN_PROGRESS_MESSAGE_KEY,
  COMMON_ERROR_MESSAGE_KEY,
  COMMON_ERROR_NO_PERMISSION,
  COMMON_ERROR_PROJECT_TRANSFER_IN_PROGRESS_MESSAGE_KEY,
  STATUS_CODE,
  UNKNOWNS_ERROR
} from '@/constants/error.const';
import { REDIRECT_TO_GROUP_HOME } from '@/constants/url.const';
import { useAppStore } from '@/stores/app.store';
import type { ErrorResponse, UseRequestModel } from '@/types/common/common.type';
import { authHeader, snakeToCamel } from '@/utils/api.util';
import { getConfigs, showCommonErrorDialog } from '@/utils/common.util';
import { goToLoginPage, logout } from '@/utils/user.utils';
import { generateRandomNumber } from '@/utils/uuid.util';

export class HttpError extends Error implements ErrorResponse {
  statusCode: number;
  data: any;

  constructor(message: string = '', statusCode: number, data: any) {
    super(message);
    this.statusCode = statusCode;
    this.data = data;
  }
}

export const useRequest = async <T, K = never>(
  url: string,
  options: UseRequestModel = {},
  mapperFn?: _Transform<ResponseNormalization<K>, ResponseNormalization<T>>
): Promise<ResponseNormalization<T>> => {
  const appStore = useAppStore();
  const apiId = generateRandomNumber();
  const controller = new AbortController();
  const { axiosService } = useSeedCore();
  const { request } = axiosService;
  const { API_BASE_URL } = getConfigs();
  const {
    showCommonError = true,
    showLoading = true,
    // milliseconds
    cancelAfter = DEFAULT_API_TIMEOUT,
    showTransferInProgressError = true,
    toCamelCase = true,
    ...opts
  } = authHeader(options);

  if (showLoading) {
    appStore.startLoading(apiId);
  }

  // only cancel if cancelAfter > 0
  if (cancelAfter) {
    setTimeout(async () => {
      if (appStore.loadingApis.includes(apiId)) {
        appStore.endLoading(apiId);
        controller.abort();
      }
    }, cancelAfter);
  }

  const apiUrl = `${opts.baseURL ?? API_BASE_URL}/${url}`;

  const { data, error } = await useAsyncData(
    `${apiUrl}?_t=${generateRandomNumber()}`,
    async () => {
      const { data: res, status } = await request<T>(apiUrl, {
        ...opts,
        signal: controller.signal
      });
      const hasError =
        Object.values(STATUS_CODE).includes(Number(res?.status)) ||
        (status !== 200 && res?.status !== 0);
      if (hasError) {
        throw new HttpError(res?.statusText, res?.status as number, res);
      }
      if (res?.option?.responseType === 'blob') {
        return res;
      }
      return toCamelCase ? snakeToCamel(res) : res;
    },
    { ...(mapperFn && { transform: mapperFn }) }
  );

  if (showLoading) {
    appStore.endLoading(apiId);
  }

  if (error.value) {
    const myError: any = error.value;
    if (myError?.statusCode === UNKNOWNS_ERROR) {
      logout();
      goToLoginPage(REDIRECT_TO_GROUP_HOME);
    }
    let errorMsg = '';
    let errorCode = 0;
    if (myError?.message) {
      errorMsg = `[api issue] ${myError?.message} - status code ${myError?.cause?.statusCode}`;
      errorCode = myError?.cause?.statusCode as number;
    }
    if (myError?.statusMessage) {
      errorMsg = myError?.statusMessage;
      errorCode = myError?.statusCode as number;
    }
    switch (errorCode) {
      case STATUS_CODE.GROUP_MIGRATION_ALREADY_PROGRESS:
        if (showTransferInProgressError) {
          await showCommonErrorDialog(COMMON_ERROR_GROUP_TRANSFER_IN_PROGRESS_MESSAGE_KEY);
        }
        break;
      case STATUS_CODE.PROJECT_MIGRATION_ALREADY_PROGRESS:
        if (showTransferInProgressError) {
          await showCommonErrorDialog(COMMON_ERROR_PROJECT_TRANSFER_IN_PROGRESS_MESSAGE_KEY);
        }
        break;
      case STATUS_CODE.READ_WRITE_ACCESS_DENIED:
        await showCommonErrorDialog(COMMON_ERROR_NO_PERMISSION);
        break;
      case STATUS_CODE.ACCESS_DENIED:
        await showCommonErrorDialog(COMMON_ERROR_NO_PERMISSION);
        break;
      default:
        if (showCommonError) {
          await showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
        }
        break;
    }
    // if (showCommonError) {
    //   await showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
    // }
    const data = myError.data?.data;
    throw new HttpError(errorMsg, errorCode, data);
  }
  if (data.value?.option?.responseType === 'blob') {
    return data.value;
  }

  return toCamelCase ? snakeToCamel(data.value) : data.value;
};

import { COLLECTION_MORE_OPTIONS, SCHEDULE_STATUS } from '@/constants/collection.const';
import {
  DELETE_REQUEST_STATUS,
  PLAN_STATUS,
  PRODUCT_TYPE,
  PRODUCT_TYPE_DETAIL,
  RESTRICT_STATUS,
  SALE_STATUS,
  VERIFY_STATUS
} from '@/constants/products.const';
import { Confirmation } from '@/enums/common.enum';
import { productDetailTypeMapper } from '@/mappers/products.mapper';
import type { SettingLanguage } from '@/types/common/common.type';

export const getProductReleaseStatusLabel = (
  planStatus?: string,
  saleStatus?: string,
  verifyStatus?: string,
  displayYn?: string,
  deleteRequestStatus?: string
): string => {
  if (
    deleteRequestStatus === DELETE_REQUEST_STATUS.REQUEST ||
    deleteRequestStatus === DELETE_REQUEST_STATUS.START
  ) {
    return 'studio.prj_prod.this_prod.stt_del_req_completed';
  }

  if (deleteRequestStatus === DELETE_REQUEST_STATUS.REJECT) {
    return 'studio.prj_prod.this_prod.stt_del_req_rejected';
  }

  if (displayYn === Confirmation.NO) {
    return 'studio.prj_prod.this_prod.stt_product_page_withdrawn';
  }

  if (planStatus === PLAN_STATUS.READY) {
    if (!deleteRequestStatus || deleteRequestStatus === DELETE_REQUEST_STATUS.NONE) {
      return 'studio.prj_prod.this_prod.stt_product_page_upcoming';
    }
  } else if (planStatus === PLAN_STATUS.PAGE_OPEN) {
    if (!deleteRequestStatus || deleteRequestStatus === DELETE_REQUEST_STATUS.NONE) {
      if (displayYn === Confirmation.YES) {
        return 'studio.prj_prod.this_prod.stt_product_page_released';
      }
    }
  } else if (planStatus === PLAN_STATUS.PRE_PURCHASE) {
    if (!deleteRequestStatus || deleteRequestStatus === DELETE_REQUEST_STATUS.NONE) {
      if (saleStatus === SALE_STATUS.START) {
        return 'studio.prj_prod.this_prod.stt_pre_order_period';
      } else if (saleStatus === SALE_STATUS.STOP) {
        return 'studio.prj_prod.this_prod.stt_release_sale_suspended';
      }
    }
  } else if (planStatus === PLAN_STATUS.RELEASE) {
    if (!deleteRequestStatus || deleteRequestStatus === DELETE_REQUEST_STATUS.NONE) {
      if (saleStatus === SALE_STATUS.START) {
        return 'studio.prj_prod.this_prod.stt_official_released';
      } else if (saleStatus === SALE_STATUS.STOP) {
        return 'studio.prj_prod.this_prod.stt_release_sale_suspended';
      }
    }
  }

  if (verifyStatus === VERIFY_STATUS.REQUEST || verifyStatus === VERIFY_STATUS.START) {
    return 'studio.prj_prod.status_in_review';
  }

  if (verifyStatus === VERIFY_STATUS.PASS) {
    return 'studio.prj_prod.status_review_completed';
  }

  return '-';
};

export const isEmptyTranslated = (translatedLanguages: SettingLanguage[]): boolean => {
  const keys = Object.keys(translatedLanguages[0]);
  for (const lang of translatedLanguages) {
    for (const key of keys) {
      if (key === 'langCode' || key === 'default') {
        continue;
      }
      if (lang[key] !== '') {
        return false;
      }
    }
  }

  return true;
};

export const isNotTranslated = (translatedLanguages: SettingLanguage[]): boolean => {
  const keys = Object.keys(translatedLanguages[0]);
  for (const lang of translatedLanguages) {
    for (const key of keys) {
      if (key === 'langCode' || key === 'default' || key === 'productName') {
        continue;
      }
      if (lang[key] === '') {
        return true;
      }
    }
  }

  return false;
};

export const getDisplaySanctionText = (
  restrictStatus?: string,
  options?: { isGuideManagementPage: boolean }
): string => {
  switch (restrictStatus) {
    case RESTRICT_STATUS.RUN:
      return 'studio.prj_prod.this_prod.stt_restriction_install'; // 실행 제재
    case RESTRICT_STATUS.DISPLAY:
      if (options?.isGuideManagementPage) {
        return 'studio.prj_prod.this_prod.stt_restriction_display_operator'; // 전시 제재(운영자)
      }
      return 'studio.prj_prod.this_prod.stt_restriction_display_operator_stove'; // 전시 제재
    case RESTRICT_STATUS.REPORT:
      return 'studio.prj_prod.this_prod.stt_restriction_display_user_report'; // 전시 제재(유저신고)
    case RESTRICT_STATUS.PURCHASE:
      return 'studio.prj_prod.this_prod.stt_restriction_for_sales'; // 구매 제재
    case RESTRICT_STATUS.COLLECTION_PURCHASE:
      return 'studio.prj_prod.this_prod.stt_restriction_for_collection'; // 컬렉션 구매 제재
    case RESTRICT_STATUS.RATING_DISPLAY:
    case RESTRICT_STATUS.RATING_RUN:
      return 'studio.prj_prod_mngmt.review_restricted_case'; // 심의 제재
    default:
      return '';
  }
};

export const getCollectionStatusAndOptions = (
  planStatus?: string,
  saleStatus?: string,
  // restrictStatus?: string,
  displayYn?: string,
  deleteRequestStatus?: string,
  scheduleStatus?: string,
  pageSuspendYn?: string
): {
  label: string;
  options: string[];
} => {
  if (
    deleteRequestStatus === DELETE_REQUEST_STATUS.REQUEST ||
    deleteRequestStatus === DELETE_REQUEST_STATUS.START
  ) {
    return {
      label: 'studio.prj_prod.this_prod.stt_del_req_completed',
      options: [COLLECTION_MORE_OPTIONS.CANCEL_DELETE_REQUEST]
    };
  }

  if (planStatus === PLAN_STATUS.READY) {
    return {
      label: 'studio.prj_prod.this_prod.stt_product_page_upcoming',
      options: [COLLECTION_MORE_OPTIONS.DELETE]
    };
  }

  if (planStatus === PLAN_STATUS.PAGE_OPEN) {
    if (displayYn === Confirmation.NO) {
      return {
        label: 'studio.prj_prod.this_prod.stt_product_page_withdrawn',
        options: [COLLECTION_MORE_OPTIONS.DELETE_REQUEST, COLLECTION_MORE_OPTIONS.RESUME_OPEN]
      };
    }
    if (displayYn === Confirmation.YES) {
      return {
        label: 'studio.prj_prod.this_prod.stt_product_page_released',
        options: [
          COLLECTION_MORE_OPTIONS.OFF_OPEN,
          COLLECTION_MORE_OPTIONS.DELETE_REQUEST,
          COLLECTION_MORE_OPTIONS.GO_TO_STORE
        ]
      };
    }

    if (scheduleStatus === SCHEDULE_STATUS.END) {
      return {
        label: 'studio.prj_prod.this_prod.stt_product_page_withdrawn',
        options: [COLLECTION_MORE_OPTIONS.OFF_OPEN, COLLECTION_MORE_OPTIONS.RESUME_DISPLAY]
      };
    }
  }
  if (planStatus === PLAN_STATUS.NONE) {
    return {
      label: '-',
      options: [COLLECTION_MORE_OPTIONS.HIDE]
    };
  }
  if (planStatus === PLAN_STATUS.PRE_PURCHASE || planStatus === PLAN_STATUS.RELEASE) {
    if (saleStatus === SALE_STATUS.END || saleStatus === SALE_STATUS.STOP) {
      return {
        label: 'studio.prj_prod.this_prod.stt_release_sale_suspended',
        options: [COLLECTION_MORE_OPTIONS.RESUME_SELL, COLLECTION_MORE_OPTIONS.DELETE_REQUEST]
      };
    }
    return {
      label:
        planStatus === PLAN_STATUS.PRE_PURCHASE
          ? 'studio.prj_prod.this_prod.stt_pre_order_period'
          : 'studio.prj_prod.this_prod.stt_official_released',
      options:
        pageSuspendYn === Confirmation.NO
          ? [
            COLLECTION_MORE_OPTIONS.OFF_SELL,
            COLLECTION_MORE_OPTIONS.OFF_DISPLAY,
            COLLECTION_MORE_OPTIONS.DELETE_REQUEST,
            COLLECTION_MORE_OPTIONS.GO_TO_STORE
          ]
          : [
            COLLECTION_MORE_OPTIONS.RESUME_DISPLAY,
            COLLECTION_MORE_OPTIONS.OFF_SELL,
            COLLECTION_MORE_OPTIONS.DELETE_REQUEST
          ]
    };
  }

  return {
    label: '-',
    options: []
  };
};

export const getProductDetailTypeLabel = (
  productType: string,
  demoYn: Confirmation,
  productDetailType: string
): string => {
  const currentProductDetailType = productDetailTypeMapper(productType, demoYn, productDetailType);
  switch (currentProductDetailType) {
    case PRODUCT_TYPE_DETAIL.GAME_BASIC:
      return PRODUCT_TYPE.GAME;
    case PRODUCT_TYPE_DETAIL.GAME_DEMO:
      return PRODUCT_TYPE.DEMO;
    case PRODUCT_TYPE_DETAIL.GAME_DLC:
      return PRODUCT_TYPE.DLC;
    case PRODUCT_TYPE_DETAIL.UTILITY_BASIC:
      return PRODUCT_TYPE.UTILITY;
    case PRODUCT_TYPE_DETAIL.UTILITY_DEMO:
      return PRODUCT_TYPE.DEMO;
    case PRODUCT_TYPE_DETAIL.UTILITY_DLC:
      return PRODUCT_TYPE.DLC;
    case PRODUCT_TYPE_DETAIL.CONTENT_BASIC:
      return PRODUCT_TYPE.CONTENT;
    case PRODUCT_TYPE_DETAIL.CONTENT_DEMO:
      return PRODUCT_TYPE.DEMO;
    default:
      return '';
  }
};

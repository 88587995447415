import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

import { fetchTermsAgreedByUserApi } from '@/apis/my-info.api';
import { fetchRemainingTermsApi, fetchSubscriptionTermsAndConditionApi } from '@/apis/terms.api';
import { PLATFORM, SERVICE_ID, VIEW_AREA } from '@/constants/common.const';
import { Confirmation } from '@/enums/common.enum';
import { useAppStore } from '@/stores/app.store';
import type { AgreedTermResponse } from '@/types/my-info/my-info-response.type';
import type { ContentModel } from '@/types/terms/terms-model.type';
import type { ServiceRemainingTermsResponse } from '@/types/terms/terms-response.type';

export const useTermsStore = defineStore('termsStore', () => {
  const { gdsInfo } = storeToRefs(useAppStore());

  const isNotAgreeAllTerms = ref<boolean>(false);
  const remainingStudioTermNos = ref<number[]>([]);
  const firstSignUpNation = ref<string>(gdsInfo.value.nation);
  const agreedTerms = ref<AgreedTermResponse[]>([]);
  const remainingStudioTerms = ref<ContentModel[]>([]);

  const fetchAgreedTerms = async () => {
    const result = await fetchTermsAgreedByUserApi({
      serviceId: SERVICE_ID.INDIEGAME,
      viewareaId: VIEW_AREA.STDJOIN
    });
    if (!result || result.length === 0) {
      return;
    }
    agreedTerms.value = result;
    if (agreedTerms.value[0].nation) {
      firstSignUpNation.value = agreedTerms.value[0].nation;
    }
  };

  const fetchRemainingStudioTermNos = async () => {
    const remaining = await fetchRemainingTermsApi({
      serviceId: SERVICE_ID.INDIEGAME,
      viewareaId: VIEW_AREA.STDJOIN,
      nation: firstSignUpNation.value
    });
    if (!remaining) {
      return;
    }
    remainingStudioTermNos.value =
      remaining?.list?.find(
        (item: ServiceRemainingTermsResponse) => item.serviceId === SERVICE_ID.INDIEGAME
      )?.disagreeTermsNos ?? [];
    isNotAgreeAllTerms.value = remainingStudioTermNos.value.length > 0;
  };

  const fetchRemainingStudioTerms = async (lang: string) => {
    if (remainingStudioTermNos.value.length === 0) {
      return;
    }
    const result = await fetchSubscriptionTermsAndConditionApi({
      serviceId: SERVICE_ID.INDIEGAME,
      viewareaId: VIEW_AREA.STDJOIN,
      nation: firstSignUpNation.value,
      lang,
      textYn: Confirmation.YES,
      textFormat: PLATFORM.PC
    });
    if (!result || !result.serviceInfos || !result.serviceInfos[0].contentsList) {
      return;
    }
    remainingStudioTerms.value = result.serviceInfos[0].contentsList.filter((item: ContentModel) =>
      remainingStudioTermNos.value?.includes(item.termsTypeNo)
    );
  };

  const setIsNotAgreeAllTerms = (value: boolean) => {
    isNotAgreeAllTerms.value = value;
  };

  return {
    isNotAgreeAllTerms,
    remainingStudioTermNos,
    remainingStudioTerms,
    agreedTerms,
    firstSignUpNation,
    fetchAgreedTerms,
    fetchRemainingStudioTerms,
    fetchRemainingStudioTermNos,
    setIsNotAgreeAllTerms
  };
});

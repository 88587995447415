export const COLLECTION_STATUS = {
  ALL: 'ALL',
  PAGE_RELATED: 'PAGE_RELATED',
  RELEASED: 'RELEASED',
  NOT_AVAILABLE: 'NOT_AVAILABLE'
} as const;

export const COLLECTION_MORE_OPTIONS = {
  DELETE_REQUEST: 'DELETE_REQUEST',
  CANCEL_DELETE_REQUEST: 'CANCEL_DELETE_REQUEST',
  OFF_DISPLAY: 'OFF_DISPLAY',
  OFF_OPEN: 'OFF_OPEN',
  OFF_SELL: 'OFF_SELL',
  GO_TO_STORE: 'GO_TO_STORE',
  RESUME_DISPLAY: 'RESUME_DISPLAY',
  RESUME_SELL: 'RESUME_SELL',
  RESUME_OPEN: 'RESUME_OPEN',
  DELETE: 'DELETE',
  HIDE: 'HIDE'
};

export const SCHEDULE_TYPE = {
  PAGE_OPEN: 'PAGE_OPEN',
  PRE_PURCHASE: 'PRE_PURCHASE',
  EARLY_ACCESS: 'EARLY_ACCESS',
  RELEASE: 'RELEASE',
  PAGE: 'PAGE',
  DISCOUNT: 'DISCOUNT',
  BUILD: 'BUILD'
};

export const SCHEDULE_STATUS = {
  NONE: 'NONE',
  READY: 'READY',
  SKIP: 'SKIP',
  LIVE: 'LIVE',
  END: 'END'
};

import type { ResponseNormalization } from 'seed-core';

import { INVITE_STATE } from '@/constants/my-info.const';
import { Confirmation, MenuAuthorityIds, Permission } from '@/enums/common.enum';
import type { MenuRole } from '@/types/member/member-request.type';
import type {
  GroupDetailModel,
  GroupModel,
  MyInformationModel
} from '@/types/my-info/my-info-model.type';
import type {
  GroupAuthResponse,
  GroupDetailResponse,
  GroupResponse,
  MyInformationResponse
} from '@/types/my-info/my-info-response.type';
import type {
  ProductModel,
  ProductsProjectModel,
  ProjectModel
} from '@/types/project/project-model.type';
import type {
  ProductResponse,
  ProductsProjectResponse,
  ProjectResponse
} from '@/types/project/project-response.type';

const reorderMenu = (menu: GroupAuthResponse[]) => {
  const orderedMenu = [
    MenuAuthorityIds.GROUP_HOME,
    MenuAuthorityIds.PROJECT_PRODUCT,
    MenuAuthorityIds.COLLECTION,
    MenuAuthorityIds.STORE_COMMUNITY,
    MenuAuthorityIds.MEMBER_MANAGEMENT,
    MenuAuthorityIds.STATISTICS,
    MenuAuthorityIds.BILL_SETTLEMENT,
    MenuAuthorityIds.BIMS_BANK_SETTING,
    MenuAuthorityIds.GAME_MAINTENANCE
  ] as string[];

  return menu.sort((a: GroupAuthResponse, b: GroupAuthResponse) => {
    return orderedMenu.indexOf(a.authorityId) - orderedMenu.indexOf(b.authorityId);
  });
};

export const groupDetailMapper = (
  res: ResponseNormalization<GroupDetailResponse>
): ResponseNormalization<GroupDetailModel> => {
  const groupAuth = res.data?.groupAuth ?? [];
  const data: GroupDetailModel = {
    name: res.data?.groupInfo.groupName ?? '',
    category: '',
    date:
      res.data?.groupInfo.inviteStatus === INVITE_STATE.APPLY
        ? res.data?.groupInfo.invitedAt ?? 0
        : res.data?.groupInfo.joinedAt ?? 0,
    state: res.data?.groupInfo.inviteStatus ?? '',
    ownerYn: res.data?.groupInfo.ownerYn === Confirmation.YES,
    languageCd: res.data?.groupInfo.languageCd ?? '',
    totalMember: res.data?.groupInfo.totalMember ?? 0,
    menus:
      reorderMenu(groupAuth).map((menu: GroupAuthResponse) => ({
        name: menu.authorityId,
        viewable: menu.permission === Permission.READ || menu.permission === Permission.WRITE,
        editable: menu.permission === Permission.WRITE
      })) || ([] as MenuRole[]),
    projects:
      res.data?.projects.map((project: ProjectResponse) => ({
        id: project.projectId,
        name: project.projectName,
        count: project.productCount ?? 0
      })) || ([] as ProjectModel[])
  };
  return {
    ...res,
    data
  };
};

export const myInformationMapper = (
  res: ResponseNormalization<MyInformationResponse>
): ResponseNormalization<MyInformationModel> => {
  const data: MyInformationModel = {
    email: res.data?.email ?? '-',
    nickname: res.data?.nickname ?? '-',
    joinedAt: res.data?.joinedAt ?? 0,
    marketingNotificationYn: res.data?.marketingNotificationYn === Confirmation.YES,
    marketingNotificationModifiedAt: res.data?.marketingNotificationModifiedAt ?? 0,
    joinedGroups:
      res.data?.joinedGroups.map((group: GroupResponse) => ({
        groupId: group.groupId,
        groupName: group.groupName,
        ownerYn: group.ownerYn === Confirmation.YES,
        inviteStatus: group.inviteStatus,
        inviteId: group.inviteId,
        appliedAt: group.appliedAt,
        joinedAt: group.joinedAt
      })) || ([] as GroupModel[]),
    invitedGroups:
      res.data?.invitedGroups.map((group: GroupResponse) => ({
        groupId: group.groupId,
        groupName: group.groupName,
        ownerYn: group.ownerYn === Confirmation.YES,
        inviteStatus: group.inviteStatus,
        inviteId: group.inviteId,
        appliedAt: group.appliedAt,
        joinedAt: group.joinedAt
      })) || ([] as GroupModel[])
  };

  return {
    ...res,
    data
  };
};

export const productsProjectMapper = (
  res: ResponseNormalization<ProductsProjectResponse>
): ResponseNormalization<ProductsProjectModel> => {
  const data: ProductsProjectModel = {
    id: res.data?.projectId ?? '',
    name: res.data?.projectName ?? '',
    products:
      res.data?.products.map((product: ProductResponse) => ({
        projectId: res.data?.projectId,
        name: product.productName,
        email: product.email,
        nickname: product.nickname,
        roleName: product.roleName,
        roleNo: product.roleNo,
        roleGrantedAt: product.roleGrantedAt
      })) || ([] as ProductModel[])
  };
  return {
    ...res,
    data
  };
};

import type { IncomingMessage } from 'http';
import { navigateTo, useNuxtApp, useRequestURL, useRuntimeConfig } from 'nuxt/app';

import type { RouteRecordName } from '@/.nuxt/vue-router-stub';
import { showAlert } from '@/composables/useDialog';
import { getRunTypeConfig } from '@/configs/run-type';
import { DEFAULT_RUN_TYPE, SORT_DIRECTION } from '@/constants/common.const';
import { DEFAULT_LOCALE } from '@/constants/locale.const';
import type { RedirectParams } from '@/types/common.type';
import type { PublicRunTypeModel } from '@/types/run-type.type';

export const isServer = (): boolean => {
  return !!process.server;
};

export const host = (req?: IncomingMessage): string => {
  const { origin } = useRequestURL();
  return isServer() && req ? `https://${req?.headers?.host}` : origin;
};

export const getBoolean = (value?: 'Y' | 'N'): boolean => {
  if (value === 'Y') {
    return true;
  }

  return false;
};

const getProductParams = (params?: {
  groupId?: string;
  projectId?: string;
  productId?: number;
}) => {
  const nuxtApp = useNuxtApp();

  const { params: routeParams } = nuxtApp._route;
  const groupIdRoute = Array.isArray(routeParams.groupId)
    ? routeParams.groupId[0]
    : routeParams.groupId;
  const projectIdRoute = Array.isArray(routeParams.projectId)
    ? routeParams.projectId[0]
    : routeParams.projectId;
  const productIdRoute = Array.isArray(routeParams.productId)
    ? routeParams.productId[0]
    : routeParams.productId;
  const result: { groupId?: string; projectId?: string; productId?: string } = {};

  const groupId = params?.groupId || groupIdRoute;
  const projectId = params?.projectId || projectIdRoute;
  const productId = params?.productId?.toString() || productIdRoute;

  if (groupId) {
    result.groupId = groupId;
  }
  if (projectId) {
    result.projectId = projectId;
  }
  if (productId) {
    result.productId = productId;
  }
  return result;
};

export const generateProductUrl = (
  url: string,
  params?: {
    groupId?: string;
    projectId?: string;
    productId?: number;
  }
) => {
  const paramValues = getProductParams(params);
  for (const key in paramValues) {
    const value = paramValues[key as keyof typeof paramValues];
    if (!value) {
      continue;
    }
    url = url.replace(`{${key}}`, value);
  }

  return url;
};

export const isDirectAccess = (
  toName?: RouteRecordName | null,
  fromName?: RouteRecordName | null
) => {
  const to = toName?.toString();
  const from = fromName?.toString();
  if (!fromName) {
    return false;
  }
  const toWithoutLocale = to?.replace(/___\w+/, '');
  const fromWithoutLocale = from?.replace(/___\w+/, '');
  return toWithoutLocale === fromWithoutLocale;
};

export const redirectTo = async (to: string, opts?: RedirectParams) => {
  const {
    toType = 'path',
    withLocale = true,
    external = false,
    open,
    query,
    ...params
  } = opts || {};

  let q = { ...query };

  const nuxtApp = useNuxtApp();
  const { locale } = nuxtApp.$i18n as { locale: any };

  const isExternal = to.includes('http') || external;
  let url = to;

  if (toType === 'name') {
    url = `${url}___${locale.value}`;
  } else {
    url = generateProductUrl(to, params);

    if (withLocale && !isExternal) {
      url = `/${locale.value}${url}`;
    }
  }

  const queryString = url.split('?')[1];
  if (queryString) {
    const queryObject = Object.fromEntries(new URLSearchParams(queryString).entries());
    q = {
      ...queryObject,
      ...q
    };
  }

  return await navigateTo(
    {
      [toType]: url,
      ...(toType === 'name' ? { params } : {}),
      ...{ query: q }
    },
    {
      external: isExternal,
      open: open ? { target: open.target ?? '_self' } : undefined
    }
  );
};

export const sortValueToDirection = (sortValue?: number) => {
  let direction;
  switch (sortValue) {
    case 1:
      direction = SORT_DIRECTION.DESC;
      break;
    case -1:
      direction = SORT_DIRECTION.ASC;
      break;
  }

  return direction;
};

export const scrollToElementId = (id: string) => {
  const element: HTMLElement | null = document.getElementById(`${id}`);
  if (!element) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    return;
  }
  window.scrollTo({
    top: element.offsetTop - 160,
    behavior: 'smooth'
  });
};

export const showCommonErrorDialog = async (message: string, callback?: any) => {
  const { t } = useNuxtApp().$i18n as any;
  const confirmResult = await showAlert({
    severity: 'info',
    content: t(message),
    confirmClasses: 'max-w-full'
  });
  if (confirmResult) {
    if (callback) {
      callback();
    }
    // location.reload();
  }
};

export const showConfirmLeaveDialog = async (): Promise<boolean> => {
  // const { t } = useNuxtApp().$i18n as any;
  // const result = await showConfirm<boolean>({
  //   content: t('studio.common.popup_case_a_without_saving'),
  //   confirmLabel: t('studio.common.popup_case_a_without_saving_exit_btn'),
  //   cancelVariant: 'outline'
  // });
  // return result;
  return true;
};

export const getLanguageNameI18n = (langCode?: string) => {
  if (!langCode) {
    return 'studio.ai_translation.base_lang_ko';
  }
  return `studio.ai_translation.base_lang_${langCode.toLowerCase().replace('-', '_')}`;
};

export const getConfigs = (): PublicRunTypeModel => {
  const runtimeConfig = useRuntimeConfig();
  const runType = runtimeConfig.public.runTypeConfig.RUN_TYPE || DEFAULT_RUN_TYPE;
  return getRunTypeConfig(runType);
};

export const generateExternalLink = async (to: string, locale: string, groupId: string) => {
  const { STUDIO_V2_URL } = getConfigs();
  const host = STUDIO_V2_URL;
  return `${host}/${locale}/${groupId}/${to}`;
};

export const generateExternalLinkWithHostLocale = async (to: string, locale: string) => {
  const { STUDIO_V2_URL } = getConfigs();
  return `${STUDIO_V2_URL}/${locale}${to}`;
};

export const toQueryString = (params: any) => {
  const query = [];
  for (const key in params) {
    if (params[key] && Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      if (Array.isArray(value)) {
        value.forEach((val: any) => {
          query.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
        });
      } else {
        query.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  }

  return query.join('&');
};

export const sortStrings = (a: string, b: string, order: 'asc' | 'desc' = 'asc') => {
  // This func will sort the array in ascending or descending order
  //  Special characters 2. 123 (numbers) 3. ABC (English) 4. 가나다 (Korean)
  const specialCharRegex = /^[^a-zA-Z0-9가-힣]/;
  const numberRegex = /^[0-9]/;
  const englishRegex = /^[a-zA-Z]/;
  const koreanRegex = /^[가-힣]/;

  const getSortPriority = (name: string) => {
    if (specialCharRegex.test(name)) {
      return 0;
    }
    if (numberRegex.test(name)) {
      return 1;
    }
    if (englishRegex.test(name)) {
      return 2;
    }
    if (koreanRegex.test(name)) {
      return 3;
    }
    return 4;
  };

  const priorityA = getSortPriority(a);
  const priorityB = getSortPriority(b);

  if (priorityA !== priorityB) {
    return order === 'asc' ? priorityA - priorityB : priorityB - priorityA;
  }

  return order === 'asc' ? a?.localeCompare(b) : b?.localeCompare(a);
};

export const getBaseUrlDevCenterBeta = (): string => {
  const nuxtApp = useNuxtApp();
  const { locale } = nuxtApp.$i18n as { locale: any };
  const baseUrl: string = getConfigs().DEVELOPERS_DOMAIN ?? 'https://developers-beta.onstove.com';

  return locale.value === DEFAULT_LOCALE ? baseUrl : `${baseUrl}/en`;
};

export const BRAND_PAGE_URL = '/brand';
export const REDIRECT_TO_GROUP_HOME = '/redirect-to/group-home';
export const MY_INFO_PAGE_URL = '/my-info';
export const MY_INFO_GROUP_ID_PAGE_URL = '/my-info/group/{groupId}';
export const GROUP_REGISTER_PAGE_URL = '/group/register';
export const NOTICE_PAGE_URL = '/notice';
export const PUBLIC_NOTICE_PAGE_URL = '/public/notice';
export const TERMS_PAGE_URL = '/terms';
export const TERMS_DETAIL_PAGE_URL = '/terms/detail';
export const TUTORIAL_PAGE_URL = '/tutorial';

export const HOME_PAGE_URL = '/{groupId}/home';
export const MANAGER_COMMUNITY = 'operation/community';

export const BUSINESS_BANK_PAGE_URL = '/{groupId}/business-bank';
export const BANK_LIST_PAGE_URL = `${BUSINESS_BANK_PAGE_URL}/bank-list`;
export const BANK_REGISTER_PAGE_URL = `${BUSINESS_BANK_PAGE_URL}/bank-register`;
export const BUSINESS_LIST_PAGE_URL = `${BUSINESS_BANK_PAGE_URL}/business-list`;
export const BUSINESS_REGISTER_PAGE_URL = `${BUSINESS_BANK_PAGE_URL}/business-register`;

export const MEMBER_MNG_PAGE_URL = '/{groupId}/members';
export const MEMBER_ROLES_PAGE_URL = `${MEMBER_MNG_PAGE_URL}/roles`;
export const MEMBER_INVITE_PAGE_URL = `${MEMBER_MNG_PAGE_URL}/invite`;
export const MEMBER_INVITE_HISTORY_PAGE_URL = `${MEMBER_MNG_PAGE_URL}/invite-history`;
export const MEMBER_WAIT_LIST_PAGE_URL = `${MEMBER_MNG_PAGE_URL}/wait-list`;

export const PROJECT_MNG_PAGE_URL = '/{groupId}/projects';
export const PROJECT_REGISTER_PAGE_URL = '/{groupId}/projects/register';
const PROJECT_HOME_PAGE_URL = '/{groupId}/projects/{projectId}';
export const PRODUCT_REGISTER_PAGE_URL = `${PROJECT_HOME_PAGE_URL}/products/register`;
export const PRODUCT_REVIEWS_PAGE_URL = `${PROJECT_HOME_PAGE_URL}/products/{productId}/reviews`;

export const PRODUCT_HOME_PAGE_URL = `${PROJECT_HOME_PAGE_URL}/products/{productId}`;
export const PRODUCT_DISCOUNT_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/discount`;
export const PRODUCT_PAGES_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/product-pages`;
export const PRODUCT_SETTING_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/settings`;
export const PRODUCT_RELEASE_PLAN_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/release-plan`;
export const PRODUCT_RELEASE_PLAN_DETAIL_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/release-plan/detail`;
export const PRODUCT_BUILD_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/build`;
export const PRODUCT_LAUNCH_SETTING_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/launch-setting`;
export const PRODUCT_EARLY_ACCESS_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/early-access`;
export const PRODUCT_SELLING_PRICE_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/selling-price`;
export const PRODUCT_TERMS_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/terms`;
export const PRODUCT_PURCHASE_CONDITION_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/purchase-condition`;
export const PRODUCT_BANNER_IMG_MNG_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/marketing-management/banner-image`;

// V2
export const PRODUCT_STATS_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/game-service/stats`;
export const PRODUCT_LEADER_BOARD_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/game-service/leaderboard`;
export const PRODUCT_ACHIEVEMENTS_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/game-service/achievements`;
export const PRODUCT_CLOUD_SAVING_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/game-service/cloud-saving`;
export const PRODUCT_MATCH_MAKING_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/game-service/matchmaking`;
export const PRODUCT_IN_GAME_PAYMENT_SETTING_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/ingame-payment/setting`;
export const PRODUCT_IN_GAME_PAYMENT_SHOPS_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/ingame-payment/shops`;
export const PRODUCT_IN_GAME_PAYMENT_PRODUCTS_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/ingame-payment/products`;
export const PRODUCT_IN_GAME_PAYMENT_DISCOUNT_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/ingame-payment/discount-items`;
export const PRODUCT_IN_GAME_PAYMENT_HISTORY_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/ingame-payment/history`;

// Rating
export const PRODUCT_RATING_URL = `${PRODUCT_HOME_PAGE_URL}/rating`;
export const PRODUCT_RATING_REGISTER_URL = `${PRODUCT_HOME_PAGE_URL}/rating/register`;
export const PRODUCT_RATING_GRAC_INPUT_EDIT_URL = `${PRODUCT_HOME_PAGE_URL}/rating/register/grac-input`;
export const PRODUCT_RATING_GRAC_INPUT_DETAIL_URL = `${PRODUCT_HOME_PAGE_URL}/rating/detail/grac-input`;
export const PRODUCT_RATING_DETAIL_PAGE_URL = `${PRODUCT_HOME_PAGE_URL}/rating/detail`;
export const PRODUCT_RATING_RESULT_URL = `${PRODUCT_HOME_PAGE_URL}/rating/result`;
export const PRODUCT_RATING_RESULT_DETAIL_ADJUSTMENT_URL = `${PRODUCT_HOME_PAGE_URL}/rating/adjustment`;
export const PRODUCT_RATING_RESULT_DETAIL_RECLASSIFICATION_URL = `${PRODUCT_HOME_PAGE_URL}/rating/reclassification`;
export const PRODUCT_RATING_RESULT_DETAIL_CANCEL_URL = `${PRODUCT_HOME_PAGE_URL}/rating/cancel`;
export const PRODUCT_RATING_RESULT_DETAIL_REJECTION_URL = `${PRODUCT_HOME_PAGE_URL}/rating/rejection`;
export const PRODUCT_RATING_HISTORY_URL = `${PRODUCT_HOME_PAGE_URL}/rating/history`;
export const PRODUCT_RATING_REJECT_URL = `${PRODUCT_HOME_PAGE_URL}/rating/reject-reason`;

// Collection
export const COLLECTION_URL = '/{groupId}/collections';
export const COLLECTION_REGISTER_URL = '/{groupId}/collections/CREATE';
export const COLLECTION_MODIFY_URL = '/{groupId}/collections/EDIT';

// PAGE NAMES

export const ERROR_PAGE_NAME = 'error';
export const PROJECT_MNG_PAGE_NAME = 'groupId-projects';
export const PRODUCT_HOME_PAGE_NAME = 'groupId-projects-projectId-products-productId';
export const RATING_HOME_PAGE_NAME = 'groupId-projects-projectId-products-productId-rating';
export const LAUNCH_SETTING_HOME_PAGE_NAME = `${PRODUCT_HOME_PAGE_NAME}-launch-setting`;
export const TERMS_UPDATE_PAGE_NAME = 'terms-update';
export const GROUP_HOME_PAGE_NAME = 'groupId-home';
export const MEMBER_PAGE_NAME = 'groupId-members';
export const PUBLIC_STUDIO_REGISTER_PAGE_URL = 'register';
export const PUBLIC_STUDIO_SERVICE_UNAVAILABLE_PAGE_NAME = 'service-unavailable';
export const PRODUCT_RATING_GRAC_INPUT_NAME = `${PRODUCT_HOME_PAGE_NAME}-rating-register-grac-input`;

export const DEVELOPERS_STORE_INTRO_PATH = '/docs/Store/Intro';

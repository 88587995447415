export const SEARCH_PROJECT_REGEX =
  /[^ぁ-ゔァ-ヴー々〆〤\u4E00-\u9FFFa-zA-Zㄱ-ㅎ가-힣ㅏ-ㅣ0-9\-_.]/g;

export const NO_SPECIAL_CHAR_REGEX =
  /^[a-zA-Z0-9\s\u3130-\u318F\uAC00-\uD7A3\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]*$/;
export const ONLY_NUMBER_REGEX = /^\d+$/;
export const ONLY_NUMBER_WITH_COMMA_REGEX = /^\d+(,\d+)*$/;
export const COMMA_SEPARATED_NAMES_REGEX = /^([^,]+)(,[^,]+)*$/;
export const NO_SPECIAL_CHARS_KR_REGEX = /^[^/:*?"<>|￦]+$/;
export const ALPHABE_NUMBER_HYPHEN_REGEX = /^[a-zA-Z0-9-]+$/;

export const LOCALE_REGEX = /^\/?([a-z]{2}(?:-[a-z]{2})?)/i;
export const URL_REGEX =
  /^http(?:s)?:\/\/(www\.)?[-a-zA-Z0-9@:%_+~#=]{1,256}(\.{1}[a-zA-Z0-9()]+){1,6}\b([-a-zA-Z0-9()@:%_+~#?&/=]*)$/;
export const HTTPS_URL_REGEX =
  /^https:\/\/(www\.)?[-a-zA-Z0-9@:%_+~#=]{1,256}(\.{1}[a-zA-Z0-9()]+){1,6}\b([-a-zA-Z0-9()@:%_+~#?&/=]*)$/;

export const REGEX_PRODUCT_NAME =
  /^[A-Za-zp0-9\s\u3130-\u318F\uAC00-\uD7A3\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF-：:[\].™®#()'!?•|&_"~,<>*/ 、。，]+$/;

// No spaces
export const NO_SPACES_REGEX = /^\S*$/;

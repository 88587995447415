export enum CurrencyCode {
  Usd = 'USD',
  Krw = 'KRW',
  Cny = 'CNY',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Idr = 'IDR',
  Jpy = 'JPY',
  Myr = 'MYR',
  Php = 'PHP',
  Sgd = 'SGD',
  Thb = 'THB',
  Vnd = 'VND'
}

export type CurrencyCodeType = keyof typeof CurrencyCode;

export enum LocaleByCurrency {
  CNY = 'zh',
  EUR = 'de',
  GBP = 'en',
  HKD = 'hk',
  IDR = 'id',
  JPY = 'ja',
  KRW = 'ko',
  MYR = 'ms',
  PHP = 'ph',
  SGD = 'sg',
  THB = 'th',
  USD = 'en',
  VND = 'vi'
}

export enum CurrencyCodeFormat {
  USD = 'en-US',
  KRW = 'ko-KR',
  CNY = 'zh-CN',
  EUR = 'de-DE',
  GBP = 'en-GB',
  HKD = 'zh-HK',
  IDR = 'id-ID',
  JPY = 'ja-JP',
  MYR = 'ms-MY',
  PHP = 'en-PH',
  SGD = 'en-SG',
  THB = 'th-TH',
  VND = 'vi-VN'
}

export const NUMBER_STAY_SECONDS = 5;

export const STATUS_CODE = {
  MOVED_TEMPORARILY_302: 302,
  INVALID_TOKEN_401: 401,
  CODE_40103: 40103,
  CODE_40101: 40101,
  GROUP_DUPLICATION: 1001,
  STUDIO_MEMBER_NICKNAME_DUPLICATE: 4602,
  GROUP_NOT_FOUND: 1000,
  PRODUCT_LANGUAGE_MAP_HAS_MISSING_LANGUAGE: 2020,
  MEMBER_NOT_FOUND: 4500,
  MEMBER_INVALID_TOKEN: 4501,
  ACCESS_DENIED: 4700,
  INTERNAL_SERVER_ERROR: 500,
  DEPENDENCY_SERVER_ERROR: 500,
  EXTERNAL_API_ERROR: 9999,
  GROUP_NAME_DUPLICATED: 1001,
  GROUP_MAX_OWNED: 1002,
  GROUP_DELETE_DISABLE: 1003,
  COMPANY_ALREADY_REQUEST: 1200,
  BANK_ALREADY_REQUEST: 2202,
  PROJECT_NOT_FOUND: 1500,
  PROJECT_NAME_DUPLICATED: 1501,
  PROJECT_CONTAINS_RELEASED_PRODUCT: 1502,
  PROJECT_CAPACITY_EXCEEDED: 1503,
  PROJECT_MAX_EXISTED: 1504,
  DIRECTORY_NOT_FOUND: 1505,
  DUPLICATED_DIRECTORY_NAME: 1506,
  INVALID_DIRECTORY_NAME: 1507,
  FILE_NOT_FOUND: 1508,
  INVALID_FILE_NAME: 1509,
  BULK_SIZE_LIMIT_EXCEEDED: 1510,
  UNSUPPORTED_FILE_TYPE: 1511,
  FILE_SIZE_ZERO: 1512,
  FILE_SIZE_EXCEEDED: 1513,
  PROJECT_CONTAINS_RELEASE_PRODUCT: 1514,
  PRODUCT_NOT_FOUND: 2000,
  PRODUCT_NAME_DUPLICATED: 2001,
  RELEASE_PRODUCT_NOT_DELETE: 2002,
  PRODUCT_DEFAULT_GENRE_NOT_FOUND: 2003,
  PRODUCT_MAX_EXISTED: 2004,
  PRODUCT_BULK_REQUEST_EXCEEDED_MAX: 2005,
  INVALID_CHILDREN: 2008,
  PRODUCT_UNDER_COLLECTION_NOT_DELETABLE: 2014,
  PRODUCT_CONTAINS_SUB_PRODUCT: 2016,
  PRODUCT_STATUS_NOT_MODIFIABLE: 2019,
  PRODUCT_RELEASE_NOT_FOUND: 2050,
  INVALID_PLAN_SCHEDULE_DATE: 2053,
  RELEASE_ERROR: 2056,
  PRODUCT_SELLING_PRICE_NOT_FOUND: 2070,
  PRODUCT_PRICE_ALREADY_EXIST: 2071,
  PRODUCT_PRICE_CAN_NOT_BE_DELETED: 2074,
  PRODUCT_DISCOUNT_PERIOD_DUPLICATED: 2082,
  PRODUCT_DISCOUNT_PERIOD_IS_PAST: 2084,
  ACCOUNT_NOT_FOUND: 2200,
  PROJECT_ACCESS_DENIED: 3510,
  TEAM_ACCESS_DENIED: 3010,
  GROUP_INDICATOR_PROJECT_NOT_FOUND: 3500,
  IN_GAME_PROJECT_ACCESS_DENIED: 4301,
  PRODUCT_GAME_ID_NOT_FOUND: 4303,
  PRODUCT_ACCESS_DENIED: 4304,
  IN_GAME_GAME_NOT_FOUND: 4305,
  IN_GAME_PURCHASE_NOT_IN_USE: 4306,
  REGIST_SHOP_FAIL: 4307,
  INVALID_BULK_FILE_CONTENTS: 4308,
  INVALID_BULK_FILE_EXTENSION: 4309,
  IN_GAME_BULK_FILE_ROW_COUNT_LIMIT_EXCEED: 4310,
  INVALID_BULK_FILE_DATETIME_CONTENTS: 4311,
  INVALID_BULK_FILE_NULL_CONTENTS: 4312,
  INVALID_BULK_FILE_NUMBER_CONTENTS: 4313,
  IN_GAME_PURCHASE_NOT_FOUND: 4350,
  MISSING_BANK_INFO: 4404,
  STUDIO_MEMBER_NOT_FOUND: 4600,
  STUDIO_MEMBER_EXISTS: 4601,
  GROUP_MEMBER_NOT_FOUND: 4800,
  INVALID_GROUP_MEMBER_INVITE_STATUS: 4801,
  GROUP_MEMBER_ROLE_AUTH_NOT_FOUND: 4803,
  GAME_NOT_FOUND: 5000,
  READ_WRITE_ACCESS_DENIED: 4701,
  GROUP_INVITATION_LINK_NOT_FOUND: 4804,
  GROUP_INVITATION_LINK_EXPIRED: 4805,
  GROUP_MEMBER_ALREADY_JOINED: 4806,
  GROUP_MEMBER_ALREADY_APPLIED: 4809,
  PRODUCT_PAGE_PUBLISHED: 6000,
  PRODUCT_PAGE_NOT_FOUND: 6001,
  GAME_KEY_NOT_FOUND: 9146000,
  BUILD_PIPELINE_API_ERROR: 917500,
  BUILD_PIPELINE_API_ERROR_1: 9171103,
  RATING_API_ERROR: 914400,
  BAD_REQUEST: 400,
  // Start menu 34
  MIGRATION_INVALID: 4950,
  MIGRATION_INVALID_REQUEST: 4951,
  MIGRATION_ALREADY_PROCESS: 4952,
  MIGRATION_NOT_FOUND: 4953,
  MIGRATION_KEY_EXPIRE: 4954,
  MIGRATION_EMAIL_INVALID: 4955,
  MIGRATION_NOT_AVAILABLE: 4956,
  GROUP_MIGRATION_ALREADY_PROGRESS: 4957,
  PROJECT_MIGRATION_ALREADY_PROGRESS: 4958,
  TARGET_PROJECT_MIGRATION_EXIST: 4959,
  MIGRATION_HISTORY_NOT_FOUND: 4960,
  MIGRATION_PRODUCT_PLATFORM_TYPE_NOT_FOUND: 4961,
  GROUP_MIGRATION_REACH_LIMIT: 4962,
  PROJECT_MIGRATION_REACH_LIMIT: 4963,
  MIGRATION_TARGET_MEMBER_NOT_FOUND: 4964,
  TARGET_MEMBER_NOT_FOUND: 4965,
  INVALID_MIGRATION_REQUEST_MEMBER: 4966,
  EXISTS_PROJECT_IS_UNDER_VERIFICATION: 4967,
  MIGRATION_TARGET_USER_INVALID: 4968,
  MIGRATION_TARGET_USER_GROUP_OWNER_NOT_FOUND: 4969,
  RATING_BUILD_OPTION_NOT_ACCEPTABLE: 7010,
  PRODUCT_PAGE_WRONG_PARAMETERS: 6002,
  PRODUCT_PAGE_DUPLICATED_RELEASE_DATE: 6005,
  PRODUCT_PAGE_MANDATORY_RELEASE: 6006,
  RATING_BUILD_OPTION_OF_PARENT_NOT_ACCEPTABLE: 7011,
  REVISION_ALREADY_IN_PROGRESS: 7018,
  RATING_REVISION_STATUS_CANNOT_BE_MODIFIED: 7006,
  // End menu 34
  // MENU 16
  STORE_RELEASE_ERROR: 2060,
  PRODUCT_VERIFY_REJECT_INFO_NOT_FOUND: 2040,
  VERIFY_RATING_NOT_FOUND: 2041,
  VERIFY_CONTENT_NOT_FOUND: 2042,
  BUILD_CAN_NOT_VERIFY: 2043,
  BUILD_NOT_RELEASED_FOR_VERIFY: 2044,
  PRODUCT_PRICE_NOT_FOUND: 2049,
  PRODUCT_RELEASE_ALREADY_EXIST: 2051,
  GAME_KEY_DUPLICATED: 9146020,

  // Menu 25
  BUILD_SCHEDULE_CAN_NOT_BE_APPLIED: 4933,

  // Menu 21
  EARLY_ACCESS_NOT_FOUND: 4992
};

export const COMMON_ERROR_MESSAGE_KEY = 'studio.common.popup_case_b_error_retry';
export const COMMON_ERROR_GROUP_TRANSFER_IN_PROGRESS_MESSAGE_KEY =
  'studio.group_transfer_in_progress.no_action_allowed_msg';
export const COMMON_ERROR_PROJECT_TRANSFER_IN_PROGRESS_MESSAGE_KEY =
  'studio.group_transfer.prj_transfer_in_progress_4958_msg';
export const COMMON_ERROR_NO_PERMISSION = 'studio.common.popup_case_g_not_view';

export const COMMON_ERROR_REVIEW_INPROCESS_CANNOT_EDIT = 'studio.execution_setting.review_in_progress.cannot_edit_msg';

export const INVALID_TOKEN_STATUS_CODES = [
  STATUS_CODE.INVALID_TOKEN_401,
  STATUS_CODE.CODE_40101,
  STATUS_CODE.CODE_40103
];

export const INVALID_MEMBER_CODES = {
  MEMBER_ROLE_NAME_DUPLICATED: 4703
};

export const PRECESSION_ERROR_CODE = {
  PRECESSION_NOT_FOUND: 2400
};

export const EXPIRED_TOKEN = 40104;
export const INVALID_TOKEN = 401;
export const UNKNOWNS_ERROR = 403;

export const ERROR_CODE = {
  DISCOUNT_PRE_RELEASED_ACCESS: '1'
};

export const GROUP_ACCESS_DENY_ERROR_CODE = '4701';
export const MENU_ACCESS_DENY_ERROR_CODE = '4702';
export const PRODUCT_FEAT_DISABLED = '4703';
export const PRODUCT_ACCESS_DENY_ERROR_CODE = '4704';
export const PAGE_NOT_FOUND_404 = 404;

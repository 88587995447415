import * as Sentry from '@sentry/vue';
import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app';
import { useRouter } from 'vue-router';

import { getConfigs } from '@/utils/common.util';

// eslint-disable-next-line @typescript-eslint/typedef
export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const runtime = useRuntimeConfig();
  const { SENTRY_PROJECT_NAME, SENTRY_DSN } = getConfigs();
  const isForceSentryOn: boolean = runtime.public.isForceSentryOn;
  const releaseName = (() => {
    return `${SENTRY_PROJECT_NAME}-${new Date().toISOString()}`;
  })();
  // list of ignoreErrors
  const ignoreErrors = ['exception.forAdult'];

  Sentry.init({
    enabled: isForceSentryOn,
    app: nuxtApp.vueApp,
    debug: false,
    dsn: SENTRY_DSN,
    release: releaseName,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    beforeSend: (event: any, hint: any) => {
      if (hint.originalException?.statusCode) {
        return event;
      }
    },
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    tracesSampleRate: 0.1,
    profilesSampleRate: 0.1,
    ignoreErrors
  });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  };
});

import { StoveUI } from '@stove-ui/vue';
import { defineNuxtPlugin, useNuxtApp } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import { COOKIE_KEYS, DependencyInjectId, UseContext } from 'seed-core';

import { useCookieWithDomain } from '@/composables/useCookieWithDomain';
import { useAppStore } from '@/stores/app.store';
import { getConfigs } from '@/utils/common.util';

export default defineNuxtPlugin(async () => {
  const nuxtApp = useNuxtApp();
  const appStore = useAppStore();
  const { SEED_CORE } = getConfigs();

  const useContext = await UseContext(SEED_CORE || {});
  const { authService, axiosService, gdService } = useContext;
  const { gdsInfo } = storeToRefs(appStore);
  if (gdsInfo.value) {
    axiosService.setCommonHeader(gdsInfo.value);
  }
  // Use GDS from GNBHeader
  await gdService.getGlobalDomainData();

  appStore.fetchGDSInfo();
  const { authenticateByToken } = authService;

  const accessToken = useCookieWithDomain(COOKIE_KEYS.ACCESS_TOKEN || '');
  const refreshToken = useCookieWithDomain(COOKIE_KEYS.REFRESH_TOKEN || '');
  const accessExpiredCheck = useCookieWithDomain(COOKIE_KEYS.ACCESS_EXPIRED_CHECK || '');

  await authenticateByToken(
    accessToken.value || '',
    accessExpiredCheck.value || '',
    refreshToken.value || ''
  );

  nuxtApp.provide(DependencyInjectId.ContextCore, useContext);
  nuxtApp.vueApp.use(StoveUI);
});

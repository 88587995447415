import {
  DEFAULT_ROLE,
  PRODUCT_DATA_MANAGEMENT,
  PRODUCT_DATA_MANAGEMENT_ROLES,
  PRODUCT_INGAME_MANAGEMENT,
  PRODUCT_INGAME_MANAGEMENT_ROLES,
  PRODUCT_MARKETING_MANAGEMENT,
  PRODUCT_MARKETING_MANAGEMENT_ROLES,
  PRODUCT_PAGE_MANAGEMENT,
  PRODUCT_PAGE_MANAGEMENT_ROLES,
  RELEASE_PLAN
} from '@/constants/member-role.const';
import { Confirmation, Permission } from '@/enums/common.enum';
import { NotificationId } from '@/enums/member.enum';
import type {
  AuthorityModel,
  ChildRoleAuthorityModel,
  MemberAuthorityFormModel,
  ProductMemberModel,
  ProductMemberResponse,
  ProductResponse,
  ProjectProductMemberModel,
  ProjectWithProductsModel,
  RoleAuthorityModel,
  RoleDetailModel
} from '@/types/member/member-model.type';
import type {
  MemberInfoUpdateRolesRequest,
  MemberInvite,
  MemberRole,
  MemberRoleUpdate,
  MenuAuthority,
  MenuRole,
  ProductRole
} from '@/types/member/member-request.type';
import type {
  ChildRoleAuthorityResponse,
  RoleDetailResponse
} from '@/types/member/member-response.type';
import type { ProjectProductModel } from '@/types/project-product/project-product-model.type';
import type {
  ChildRoleAuthority as ChildRole,
  CreateRoleRequest,
  RolesRequest
} from '@/types/role/role.request.type';
import { getBoolean } from '@/utils/common.util';

const getMemberNotification = (data: {
  isContract: boolean;
  isSettlement: boolean;
  isVerification: boolean;
}): NotificationId[] => {
  const contract = data.isContract ? NotificationId.CONTRACTION : undefined;
  const settlement = data.isSettlement ? NotificationId.BILL_SETTLEMENT : undefined;
  const verification = data.isVerification ? NotificationId.VERIFICATION : undefined;

  return [contract, settlement, verification].filter(
    (item: NotificationId | undefined) => item !== undefined
  );
};

const getMenuAuthority = (menus: MenuRole[]): MenuAuthority[] => {
  const menuAuthorities = menus
    .filter((m: MenuRole) => m.editable || m.viewable)
    .map((item: MenuRole) => {
      const permission = item.editable
        ? Permission.WRITE
        : item.viewable
          ? Permission.READ
          : undefined;

      return {
        menuAuthorityId: item.id,
        permission
      };
    });

  return menuAuthorities as MenuAuthority[];
};

const getMemberRole = (projects: ProjectProductModel[]): MemberRoleUpdate[] => {
  return projects
    .map((project: ProjectProductModel) => {
      return (project.products ?? []).map((product: ProductRole) => ({
        memberRoleNo: product.role as number,
        productNo: product.productNo
      }));
    })
    .flat()
    .filter((item: MemberRoleUpdate) => item.memberRoleNo !== undefined);
};

export const memberInfoMapper = (data: MemberInvite): MemberInfoUpdateRolesRequest => {
  const memberNotification = getMemberNotification({
    isContract: data.isContract,
    isSettlement: data.isSettlement,
    isVerification: data.isVerification
  });

  const memberMenuAuthorities = getMenuAuthority(data.menus);
  const memberMenuRoles = getMemberRole(data.projects);

  const notifications: NotificationId[] = memberNotification;
  const menuAuthorities: MenuAuthority[] = memberMenuAuthorities;
  const memberRoles: MemberRoleUpdate[] = memberMenuRoles;

  return {
    notifications,
    menuAuthorities,
    memberRoles,
    autoRoleYn: data.autoRoleYn ? Confirmation.YES : Confirmation.NO,
    autoRoleNo: data.autoRoleNo
  };
};

export const memberRoleMapper = (data: ProjectWithProductsModel[], email: string): MemberRole[] => {
  const res = data.map((project: ProjectWithProductsModel) => {
    const productRoles: ProductRole[] = project.products.map((product: ProductResponse) => {
      const member = product.productMembers.find(
        (member: ProductMemberResponse) => member.email === email
      );

      if (!member) {
        return product;
      }

      const result = {
        productNo: product.productNo,
        productName: product.productName,
        memberRoleName: member?.memberRoleName,
        memberRoleNo: member?.memberRoleNo
      };

      return result;
    });

    return {
      projectId: project.projectId,
      projectName: project.projectName,
      productRoles
    };
  });

  return res;
};

export const projectWithProductMemberMapper = (
  data: ProjectWithProductsModel[]
): ProjectProductMemberModel[] => {
  const res = data.map((project: ProjectWithProductsModel) => {
    const products: ProductMemberModel[] = project.products
      .map((product: ProductResponse) => {
        const productMembers = product.productMembers.map((member: ProductMemberResponse) => ({
          ...member,
          productName: product.productName,
          productNo: product.productNo
        }));

        return productMembers;
      })
      .flat();

    return {
      products,
      groupId: project.groupId,
      projectId: project.projectId,
      projectName: project.projectName,
      projectMemberCount: project.projectMemberCount
    };
  });

  return res;
};

export const createRoleFormMapper = (data: RoleDetailModel): CreateRoleRequest => {
  const res = {
    name: data.roleName,
    roles: (data.authorities ?? []).map((roleAuthority: RoleAuthorityModel) => {
      return {
        name: roleAuthority.parentRoleAuthorityId,
        viewable:
          roleAuthority.permission === Permission.READ ||
          roleAuthority.permission === Permission.WRITE,
        editable: roleAuthority.permission === Permission.WRITE,
        childRoles: roleAuthority.childRoleAuthorities.map(
          (childRole: ChildRoleAuthorityModel) => ({
            roleAuthorityId: childRole.roleAuthorityId,
            roleAuthorityNo: childRole.roleAuthorityNo,
            viewable:
              childRole.permission === Permission.READ || childRole.permission === Permission.WRITE,
            editable: childRole.permission === Permission.WRITE
          })
        ),
        expanded: true
      };
    }),
    isBasicRole: !getBoolean(data.editableYn),
    id: data.roleNo
  };

  return res;
};

export const roleFormModelMapper = (data: CreateRoleRequest): MemberAuthorityFormModel => {
  const authorities: AuthorityModel[] = [];

  (data.roles ?? []).forEach((role: RolesRequest) => {
    if (role.childRoles?.length === 0) {
      const authorityPermission = role.editable
        ? Permission.WRITE
        : role.viewable
          ? Permission.READ
          : undefined;

      if (!authorityPermission) {
        return;
      }

      authorities.push({
        authorityPermission,
        roleAuthorityId: role.name
      });

      return;
    }

    role.childRoles?.forEach((childRole: ChildRole) => {
      if (childRole.editable || childRole.viewable) {
        const authorityPermission = childRole.editable
          ? Permission.WRITE
          : childRole.viewable
            ? Permission.READ
            : undefined;
        authorities.push({
          authorityPermission,
          roleAuthorityId: childRole.roleAuthorityId
        });
      }
    });
  });

  const res: MemberAuthorityFormModel = {
    roleName: data.name,
    authorities
  };

  return res;
};

const getParentPermission = (childRole: string) => {
  if (PRODUCT_PAGE_MANAGEMENT_ROLES.includes(childRole)) {
    return PRODUCT_PAGE_MANAGEMENT;
  }
  if (PRODUCT_DATA_MANAGEMENT_ROLES.includes(childRole)) {
    return PRODUCT_DATA_MANAGEMENT;
  }
  if (PRODUCT_MARKETING_MANAGEMENT_ROLES.includes(childRole)) {
    return PRODUCT_MARKETING_MANAGEMENT;
  }
  if (PRODUCT_INGAME_MANAGEMENT_ROLES.includes(childRole)) {
    return PRODUCT_INGAME_MANAGEMENT;
  }

  return RELEASE_PLAN;
};

export const roleDetailModelMapper = (data: RoleDetailResponse): RoleDetailModel => {
  const defaultRoles = structuredClone(DEFAULT_ROLE).map((defaultRole: RolesRequest) => {
    data.authorities.forEach((child: ChildRoleAuthorityResponse) => {
      if (getParentPermission(child.roleAuthorityId) === defaultRole.name) {
        if (defaultRole.childRoles?.length !== 0) {
          const role = defaultRole.childRoles?.find(
            (r: ChildRole) => r.roleAuthorityId === child.roleAuthorityId
          );

          if (role) {
            role.editable = child.permission === Permission.WRITE;
            role.viewable =
              child.permission === Permission.READ || child.permission === Permission.WRITE;
          }
        } else {
          defaultRole.editable = child.permission === Permission.WRITE;
          defaultRole.viewable =
            child.permission === Permission.READ || child.permission === Permission.WRITE;
        }
      }
    });

    return defaultRole;
  });

  const authorities: RoleAuthorityModel[] = defaultRoles.map((role: RolesRequest) => {
    return {
      parentRoleAuthorityId: role.name,
      permission:
        (role.childRoles ?? []).length > 0
          ? Permission.NONE
          : role.editable
            ? Permission.WRITE
            : role.viewable
              ? Permission.READ
              : Permission.NONE,
      childRoleAuthorities: (role.childRoles ?? []).map((childRole: ChildRole) => ({
        roleAuthorityId: childRole.roleAuthorityId,
        roleAuthorityNo: childRole.roleAuthorityNo,
        permission:
          childRole.viewable && childRole.editable
            ? Permission.WRITE
            : childRole.viewable
              ? Permission.READ
              : undefined
      }))
    };
  });

  const res: RoleDetailModel = {
    roleNo: data.roleNo,
    roleName: data.roleName,
    groupId: data.groupId,
    activateYn: data.activateYn,
    editableYn: data.editableYn,
    createdAt: data.createdAt,
    modifiedAt: data.modifiedAt,
    authorities
  };

  return res;
};

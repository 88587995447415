import { defineNuxtPlugin } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

import { STATUS_CODE } from '@/constants/error.const';
import { useAppStore } from '@/stores/app.store';
import { useTermsStore } from '@/stores/terms.store';
import { useUserStore } from '@/stores/user.store';
import { isLogin } from '@/utils/user.utils';

export default defineNuxtPlugin(async () => {
  // Call and set store for UserInfo
  const route = useRoute();
  if (route.meta.isPublic === true && !isLogin()) {
    return;
  }

  if (isLogin()) {
    const { fetchMemberInfo } = useUserStore();
    const userStore = useUserStore();
    const { hasApiError } = storeToRefs(useAppStore());
    const { fetchSimpleMyInfos, setMemberOrNot } = userStore;
    const termsStore = useTermsStore();
    const { fetchRemainingStudioTermNos, fetchAgreedTerms } = termsStore;

    try {
      await fetchMemberInfo();
      await fetchSimpleMyInfos();
      await fetchAgreedTerms();
      await fetchRemainingStudioTermNos();
    } catch (error: any) {
      if (error.statusCode === STATUS_CODE.STUDIO_MEMBER_NOT_FOUND) {
        setMemberOrNot(false);
        return;
      }
      if (error.statusCode === STATUS_CODE.BAD_REQUEST || error.status === STATUS_CODE.INVALID_TOKEN_401) {
        location.reload();
        return;
      }
      hasApiError.value = true;
    }
  }
});

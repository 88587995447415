import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/themes/dark.min.css';

import { defineNuxtPlugin, useNuxtApp } from 'nuxt/app';
import VueFroala from 'vue-froala-wysiwyg';

export default defineNuxtPlugin(async () => {
  const nuxtApp = useNuxtApp();

  nuxtApp.vueApp.use(VueFroala);
});

<template>
  <div
    v-show="show"
    class="z-toast top-0 left-0 flex flex-col gap-20 items-center justify-center font-bold"
    :class="[
      isFullPage ? 'fixed w-[100dvw] h-[100dvh]' : 'absolute w-full h-full',
      hasBackdrop ? 'bg-black/6' : ''
    ]"
    :hasBackdrop="true"
  >
    <div ref="animationContainer" class="w-56 h-56 md:w-72 md:h-72 rounded-full"></div>
  </div>
</template>
<script setup lang="ts">
import lottie from 'lottie-web';
import { onMounted, onUnmounted, ref } from 'vue';

let animation: any = null;
const earthAnimation = ref<any>(null);
const animationContainer = ref<any>(null);

withDefaults(
  defineProps<{
    hasBackdrop?: boolean;
    show: boolean;
    isFullPage?: boolean;
  }>(),
  {
    hasBackdrop: true,
    isFullPage: true
  }
);

onMounted(async () => {
  // Animation Earth
  try {
    earthAnimation.value = await import('@/public/json/loading.json');

    if (earthAnimation.value) {
      animation = lottie.loadAnimation({
        container: animationContainer.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: earthAnimation.value.default
      });
    }
  } catch (error) {}
});
onUnmounted(() => {
  if (animation) {
    animation.destroy();
  }
});
</script>

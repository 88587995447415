import { useRequest } from '@/composables/useRequest';
import { FORMAT_RESPONSE } from '@/constants/common.const';
import { ApiMethod, HeaderTypes } from '@/enums/api.enum';
import type { Confirmation } from '@/enums/common.enum';
import { subscriptionTermsAndConditionMapper } from '@/mappers/terms.mapper';
import type { Pagination } from '@/types/common/pagination.type';
import type { SubscriptionTermsAndConditionModel } from '@/types/terms/terms-model.type';
import type {
  AgreeTermsAndConditionRequest,
  BaseTermRequest,
  RemainingTermsRequest,
  SubscriptionTermsAndConditionRequest,
  TermsAndConditionRequest,
  TermsHistoryRequest
} from '@/types/terms/terms-request.type';
import type {
  DetailContentResponse,
  RemainingTermsResponse,
  RunningTermsAndVersionResponse,
  SubscriptionTermsAndConditionResponse,
  TermDetailResponse,
  TermsHistoryResponse,
  TermTypeVersionResponse
} from '@/types/terms/terms-response.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';

// https://wiki.smilegate.net/pages/viewpage.action?pageId=392235498
export const fetchSubscriptionTermsAndConditionApi = async (
  params: SubscriptionTermsAndConditionRequest
): Promise<SubscriptionTermsAndConditionModel | undefined> => {
  const { data } = await useRequest<
    SubscriptionTermsAndConditionModel,
    SubscriptionTermsAndConditionResponse
  >(
    'tos/v3.0/contents',
    {
      headers: {
        ...generateHeader(HeaderTypes.CallerDetail, HeaderTypes.CallerId)
      },
      params: camelToSnake(params)
    },
    subscriptionTermsAndConditionMapper
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=392235461
export const agreeWithSubscriptionTermsAndConditionApi = async (
  dataRequest: AgreeTermsAndConditionRequest
): Promise<boolean | undefined> => {
  const { data } = await useRequest('stove-terms/v3.0/agree', {
    headers: {
      ...generateHeader(HeaderTypes.CallerDetail, HeaderTypes.CallerId)
    },
    data: camelToSnake(dataRequest),
    method: ApiMethod.Post
  });
  return !!data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=478319831
export const fetchServiceTermsAndConditionHistoryApi = async (
  requestData: TermsHistoryRequest
): Promise<TermsHistoryResponse | undefined> => {
  const { data } = await useRequest<TermsHistoryResponse>('tos/v3.0/history', {
    headers: {
      ...generateHeader(HeaderTypes.CallerDetail, HeaderTypes.CallerId)
    },
    params: camelToSnake(requestData)
  });
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=392238055
export const fetchTermContentApi = async (
  contentNo: number
): Promise<DetailContentResponse | undefined> => {
  const res = await useRequest<DetailContentResponse>(`tos/v3.0/contents/${contentNo}`, {
    headers: {
      ...generateHeader(HeaderTypes.CallerDetail, HeaderTypes.CallerId)
    }
  });

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SDKAPIDOCU&title=WDB03.+studio+%7C+terms+and+conditions#4c99da1d-8287-4a47-83e8-838c208ba27f-449992825
export const fetchTermTypeVersionListApi = async (
  productNo: string,
  termsTypeId: string
): Promise<Pagination<TermTypeVersionResponse[]>> => {
  const { data } = await useRequest<Pagination<TermTypeVersionResponse[]>>(
    `studio/v1/product/${productNo}/terms/version/list?sub_category=${termsTypeId}&page=1&size=9999`
  );

  if (!data) {
    throw new Error('Failed to fetch terns with version list');
  }

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SDKAPIDOCU&title=WDB03.+studio+%7C+terms+and+conditions#4c99da1d-8287-4a47-83e8-838c208ba27f-450843190
export const deleteTermApi = async (productNo: string, versionId: string) => {
  return await useRequest<{ success: boolean }>(
    `studio/v1/product/${productNo}/terms/version/${versionId}`,
    {
      method: ApiMethod.Delete,
      showCommonError: true
    }
  );
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SDKAPIDOCU&title=WDB03.+studio+%7C+terms+and+conditions#4c99da1d-8287-4a47-83e8-838c208ba27f-449992817
export const fetchTermByVersionIdApi = async (
  productNo: string,
  versionId: string
): Promise<TermDetailResponse | undefined> => {
  const res = await useRequest<TermDetailResponse>(
    `studio/v1/product/${productNo}/terms/version/${versionId}`
  );

  if (!res) {
    return;
  }

  return res.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SDKAPIDOCU&title=WDB03.+studio+%7C+terms+and+conditions#4c99da1d-8287-4a47-83e8-838c208ba27f-450837192
export const createTermApi = async ({
  productNo,
  request
}: {
  productNo: string;
  request: TermsAndConditionRequest;
}): Promise<void> => {
  await useRequest(`studio/v1/product/${productNo}/terms`, {
    method: ApiMethod.Post,
    data: camelToSnake(request)
  });
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SDKAPIDOCU&title=WDB03.+studio+%7C+terms+and+conditions#4c99da1d-8287-4a47-83e8-838c208ba27f-450837192
export const updateTermApi = async ({
  productNo,
  request,
  versionId
}: {
  productNo: string;
  request: BaseTermRequest;
  versionId: number;
}): Promise<void> => {
  await useRequest(`studio/v1/product/${productNo}/terms/version/${versionId}`, {
    method: ApiMethod.Post,
    data: camelToSnake(request)
  });
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=381160518
export const fetchRemainingTermsApi = async (
  dataRequest: RemainingTermsRequest
): Promise<RemainingTermsResponse | undefined> => {
  const { data } = await useRequest<RemainingTermsResponse>('stove-terms/v3.0/agree', {
    headers: {
      ...generateHeader(HeaderTypes.CallerDetail, HeaderTypes.CallerId)
    },
    params: camelToSnake(dataRequest),
    method: ApiMethod.Get,
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+terms+and+conditions#5ca892d1-9d80-41cb-930b-73a273848188-456754368
export const fetchRunningTermsAndVersionApi = async ({
  productNo,
  termType
}: {
  productNo: string;
  termType: string;
}): Promise<RunningTermsAndVersionResponse | undefined> => {
  const res = await useRequest<RunningTermsAndVersionResponse>(
    `studio/v1/product/${productNo}/terms/version/list-and-running`,
    {
      method: ApiMethod.Get,
      params: {
        sub_category: termType,
        page: 1,
        size: 9999
      }
    }
  );

  if (!res) {
    return;
  }

  return res.data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+terms+and+conditions#5ca892d1-9d80-41cb-930b-73a273848188-453487676
export const checkTermTypeRegisterApi = async ({
  productNo,
  termTypeId
}: {
  productNo: string;
  termTypeId: string;
}): Promise<{ isRegistered: Confirmation.NO | Confirmation.YES } | undefined> => {
  const res = await useRequest<{ isRegistered: Confirmation.NO | Confirmation.YES }>(
    `studio/v1/product/${productNo}/terms/is-registered?sub_category=${termTypeId}`,
    {
      method: ApiMethod.Get
    }
  );

  return res?.data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+terms+and+conditions#5ca892d1-9d80-41cb-930b-73a273848188-449992813
export const updateTermByVersionApi = async ({
  productNo,
  request
}: {
  productNo: string;
  request: TermsAndConditionRequest;
}): Promise<
  | {
      code: number;
      message: string;
      value?: boolean;
    }
  | undefined
> => {
  const res = await useRequest<{
    code: number;
    message: string;
    value?: boolean;
  }>(`studio/v1/product/${productNo}/terms`, {
    method: ApiMethod.Put,
    data: camelToSnake(request),
    formatResponse: FORMAT_RESPONSE.CUSTOM,
    showCommonError: false
  });

  return res?.data;
};

export const checkTermGuide = async (
  gameId: string
): Promise<{ useYn: Confirmation } | undefined> => {
  const res = await useRequest<{ useYn: Confirmation }>(
    `studio/v1/in-game/purchase/${gameId}/check`
  );

  return res?.data;
};

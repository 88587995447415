import { useNuxtApp } from 'nuxt/app';
import { computed, ref, shallowRef } from 'vue';

import AppConfirmDialog from '@/components/app/dialog/confirm-dialog.vue';
import type { AppConfirmDialogOptions, AppDialog } from '@/types/app.type';

const counter = ref(1);

export const appDialogs = ref<AppDialog[]>([]);
export const activeDialog = computed(() =>
  appDialogs.value.length > 0 ? appDialogs.value[appDialogs.value.length - 1] : null
);
export const hasActiveDialog = computed(() => appDialogs.value.length > 0);

export const activeNotAlertDialog = computed(() => {
  if (appDialogs.value.length <= 0) {
    return null;
  }
  const notAlertDialogs = appDialogs.value.filter((dialog: AppDialog) => !dialog.isOverlayDialog);

  return notAlertDialogs[notAlertDialogs.length - 1];
});
export const showDialog = <T>(dialog: AppDialog): Promise<T> => {
  if (activeDialog.value?.onClose && activeDialog.value?.isOverlayDialog) {
    return activeDialog.value.onClose();
  }
  const cssClasses = [dialog.props?.class];

  const headerProps = (dialog?.props?.pt as any)?.header || { class: [] as string[] };

  if (dialog.isCommonDialog !== false) {
    cssClasses.push('common-dialog');

    switch (dialog.severity) {
      case 'danger':
        headerProps.class.push('bg-danger');
        break;
      case 'warning':
        headerProps.class.push('bg-warning');
        break;
      case 'success':
        headerProps.class.push('bg-success');
        break;
      case 'secondary':
        headerProps.class.push('bg-secondary');
        break;

      default:
        headerProps.class.push('bg-info');
        break;
    }
  }

  const props = {
    ...dialog.props,
    pt: { header: headerProps },
    class: cssClasses,
    closable: dialog.closable !== false
  };

  return new Promise((resolve: (value: any) => void) => {
    appDialogs.value.push({
      ...dialog,
      props,
      id: ++counter.value,
      onClose: resolve
    });
  });
};

export const showAlert = <T>(options: AppConfirmDialogOptions): Promise<T> => {
  return showDialog({
    props: {
      severity: 'info',
      pt: { header: { class: 'bg-white' } },
      ...options
    },
    isCommonDialog: false,
    isOverlayDialog: true,
    closable: false,
    component: shallowRef(AppConfirmDialog)
  });
};

export const showConfirm = <T>(options: AppConfirmDialogOptions): Promise<T> => {
  return showAlert({
    isCancelButtonVisible: true,
    ...options
  });
};

export const closeDialog = (dialog: AppDialog, payload: any) => {
  const [removedDialog]: AppDialog[] = appDialogs.value.splice(
    appDialogs.value.findIndex(({ id }: AppDialog) => id === dialog.id),
    1
  );

  if (!(removedDialog && dialog.onClose)) {
    return;
  }

  dialog.onClose(payload);
};

export const closeLatestDialog = (payload?: any) => {
  if (appDialogs.value.length <= 0) {
    return;
  }

  closeDialog(appDialogs.value[appDialogs.value.length - 1], payload);
};

export const closeAllDialogs = (payload?: any) => {
  if (appDialogs.value.length <= 0) {
    return;
  }

  appDialogs.value.forEach((dialog: AppDialog) => {
    closeDialog(dialog, payload);
  });
};

export const showMaxLengthFileNameDialog = async () => {
  const { t } = useNuxtApp().$i18n as any;
  return await showAlert({
    content: t('studio.file_upload.name_max_length_exceed_msg')
  });
};

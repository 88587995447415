// PRODUCT_PAGE_MANAGEMENT_ROLES
export const RELEASE_PLAN = 'RELEASE_PLAN';
export const PRODUCT_SETTING = 'PRODUCT_SETTING';
export const PRODUCT_STORE_PAGE = 'PRODUCT_STORE_PAGE';
export const PRODUCT_ALWAYS_ON_SALE_PRICE = 'PRODUCT_ALWAYS_ON_SALE_PRICE';
export const PRODUCT_ALWAYS_ON_SALE_DISCOUNT = 'PRODUCT_ALWAYS_ON_SALE_DISCOUNT';
export const PRODUCT_SALE_CONDITION = 'PRODUCT_SALE_CONDITION';
export const PRODUCT_EARLY_ACCESS_TEXT_SETTING = 'PRODUCT_EARLY_ACCESS_TEXT_SETTING';
export const PRODUCT_TERMS = 'PRODUCT_TERMS';
export const PRODUCT_BILL_SETTLEMENT = 'PRODUCT_BILL_SETTLEMENT';

// PRODUCT_DATA_MANAGEMENT_ROLES
export const PRODUCT_BUILD_MANAGEMENT = 'PRODUCT_BUILD_MANAGEMENT';
export const PRODUCT_RUN_OPTION = 'PRODUCT_RUN_OPTION';
export const PRODUCT_RATING_BUILD_VERIFICATION = 'PRODUCT_RATING_BUILD_VERIFICATION';
export const PRODUCT_STAT = 'PRODUCT_STAT';
export const PRODUCT_RANKING = 'PRODUCT_RANKING';
export const PRODUCT_ACHIEVEMENT = 'PRODUCT_ACHIEVEMENT';
export const PRODUCT_CLOUD_SAVING = 'PRODUCT_CLOUD_SAVING';
export const PRODUCT_MATCH_MAKING = 'PRODUCT_MATCH_MAKING';

// PRODUCT_MARKETING_MANAGEMENT_ROLES
export const PRODUCT_COUPON = 'PRODUCT_COUPON';
export const PRODUCT_EVENT = 'PRODUCT_EVENT';
export const PRODUCT_STATISTICS = 'PRODUCT_STATISTICS';

// PRODUCT_INGAME_MANAGEMENT_ROLES
export const INGAME_PURCHASE_SETTING = 'INGAME_PURCHASE_SETTING';
export const INGAME_SHOP = 'INGAME_SHOP';
export const INGAME_PRODUCT = 'INGAME_PRODUCT';
export const INGAME_DISCOUNT_PRODUCT = 'INGAME_DISCOUNT_PRODUCT';
export const INGAME_PURCHASE_HISTORY = 'INGAME_PURCHASE_HISTORY';

export const BANNER_IMAGE = 'BANNER_IMAGE';

export const DEFAULT = 'DEFAULT';

export const PRODUCT_INGAME_MANAGEMENT_ROLES = [
  INGAME_PURCHASE_SETTING,
  INGAME_SHOP,
  INGAME_PRODUCT,
  INGAME_DISCOUNT_PRODUCT,
  INGAME_PURCHASE_HISTORY
];

export const PRODUCT_PAGE_MANAGEMENT_ROLES = [
  PRODUCT_SETTING,
  PRODUCT_STORE_PAGE,
  PRODUCT_ALWAYS_ON_SALE_DISCOUNT,
  PRODUCT_SALE_CONDITION,
  PRODUCT_ALWAYS_ON_SALE_PRICE,
  PRODUCT_EARLY_ACCESS_TEXT_SETTING
];

export const PRODUCT_DATA_MANAGEMENT_ROLES = [
  PRODUCT_BUILD_MANAGEMENT,
  PRODUCT_RUN_OPTION,
  PRODUCT_RATING_BUILD_VERIFICATION,
  PRODUCT_STAT,
  PRODUCT_RANKING,
  PRODUCT_ACHIEVEMENT,
  PRODUCT_CLOUD_SAVING,
  PRODUCT_MATCH_MAKING,
  PRODUCT_TERMS
];

export const PRODUCT_MARKETING_MANAGEMENT_ROLES = [
  BANNER_IMAGE,
  PRODUCT_STATISTICS,
  PRODUCT_BILL_SETTLEMENT
];

export const PRODUCT_PAGE_MANAGEMENT = 'PRODUCT_PAGE_MANAGEMENT';
export const PRODUCT_DATA_MANAGEMENT = 'PRODUCT_DATA_MANAGEMENT';
export const PRODUCT_MARKETING_MANAGEMENT = 'PRODUCT_MARKETING_MANAGEMENT';
export const PRODUCT_INGAME_MANAGEMENT = 'PRODUCT_INGAME_MANAGEMENT';

export const DEFAULT_ROLE = [
  {
    name: RELEASE_PLAN,
    viewable: false,
    editable: false,
    childRoles: []
  },
  {
    name: PRODUCT_PAGE_MANAGEMENT,
    viewable: false,
    editable: false,
    childRoles: [
      {
        roleAuthorityId: PRODUCT_SETTING,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_STORE_PAGE,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_ALWAYS_ON_SALE_PRICE,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_ALWAYS_ON_SALE_DISCOUNT,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_SALE_CONDITION,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_EARLY_ACCESS_TEXT_SETTING,
        viewable: false,
        editable: false
      }
    ]
  },
  {
    name: PRODUCT_DATA_MANAGEMENT,
    viewable: false,
    editable: false,
    childRoles: [
      {
        roleAuthorityId: PRODUCT_BUILD_MANAGEMENT,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_RUN_OPTION,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_RATING_BUILD_VERIFICATION,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_TERMS,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_STAT,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_RANKING,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_ACHIEVEMENT,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_CLOUD_SAVING,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: PRODUCT_MATCH_MAKING,
        viewable: false,
        editable: false
      }
    ]
  },
  {
    name: PRODUCT_INGAME_MANAGEMENT,
    viewable: false,
    editable: false,
    childRoles: [
      {
        roleAuthorityId: INGAME_PURCHASE_SETTING,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: INGAME_SHOP,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: INGAME_PRODUCT,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: INGAME_DISCOUNT_PRODUCT,
        viewable: false,
        editable: false
      },
      {
        roleAuthorityId: INGAME_PURCHASE_HISTORY,
        viewable: false,
        editable: false
      }
    ]
  },
  {
    name: PRODUCT_MARKETING_MANAGEMENT,
    viewable: false,
    editable: false,
    childRoles: [
      {
        roleAuthorityId: BANNER_IMAGE,
        viewable: false,
        editable: false
      }
    ]
  }
];

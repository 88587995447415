<template>
  <!-- eslint-disable-next-line vue/no-v-html, vue/no-v-text-v-html-on-component -->
  <component :is="tag" v-html="cleanHtml" />
</template>

<script setup lang="ts">
import dompurify from 'dompurify';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    html: string;
    tag?: string;
    options?: any; // https://www.npmjs.com/package/dompurify#general-settings
  }>(),
  {
    tag: 'span',
    options: () => ({})
  }
);

const sanitize = (str: string, options?: any) => {
  return dompurify.sanitize(str, { ...options, ADD_ATTR: ['target'], ADD_TAGS: ['iframe'] });
};

const cleanHtml = computed(() => sanitize(props.html, props.options));
</script>

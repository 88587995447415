import type { LocaleObjectModel } from '@/types/locale.type';

export const DEFAULT_LOCALE: string = 'ko';
export const DEFAULT_LOCALE_UPPERCASE: string = 'KO';

export const LANG_DIR: string = './i18n/default';

export const DEFAULT_LOCALES: Record<string, LocaleObjectModel> = {
  en: { code: 'en', name: 'English (US)', iso: 'en-US', timezone: 'UTC' },
  ko: { code: 'ko', name: '한국어', iso: 'ko-KR', timezone: 'Asia/Seoul' },
  vi: { code: 'vi', name: 'Tiếng Việt', iso: 'vi-VN', timezone: 'Asia/Ho_Chi_Minh' },
  de: { code: 'de', name: 'Deutsch', iso: 'de', timezone: 'Europe/Berlin' },
  pt: { code: 'pt', name: 'Português (BR)', iso: 'pt', timezone: 'America/Sao_Paulo' },
  th: { code: 'th', name: 'ภาษาไทย', iso: 'th', timezone: 'Asia/Bangkok' },
  'zh-tw': { code: 'zh-tw', name: '中文 (繁體)', iso: 'zh-tw', timezone: 'Asia/Taipei' },
  ja: { code: 'ja', name: '日本語', iso: 'ja', timezone: 'Asia/Tokyo' },
  it: { code: 'it', name: 'Italiano', iso: 'it', timezone: 'Europe/Rome' },
  fr: { code: 'fr', name: 'Français', iso: 'fr', timezone: 'Europe/Paris' },
  es: { code: 'es', name: 'Español (LA)', iso: 'es', timezone: 'America/Mexico_City' },
  ru: { code: 'ru', name: 'Russian - русский', iso: 'ru', timezone: 'Europe/Moscow' },
  'zh-cn': {
    code: 'zh-cn',
    name: 'Chinese (Traditional) - 中文（繁體)',
    iso: 'zh-cn',
    timezone: 'Asia/Shanghai'
  },
  id: { code: 'id', name: 'Indonesian - Indonesia', iso: 'id', timezone: 'Asia/Jakarta' }
};

// export const DEFAULT_COVERAGES: string[] = getAllLocale();
export const DEFAULT_COVERAGES: string[] = ['en', 'zh-cn', 'zh-tw', 'ja', 'ko'];

export const LOCALE_TIMEOUT_DELAY: number = 2000;

import { defineNuxtPlugin } from 'nuxt/app';
import { defineRule, ErrorMessage, Field, FieldArray, Form } from 'vee-validate';
import type { App } from 'vue';

import { RuleNames } from '@/enums/validation.enum';
import {
  asyncRule,
  betweenRule,
  checkDuplicate,
  checkMultiplyByTen,
  dimensionRule,
  emailRule,
  equalRule,
  extensionRule,
  fileCountRule,
  gteRule,
  gtRule,
  lessThanCurrentTimeRule,
  likeRule,
  lteRule,
  ltRule,
  maxCharCountRule,
  maxDateRule,
  maxLengthRule,
  maxSizeRule,
  minDateRule,
  minLengthRule,
  noSpaceRule,
  noSpecialCharacterRule,
  notEqualRule,
  numberRule,
  regexRule,
  requiredRule,
  urlRule
} from '@/utils/validation.util';

export default defineNuxtPlugin(({ vueApp }: { vueApp: App }) => {
  vueApp.component('VField', Field);
  vueApp.component('VForm', Form);
  vueApp.component('VFieldArray', FieldArray);
  vueApp.component('ErrorMessage', ErrorMessage);

  defineRule(RuleNames.URL, urlRule);
  defineRule(RuleNames.REQUIRED, requiredRule);
  defineRule(RuleNames.EMAIL, emailRule);
  defineRule(RuleNames.NO_SP_CHARACTER, noSpecialCharacterRule);
  defineRule(RuleNames.NUMBER, numberRule);
  defineRule(RuleNames.NO_SPACE, noSpaceRule);
  defineRule(RuleNames.BETWEEN, betweenRule);
  defineRule(RuleNames.MIN_LENGTH, minLengthRule);
  defineRule(RuleNames.MAX_LENGTH, maxLengthRule);
  defineRule(RuleNames.MAX_CHAR_COUNT, maxCharCountRule);
  defineRule(RuleNames.LIKE, likeRule);
  defineRule(RuleNames.LTE, lteRule);
  defineRule(RuleNames.LT, ltRule);
  defineRule(RuleNames.GTE, gteRule);
  defineRule(RuleNames.GT, gtRule);
  defineRule(RuleNames.EQUAL, equalRule);
  defineRule(RuleNames.NOT_EQUAL, notEqualRule);
  defineRule(RuleNames.MIN_DATE, minDateRule);
  defineRule(RuleNames.MAX_DATE, maxDateRule);
  defineRule(RuleNames.REGEX, regexRule);
  defineRule(RuleNames.MAX_SIZE, maxSizeRule);
  defineRule(RuleNames.EXTENSION, extensionRule);
  defineRule(RuleNames.FILE_COUNT, fileCountRule);
  defineRule(RuleNames.DIMENSION, dimensionRule);
  defineRule(RuleNames.ASYNC_RULE, asyncRule);
  defineRule(RuleNames.CHECK_DUPLICATE, checkDuplicate);
  defineRule(RuleNames.MULTIPLY_BY_TEN, checkMultiplyByTen);
  defineRule(RuleNames.LESS_THAN_CURRENT_TIME, lessThanCurrentTimeRule);
});

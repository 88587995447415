import { LanguageCode } from '@/enums/language-code.enum';

/**
 * Tutorial video URLs for the intro video
 */
export const INTRO_VIDEO_URLS: Record<string, string> = {
  [LanguageCode.Ko]:
    'https://d2x8kymwjom7h7.cloudfront.net/live/application_no/STATIC/indie-studio/mp4-tutorial/%EC%8A%A4%ED%86%A0%EB%B8%8C%20%EA%B3%A0%EB%8F%84%ED%99%94_1%ED%99%94_%EC%9D%B8%ED%8A%B8%EB%A1%9C_250306.mp4',
  [LanguageCode.En]:
    'https://d2x8kymwjom7h7.cloudfront.net/live/application_no/STATIC/indie-studio/mp4-tutorial/STOVE_%EC%8A%A4%ED%8A%9C%EB%94%94%EC%98%A4%20%EA%B8%B0%EB%8A%A5%20%EA%B0%80%EC%9D%B4%EB%93%9C%201%ED%99%94_EN%20v3.mp4',
  [LanguageCode.Ja]:
    'https://d2x8kymwjom7h7.cloudfront.net/live/application_no/STATIC/indie-studio/mp4-tutorial/STOVE_%EC%8A%A4%ED%8A%9C%EB%94%94%EC%98%A4%20%EA%B8%B0%EB%8A%A5%20%EA%B0%80%EC%9D%B4%EB%93%9C%201%ED%99%94_EN%20v3.mp4',
  [LanguageCode.ZhTw]:
    'https://d2x8kymwjom7h7.cloudfront.net/live/application_no/STATIC/indie-studio/mp4-tutorial/STOVE_%EC%8A%A4%ED%8A%9C%EB%94%94%EC%98%A4%20%EA%B8%B0%EB%8A%A5%20%EA%B0%80%EC%9D%B4%EB%93%9C%201%ED%99%94_EN%20v3.mp4',
  [LanguageCode.ZhCn]:
    'https://d2x8kymwjom7h7.cloudfront.net/live/application_no/STATIC/indie-studio/mp4-tutorial/STOVE_%EC%8A%A4%ED%8A%9C%EB%94%94%EC%98%A4%20%EA%B8%B0%EB%8A%A5%20%EA%B0%80%EC%9D%B4%EB%93%9C%201%ED%99%94_EN%20v3.mp4'
};

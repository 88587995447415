<template>
  <footer class="w-full min-h-[6.6rem] px-24 pt-24 pb-20 max-w-[136rem] mx-auto">
    <div id="footer-wrapper" class="flex flex-col md:flex-row items-start md:items-center gap-24">
      <p class="text-sm leading-md text-on-surface-elevation-4">
        © Smilegate. All Rights Reserved.
      </p>
      <ul class="flex items-center gap-20 shrink-0 md:ml-auto">
        <li>
          <a
            href="/terms/detail"
            target="_blank"
            class="text-sm leading-md text-on-surface-elevation-4"
          >{{ $t('studio.common.footer.stu_usage_term') }}</a>
        </li>
        <li>
          <a
            :href="`${TERM_URL}/terms?category=service`"
            target="_blank"
            title="새창으로 열림"
            class="text-sm leading-md text-on-surface-elevation-4"
          >
            {{ $t('studio.common.footer.stove_usage_term') }}
          </a>
        </li>
        <li>
          <a
            :href="`${TERM_URL}/terms?category=privacy`"
            target="_blank"
            title="새창으로 열림"
            class="text-sm font-medium leading-md text-on-surface-elevation-2"
          >
            {{ $t('studio.common.footer.stu_personal_info_protection') }}
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { getConfigs } from '@/utils/common.util';

const { TERM_URL } = getConfigs();
</script>

import type { CommonHeaderModel } from 'seed-core';

import { useCookieWithDomain } from '@/composables/useCookieWithDomain';
import {
  COOKIE_KEYS,
  DEFAULT_COUNTRY,
  DEFAULT_LANGUAGE,
  DEFAULT_TIMEZONE,
  DEFAULT_UTC_OFFSET
} from '@/constants/cookie.const';
import { DEFAULT_COVERAGES } from '@/constants/locale.const';

export interface GDSInfo extends CommonHeaderModel {
  locale: string;
  uuid: string;
}

export const getGDSInfo = (): GDSInfo => {
  const path = window.location.pathname;
  const urlLocale = DEFAULT_COVERAGES.find((v: string) => v === path.split('/')[1]);
  const browserLocale = navigator.language.toLowerCase();
  const localeCookie = useCookieWithDomain(COOKIE_KEYS.LOCALE || '');
  const locale =
    urlLocale || localeCookie.value?.toLowerCase() || browserLocale || DEFAULT_LANGUAGE;

  return {
    nation: useCookieWithDomain(COOKIE_KEYS.NNTO || '').value || DEFAULT_COUNTRY,
    timezone: useCookieWithDomain(COOKIE_KEYS.TIMEZONE || '').value || DEFAULT_TIMEZONE,
    utcOffset:
      useCookieWithDomain(COOKIE_KEYS.UTC_OFFSET || '').value || DEFAULT_UTC_OFFSET.toString(),
    lang: locale,
    locale,
    uuid: useCookieWithDomain(COOKIE_KEYS.UUID || '').value || ''
  };
};
